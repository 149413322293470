import React, { useState } from 'react';
import { Box, Container, Tabs, Tab, Paper } from '@mui/material';
import { PageHeader, DescriptiveText } from '../components/ui/typography';
import GroupsSection from '../components/community/GroupsSection';
import { cardStyles } from '../styles/theme/components/cards';

const Groups = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [myGroupsTab, setMyGroupsTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleMyGroupsTabChange = (event, newValue) => {
        setMyGroupsTab(newValue);
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            {/* Hero Section */}
            <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (--tablet)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <PageHeader 
                        sx={{ 
                            mb: 'var(--spacing-2)',
                        }}
                    >
                        Groups
                    </PageHeader>
                    <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                        Join and create groups to connect with families who share your interests and goals.
                    </DescriptiveText>
                </Container>
            </Box>

            {/* Main Content */}
            <Container 
                maxWidth="var(--container-max-width)"
                sx={{ 
                    px: 'var(--container-padding-x)',
                    py: 'var(--spacing-6)',
                    '@media (--tablet)': {
                        px: 'var(--container-padding-x-mobile)',
                    },
                }}
            >
                <Paper 
                    elevation={0}
                    sx={{
                        ...cardStyles.feature,
                        mb: 'var(--spacing-4)',
                    }}
                >
                    <Tabs 
                        value={currentTab} 
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTab-root': {
                                textTransform: 'none',
                                fontSize: 'var(--font-size-base)',
                                fontWeight: 500,
                                color: 'hsl(var(--text-secondary))',
                                '&.Mui-selected': {
                                    color: 'hsl(var(--brand-primary))',
                                    fontWeight: 600
                                }
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'hsl(var(--brand-primary))'
                            }
                        }}
                    >
                        <Tab label="Explore Groups" />
                        <Tab label="My Groups" />
                    </Tabs>
                </Paper>

                {currentTab === 1 && (
                    <Paper 
                        elevation={0}
                        sx={{
                            ...cardStyles.feature,
                            mb: 'var(--spacing-4)',
                        }}
                    >
                        <Tabs 
                            value={myGroupsTab} 
                            onChange={handleMyGroupsTabChange}
                            sx={{
                                '& .MuiTab-root': {
                                    textTransform: 'none',
                                    fontSize: 'var(--font-size-sm)',
                                    fontWeight: 500,
                                    color: 'hsl(var(--text-secondary))',
                                    '&.Mui-selected': {
                                        color: 'hsl(var(--brand-primary))',
                                        fontWeight: 600
                                    }
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'hsl(var(--brand-primary))'
                                }
                            }}
                        >
                            <Tab label="Groups You're In" />
                            <Tab label="Pending Invites" />
                            <Tab label="Groups I Made" />
                        </Tabs>
                    </Paper>
                )}

                <GroupsSection 
                    view={currentTab === 0 ? 'explore' : 'my-groups'} 
                    myGroupsView={myGroupsTab === 0 ? 'joined' : myGroupsTab === 1 ? 'pending' : 'created'} 
                />
            </Container>
        </Box>
    );
};

export default Groups; 