import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
    Container, 
    Box,
    List,
    ListItem,
    ListItemText,
    Link as MuiLink,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageHeader, SectionHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography';

const StateFundingOpportunities = () => {
    const navigate = useNavigate();
    const [userState, setUserState] = useState('California');
    const [opportunities, setOpportunities] = useState([]);
    const [loading, setLoading] = useState(true);

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    useEffect(() => {
        // This will be replaced with actual API call to fetch state funding opportunities
        setLoading(true);
        if (userState === 'California') {
            setOpportunities([]);
            setLoading(false);
        }
    }, [userState]);

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
            {/* Back Button Section */}
            <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2, mb: 3 }}>
                <Container maxWidth="lg">
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate('/compliance')}
                        sx={{
                            color: '#00356b',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 53, 107, 0.08)',
                            },
                        }}
                    >
                        Back to Compliance
                    </Button>
                </Container>
            </Box>
            {/* Hero Section */}
            <Box 
                sx={{ 
                    position: 'relative',
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
                        backgroundSize: '20px 20px',
                        opacity: 0.1,
                    },
                    pt: 'var(--spacing-8)',
                    pb: 'var(--spacing-6)',
                }}
            >
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (max-width: 768px)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <Box>
                        <PageHeader>State Funding Opportunities</PageHeader>
                        <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                            Explore available funding and financial assistance for homeschooling in your state.
                        </DescriptiveText>
                    </Box>
                </Container>
            </Box>

            {/* Main Content */}
            <Container 
                maxWidth="var(--container-max-width)"
                sx={{ 
                    py: 'var(--spacing-8)',
                    px: 'var(--container-padding-x)',
                    '@media (max-width: 768px)': {
                        px: 'var(--container-padding-x-mobile)',
                    },
                }}
            >
                <Box 
                    component="section"
                    sx={{ 
                        p: 'var(--spacing-4)', 
                        mb: 'var(--spacing-6)', 
                        backgroundColor: 'hsl(var(--muted))',
                        border: '1px solid hsl(var(--border))',
                        borderRadius: 'var(--radius-lg)'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="state-select-label">Select State</InputLabel>
                            <Select
                                labelId="state-select-label"
                                value={userState}
                                label="Select State"
                                onChange={(e) => setUserState(e.target.value)}
                                sx={{
                                    backgroundColor: 'hsl(var(--background))',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--border))',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                }}
                            >
                                {states.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <BodyText>View funding opportunities for your state</BodyText>
                    </Box>
                </Box>

                <Box 
                    component="section"
                    sx={{ 
                        p: 'var(--spacing-4)', 
                        backgroundColor: 'hsl(var(--muted))',
                        border: '1px solid hsl(var(--border))',
                        borderRadius: 'var(--radius-lg)'
                    }}
                >
                    {loading ? (
                        <SupportingText>Loading funding opportunities...</SupportingText>
                    ) : opportunities.length > 0 ? (
                        <>
                            <BodyText sx={{ mb: 'var(--spacing-4)' }}>
                                Below are the current funding opportunities available for homeschooling families in {userState}:
                            </BodyText>
                            <List sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-3)' }}>
                                {opportunities.map((opportunity, index) => (
                                    <ListItem 
                                        key={index} 
                                        sx={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            p: 'var(--spacing-4)',
                                            backgroundColor: 'hsl(var(--background))',
                                            border: '1px solid hsl(var(--border))',
                                            borderRadius: 'var(--radius-lg)'
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <SectionHeader sx={{ fontSize: '1.1rem', mb: 'var(--spacing-2)' }}>
                                                    {opportunity.title}
                                                </SectionHeader>
                                            }
                                            secondary={
                                                <>
                                                    <BodyText sx={{ mb: 'var(--spacing-2)' }}>
                                                        {opportunity.description}
                                                    </BodyText>
                                                    {opportunity.link && (
                                                        <MuiLink 
                                                            href={opportunity.link} 
                                                            target="_blank" 
                                                            rel="noopener noreferrer"
                                                            sx={{ 
                                                                color: 'hsl(var(--brand-primary))',
                                                                '&:hover': {
                                                                    color: 'hsl(var(--brand-primary-dark))'
                                                                }
                                                            }}
                                                        >
                                                            Learn More
                                                        </MuiLink>
                                                    )}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <BodyText>
                            There are currently no direct state funding opportunities for homeschooling available in {userState}.
                        </BodyText>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default StateFundingOpportunities; 