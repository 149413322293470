import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Button } from '@mui/material';
import { BsBuilding, BsBook, BsLaptop, BsBag, BsMusicNote } from 'react-icons/bs';
import { PageHeader, SectionHeader, DescriptiveText, SupportingText } from '../components/ui/typography';
import { cardStyles } from '../styles/theme/components/cards';

const Marketplace = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  
  // Mock data for education providers and perks
  const providers = [
    {
      id: 1,
      name: "Math-U-See",
      icon: <BsBook />,
      description: "Complete math curriculum with manipulatives and video instruction",
      discount: "15% off all curriculum packages",
      category: "Education"
    },
    {
      id: 2,
      name: "Discovery Education",
      icon: <BsBook />,
      description: "Digital learning platform with interactive science resources",
      discount: "20% off annual subscriptions",
      category: "Education"
    },
    {
      id: 3,
      name: "Apple Store",
      icon: <BsLaptop />,
      description: "Special education pricing on MacBooks and iPads",
      discount: "Up to $200 off + free AirPods",
      category: "Technology"
    },
    {
      id: 4,
      name: "Microsoft Office",
      icon: <BsLaptop />,
      description: "Microsoft 365 Education for students and teachers",
      discount: "Free access to Microsoft 365",
      category: "Technology"
    },
    {
      id: 5,
      name: "Barnes & Noble",
      icon: <BsBook />,
      description: "Books and educational materials",
      discount: "15% off for students",
      category: "Books"
    },
    {
      id: 6,
      name: "Staples",
      icon: <BsBag />,
      description: "School and office supplies",
      discount: "20% off school supplies",
      category: "Supplies"
    },
    {
      id: 7,
      name: "Adobe Creative Cloud",
      icon: <BsLaptop />,
      description: "Creative software suite for students",
      discount: "60% off Creative Cloud",
      category: "Technology"
    },
    {
      id: 8,
      name: "Spotify Premium",
      icon: <BsMusicNote />,
      description: "Music streaming service",
      discount: "50% off Premium subscription",
      category: "Entertainment"
    }
  ];

  const categories = [
    { id: 'all', label: 'All Perks' },
    { id: 'Education', label: 'Education' },
    { id: 'Technology', label: 'Technology' },
    { id: 'Books', label: 'Books' },
    { id: 'Supplies', label: 'Supplies' },
    { id: 'Entertainment', label: 'Entertainment' }
  ];

  const filteredProviders = providers.filter(provider =>
    (selectedCategory === 'all' || provider.category === selectedCategory) &&
    (provider.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    provider.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    provider.category.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            YourEDU Perks
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Exclusive discounts and offers for YourEDU members
          </DescriptiveText>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mt: 'var(--spacing-4)'
          }}>
            <SupportingText>Want to get listed here?</SupportingText>
            <Button 
              variant="contained"
              onClick={() => window.location.href = 'mailto:partners@youredu.com'}
              sx={{
                backgroundColor: 'hsl(var(--brand-primary))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--brand-primary-dark))'
                }
              }}
            >
              Partner With Us
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container sx={{ 
        maxWidth: 'var(--container-max-width) !important',
        py: 'var(--spacing-6)',
        px: 'var(--container-padding-x)'
      }}>
        {/* Category Filter */}
        <Box sx={{ 
          display: 'flex',
          gap: 'var(--spacing-2)',
          mb: 'var(--spacing-6)',
          flexWrap: 'wrap'
        }}>
          {categories.map(category => (
            <Button
              key={category.id}
              variant={selectedCategory === category.id ? "contained" : "outlined"}
              onClick={() => setSelectedCategory(category.id)}
              sx={{
                borderRadius: 'var(--radius-full)',
                py: 'var(--spacing-1)',
                px: 'var(--spacing-3)',
                backgroundColor: selectedCategory === category.id ? 
                  'hsla(var(--brand-primary), 0.12)' : 'transparent',
                color: selectedCategory === category.id ? 
                  'hsl(var(--brand-primary))' : 'hsl(var(--text-secondary))',
                borderColor: selectedCategory === category.id ? 
                  'hsl(var(--brand-primary))' : 'hsl(var(--border-default))',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: selectedCategory === category.id ? 
                    'hsla(var(--brand-primary), 0.18)' : 'hsla(var(--brand-primary), 0.06)',
                  transform: 'translateY(-1px)'
                }
              }}
            >
              {category.label}
            </Button>
          ))}
        </Box>

        {/* Search Bar */}
        <Box sx={{ mb: 'var(--spacing-6)' }}>
          <input
            type="text"
            placeholder="Search perks and providers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: '100%',
              padding: 'var(--spacing-3) var(--spacing-4)',
              fontSize: '15px',
              border: '1px solid hsl(var(--border-default))',
              borderRadius: 'var(--radius-lg)',
              boxShadow: 'var(--shadow-sm)',
              color: 'hsl(var(--text-primary))',
              '&::placeholder': {
                color: 'hsl(var(--text-tertiary))'
              }
            }}
          />
        </Box>

        {/* Provider Grid */}
        <Grid container spacing={3}>
          {filteredProviders.map(provider => (
            <Grid item xs={12} sm={6} md={4} key={provider.id}>
              <Box
                sx={{
                  ...cardStyles.feature,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 'var(--spacing-3)',
                  position: 'relative',
                  p: 'var(--spacing-4)',
                  height: '100%',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 12px 24px -10px hsla(var(--shadow-color), 0.15)',
                    '& .icon-container': {
                      transform: 'scale(1.1)',
                      backgroundColor: 'hsla(var(--brand-primary), 0.12)'
                    },
                    '& .learn-more': {
                      backgroundColor: 'hsla(var(--brand-primary), 0.12)',
                      borderColor: 'hsl(var(--brand-primary))'
                    }
                  }
                }}
                onClick={() => navigate(`/marketplace/${provider.id}`)}
              >
                {/* Category Tag */}
                <Box sx={{
                  position: 'absolute',
                  top: 'var(--spacing-3)',
                  right: 'var(--spacing-3)',
                  padding: 'var(--spacing-1) var(--spacing-2)',
                  backgroundColor: 'hsla(var(--brand-primary), 0.06)',
                  borderRadius: 'var(--radius-full)',
                  fontSize: '12px',
                  color: 'hsl(var(--brand-primary))',
                  border: '1px solid hsla(var(--brand-primary), 0.12)',
                  fontWeight: 500
                }}>
                  {provider.category}
                </Box>

                {/* Icon */}
                <Box 
                  className="icon-container"
                  sx={{
                    width: '56px',
                    height: '56px',
                    borderRadius: 'var(--radius-lg)',
                    backgroundColor: 'hsla(var(--brand-primary), 0.06)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '28px',
                    color: 'hsl(var(--brand-primary))',
                    transition: 'all 0.2s ease'
                  }}
                >
                  {provider.icon}
                </Box>

                {/* Title */}
                <SectionHeader sx={{ 
                  fontSize: '18px',
                  textAlign: 'center',
                  margin: 0,
                  color: 'hsl(var(--text-primary))'
                }}>
                  {provider.name}
                </SectionHeader>

                {/* Description */}
                <DescriptiveText sx={{
                  fontSize: '14px',
                  textAlign: 'center',
                  margin: 0,
                  lineHeight: 1.5,
                  flex: 1,
                  color: 'hsl(var(--text-secondary))'
                }}>
                  {provider.description}
                </DescriptiveText>

                {/* Discount Badge */}
                <Box sx={{
                  backgroundColor: 'hsla(var(--brand-primary), 0.06)',
                  color: 'hsl(var(--brand-primary))',
                  padding: 'var(--spacing-1) var(--spacing-3)',
                  borderRadius: 'var(--radius-full)',
                  fontSize: '13px',
                  fontWeight: 500,
                  border: '1px solid hsla(var(--brand-primary), 0.12)',
                  textAlign: 'center'
                }}>
                  {provider.discount}
                </Box>

                {/* Learn More Button */}
                <Button
                  className="learn-more"
                  variant="outlined"
                  sx={{
                    mt: 'var(--spacing-2)',
                    color: 'hsl(var(--brand-primary))',
                    borderColor: 'hsl(var(--border-default))',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: 'hsla(var(--brand-primary), 0.12)',
                      borderColor: 'hsl(var(--brand-primary))'
                    }
                  }}
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Marketplace; 