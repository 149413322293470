import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AuthProvider } from './utils/AuthContext'
import '@fontsource/ibm-plex-sans/400.css' // Regular
import '@fontsource/ibm-plex-sans/500.css' // Medium
import '@fontsource/ibm-plex-sans/600.css' // Semi-bold
import '@fontsource/ibm-plex-sans/700.css' // Bold
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)
