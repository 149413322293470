import React, { useEffect, useState } from 'react'
import { useAuth } from '../utils/AuthContext'
import { supabase } from '../config/supabase'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Menu,
  DialogContentText,
} from '@mui/material'
import { Add as AddIcon, ExpandMore as ExpandMoreIcon, MoreVert as MoreVertIcon } from '@mui/icons-material'
import { toast } from 'react-toastify'
import {
  PageHeader,
  SectionHeader,
  FeatureHeader,
  DescriptiveText,
  BodyText,
  SupportingText,
} from '../components/ui/typography'

const CreateCourseModal = ({
  isOpen,
  onClose,
  newCourse,
  setNewCourse,
  onCreateCourse,
  termOptions,
  durationOptions,
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      <FeatureHeader>Create New Course</FeatureHeader>
    </DialogTitle>
    <DialogContent sx={{ pt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Course Title"
          fullWidth
          value={newCourse.title}
          onChange={(e) => setNewCourse((prev) => ({ ...prev, title: e.target.value }))}
          required
        />
        <TextField
          label="Year"
          type="number"
          fullWidth
          value={newCourse.year}
          onChange={(e) => setNewCourse((prev) => ({ ...prev, year: parseInt(e.target.value) }))}
          required
        />
        <FormControl fullWidth required>
          <InputLabel>Term</InputLabel>
          <Select
            value={newCourse.term_start}
            label="Term"
            onChange={(e) => setNewCourse((prev) => ({ ...prev, term_start: e.target.value }))}
          >
            {termOptions.map((term) => (
              <MenuItem key={term} value={term}>
                {term}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel>Term Duration</InputLabel>
          <Select
            value={newCourse.term_duration}
            label="Term Duration"
            onChange={(e) => setNewCourse((prev) => ({ ...prev, term_duration: e.target.value }))}
          >
            {durationOptions.map((duration) => (
              <MenuItem key={duration} value={duration}>
                {duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button onClick={onClose} sx={{ color: 'hsl(var(--text-primary))' }}>
        Cancel
      </Button>
      <Button
        onClick={onCreateCourse}
        variant="contained"
        sx={{
          backgroundColor: 'hsl(var(--brand-primary))',
          color: 'hsl(var(--background))',
          '&:hover': {
            backgroundColor: 'hsl(var(--brand-primary-dark))',
          },
        }}
      >
        Create Course
      </Button>
    </DialogActions>
  </Dialog>
)

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, courseTitle }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <FeatureHeader>Delete Course</FeatureHeader>
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ mb: 2 }}>
        Are you sure you want to delete "{courseTitle}"? This action cannot be undone.
      </DialogContentText>
      <DialogContentText sx={{ color: 'hsl(var(--destructive))' }}>
        Warning: This will also delete all associated files, assignments, and other course content.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} sx={{ color: 'hsl(var(--text-primary))' }}>
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        sx={{
          backgroundColor: 'hsl(var(--destructive))',
          color: 'hsl(var(--destructive-foreground))',
          '&:hover': {
            backgroundColor: 'hsl(var(--destructive))',
            opacity: 0.9,
          },
        }}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const MyCourses = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [courses, setCourses] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const currentYear = new Date().getFullYear()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [newCourse, setNewCourse] = useState({
    title: '',
    year: new Date().getFullYear(),
    term_start: '',
    term_duration: '',
  })
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const termOptions = ['Fall', 'Winter', 'Spring', 'Summer']
  const durationOptions = ['Quarter', 'Semester', 'School Year', 'Summer']

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // Get user's local courses
        const { data: userCourses, error: userCoursesError } = await supabase
          .from('user_courses')
          .select('*')
          .eq('uid', user.id)
          .order('year', { ascending: false })

        if (userCoursesError) throw userCoursesError

        // Get YourEDU courses where user is creator or student
        const { data: youreduCourses, error: youreduError } = await supabase
          .from('youredu_courses')
          .select('*, creator_id')
          .or(`creator_id.eq.${user.id}`)
          .order('year', { ascending: false })

        if (youreduError) throw youreduError

        // Transform YourEDU courses to match format
        const transformedYoureduCourses = youreduCourses.map((course) => ({
          ...course,
          uid: user.id,
          is_youredu_course: true,
          term_start: course.term_start || null,
          college: 'YourEdu',
        }))

        // Combine and group all courses by year
        const allCourses = [...userCourses, ...transformedYoureduCourses]
        const coursesByYear = allCourses.reduce((acc, course) => {
          const year = course.year || currentYear
          if (!acc[year]) {
            acc[year] = []
          }
          acc[year].push(course)
          return acc
        }, {})

        setCourses(coursesByYear)
      } catch (err) {
        console.error('Error fetching courses:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (user) {
      fetchCourses()
    }
  }, [user, currentYear])

  const handleCreateCourse = async () => {
    try {
      const { data, error } = await supabase
        .from('youredu_courses')
        .insert([
          {
            title: newCourse.title,
            year: newCourse.year,
            term_start: newCourse.term_start,
            term_duration: newCourse.term_duration,
            creator_id: user.id,
            teachers: [user.id],
          },
        ])
        .select()
        .single()

      if (error) throw error

      setCourses((prev) => {
        const year = newCourse.year
        return {
          ...prev,
          [year]: [...(prev[year] || []), data],
        }
      })

      setIsCreateModalOpen(false)
      setNewCourse({
        title: '',
        year: new Date().getFullYear(),
        term_start: '',
        term_duration: '',
      })

      toast.success('Course created successfully')
    } catch (err) {
      console.error('Error creating course:', err)
      toast.error('Failed to create course')
    }
  }

  const handleMenuOpen = (event, course) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedCourse(course)
  }

  const handleMenuClose = (event) => {
    event?.stopPropagation()
    setMenuAnchorEl(null)
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    handleMenuClose(event)
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      let error

      if (selectedCourse.is_youredu_course) {
        // Delete from youredu_courses table
        const { error: youreduError } = await supabase
          .from('youredu_courses')
          .delete()
          .eq('id', selectedCourse.id)
          .eq('creator_id', user.id) // Ensure user is the creator

        error = youreduError
      } else {
        // Delete from user_courses table
        const { error: userCoursesError } = await supabase
          .from('user_courses')
          .delete()
          .eq('id', selectedCourse.id)
          .eq('uid', user.id)

        error = userCoursesError
      }

      if (error) throw error

      // Update local state
      setCourses((prev) => {
        const year = selectedCourse.year
        const updatedYearCourses = prev[year].filter((course) => course.id !== selectedCourse.id)

        if (updatedYearCourses.length === 0) {
          const { [year]: _, ...rest } = prev
          return rest
        }

        return {
          ...prev,
          [year]: updatedYearCourses,
        }
      })

      toast.success('Course deleted successfully')
    } catch (err) {
      console.error('Error deleting course:', err)
      toast.error(
        err.code === '23503'
          ? 'Cannot delete course: Please delete all associated content first'
          : 'Failed to delete course'
      )
    } finally {
      setDeleteDialogOpen(false)
      setSelectedCourse(null)
    }
  }

  const yearSections = Object.keys(courses)
    .sort((a, b) => b - a)
    .map((year) => ({
      year,
      courses: courses[year],
    }))

  const renderCourseCard = (course) => (
    <Paper
      key={course.id}
      onClick={() => navigate(`/user-course/${course.id}`)}
      elevation={0}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.2s',
        backgroundColor: 'hsl(var(--card))',
        border: '1px solid hsl(var(--border))',
        borderRadius: 'var(--radius-lg)',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-2px)',
        },
      }}
    >
      <Box sx={{ p: 'var(--spacing-4)' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <FeatureHeader>
            {course.title || 'Untitled Course'}
            {course.is_youredu_course && (
              <Box
                component="span"
                sx={{
                  ml: 1,
                  px: 1,
                  py: 0.5,
                  bgcolor: 'hsl(var(--brand-primary))',
                  color: 'white',
                  borderRadius: 'var(--radius-badge)',
                  fontSize: '0.75rem',
                }}
              >
                YourEDU
              </Box>
            )}
          </FeatureHeader>
          {(!course.is_youredu_course || (course.is_youredu_course && course.creator_id === user.id)) && (
            <IconButton
              onClick={(e) => handleMenuOpen(e, course)}
              size="small"
              sx={{ color: 'hsl(var(--muted-foreground))' }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: 3, mt: 2 }}>
          {course.term_start && <SupportingText>Term: {course.term_start}</SupportingText>}
          {course.term_duration && <SupportingText>Duration: {course.term_duration}</SupportingText>}
          {course.college && <SupportingText>Provider: {course.college}</SupportingText>}
        </Box>
      </Box>
    </Paper>
  )

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container
          maxWidth="var(--container-max-width)"
          sx={{
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <PageHeader>My Courses</PageHeader>
              <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                Manage and track all your courses in one place
              </DescriptiveText>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsCreateModalOpen(true)}
              sx={{
                backgroundColor: 'hsl(var(--brand-primary))',
                color: 'hsl(var(--background))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--brand-primary-dark))',
                },
              }}
            >
              Create Course
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container
        maxWidth="var(--container-max-width)"
        sx={{
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 'var(--spacing-8)' }}>
            <CircularProgress sx={{ color: 'hsl(var(--brand-primary))' }} />
          </Box>
        ) : error ? (
          <Alert
            severity="error"
            sx={{
              backgroundColor: 'hsla(var(--error), 0.1)',
              color: 'hsl(var(--error))',
              border: '1px solid hsl(var(--error))',
            }}
          >
            {error}
          </Alert>
        ) : yearSections.length === 0 ? (
          <Paper
            elevation={0}
            sx={{
              p: 'var(--spacing-4)',
              backgroundColor: 'hsl(var(--muted))',
              border: '1px solid hsl(var(--border))',
              borderRadius: 'var(--radius-lg)',
              textAlign: 'center',
            }}
          >
            <DescriptiveText>No courses added yet. Create your first course to get started.</DescriptiveText>
          </Paper>
        ) : (
          <Grid container spacing={3}>
            {yearSections.map(({ year, courses }) => (
              <Grid item xs={12} key={year}>
                <Accordion
                  defaultExpanded={parseInt(year) === currentYear}
                  elevation={0}
                  sx={{
                    backgroundColor: 'hsl(var(--muted))',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: 'var(--radius-lg)',
                    overflow: 'hidden',
                    '&:before': { display: 'none' },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: 'hsl(var(--muted))',
                      borderBottom: '1px solid hsl(var(--border))',
                      '&:hover': { backgroundColor: 'hsl(var(--muted))' },
                    }}
                  >
                    <FeatureHeader>
                      {year} - {parseInt(year) + 1} Academic Year
                    </FeatureHeader>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 'var(--spacing-4)', backgroundColor: 'hsl(var(--background))' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-3)' }}>
                      {courses.map(renderCourseCard)}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      <CreateCourseModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        newCourse={newCourse}
        setNewCourse={setNewCourse}
        onCreateCourse={handleCreateCourse}
        termOptions={termOptions}
        durationOptions={durationOptions}
      />

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            color: 'hsl(var(--destructive))',
            '&:hover': {
              backgroundColor: 'hsl(var(--destructive) / 0.1)',
            },
          }}
        >
          Delete Course
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        courseTitle={selectedCourse?.title}
      />
    </Box>
  )
}

export default MyCourses
