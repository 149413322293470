import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Paper, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { AdminMaterialsService } from '../services/AdminMaterialsService';
import { toast } from 'react-toastify';
import { BsBuilding, BsFileText, BsBook, BsClipboard, BsEnvelope } from 'react-icons/bs';
import { PageHeader, DescriptiveText } from '../components/ui/typography.jsx';
import { cardStyles } from '../styles/theme/components/cards';

const AdminMaterials = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState({
    schoolPhilosophy: 0,
    transcript: 0,
    courseDescription: 0,
    gradingRubric: 0,
    guidanceLetter: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        setLoading(true);
        const completionStatus = await AdminMaterialsService.getCompletionStatus();
        setProgress(completionStatus);
      } catch (error) {
        console.error('Error fetching progress:', error);
        toast.error('Failed to load progress data');
      } finally {
        setLoading(false);
      }
    };

    fetchProgress();
  }, []);

  const materials = [
    {
      title: 'School Profile Report',
      path: '/admin-materials/school-philosophy',
      progress: progress.schoolPhilosophy,
      description: 'Create a comprehensive school profile that includes your educational philosophy and approach.',
      icon: BsBuilding,
      color: '#3182CE'
    },
    {
      title: 'Transcript',
      path: '/admin-materials/transcript',
      progress: progress.transcript,
      description: 'Generate and manage your academic transcript with course details and grades.',
      icon: BsFileText,
      color: '#38A169'
    },
    {
      title: 'Course Descriptions',
      path: '/admin-materials/course-descriptions',
      progress: progress.courseDescription,
      description: 'Document detailed descriptions of your courses, including content and learning objectives.',
      icon: BsBook,
      color: '#805AD5'
    },
    {
      title: 'Grading Rubric',
      path: '/admin-materials/grading-rubric',
      progress: progress.gradingRubric,
      description: 'Define your grading system and evaluation criteria.',
      icon: BsClipboard,
      color: '#DD6B20'
    },
    {
      title: 'Guidance Counselor Letter',
      path: '/admin-materials/guidance-counselor-letter',
      progress: progress.guidanceLetter,
      description: 'Generate a guidance counselor letter for college applications.',
      icon: BsEnvelope,
      color: '#319795'
    }
  ];

  const styles = {
    iconContainer: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '12px'
    },
    icon: {
      fontSize: '20px'
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: 'hsl(var(--text-primary))',
    },
    description: {
      fontSize: '14px',
      color: 'hsl(var(--text-secondary))',
      marginBottom: '15px',
      flex: 1,
    },
    progressContainer: {
      width: '100%',
      backgroundColor: 'hsl(var(--neutral-100))',
      borderRadius: '5px',
      overflow: 'hidden',
      marginTop: 'auto',
    },
    progressBar: (progress, color) => ({
      width: `${progress}%`,
      height: '10px',
      backgroundColor: color,
      transition: 'width 0.3s ease',
    }),
    progressText: {
      fontSize: '12px',
      color: 'hsl(var(--text-secondary))',
      marginTop: '5px',
      textAlign: 'right',
    },
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/college')}
            sx={{ 
              color: 'hsl(var(--text-primary))',
              mb: 'var(--spacing-4)',
              '&:hover': {
                backgroundColor: 'hsla(var(--brand-primary-light), 0.1)',
              }
            }}
          >
            Back to College
          </Button>
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Administrative Materials
          </PageHeader>
          <DescriptiveText 
            sx={{ maxWidth: 'var(--text-max-width)' }}
          >
            Access and manage administrative materials for college applications, including transcripts, recommendation letters, and other essential documentation.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>Loading progress...</div>
        ) : (
          <Grid container spacing={3}>
            {materials.map((material, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    height: '100%',
                    borderRadius: 'var(--radius-card)',
                    backgroundColor: 'white',
                    border: '1px solid hsl(var(--neutral-200))',
                    transition: 'transform 0.2s',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    },
                  }}
                  onClick={() => navigate(material.path)}
                >
                  <div style={{...styles.iconContainer, backgroundColor: `${material.color}15`}}>
                    <material.icon style={{...styles.icon, color: material.color}} />
                  </div>
                  <h2 style={styles.title}>{material.title}</h2>
                  <p style={styles.description}>{material.description}</p>
                  <div style={styles.progressContainer}>
                    <div style={{...styles.progressBar(material.progress, material.color)}} />
                  </div>
                  <div style={styles.progressText}>{material.progress}% Complete</div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default AdminMaterials;

