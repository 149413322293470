import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from '../config/supabase'
import CourseCard from '../components/courses/CourseCard'
import { Box, Typography, Button, Modal, Container } from '@mui/material'
import RegistrationModal from '../components/RegistrationModal'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/AuthContext'
import { PageHeader, DescriptiveText } from '../components/ui/typography'

const CoursePage = () => {
  const { college, courseCode } = useParams()
  const [courseInfo, setCourseInfo] = useState(null)
  const [sections, setSections] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const termOrder = ['winter', 'spring', 'summer', 'fall']

  const sortSectionsByTerm = (sections) => {
    return sections.sort((a, b) => {
      const termA = a.term.toLowerCase()
      const termB = b.term.toLowerCase()

      const yearA = parseInt(termA.match(/\d+/)[0], 10)
      const yearB = parseInt(termB.match(/\d+/)[0], 10)

      const termIndexA = termOrder.findIndex((term) => termA.includes(term))
      const termIndexB = termOrder.findIndex((term) => termB.includes(term))

      if (yearA !== yearB) {
        return yearA - yearB
      }
      return termIndexA - termIndexB
    })
  }

  useEffect(() => {
    const loadCourseData = async () => {
      console.log('Loading course data for:', { college, courseCode })
      setIsLoading(true)
      setError(null)

      try {
        // First try to get from college_courses
        const { data: courseData, error: courseError } = await supabase
          .from('college_courses')
          .select('*')
          .eq('code', courseCode)
          .eq('college', college)
          .single()

        if (!courseError && courseData) {
          setCourseInfo(courseData)

          const { data: sectionData, error: sectionError } = await supabase
            .from('college_courses_schedules')
            .select('*')
            .eq('course_code', courseCode)
            .eq('college', college)

          if (sectionError) throw sectionError
          setSections(sortSectionsByTerm(sectionData))
        } else {
          // If not found, try youredu_courses
          const { data: youreduData, error: youreduError } = await supabase
            .from('youredu_courses')
            .select('*')
            .eq('id', courseCode) // Using courseCode param as the ID
            .single()

          if (youreduError) throw youreduError

          // Transform youredu course data to match expected format
          const transformedData = {
            code: `YE-${youreduData.id.slice(0, 8)}`,
            title: youreduData.title,
            description: youreduData.description,
            units: youreduData.units,
            hs_subject: youreduData.hs_subject,
            college: 'YourEdu',
            total_hours: youreduData.total_hours,
          }
          setCourseInfo(transformedData)

          // Create a section object from youredu course data
          const sectionData = [
            {
              crn: youreduData.id,
              course_code: transformedData.code,
              instructor: 'YourEdu Instructor',
              section_times: youreduData.days && youreduData.times ? [youreduData.days, youreduData.times] : null,
              section_dates: youreduData.dates ? [youreduData.dates] : null,
              section_locations: youreduData.location ? [youreduData.location] : null,
              term: youreduData.term_start || null,
              max_students: youreduData.enrollment_capacity || null,
              enrolled: (youreduData.students || []).length,
              waitlisted: 0,
              term_duration: youreduData.term_duration || null,
            },
          ]
          setSections(sectionData)
        }
      } catch (error) {
        console.error('Error loading course data:', error)
        setError(error.message)
      } finally {
        setIsLoading(false)
        console.log('Loading complete')
      }
    }

    loadCourseData()
  }, [college, courseCode])

  const handleRegisterClick = async (course) => {
    try {
      if (!courseInfo || !user) {
        throw new Error('Missing course information or user not logged in')
      }

      // First check if user is already enrolled
      const { data: existingEnrollments, error: enrollCheckError } = await supabase
        .from('user_courses')
        .select('*')
        .eq('uid', user.id)
        .eq('college', courseInfo.college)
        .eq('course_code', courseCode)

      if (enrollCheckError) throw enrollCheckError

      if (existingEnrollments && existingEnrollments.length > 0) {
        toast.warning('You are already enrolled in this course')
        return
      }

      // Then check if course is already in cart
      const { data: existingCartItem, error: cartCheckError } = await supabase
        .from('cart_items')
        .select('*')
        .eq('user_id', user.id)
        .eq('college', courseInfo.college)
        .eq('course_id', courseCode)
        .eq('crn', course.crn)
        .single()

      if (cartCheckError && cartCheckError.code !== 'PGRST116') throw cartCheckError // PGRST116 is "not found" which is ok
      if (existingCartItem) {
        toast.info('This course is already in your cart')
        return
      }

      // Add to cart
      const cartItem = {
        user_id: user.id,
        course_id: courseCode,
        college: courseInfo.college,
        course_type: college === 'YourEdu' ? 'youredu' : 'college',
        crn: course.crn,
        price: course.price || 0,
        discount: course.discount || 0,
      }

      const { error: insertError } = await supabase.from('cart_items').insert([cartItem])

      if (insertError) throw insertError

      toast.success('Added to cart')

      /* Original enrollment code - commented out for later use in cart checkout
      setSelectedCourse(course)
      setIsRegistrationModalOpen(true)

      const isYourEduCourse = course.crn && course.crn.length > 10

      if (isYourEduCourse) {
        const { data: youreduCourse, error: youreduError } = await supabase
          .from('youredu_courses')
          .select('students')
          .eq('id', course.crn)
          .single()

        if (youreduError) throw youreduError

        // Check if already enrolled in YourEDU course
        const currentStudents = youreduCourse.students || []
        if (currentStudents.includes(user.id)) {
          toast.warning('You are already enrolled in this course')
          return
        }

        // Add student to the students array
        const { error: updateError } = await supabase
          .from('youredu_courses')
          .update({ students: [...currentStudents, user.id] })
          .eq('id', course.crn)

        if (updateError) throw updateError
      } else {
        const userCourseData = {
          uid: user.id,
          title: courseInfo.title || null,
          college: courseInfo.college || null,
          hs_subject: courseInfo.hs_subject || null,
          units: courseInfo.units || null,
          total_hours: courseInfo.total_hours || null,
          description: courseInfo.description || null,
          days: course.section_times?.[0] || null,
          times: course.section_times?.[1] || null,
          dates: course.section_dates?.[0] || null,
          year: parseInt(course.term?.match(/\d+/)?.[0] || new Date().getFullYear()),
          term_start: course.term || null,
          term_duration: course.term_duration || null,
          location: course.section_locations?.[0] || null,
          instruction_method: course.section_locations?.[0]?.includes('ONLINE') ? 'Online' : 'In Person',
          teacher: course.instructor || null,
          is_college_level: true,
          materials: null,
          evaluation_method: null,
          final_grade: null,
          course_code: courseCode,
          description: `Course Code: ${courseCode}\n${courseInfo.description || ''}`,
        }

        const { error } = await supabase.from('user_courses').insert([userCourseData]).select()
        if (error) throw error
      }
      */
    } catch (error) {
      console.error('Error adding to cart:', error)
      toast.error('Failed to add to cart. Please try again.')
    }
  }

  const handleCloseModal = () => {
    setSelectedCourse(null)
    setIsRegistrationModalOpen(false)
  }

  const formatSchedule = (schedule) => {
    if (!schedule) return 'Not set'
    if (schedule === 'Asynchronous') return schedule

    const dayMap = {
      M: 'Monday',
      T: 'Tuesday',
      W: 'Wednesday',
      R: 'Thursday',
      F: 'Friday',
      S: 'Saturday',
      U: 'Sunday',
    }

    return schedule
      .trim()
      .split(/\s+/)
      .filter((day) => day)
      .map((day) => dayMap[day] || day)
      .join(', ')
  }

  const formatTimes = (times) => {
    if (!times) return ''
    const timePattern = /(\d{1,2}:\d{2}[ap]m)\s*-\s*(\d{1,2}:\d{2}[ap]m)/i
    const match = times.match(timePattern)
    if (match) {
      return `${match[1]} - ${match[2]}`
    }
    return ''
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-4)',
          pb: 'var(--spacing-2)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--spacing-2)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
            <Button 
              onClick={() => navigate('/course-search')}
              sx={{ 
                color: 'hsl(var(--brand-primary))',
                p: 'var(--spacing-1) var(--spacing-2)',
                minWidth: 0,
                '&:hover': {
                  backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                }
              }}
            >
              ← Back
            </Button>
          </Box>
          <PageHeader sx={{ mb: 1, fontSize: '1.75rem' }}>
            {courseInfo?.title} ({courseCode})
          </PageHeader>
          <DescriptiveText sx={{ color: 'hsl(var(--text-secondary))', mb: 0 }}>
            Offered by {college}
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-4)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        
        {courseInfo && (
          <Box sx={{ mb: 'var(--spacing-4)' }}>
            <CourseCard
              courseGroup={[
                courseInfo.title,
                [{
                  institution: courseInfo.college,
                  code: courseInfo.code,
                  courseTitle: courseInfo.title,
                  credits: courseInfo.units,
                  hsSubject: courseInfo.hs_subject,
                  description: courseInfo.description,
                  totalHours: courseInfo.total_hours,
                  preventViewMore: true,
                  showFullDescription: true,
                }],
              ]}
              expandedCourses={{}}
              toggleCourseExpansion={() => {}}
              handleRegisterClick={() => {}}
              tags={[
                { title: courseInfo.college, color: 'hsl(var(--brand-primary))' },
                { title: courseInfo.hs_subject, color: 'hsl(var(--brand-primary))' },
              ]}
            />
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-4)' }}>
          {sections && sections.length > 0 ? (
            sections.reduce((acc, section, index) => {
              if (!section) return acc

              const previousSection = sections[index - 1]
              const currentTerm = section.term
              const previousTerm = previousSection?.term

              if (currentTerm !== previousTerm) {
                acc.push(
                  <Typography 
                    key={`header-${currentTerm}`} 
                    variant="h6" 
                    sx={{ 
                      mt: index > 0 ? 'var(--spacing-6)' : 0,
                      mb: 'var(--spacing-4)',
                      color: 'hsl(var(--text-primary))',
                      fontWeight: 600,
                    }}
                  >
                    {currentTerm || 'Term Not Set'}
                  </Typography>
                )
              }

              acc.push(
                <Box
                  key={section.crn}
                  sx={{
                    p: 'var(--spacing-4)',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: 'var(--radius)',
                    backgroundColor: 'hsl(var(--background))',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      borderColor: 'hsl(var(--brand-primary))',
                      transform: 'translateY(-2px)',
                      boxShadow: 'var(--shadow-md)',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-2)' }}>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Professor: {section.instructor || 'Not assigned'}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Schedule: {section.section_times?.[0]
                          ? formatSchedule(section.section_times[0]) +
                            ' ' +
                            (section.section_times[1] ? formatTimes(section.section_times[1]) : '')
                          : 'Schedule not set'}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Location: {section.section_locations?.[0] || 'Location not set'}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Term: {section.term || 'Term not set'}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Date: {section.section_dates?.[0] || 'Dates not set'}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-2)', alignItems: 'flex-end' }}>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Capacity: {section.max_students || 'Not set'}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Enrolled: {section.enrolled || 0}
                      </Typography>
                      <Typography sx={{ color: 'hsl(var(--text-primary))' }}>
                        Waitlist: {section.waitlisted || 0}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleRegisterClick(section)}
                        disabled={section.enrolled >= section.max_students}
                        sx={{ 
                          mt: 'var(--spacing-2)',
                          backgroundColor: 'hsl(var(--brand-primary))',
                          color: 'hsl(var(--background))',
                          '&:hover': {
                            backgroundColor: 'hsl(var(--brand-primary-dark))',
                          },
                          '&:disabled': {
                            backgroundColor: 'hsl(var(--muted))',
                          }
                        }}
                      >
                        {section.enrolled >= section.max_students ? 'Full' : 'Add to Cart'}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )

              return acc
            }, [])
          ) : (
            <Typography 
              sx={{ 
                mt: 'var(--spacing-4)', 
                textAlign: 'center',
                color: 'hsl(var(--text-secondary))'
              }}
            >
              No sections available for this course
            </Typography>
          )}
        </Box>
      </Container>

      {/* Registration Modal */}
      <Modal open={isRegistrationModalOpen} onClose={handleCloseModal}>
        <div>
          <RegistrationModal course={selectedCourse} onClose={handleCloseModal} />
        </div>
      </Modal>
    </Box>
  )
}

export default CoursePage
