import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { supabase } from '../supabase/utils/supabaseClient';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import { 
  Close as CloseIcon,
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
  CardGiftcard as GiftIcon,
  AttachMoney as MoneyIcon,
  Help as HelpIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import Logo from '../assets/logo.png';
import ReferralPopover from './ReferralPopover';
import HelpPopover from './HelpPopover';
import AuthWrapper from './AuthWrapper';
import { BodyText, SupportingText } from './ui/typography'

const TopBar = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [searchVisible, setSearchVisible] = useState(false);
  const [referralVisible, setReferralVisible] = useState(false);
  const [helpVisible, setHelpVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [referButtonRef, setReferButtonRef] = useState(null);
  const [helpButtonRef, setHelpButtonRef] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Get user's name, fallback to email if name not available
  const displayName = (user?.user_metadata?.name || user?.email?.split('@')[0] || '')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  const getAccountType = () => {
    const userType = localStorage.getItem('userType');
    switch(userType) {
      case 'student':
        return 'Student';
      case 'college':
        return 'College';
      case 'lafire':
        return 'LA Fire';
      default:
        return 'Parent';
    }
  };

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccount = () => {
    handleUserMenuClose();
    navigate('/my-account');
  };

  const handleLogout = async () => {
    handleUserMenuClose();
    await logout();
  };

  const handleShare = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setSnackbar({
      open: true,
      message: 'Link copied to clipboard!',
      severity: 'success'
    });
  };

  return (
    <>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 'var(--spacing-4)',
        py: 'var(--spacing-2)',
        backgroundColor: 'white',
        borderBottom: '1px solid',
        borderColor: `hsl(var(--neutral-200))`,
        height: '64px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AuthWrapper>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                gap: 'var(--spacing-2)',
              }}
              onClick={() => navigate('/')}
            >
              <img src={Logo} alt="YourEDU Logo" style={{ height: 32 }} />
              <BodyText sx={{ fontWeight: 600 }}>YourEDU</BodyText>
            </Box>
          </AuthWrapper>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-2)' }}>
          <AuthWrapper>
            <Button
              startIcon={<SearchIcon />}
              onClick={() => setSearchVisible(true)}
              sx={{ 
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
            >
              Search
            </Button>
          </AuthWrapper>
          
          <AuthWrapper>
            <Button
              startIcon={<CalendarIcon />}
              onClick={() => navigate('/calendar')}
              sx={{ 
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
            >
              Calendar
            </Button>
          </AuthWrapper>

          <AuthWrapper>
            <Button
              startIcon={<GiftIcon />}
              onClick={(e) => {
                setReferButtonRef(e.currentTarget);
                setReferralVisible(true);
              }}
              sx={{ 
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
            >
              Refer
            </Button>
          </AuthWrapper>

          <AuthWrapper>
            <Button
              startIcon={<MoneyIcon />}
              onClick={() => navigate('/pricing')}
              sx={{ 
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
            >
              Pricing
            </Button>
          </AuthWrapper>

          <AuthWrapper>
            <Button
              startIcon={<HelpIcon />}
              onClick={(e) => {
                setHelpButtonRef(e.currentTarget);
                setHelpVisible(true);
              }}
              sx={{ 
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
            >
              Help
            </Button>
          </AuthWrapper>

          {user ? (
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                cursor: 'pointer',
                gap: 'var(--spacing-2)',
                px: 'var(--spacing-2)',
                py: 'var(--spacing-1)',
                borderRadius: 'var(--radius-sm)',
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                },
              }}
              onClick={handleUserMenuOpen}
            >
              <PersonIcon sx={{ color: `hsl(var(--text-primary))` }} />
              <Box>
                <BodyText sx={{ fontWeight: 600 }}>{displayName}</BodyText>
                <SupportingText>{getAccountType()}</SupportingText>
              </Box>
            </Box>
          ) : (
            <AuthWrapper>
              <Button
                variant="contained"
                onClick={() => navigate('/signup')}
                sx={{
                  backgroundColor: `hsl(var(--brand-primary))`,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: `hsl(var(--brand-primary-dark))`,
                  },
                }}
              >
                Sign Up
              </Button>
            </AuthWrapper>
          )}
        </Box>
      </Box>

      {/* Search Dialog */}
      <Dialog
        open={searchVisible}
        onClose={() => setSearchVisible(false)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: 'var(--radius-lg)',
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 'var(--spacing-2)',
          }}>
            <SearchIcon sx={{ color: `hsl(var(--text-primary))` }} />
            <BodyText>Search</BodyText>
            <IconButton
              sx={{ 
                ml: 'auto',
                color: `hsl(var(--text-primary))`,
                '&:hover': {
                  backgroundColor: `hsl(var(--neutral-50))`,
                }
              }}
              onClick={() => setSearchVisible(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            placeholder="Search for courses, events, groups..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ 
              mt: 'var(--spacing-2)',
              '& .MuiOutlinedInput-root': {
                borderRadius: 'var(--radius-sm)',
                '& fieldset': {
                  borderColor: `hsl(var(--neutral-200))`,
                },
                '&:hover fieldset': {
                  borderColor: `hsl(var(--neutral-300))`,
                },
                '&.Mui-focused fieldset': {
                  borderColor: `hsl(var(--brand-primary))`,
                },
              },
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 'var(--radius-lg)',
            border: '1px solid',
            borderColor: `hsl(var(--neutral-200))`,
            boxShadow: 'var(--shadow-lg)',
          }
        }}
      >
        <MenuItem onClick={handleMyAccount}>
          <BodyText>My Account</BodyText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <BodyText>Logout</BodyText>
        </MenuItem>
      </Menu>

      {/* Referral Popover */}
      <ReferralPopover
        anchorEl={referButtonRef}
        open={referralVisible}
        onClose={() => setReferralVisible(false)}
      />

      {/* Help Popover */}
      <HelpPopover
        anchorEl={helpButtonRef}
        open={helpVisible}
        onClose={() => setHelpVisible(false)}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            borderRadius: 'var(--radius-sm)',
          }}
        >
          <BodyText>{snackbar.message}</BodyText>
        </Alert>
      </Snackbar>
    </>
  );
};

export default TopBar; 