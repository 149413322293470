import { supabase } from '../supabase/client';

export const TranscriptService = {
  async getTranscript() {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('transcripts')
        .select(`
          *,
          courses:courses(*)
        `)
        .eq('user_id', user.id)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }

      if (!data) return null;

      // Transform the data structure to match the frontend expectations
      const transformedData = {
        ...data,
        preHighSchoolCourses: data.courses.filter(c => c.grade_level === 'preHighSchool')
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(this._transformCourse),
        freshmanCourses: data.courses.filter(c => c.grade_level === 'freshman')
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(this._transformCourse),
        sophomoreCourses: data.courses.filter(c => c.grade_level === 'sophomore')
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(this._transformCourse),
        juniorCourses: data.courses.filter(c => c.grade_level === 'junior')
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(this._transformCourse),
        seniorCourses: data.courses.filter(c => c.grade_level === 'senior')
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(this._transformCourse),
      };

      delete transformedData.courses;
      return transformedData;
    } catch (error) {
      console.error('Error in getTranscript:', error);
      throw error;
    }
  },

  async saveTranscript(transcriptData) {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      // Format the transcript data
      const formattedTranscript = {
        user_id: user.id,
        name: transcriptData.name?.trim(),
        gender: transcriptData.gender?.trim(),
        address: transcriptData.address?.trim(),
        city: transcriptData.city?.trim(),
        state: transcriptData.state?.trim(),
        zip: transcriptData.zip?.trim(),
        dob: transcriptData.dob || null,
        parent_guardian: transcriptData.parentGuardian?.trim(),
        student_email: transcriptData.studentEmail?.trim(),
        projected_grad_date: transcriptData.projectedGradDate || null,
        parent_email: transcriptData.parentEmail?.trim(),
        school_name: transcriptData.schoolName?.trim(),
        school_phone: transcriptData.schoolPhone?.trim(),
        school_address: transcriptData.schoolAddress?.trim(),
        school_city: transcriptData.schoolCity?.trim(),
        school_state: transcriptData.schoolState?.trim(),
        school_zip: transcriptData.schoolZip?.trim(),
        issue_date: transcriptData.issueDate || null,
        graduation_date: transcriptData.graduationDate || null,
        freshman_year: transcriptData.freshmanYear?.trim(),
        sophomore_year: transcriptData.sophomoreYear?.trim(),
        junior_year: transcriptData.juniorYear?.trim(),
        senior_year: transcriptData.seniorYear?.trim(),
        pre_high_school_year: transcriptData.preHighSchoolYear?.trim(),
        cumulative_summary: transcriptData.cumulativeSummary,
        test_scores: transcriptData.testScores?.trim(),
        grading_scale: transcriptData.gradingScale,
        miscellaneous: transcriptData.miscellaneous?.trim(),
        signature_date: transcriptData.signatureDate || null
      };

      // Save or update the transcript
      const { data: transcript, error: transcriptError } = await supabase
        .from('transcripts')
        .upsert(formattedTranscript, {
          onConflict: 'user_id',
          returning: 'representation'
        })
        .select()
        .single();

      if (transcriptError) throw transcriptError;

      // Prepare all courses
      const allCourses = [
        ...this._prepareCourses(transcriptData.preHighSchoolCourses, 'preHighSchool', transcript.id),
        ...this._prepareCourses(transcriptData.freshmanCourses, 'freshman', transcript.id),
        ...this._prepareCourses(transcriptData.sophomoreCourses, 'sophomore', transcript.id),
        ...this._prepareCourses(transcriptData.juniorCourses, 'junior', transcript.id),
        ...this._prepareCourses(transcriptData.seniorCourses, 'senior', transcript.id)
      ];

      // Delete existing courses and insert new ones
      const { error: deleteError } = await supabase
        .from('courses')
        .delete()
        .eq('transcript_id', transcript.id);

      if (deleteError) throw deleteError;

      if (allCourses.length > 0) {
        const { error: coursesError } = await supabase
          .from('courses')
          .insert(allCourses);

        if (coursesError) throw coursesError;
      }

      return this.getTranscript();
    } catch (error) {
      console.error('Error in saveTranscript:', error);
      throw error;
    }
  },

  _prepareCourses(courses, gradeLevel, transcriptId) {
    return (courses || []).map((course, index) => ({
      transcript_id: transcriptId,
      grade_level: gradeLevel,
      method: course.method?.trim(),
      course_title: course.courseTitle?.trim(),
      term1_grade: course.term1Grade?.trim(),
      term2_grade: course.term2Grade?.trim(),
      term3_grade: course.term3Grade?.trim(),
      credits: course.credits?.trim(),
      ap_score: course.apScore?.trim(),
      sort_order: index
    }));
  },

  _transformCourse(course) {
    return {
      method: course.method || '',
      courseTitle: course.course_title || '',
      term1Grade: course.term1_grade || '',
      term2Grade: course.term2_grade || '',
      term3Grade: course.term3_grade || '',
      credits: course.credits || '',
      apScore: course.ap_score || ''
    };
  }
}; 