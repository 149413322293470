import React, { useEffect, useState } from 'react'
import { useAuth } from '../utils/AuthContext'
import { supabase } from '../config/supabase'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  History as HistoryIcon,
  ViewList as ListIcon,
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import {
  PageHeader,
  SectionHeader,
  FeatureHeader,
  DescriptiveText,
  BodyText,
  SupportingText,
} from '../components/ui/typography'
import MyCoursesCard from '../components/courses/MyCoursesCard'

const CreateCourseModal = ({
  isOpen,
  onClose,
  newCourse,
  setNewCourse,
  onCreateCourse,
  termOptions,
  durationOptions,
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>
      <FeatureHeader>Create New Course</FeatureHeader>
    </DialogTitle>
    <DialogContent sx={{ pt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Course Title"
          fullWidth
          value={newCourse.title}
          onChange={(e) => setNewCourse((prev) => ({ ...prev, title: e.target.value }))}
          required
        />
        <TextField
          label="Year"
          type="number"
          fullWidth
          value={newCourse.year}
          onChange={(e) => setNewCourse((prev) => ({ ...prev, year: parseInt(e.target.value) }))}
          required
        />
        <FormControl fullWidth required>
          <InputLabel>Term</InputLabel>
          <Select
            value={newCourse.term_start}
            label="Term"
            onChange={(e) => setNewCourse((prev) => ({ ...prev, term_start: e.target.value }))}
          >
            {termOptions.map((term) => (
              <MenuItem key={term} value={term}>
                {term}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel>Term Duration</InputLabel>
          <Select
            value={newCourse.term_duration}
            label="Term Duration"
            onChange={(e) => setNewCourse((prev) => ({ ...prev, term_duration: e.target.value }))}
          >
            {durationOptions.map((duration) => (
              <MenuItem key={duration} value={duration}>
                {duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button onClick={onClose} sx={{ color: 'hsl(var(--text-primary))' }}>
        Cancel
      </Button>
      <Button
        onClick={onCreateCourse}
        variant="contained"
        sx={{
          backgroundColor: 'hsl(var(--brand-primary))',
          color: 'hsl(var(--background))',
          '&:hover': {
            backgroundColor: 'hsl(var(--brand-primary-dark))',
          },
        }}
      >
        Create Course
      </Button>
    </DialogActions>
  </Dialog>
)

const MyCourses = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [courses, setCourses] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const currentYear = new Date().getFullYear()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [newCourse, setNewCourse] = useState({
    title: '',
    year: new Date().getFullYear(),
    term_start: '',
    term_duration: '',
  })
  const [showAllYears, setShowAllYears] = useState(false)

  const termOptions = ['Fall', 'Winter', 'Spring', 'Summer']
  const durationOptions = ['Quarter', 'Semester', 'School Year', 'Summer']

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        console.log('Fetching courses for user:', user.id)

        // Get user's local courses
        const { data: userCourses, error: userCoursesError } = await supabase
          .from('user_courses')
          .select('*')
          .eq('uid', user.id)
          .order('year', { ascending: false })

        console.log('User courses fetched:', userCourses)
        if (userCoursesError) throw userCoursesError

        // Get YourEDU courses where user is creator or student
        const { data: youreduCourses, error: youreduError } = await supabase
          .from('youredu_courses')
          .select('*')
          .or(`creator_id.eq.${user.id}`)
          .order('year', { ascending: false })

        console.log('YourEDU courses fetched:', youreduCourses)
        if (youreduError) throw youreduError

        // Transform YourEDU courses to match format
        const transformedYoureduCourses = youreduCourses.map((course) => ({
          ...course,
          uid: user.id,
          is_youredu_course: true,
          term_start: course.term_start || null,
          college: 'YourEdu',
        }))
        console.log('Transformed YourEDU courses:', transformedYoureduCourses)

        // Combine and group all courses by year
        const allCourses = [...userCourses, ...transformedYoureduCourses]
        console.log('All courses combined:', allCourses)

        // Fix the grouping structure to match what MyCoursesCard expects
        const coursesByYear = allCourses.reduce((acc, course) => {
          const year = course.year || currentYear
          if (!acc[year]) {
            acc[year] = {}
          }
          // Group courses by term within each year
          const term = course.term_start || 'Unspecified'
          if (!acc[year][term]) {
            acc[year][term] = []
          }
          acc[year][term].push(course)
          return acc
        }, {})

        console.log('Courses grouped by year:', coursesByYear)
        setCourses(coursesByYear)
      } catch (err) {
        console.error('Error fetching courses:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (user) {
      fetchCourses()
    }
  }, [user, currentYear])

  const handleCreateCourse = async () => {
    try {
      const { data, error } = await supabase
        .from('youredu_courses')
        .insert([
          {
            title: newCourse.title,
            year: newCourse.year,
            term_start: newCourse.term_start,
            term_duration: newCourse.term_duration,
            creator_id: user.id,
            teachers: [user.id],
          },
        ])
        .select()
        .single()

      if (error) throw error

      setCourses((prev) => {
        const year = newCourse.year
        return {
          ...prev,
          [year]: {
            ...prev[year],
            [newCourse.term_start]: [...(prev[year][newCourse.term_start] || []), data],
          },
        }
      })

      setIsCreateModalOpen(false)
      setNewCourse({
        title: '',
        year: new Date().getFullYear(),
        term_start: '',
        term_duration: '',
      })

      toast.success('Course created successfully')
    } catch (err) {
      console.error('Error creating course:', err)
      toast.error('Failed to create course')
    }
  }

  const yearSections = Object.keys(courses)
    .sort((a, b) => b - a)
    .map((year) => ({
      year,
      courses: courses[year],
    }))

  console.log('Current year courses:', courses[currentYear])
  console.log('Rendering courses:', Object.entries(courses[currentYear] || {}))

  // Get courses to display based on filter
  const getDisplayedCourses = () => {
    if (showAllYears) {
      // Return all courses grouped by year
      return Object.entries(courses).flatMap(([year, yearCourses]) =>
        Object.entries(yearCourses).map(([term, termCourses]) => (
          <MyCoursesCard
            key={`${year}-${term}`}
            courseGroup={[term, Array.isArray(termCourses) ? termCourses : [termCourses]]}
          />
        ))
      )
    }
    // Return only current year courses
    return Object.entries(courses[currentYear] || {}).map(([term, termCourses], index) => (
      <MyCoursesCard key={index} courseGroup={[term, Array.isArray(termCourses) ? termCourses : [termCourses]]} />
    ))
  }

  return (
    <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 3, py: 6 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 8 }}>
        <Box>
          <FeatureHeader
            sx={{
              fontSize: '2.5rem',
              fontWeight: 700,
              mb: 1,
              color: 'text.primary',
            }}
          >
            My Courses
          </FeatureHeader>
          <DescriptiveText sx={{ color: 'text.secondary' }}>
            Manage and track all your courses in one place
          </DescriptiveText>
        </Box>
        <Button
          onClick={() => setIsCreateModalOpen(true)}
          startIcon={<AddIcon sx={{ fontSize: 20 }} />}
          sx={{
            bgcolor: 'filter.active',
            color: 'common.white',
            '&:hover': {
              bgcolor: 'filter.active',
              opacity: 0.9,
            },
            textTransform: 'none',
            fontWeight: 500,
            height: 40,
            minWidth: 140,
            px: 3,
          }}
        >
          ADD A COURSE
        </Button>
      </Box>

      <Box sx={{ mb: 6 }}>
        <FeatureHeader
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            mb: 2,
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
          }}
        >
          QUICK FILTERS
        </FeatureHeader>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            startIcon={<ListIcon />}
            onClick={() => setShowAllYears(false)}
            sx={{
              bgcolor: !showAllYears ? 'filter.active' : 'filter.inactive',
              color: !showAllYears ? 'common.white' : 'filter.active',
              height: 40,
              minWidth: 160,
              px: 4,
              '&:hover': {
                bgcolor: !showAllYears ? 'filter.active' : 'filter.inactive',
                opacity: 0.9,
              },
            }}
          >
            {`${new Date().getFullYear()}`}
          </Button>
          <Button
            startIcon={<ListIcon />}
            onClick={() => setShowAllYears(true)}
            sx={{
              bgcolor: showAllYears ? 'filter.active' : 'filter.inactive',
              color: showAllYears ? 'common.white' : 'filter.active',
              height: 40,
              minWidth: 120,
              px: 3,
              '&:hover': {
                bgcolor: showAllYears ? 'filter.active' : 'filter.inactive',
                opacity: 0.9,
              },
            }}
          >
            All
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : yearSections.length === 0 ? (
        <div className="text-center p-8 bg-gray-50 rounded-lg">
          <p>No courses added yet. Create your first course to get started.</p>
        </div>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
            gap: 3,
          }}
        >
          {getDisplayedCourses()}
        </Box>
      )}

      <CreateCourseModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        newCourse={newCourse}
        setNewCourse={setNewCourse}
        onCreateCourse={handleCreateCourse}
        termOptions={termOptions}
        durationOptions={durationOptions}
      />
    </Box>
  )
}

export default MyCourses
