// src/components/CourseCard.js
import React from 'react'
import { Box, Card, CardContent, Button, Collapse, Divider, Chip } from '@mui/material'
import SierraCollege from '../../assets/College Logos/Sierra College.png'
import Polygence from '../../assets/polygence.png'
import { useNavigate } from 'react-router-dom'
import { SectionHeader, FeatureHeader, DescriptiveText, BodyText, SupportingText } from '../ui/typography'

const CourseCard = ({ courseGroup, expandedCourses, toggleCourseExpansion, handleRegisterClick, tags = [] }) => {
  const [title = '', courses = []] = courseGroup || []
  const mainCourse = courses?.[0] || {}
  const navigate = useNavigate()
  const [showFullDescription, setShowFullDescription] = React.useState(false)

  const uniqueTerms = courses?.length 
    ? [...new Set(courses.filter(course => course?.term).map(course => course.term))].join(', ') 
    : ''

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
      case 'available':
        return 'hsl(var(--secondary-green))'
      case 'waitlist':
      case 'waitlisting':
        return 'hsl(var(--secondary-orange))'
      case 'closed':
        return 'hsl(var(--destructive))'
      default:
        return 'hsl(var(--secondary-green))'
    }
  }

  if (!mainCourse?.courseTitle || !courses?.length) {
    return null
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Card
        onDoubleClick={() =>
          !mainCourse.preventViewMore && mainCourse.institution && mainCourse.courseCode && 
          navigate(`/course-detail/${mainCourse.institution}/${mainCourse.courseCode}`)
        }
        sx={{
          backgroundColor: 'hsl(var(--background))',
          border: '1px solid hsl(var(--border))',
        }}
      >
        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1, pr: 3 }}>
              {mainCourse.institution && (mainCourse.institution === 'Sierra College' || mainCourse.institution === 'Polygence') && (
                <Box 
                  sx={{ 
                    mb: 2,
                    cursor: 'pointer',
                    display: 'inline-block',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.05)'
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/provider/${mainCourse.institution.toLowerCase().replace(' ', '-')}`);
                  }}
                >
                  <img 
                    src={mainCourse.institution === 'Sierra College' ? SierraCollege : Polygence} 
                    alt={`${mainCourse.institution} Logo`} 
                    style={{ width: '120px', height: 'auto' }} 
                  />
                </Box>
              )}
              <FeatureHeader>{mainCourse.courseTitle || 'Untitled Course'}</FeatureHeader>
              <Box sx={{ display: 'flex', gap: 3, mb: 2 }}>
                <BodyText>Units: {mainCourse.credits || 'N/A'}</BodyText>
                <BodyText>Hours: {mainCourse.totalHours || 'N/A'}</BodyText>
                <BodyText>Subject: {mainCourse.hsSubject || 'N/A'}</BodyText>
              </Box>
              {uniqueTerms && <SupportingText sx={{ mb: 2 }}>Terms Offered: {uniqueTerms}</SupportingText>}
              <Box>
                <DescriptiveText>
                  {mainCourse.description || 'No description available.'}
                </DescriptiveText>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                minWidth: '200px',
                justifyContent: 'space-between',
              }}
            >
              <FeatureHeader sx={{ color: getStatusColor(mainCourse.status) }}>
                {mainCourse.status || 'Available'}
              </FeatureHeader>
              {!mainCourse.preventViewMore && mainCourse.institution && mainCourse.courseCode && (
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/course-detail/${mainCourse.institution}/${mainCourse.courseCode}`)}
                  sx={{
                    mt: 'auto',
                    color: 'hsl(var(--brand-primary))',
                    borderColor: 'hsl(var(--brand-primary))',
                    '&:hover': {
                      backgroundColor: 'hsl(var(--brand-primary-light))',
                      borderColor: 'hsl(var(--brand-primary))',
                    },
                  }}
                >
                  {expandedCourses[mainCourse.courseCode] ? 'Hide Details' : 'View Details'}
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag.title} sx={{ bgcolor: tag.color, color: 'hsl(var(--background))' }} />
            ))}
          </Box>
        </CardContent>
      </Card>
      <Collapse in={expandedCourses[mainCourse.courseCode]}>
        <Card
          sx={{
            mt: 1,
            backgroundColor: 'hsl(var(--background))',
            border: '1px solid hsl(var(--border))',
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <FeatureHeader>Course Description</FeatureHeader>
                <DescriptiveText sx={{ mt: 1 }}>{mainCourse.description}</DescriptiveText>
              </Box>
              {mainCourse.prerequisites && (
                <Box>
                  <BodyText sx={{ fontWeight: 600 }}>Prerequisites: {mainCourse.prerequisites}</BodyText>
                </Box>
              )}
              <Divider sx={{ borderColor: 'hsl(var(--border))' }} />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {courses.map((section) => (
                  <Box
                    key={section.id}
                    sx={{
                      p: 2,
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 2,
                      backgroundColor: 'hsl(var(--muted))',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <BodyText>Professor: {section.instructor}</BodyText>
                        <BodyText>Schedule: {section.courseSchedule}</BodyText>
                        <BodyText>Total Hours: {section.totalHours}</BodyText>
                        <BodyText>Location: {section.location}</BodyText>
                        <BodyText>Term: {section.term}</BodyText>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'flex-end' }}>
                        <BodyText>Capacity: {section.maxStudents}</BodyText>
                        <BodyText>Enrolled: {section.enrolled}</BodyText>
                        <BodyText>Waitlist: {section.waitlisted}</BodyText>
                        <Button
                          variant="contained"
                          onClick={() => handleRegisterClick(section)}
                          disabled={section.enrolled >= section.maxStudents}
                          sx={{
                            mt: 1,
                            backgroundColor: 'hsl(var(--brand-primary))',
                            color: 'hsl(var(--background))',
                            '&:hover': {
                              backgroundColor: 'hsl(var(--brand-primary-dark))',
                            },
                            '&:disabled': {
                              backgroundColor: 'hsl(var(--muted))',
                              color: 'hsl(var(--muted-foreground))',
                            },
                          }}
                        >
                          {section.enrolled >= section.maxStudents ? 'Full' : 'Enroll'}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  )
}

export default CourseCard
