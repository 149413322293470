import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Box,
} from '@mui/material';
import {
  Folder as FolderIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
  CloudDownload as DownloadIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { RecordKeepingService } from '../services/RecordKeepingService';

// Create a single instance of the service
const recordKeepingService = new RecordKeepingService();

const RecordKeeping = () => {
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('other');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalDocuments, setTotalDocuments] = useState(0);

  const categories = [
    { value: 'attendance', label: 'Attendance' },
    { value: 'curriculum', label: 'Curriculum' },
    { value: 'assessments', label: 'Assessments' },
    { value: 'legal', label: 'Legal' },
    { value: 'other', label: 'Other' }
  ];

  const initializeRecordKeeping = async () => {
    setIsLoading(true);
    try {
      // Load folders first
      const loadedFolders = await recordKeepingService.getFolders();
      setFolders(loadedFolders);

      // Check total documents
      const total = await recordKeepingService.getTotalDocumentCount();
      setTotalDocuments(total);

      // If no documents exist, create welcome file
      if (total === 0) {
        const welcomeContent = "Hello, Welcome to YourEDU!";
        const welcomeBlob = new Blob([welcomeContent], { type: 'text/plain' });
        const welcomeFile = new File([welcomeBlob], 'Welcome.txt', { type: 'text/plain' });
        
        // Find the "Other" folder
        const otherFolder = loadedFolders.find(folder => folder.category === 'other');
        if (otherFolder) {
          await recordKeepingService.uploadDocument(welcomeFile, otherFolder.id);
        }
      }

      // Load all documents
      const loadedDocuments = await recordKeepingService.getDocuments();
      setDocuments(loadedDocuments);
    } catch (error) {
      console.error('Error initializing record keeping:', error);
      setError('Failed to initialize record keeping');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeRecordKeeping();
  }, []);

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      setError('Folder name is required');
      return;
    }
    setIsLoading(true);
    try {
      await recordKeepingService.createFolder(newFolderName, selectedCategory);
      const updatedFolders = await recordKeepingService.getFolders();
      setFolders(updatedFolders);
      setIsCreateFolderDialogOpen(false);
      setNewFolderName('');
      setSelectedCategory('other');
    } catch (error) {
      setError('Failed to create folder');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadDocument = async () => {
    if (!selectedFile || !selectedFolder) {
      setError('Please select a file and folder');
      return;
    }
    setIsLoading(true);
    try {
      await recordKeepingService.uploadDocument(selectedFile, selectedFolder);
      const updatedDocuments = await recordKeepingService.getDocuments(selectedFolder);
      setDocuments(updatedDocuments);
      setIsUploadDialogOpen(false);
      setSelectedFile(null);
    } catch (error) {
      setError('Failed to upload document');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadDocument = async (documentId) => {
    setIsLoading(true);
    try {
      await recordKeepingService.downloadDocument(documentId);
    } catch (error) {
      setError('Failed to download document');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    setIsLoading(true);
    try {
      await recordKeepingService.deleteDocument(documentId);
      const updatedDocuments = await recordKeepingService.getDocuments(selectedFolder);
      setDocuments(updatedDocuments);
    } catch (error) {
      setError('Failed to delete document');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFolderClick = async (folderId) => {
    setSelectedFolder(folderId);
    setIsLoading(true);
    try {
      const folderDocuments = await recordKeepingService.getDocuments(folderId);
      setDocuments(folderDocuments);
    } catch (error) {
      setError('Failed to load folder documents');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFolder = async (folderId, event) => {
    event.stopPropagation(); // Prevent folder selection when clicking delete
    setIsLoading(true);
    try {
      await recordKeepingService.deleteFolder(folderId);
      const updatedFolders = await recordKeepingService.getFolders();
      setFolders(updatedFolders);
      if (selectedFolder === folderId) {
        setSelectedFolder(null);
        const allDocuments = await recordKeepingService.getDocuments();
        setDocuments(allDocuments);
      }
    } catch (error) {
      setError('Failed to delete folder');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Page Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ 
          color: '#2d3748', 
          fontWeight: 600,
          mb: 1 
        }}>
          Record Keeping
        </Typography>
        <Typography variant="body2" sx={{ color: '#718096' }}>
          Manage your documents and folders in one place. Upload, organize, and access your files easily.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* Left Column - Folders */}
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2.5,
              borderRadius: 2,
              border: '1px solid #e2e8f0',
              backgroundColor: '#ffffff'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2.5 
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: '#2d3748',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}
              >
                Folders
              </Typography>
              <Button
                startIcon={<AddIcon sx={{ fontSize: 18 }} />}
                onClick={() => setIsCreateFolderDialogOpen(true)}
                sx={{
                  color: '#4299e1',
                  backgroundColor: 'rgba(66, 153, 225, 0.1)',
                  fontSize: '0.875rem',
                  textTransform: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: 'rgba(66, 153, 225, 0.2)',
                  },
                  py: 0.5,
                  px: 1.5,
                  minWidth: 0
                }}
              >
                New
              </Button>
            </Box>
            <List sx={{ '& .MuiListItem-root': { px: 1.5, py: 1 } }}>
              <ListItem
                button
                onClick={() => handleFolderClick(null)}
                selected={selectedFolder === null}
                sx={{
                  borderRadius: 1,
                  mb: 0.5,
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(66, 153, 225, 0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(66, 153, 225, 0.2)',
                    }
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(66, 153, 225, 0.05)',
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <FolderIcon sx={{ color: '#4299e1', fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText 
                  primary={
                    <Typography variant="body2" sx={{ color: '#2d3748', fontWeight: 500 }}>
                      All Documents
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" sx={{ color: '#718096' }}>
                      {totalDocuments} documents
                    </Typography>
                  }
                />
              </ListItem>
              {folders.map((folder) => (
                <ListItem
                  key={folder.id}
                  button
                  onClick={() => handleFolderClick(folder.id)}
                  selected={selectedFolder === folder.id}
                  sx={{
                    borderRadius: 1,
                    mb: 0.5,
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(66, 153, 225, 0.1)',
                      '&:hover': {
                        backgroundColor: 'rgba(66, 153, 225, 0.2)',
                      }
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(66, 153, 225, 0.05)',
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <FolderIcon sx={{ color: '#4299e1', fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography variant="body2" sx={{ color: '#2d3748', fontWeight: 500 }}>
                        {folder.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" sx={{ color: '#718096' }}>
                        {folder.documentCount || 0} documents
                      </Typography>
                    }
                  />
                  {!folder.is_default && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={(e) => handleDeleteFolder(folder.id, e)}
                        sx={{ 
                          color: '#718096',
                          '&:hover': {
                            backgroundColor: 'rgba(113, 128, 150, 0.1)',
                            color: '#e53e3e'
                          }
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Right Column - Documents */}
        <Grid item xs={12} md={9}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2.5,
              borderRadius: 2,
              border: '1px solid #e2e8f0',
              backgroundColor: '#ffffff'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2.5
            }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: '#2d3748',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}
              >
                Documents
              </Typography>
              <Button
                startIcon={<AddIcon sx={{ fontSize: 18 }} />}
                onClick={() => setIsUploadDialogOpen(true)}
                sx={{
                  backgroundColor: '#4299e1',
                  color: 'white',
                  fontSize: '0.875rem',
                  textTransform: 'none',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: '#2b6cb0',
                  },
                  py: 0.75,
                  px: 2
                }}
              >
                Upload Document
              </Button>
            </Box>
            
            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 2,
                  borderRadius: 1,
                  '& .MuiAlert-message': {
                    color: '#e53e3e',
                    fontSize: '0.875rem'
                  }
                }}
              >
                {error}
              </Alert>
            )}
            
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress size={32} sx={{ color: '#4299e1' }} />
              </Box>
            ) : (
              <List sx={{ '& .MuiListItem-root': { px: 2, py: 1.5 } }}>
                {documents.map((document) => (
                  <ListItem 
                    key={document.id}
                    sx={{
                      borderRadius: 1,
                      mb: 0.5,
                      '&:hover': {
                        backgroundColor: '#f7fafc',
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <DescriptionIcon sx={{ color: '#4299e1', fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body2" sx={{ color: '#2d3748', fontWeight: 500 }}>
                          {document.name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" sx={{ color: '#718096' }}>
                          Uploaded {new Date(document.created_at).toLocaleDateString()} • {Math.round(document.size_kb)} KB
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        size="small"
                        onClick={() => handleDownloadDocument(document.id)}
                        sx={{ 
                          color: '#4299e1',
                          '&:hover': {
                            backgroundColor: 'rgba(66, 153, 225, 0.1)',
                          }
                        }}
                      >
                        <DownloadIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteDocument(document.id);
                        }}
                        sx={{ 
                          color: '#718096',
                          ml: 1,
                          '&:hover': {
                            backgroundColor: 'rgba(113, 128, 150, 0.1)',
                            color: '#e53e3e'
                          }
                        }}
                      >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Create Folder Dialog */}
      <Dialog 
        open={isCreateFolderDialogOpen} 
        onClose={() => setIsCreateFolderDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e2e8f0',
          px: 3,
          py: 2
        }}>
          <Typography variant="subtitle1" sx={{ color: '#2d3748', fontWeight: 600 }}>
            Create New Folder
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            sx={{
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e2e8f0',
                },
                '&:hover fieldset': {
                  borderColor: '#4299e1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4299e1',
                },
              }
            }}
          />
          <TextField
            select
            margin="dense"
            label="Category"
            fullWidth
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e2e8f0',
                },
                '&:hover fieldset': {
                  borderColor: '#4299e1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4299e1',
                },
              }
            }}
          >
            {categories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid #e2e8f0',
          px: 3,
          py: 2
        }}>
          <Button 
            onClick={() => setIsCreateFolderDialogOpen(false)}
            sx={{
              color: '#718096',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'rgba(113, 128, 150, 0.1)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateFolder} 
            variant="contained"
            sx={{
              backgroundColor: '#4299e1',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#2b6cb0',
              }
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Document Dialog */}
      <Dialog 
        open={isUploadDialogOpen} 
        onClose={() => setIsUploadDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #e2e8f0',
          px: 3,
          py: 2
        }}>
          <Typography variant="subtitle1" sx={{ color: '#2d3748', fontWeight: 600 }}>
            Upload Document
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box 
            sx={{ 
              border: '2px dashed #e2e8f0',
              borderRadius: 1,
              p: 3,
              mb: 2,
              textAlign: 'center',
              backgroundColor: '#f7fafc'
            }}
          >
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button
                component="span"
                startIcon={<AddIcon />}
                sx={{
                  color: '#4299e1',
                  backgroundColor: 'rgba(66, 153, 225, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(66, 153, 225, 0.2)',
                  },
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                Choose File
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 2, color: '#4a5568' }}>
                Selected: {selectedFile.name}
              </Typography>
            )}
          </Box>
          <TextField
            select
            margin="dense"
            label="Select Folder"
            fullWidth
            value={selectedFolder || ''}
            onChange={(e) => setSelectedFolder(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e2e8f0',
                },
                '&:hover fieldset': {
                  borderColor: '#4299e1',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4299e1',
                },
              }
            }}
          >
            {folders.map((folder) => (
              <MenuItem key={folder.id} value={folder.id}>
                {folder.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid #e2e8f0',
          px: 3,
          py: 2
        }}>
          <Button 
            onClick={() => setIsUploadDialogOpen(false)}
            sx={{
              color: '#718096',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: 'rgba(113, 128, 150, 0.1)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleUploadDocument} 
            variant="contained"
            disabled={!selectedFile || !selectedFolder}
            sx={{
              backgroundColor: '#4299e1',
              textTransform: 'none',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#2b6cb0',
              }
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RecordKeeping; 