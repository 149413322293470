import { supabase } from '../supabase/client';

export const IdCardService = {
  // Upload an image (photo or logo) to storage
  async uploadImage(file, path) {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${path}/${fileName}`;

      const { data, error } = await supabase.storage
        .from('id-cards')
        .upload(filePath, file);

      if (error) throw error;

      const { data: { publicUrl } } = supabase.storage
        .from('id-cards')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  },

  // Create a new ID card
  async createIdCard(cardData) {
    try {
      console.log('Creating ID card with data:', cardData);

      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      // Upload photo if provided
      let photoUrl = cardData.photo_url || null;
      if (cardData.photo) {
        photoUrl = await this.uploadImage(cardData.photo, 'photos');
      }

      // Upload school logo if provided
      let schoolLogoUrl = null;
      if (cardData.schoolLogo) {
        schoolLogoUrl = await this.uploadImage(cardData.schoolLogo, 'logos');
      }

      // Only include fields that exist in the id_cards table
      const insertData = {
        user_id: user.id,
        type: cardData.type,
        first_name: cardData.first_name,
        last_name: cardData.last_name,
        school_name: cardData.school_name,
        school_logo_url: schoolLogoUrl,
        school_address: cardData.school_address,
        school_phone: cardData.school_phone,
        photo_url: cardData.photo_url,
        expiration_date: cardData.expiration_date
      };

      // Only include grade if it exists (for student cards)
      if (cardData.grade) {
        insertData.grade = cardData.grade;
      }

      console.log('Inserting card data:', insertData);

      const { data, error } = await supabase
        .from('id_cards')
        .insert(insertData)
        .select()
        .single();

      if (error) {
        console.error('Database error:', error);
        throw error;
      }
      
      console.log('Card created successfully:', data);
      return data;
    } catch (error) {
      console.error('Error creating ID card:', error);
      throw error;
    }
  },

  // Get all ID cards for the current user
  async getUserIdCards() {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('id_cards')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching ID cards:', error);
      throw error;
    }
  },

  // Delete an ID card and its associated images
  async deleteIdCard(id) {
    try {
      // Get the card first to get image URLs
      const { data: card, error: fetchError } = await supabase
        .from('id_cards')
        .select('*')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Delete images from storage if they exist
      if (card.photo_url) {
        const photoPath = card.photo_url.split('/').pop();
        await supabase.storage
          .from('id-cards')
          .remove([`photos/${photoPath}`]);
      }

      if (card.school_logo_url) {
        const logoPath = card.school_logo_url.split('/').pop();
        await supabase.storage
          .from('id-cards')
          .remove([`logos/${logoPath}`]);
      }

      // Delete the card record
      const { error: deleteError } = await supabase
        .from('id_cards')
        .delete()
        .eq('id', id);

      if (deleteError) throw deleteError;
    } catch (error) {
      console.error('Error deleting ID card:', error);
      throw error;
    }
  }
}; 