import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { PageHeader, DescriptiveText } from '../components/ui/typography.jsx';
import { BsDownload, BsPrinter, BsArrowLeft, BsTrash } from 'react-icons/bs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import Barcode from 'react-barcode';
import { supabase } from '../lib/supabase';
import { CircularProgress } from '@mui/material';
import { cardStyles } from '../styles/theme/components/cards';

const IdCard = ({ card, onDownload, onPrint, onDelete }) => {
  const cardRef = React.useRef();

  const renderMembershipCard = () => (
    <div style={styles.membershipCard}>
      <div style={styles.membershipHeader}>
        <img 
          src={require('../assets/logo.png')}
          alt="YourEDU Logo" 
          style={styles.membershipLogo}
          onError={(e) => {
            console.error('Logo failed to load:', e);
            e.target.style.display = 'none';
          }}
        />
        <h2 style={styles.membershipTitle}>YourEDU Membership Card</h2>
      </div>
      <div style={styles.membershipContent}>
        {card.photo_url ? (
          <img 
            src={card.photo_url}
            alt="Member Photo" 
            style={styles.membershipPhoto}
            onError={(e) => {
              console.error('Profile photo failed to load:', e);
              console.log('Attempted photo URL:', card.photo_url);
              const placeholder = document.createElement('div');
              Object.assign(placeholder.style, {
                width: '80px',
                height: '80px',
                borderRadius: '40px',
                backgroundColor: '#00356B',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '32px',
                fontWeight: '500',
                border: '3px solid #00356B'
              });
              placeholder.textContent = card.first_name.charAt(0).toUpperCase();
              e.target.parentElement.replaceChild(placeholder, e.target);
            }}
          />
        ) : (
          <div style={styles.membershipPhotoPlaceholder}>
            {card.first_name.charAt(0)}
          </div>
        )}
        <div style={styles.membershipInfo}>
          <h3 style={styles.memberName}>{card.first_name}</h3>
          <p style={styles.membershipId}>Member ID: {card.user_id}</p>
          <p style={styles.membershipExpiry}>Expires: {new Date(card.expiration_date).toLocaleDateString()}</p>
        </div>
      </div>
      {card.user_id && (
        <div style={styles.membershipBarcode}>
          <Barcode 
            value={card.user_id} 
            width={1.5}
            height={40} 
            fontSize={12}
            background="#ffffff"
          />
        </div>
      )}
    </div>
  );

  return (
    <div style={styles.idCardContainer}>
      <div ref={cardRef} style={styles.idCard}>
        {card.type === 'membership' ? (
          renderMembershipCard()
        ) : (
          <>
            {card.school_logo_url && (
              <img 
                src={card.school_logo_url} 
                alt="School Logo" 
                style={styles.logo}
              />
            )}
            {card.photo_url && (
              <img 
                src={card.photo_url} 
                alt="ID Photo" 
                style={styles.photo}
              />
            )}
            <div style={styles.cardInfo}>
              <h3 style={styles.schoolName}>{card.school_name}</h3>
              <p style={styles.name}>{card.first_name} {card.last_name}</p>
              {card.type === 'student' && card.grade && (
                <p style={styles.detail}>Grade: {card.grade}</p>
              )}
              <p style={styles.type}>{card.type.toUpperCase()}</p>
              <p style={styles.detail}>{card.school_address}</p>
              <p style={styles.detail}>Phone: {card.school_phone}</p>
              <p style={styles.expiry}>Expires: {new Date(card.expiration_date).toLocaleDateString()}</p>
            </div>
            {card.user_id && (
              <div style={styles.barcode}>
                <Barcode value={card.user_id} width={1} height={40} fontSize={12} />
              </div>
            )}
          </>
        )}
      </div>
      <div style={styles.cardActions}>
        <button 
          onClick={() => onDownload(cardRef.current)}
          style={styles.actionButton}
        >
          <BsDownload /> Download
        </button>
        <button 
          onClick={() => onPrint(cardRef.current)}
          style={styles.actionButton}
        >
          <BsPrinter /> Print
        </button>
        <button 
          onClick={() => onDelete(card.id)}
          style={styles.deleteButton}
        >
          <BsTrash /> Delete
        </button>
      </div>
    </div>
  );
};

const IdGenerationView = () => {
  const navigate = useNavigate();
  const [cards, setCards] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    loadCards();
  }, []);

  const loadCards = async () => {
    try {
      const { IdCardService } = await import('../services/IdCardService');
      const loadedCards = await IdCardService.getUserIdCards();
      setCards(loadedCards);
    } catch (error) {
      console.error('Error loading cards:', error);
      toast.error('Failed to load ID cards');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this ID card?')) return;

    try {
      const { IdCardService } = await import('../services/IdCardService');
      await IdCardService.deleteIdCard(id);
      toast.success('ID card deleted successfully');
      await loadCards();
    } catch (error) {
      console.error('Error deleting card:', error);
      toast.error('Failed to delete ID card');
    }
  };

  const handleDownload = async (element) => {
    try {
      const canvas = await html2canvas(element, {
        scale: 2, // Increase quality
        useCORS: true, // Enable cross-origin image loading
        backgroundColor: '#f8fafc',
        logging: false,
        onclone: (clonedDoc) => {
          // Apply computed styles to cloned element
          const clonedElement = clonedDoc.querySelector('[ref="cardRef"]');
          if (clonedElement) {
            clonedElement.style.width = '600px'; // Fixed width for better quality
            clonedElement.style.margin = '0';
            clonedElement.style.padding = '24px';
          }
        }
      });

      // Create PDF with proper dimensions
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pdf = new jsPDF({
        orientation: imgHeight > imgWidth ? 'portrait' : 'landscape',
        unit: 'mm',
        format: 'a4'
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST');
      pdf.save(`id-card-${Date.now()}.pdf`);
      
      toast.success('ID card downloaded successfully');
    } catch (error) {
      console.error('Error downloading card:', error);
      toast.error('Failed to download ID card');
    }
  };

  const handlePrint = async (element) => {
    try {
      const canvas = await html2canvas(element, {
        scale: 2, // Increase quality
        useCORS: true, // Enable cross-origin image loading
        backgroundColor: '#f8fafc',
        logging: false,
        onclone: (clonedDoc) => {
          // Apply computed styles to cloned element
          const clonedElement = clonedDoc.querySelector('[ref="cardRef"]');
          if (clonedElement) {
            clonedElement.style.width = '600px'; // Fixed width for better quality
            clonedElement.style.margin = '0';
            clonedElement.style.padding = '24px';
          }
        }
      });

      const win = window.open('', '', 'width=800,height=600');
      win.document.write(`
        <html>
          <head>
            <title>Print ID Card</title>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                background-color: #f8fafc;
              }
              img {
                max-width: 100%;
                height: auto;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
              }
              @media print {
                body {
                  background-color: white;
                }
                img {
                  max-width: 100%;
                  box-shadow: none;
                }
              }
            </style>
          </head>
          <body>
            <img src="${canvas.toDataURL('image/jpeg', 1.0)}" />
          </body>
        </html>
      `);
      win.document.close();
      
      // Wait for images to load before printing
      setTimeout(() => {
        win.focus();
        win.print();
        win.close();
      }, 250);

      toast.success('Print window opened');
    } catch (error) {
      console.error('Error printing card:', error);
      toast.error('Failed to print ID card');
    }
  };

  const handleGenerateMembershipCard = async () => {
    try {
      // Get the user's UUID first
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not found');

      // Fetch user profile data
      const { data: profileData, error: profileError } = await supabase
        .from('account_profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;

      // Log profile data to check what we're getting
      console.log('Profile Data:', profileData);

      if (!profileData) {
        toast.error('Profile data not found. Please complete your profile first.');
        return;
      }

      // Check for required fields using the correct field names
      if (!profileData.name || !profileData.email || !profileData.street_address) {
        toast.error('Please complete your profile. Required fields: Name, Email, and Street Address');
        return;
      }

      // Get profile picture URL if it exists
      let photoUrl = '';
      if (profileData.profile_picture) {
        const { data: urlData } = supabase.storage
          .from('profile-pictures')
          .getPublicUrl(`${user.id}/profile.jpg`);
        
        if (urlData?.publicUrl) {
          // Add timestamp to force cache refresh
          photoUrl = `${urlData.publicUrl}?t=${new Date().getTime()}`;
          console.log('Photo URL set to:', photoUrl);
        }
      }

      // Create membership card data
      const cardData = {
        type: 'membership',
        first_name: profileData.name,
        last_name: '',
        email: profileData.email,
        address: `${profileData.street_address}, ${profileData.city || ''}, ${profileData.state || ''} ${profileData.zip || ''}`,
        photo_url: photoUrl,
        expiration_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(),
        user_id: user.id,
        school_name: 'YourEDU',
        school_address: profileData.street_address,
        school_phone: profileData.phone || ''
      };

      console.log('Final card data:', cardData);

      // Create the membership card
      const { IdCardService } = await import('../services/IdCardService');
      await IdCardService.createIdCard(cardData);
      
      // Refresh the cards list
      await loadCards();
      
      toast.success('YourEDU Membership Card generated successfully!');
    } catch (error) {
      console.error('Error generating membership card:', error);
      toast.error('Failed to generate membership card. Please try again.');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f8fafc' }}>
      {/* Back button section */}
      <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2 }}>
        <Container maxWidth="lg">
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/my-homeschool')}
            sx={{ color: '#00356b' }}
          >
            BACK TO MY HOMESCHOOL
          </Button>
        </Container>
      </Box>

      {/* Title section with gray background */}
      <Box sx={{ backgroundColor: '#f8fafc', py: 6, borderBottom: '1px solid #e2e8f0' }}>
        <Container maxWidth="lg">
          <PageHeader sx={{ color: '#1a202c', mb: 2 }}>
            View Generated IDs
          </PageHeader>
          <DescriptiveText sx={{ color: '#4a5568', maxWidth: '65ch' }}>
            Access and manage your previously generated student identification cards. Download, print, or request replacements for existing IDs.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <div style={styles.content}>
          <div style={styles.buttonGroup}>
            <button
              onClick={handleGenerateMembershipCard}
              style={styles.membershipButton}
            >
              Generate YourEDU Membership Card
            </button>
            <button
              onClick={() => navigate('/id-generation')}
              style={styles.generateButton}
            >
              Generate Student/Teacher ID Card
            </button>
          </div>

          {isLoading ? (
            <div style={styles.loading}>
              <CircularProgress />
              <p>Loading ID cards...</p>
            </div>
          ) : cards.length === 0 ? (
            <div style={styles.emptyState}>
              <div style={styles.emptyStateContent}>
                <h2>No ID Cards Generated Yet</h2>
                <p>Click one of the buttons above to generate your first ID card!</p>
              </div>
            </div>
          ) : (
            <div style={styles.cardGrid}>
              {cards.map((card) => (
                <IdCard
                  key={card.id}
                  card={card}
                  onDownload={handleDownload}
                  onPrint={handlePrint}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '24px',
  },
  content: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  buttonGroup: {
    display: 'flex',
    gap: '16px',
    marginBottom: '32px',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  generateButton: {
    backgroundColor: '#00356B',
    color: '#ffffff',
    padding: '14px 28px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  membershipButton: {
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    padding: '14px 28px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#388E3C',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '48px',
    color: '#4A5568',
  },
  emptyState: {
    textAlign: 'center',
    padding: '48px',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    borderRadius: '8px',
  },
  emptyStateContent: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    gap: '24px',
    padding: '24px 0',
  },
  idCardContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '24px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  idCard: {
    aspectRatio: '1.586',
    width: '600px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    border: '1px solid #E2E8F0',
    margin: '0 auto',
  },
  logo: {
    width: '80px',
    height: '80px',
    objectFit: 'contain',
  },
  photo: {
    width: '120px',
    height: '120px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  cardInfo: {
    textAlign: 'center',
    width: '100%',
  },
  schoolName: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '8px',
  },
  name: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#4A5568',
    marginBottom: '4px',
  },
  type: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#2B6CB0',
    marginBottom: '8px',
  },
  detail: {
    fontSize: '14px',
    color: '#4A5568',
    marginBottom: '4px',
  },
  expiry: {
    fontSize: '12px',
    color: '#718096',
    marginTop: '8px',
  },
  cardActions: {
    display: 'flex',
    gap: '12px',
    marginTop: '16px',
    justifyContent: 'center',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    backgroundColor: '#EBF8FF',
    color: '#2B6CB0',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#BEE3F8',
    },
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    backgroundColor: '#FED7D7',
    color: '#C53030',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#FEB2B2',
    },
  },
  barcode: {
    marginTop: '16px',
    textAlign: 'center',
  },
  membershipCard: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '2px solid #00356B',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative'
  },
  membershipHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    marginBottom: '10px',
    borderBottom: '2px solid #00356B',
    paddingBottom: '8px',
  },
  membershipLogo: {
    width: '32px',
    height: '32px',
    objectFit: 'contain',
  },
  membershipTitle: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#00356B',
    margin: 0,
  },
  membershipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '12px',
  },
  membershipPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    objectFit: 'cover',
    border: '3px solid #00356B',
    backgroundColor: '#f0f0f0',
  },
  membershipPhotoPlaceholder: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    backgroundColor: '#00356B',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    fontWeight: '500',
    border: '3px solid #00356B',
  },
  membershipInfo: {
    width: '100%',
    textAlign: 'center',
    marginTop: '6px',
  },
  memberName: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '4px',
    marginTop: 0,
  },
  membershipId: {
    fontSize: '10px',
    color: '#4A5568',
    marginBottom: '2px',
    fontFamily: 'monospace',
  },
  membershipExpiry: {
    fontSize: '10px',
    color: '#718096',
    marginBottom: '4px',
  },
  membershipBarcode: {
    marginTop: 'auto',
    width: '100%',
    paddingTop: '10px',
    borderTop: '2px solid #00356B',
    display: 'flex',
    justifyContent: 'center',
  },
};

export default IdGenerationView; 