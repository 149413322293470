import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';

const LoginSelection = () => {
  const navigate = useNavigate();

  const handleSelection = (userType) => {
    localStorage.setItem('userType', userType);
    navigate('/login', { state: { showSignup: false } });
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h1 style={styles.title}>Welcome to YourEDU</h1>
        <p style={styles.subtitle}>Select your educational journey</p>
        
        <div style={styles.buttonContainer}>
          <button 
            onClick={() => handleSelection('highschool')} 
            style={{...styles.button, backgroundColor: '#2c5282'}}
          >
            <span style={styles.buttonTitle}>K-12 Parent</span>
          </button>
          
          <button 
            onClick={() => handleSelection('student')} 
            style={{...styles.button, backgroundColor: '#48bb78'}}
          >
            <span style={styles.buttonTitle}>K-12 Student</span>
          </button>
          
          <button 
            onClick={() => handleSelection('college')} 
            style={{...styles.button, backgroundColor: '#4299e1'}}
          >
            <span style={styles.buttonTitle}>Common App</span>
          </button>
          
          <button 
            onClick={() => handleSelection('lafire')} 
            style={{...styles.button, backgroundColor: '#e53e3e'}}
          >
            <span style={styles.buttonTitle}>LA Fire Education Aid</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
    padding: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '600px',
    width: '100%',
    padding: '40px',
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: '0 10px 25px rgba(0,0,0,0.1)',
  },
  logo: {
    width: '80px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '32px',
    color: '#1a365d',
    marginBottom: '10px',
    textAlign: 'center',
    fontWeight: '600',
  },
  subtitle: {
    fontSize: '18px',
    color: '#4a5568',
    marginBottom: '40px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    maxWidth: '400px',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
    },
  },
  buttonTitle: {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '5px',
  },
  buttonDescription: {
    fontSize: '14px',
    opacity: '0.9',
  },
};

export default LoginSelection;

