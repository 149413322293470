import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../utils/AuthContext'
import ChatBot from '../components/ChatBot'
import { PageHeader, FeatureHeader, BodyText, SupportingText, DescriptiveText, SectionHeader } from '../components/ui/typography.jsx'
import theme from '../styles/theme'
import { cardStyles } from '../styles/theme/components/cards'
import {
  Container,
  Grid,
  Paper,
  Box,
  List,
  IconButton,
  Card,
  CardContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Checkbox,
} from '@mui/material'
import {
  NotificationsOutlined as NotificationsIcon,
  CalendarToday as CalendarIcon,
  Chat as ChatIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  School as SchoolIcon,
  Close as CloseIcon,
} from '@mui/icons-material'

const mockAnnouncements = [
  {
    id: 1,
    title: 'New Community Event',
    subtitle: 'Join our upcoming virtual meetup this weekend',
    date: 'Just now',
  },
  {
    id: 2,
    title: 'New Perk Available',
    subtitle: 'Access to Khan Academy Pro - Click to learn more',
    date: '1 day ago',
  },
  {
    id: 3,
    title: 'Community Achievement',
    subtitle: 'Your recent post received 10 helpful votes',
    date: '2 days ago',
  },
  {
    id: 4,
    title: 'Resource Library Update',
    subtitle: 'New SAT prep materials added',
    date: '3 days ago',
  },
]

const mockCalendarEvents = [
  {
    id: 1,
    title: 'Webinar: Homeschool to College',
    subtitle: 'Join us for expert insights on college admissions',
    date: 'Jan 15, 1:00 PM',
  },
  {
    id: 2,
    title: 'Compliance Reminder: PSA Filing',
    subtitle: 'Private School Affidavit Due',
    date: 'Jan 1, 2025',
  },
  {
    id: 3,
    title: 'Math Course Deadline',
    date: 'Tomorrow, 3:00 PM',
  },
  {
    id: 4,
    title: 'Community Meetup',
    date: 'Jan 25, 2:00 PM',
  },
]

const mockTodos = [
  { text: 'Complete Math Module 3 Assessment', dueDate: '2024-01-25' },
  { text: 'Submit Science Lab Report', dueDate: '2024-01-28' },
  { text: 'Register for Spring Semester', dueDate: '2024-02-01' },
]

const welcomeTasks = [
  {
    id: 1,
    title: '1. Complete Your Profile',
    description: 'Get personalized recommendations in just 2 minutes',
    completed: false,
    action: '/my-account'
  },
  {
    id: 2,
    title: '2. Join Your First Event',
    description: 'Connect with 500+ homeschool families in virtual meetups',
    completed: false,
    action: '/events'
  },
  {
    id: 3,
    title: '3. Register for a Course',
    description: 'Find your perfect match from 100+ expert-led courses',
    completed: false,
    action: '/course-marketplace'
  },
  {
    id: 4,
    title: '4. Join a Study Group',
    description: 'Learn together with students who share your interests',
    completed: false,
    action: '/groups'
  }
];

const Home = () => {
  const { user } = useAuth()
  const firstName = (user?.user_metadata?.name || user?.email?.split('@')[0] || 'Parent')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  const [isChatOpen, setIsChatOpen] = useState(false)
  const navigate = useNavigate();
  const [tasks, setTasks] = useState(welcomeTasks);

  const formatDeadline = (date) => {
    const deadline = new Date(date)
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (deadline.toDateString() === today.toDateString()) {
      return 'Today'
    } else if (deadline.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow'
    } else {
      return deadline.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
  }

  const handleRemoveTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const handleTaskClick = (action) => {
    navigate(action);
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-4)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Parent Dashboard
          </PageHeader>
          <DescriptiveText 
            sx={{ maxWidth: 'var(--text-max-width)' }}
          >
            Welcome to your personalized dashboard. Access all your homeschool tools and resources, 
            track progress, and manage your homeschool journey in one place.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-4)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Quick Action Cards */}
        <Box sx={{ mb: 'var(--spacing-4)' }}>
          <SectionHeader sx={{ mb: 2 }}>Quick Actions</SectionHeader>
          <Grid container spacing={3}>
            {[
              {
                title: 'My Students',
                subtitle: 'Academic Overview',
                icon: SchoolIcon,
                path: '/home-parent-academics',
                color: 'hsl(var(--brand-primary))',
                bgColor: 'hsla(var(--brand-primary), 0.1)',
              },
              {
                title: 'Calendar',
                subtitle: 'Schedule & Events',
                icon: CalendarIcon,
                path: '/calendar',
                color: 'hsl(var(--secondary-green))',
                bgColor: 'hsla(var(--secondary-green), 0.1)',
              },
              {
                title: 'Community',
                subtitle: 'Connect & Share',
                icon: ChatIcon,
                path: '/community',
                color: 'hsl(var(--secondary-orange))',
                bgColor: 'hsla(var(--secondary-orange), 0.1)',
              },
              {
                title: 'Support',
                subtitle: 'Help & Resources',
                icon: PhoneIcon,
                path: '/support',
                color: 'hsl(var(--secondary-purple))',
                bgColor: 'hsla(var(--secondary-purple), 0.1)',
              },
            ].map((action) => (
              <Grid item xs={12} sm={6} md={3} key={action.title}>
                <Card
                  component={Link}
                  to={action.path}
                  sx={{
                    ...cardStyles.default,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 'var(--spacing-4)',
                    transition: 'all 0.2s ease-in-out',
                    textDecoration: 'none',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 'var(--shadow-lg)',
                      borderColor: action.color,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: 'var(--radius)',
                      backgroundColor: action.bgColor,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: action.color,
                    }}
                  >
                    <action.icon />
                  </Box>
                  <Box>
                    <FeatureHeader sx={{ color: 'hsl(var(--text-primary))' }}>
                      {action.title}
                    </FeatureHeader>
                    <SupportingText>{action.subtitle}</SupportingText>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container spacing={3}>
          {/* Tasks Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ 
              height: '100%', 
              ...cardStyles.default,
              background: 'linear-gradient(to bottom, hsla(var(--brand-primary), 0.05), transparent)',
              borderWidth: '2px',
            }}>
              <CardContent sx={{ p: 2 }}>
                <Box sx={{
                  mb: 2,
                  pb: 2,
                  borderBottom: '1px solid hsla(var(--brand-primary), 0.2)',
                }}>
                  <SectionHeader sx={{ 
                    color: 'hsl(var(--brand-primary))',
                    fontSize: '1.3rem',
                    mb: 1.5,
                  }}>
                    Get Started with Your EDU
                  </SectionHeader>
                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: 'var(--radius)',
                      backgroundColor: 'hsla(var(--brand-primary), 0.08)',
                      border: '1px solid hsla(var(--brand-primary), 0.2)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <span role="img" aria-label="sparkles" style={{ fontSize: '1.2em' }}>✨</span>
                    <SupportingText sx={{ 
                      color: 'hsl(var(--brand-primary))',
                      fontWeight: 500,
                      fontSize: '0.9rem',
                      lineHeight: 1.3,
                    }}>
                      Complete these 4 steps to unlock your full homeschool experience
                    </SupportingText>
                  </Box>
                </Box>
                <List sx={{ px: 0.5 }}>
                  {tasks.map((task) => (
                    <ListItem 
                      key={task.id}
                      sx={{ 
                        mb: 1.5,
                        backgroundColor: 'white',
                        borderRadius: 'var(--radius)',
                        border: '1px solid hsl(var(--border))',
                        position: 'relative',
                        transition: 'all 0.2s ease-in-out',
                        p: 1.5,
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: 'var(--shadow-lg)',
                          borderColor: 'hsl(var(--brand-primary))',
                          backgroundColor: 'hsla(var(--brand-primary), 0.02)',
                        },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <Checkbox
                          checked={task.completed}
                          sx={{
                            color: 'hsl(var(--text-secondary))',
                            '&.Mui-checked': {
                              color: 'hsl(var(--success))',
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1.2rem',
                            },
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <BodyText sx={{ 
                            fontWeight: 600,
                            color: task.completed ? 'hsl(var(--success))' : 'hsl(var(--brand-primary))',
                            fontSize: '1rem',
                            mb: 0.2,
                          }}>
                            {task.title}
                          </BodyText>
                        }
                        secondary={
                          <SupportingText sx={{
                            color: 'hsl(var(--text-secondary))',
                            lineHeight: 1.2,
                            fontSize: '0.85rem',
                          }}>
                            {task.description}
                          </SupportingText>
                        }
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleTaskClick(task.action)}
                      />
                      <IconButton 
                        onClick={() => handleRemoveTask(task.id)}
                        sx={{ 
                          position: 'absolute',
                          right: 4,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: 'hsl(var(--text-secondary))',
                          opacity: 0.6,
                          padding: 0.5,
                          '&:hover': {
                            color: 'hsl(var(--destructive))',
                            opacity: 1,
                          },
                          zIndex: 1,
                        }}
                      >
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* Events Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', ...cardStyles.default }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <SectionHeader>Events</SectionHeader>
                  <Button 
                    variant="outlined" 
                    onClick={() => navigate('/events')}
                    sx={{
                      color: 'hsl(var(--brand-primary))',
                      borderColor: 'hsl(var(--brand-primary))',
                      '&:hover': {
                        borderColor: 'hsl(var(--brand-primary-dark))',
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                      },
                    }}
                  >
                    View Events
                  </Button>
                </Box>
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <DescriptiveText>
                    No upcoming events yet. Check back soon or browse all events!
                  </DescriptiveText>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Updates Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', ...cardStyles.default }}>
              <CardContent>
                <SectionHeader sx={{ mb: 2 }}>Updates</SectionHeader>
                <Box sx={{ textAlign: 'center', py: 4 }}>
                  <DescriptiveText>
                    No new updates at this time. Check back soon!
                  </DescriptiveText>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <ChatBot isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </Box>
  )
}

export default Home
