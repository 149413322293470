import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Container, 
    Paper, 
    Typography, 
    Box,
    Grid,
    Button,
    TextField,
} from '@mui/material';
import {
    Chat as ChatIcon,
    Email as EmailIcon,
    Phone as PhoneIcon,
    CalendarToday as CalendarIcon,
    ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import ChatBot from '../components/ChatBot';
import PageHeader from '../components/PageHeader';
import DescriptiveText from '../components/DescriptiveText';
import { cardStyles } from '../styles/theme/components/cards';

const Support = () => {
    const navigate = useNavigate();
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log({ name, email, message });
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            {/* Hero Section */}
            <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (--tablet)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate('/college')}
                        sx={{ 
                            mb: 2,
                            color: 'hsl(var(--text-primary))',
                            '&:hover': {
                                backgroundColor: 'hsl(var(--neutral-50))',
                            }
                        }}
                    >
                        Back to College
                    </Button>
                    <PageHeader 
                        sx={{ 
                            mb: 'var(--spacing-2)',
                        }}
                    >
                        Support Center
                    </PageHeader>
                    <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                        Get help with your homeschooling journey. Find answers to common questions and access our support resources.
                    </DescriptiveText>
                </Container>
            </Box>

            {/* Main Content */}
            <Container 
                maxWidth="var(--container-max-width)"
                sx={{ 
                    px: 'var(--container-padding-x)',
                    py: 'var(--spacing-6)',
                    '@media (--tablet)': {
                        px: 'var(--container-padding-x-mobile)',
                    },
                }}
            >
                <Grid container spacing={3}>
                    {/* Contact Options */}
                    <Grid item xs={12} md={6}>
                        <Paper 
                            elevation={0}
                            sx={{ 
                                p: 3,
                                borderRadius: 3,
                                backgroundColor: 'white',
                                border: '1px solid #e2e8f0'
                            }}
                        >
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    mb: 3, 
                                    color: '#2d3748',
                                    fontWeight: 600
                                }}
                            >
                                Contact Options
                            </Typography>
                            
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<ChatIcon />}
                                    onClick={() => setIsChatOpen(true)}
                                    sx={{ 
                                        py: 2,
                                        justifyContent: 'flex-start',
                                        borderColor: '#e2e8f0',
                                        color: '#2d3748',
                                        borderRadius: 2,
                                        backgroundColor: '#f8fafc',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            borderColor: '#4299e1',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    Chat with Us
                                    <Box 
                                        component="span" 
                                        sx={{ 
                                            ml: 'auto',
                                            px: 1.5, 
                                            py: 0.5, 
                                            borderRadius: 1,
                                            backgroundColor: '#ebf8ff',
                                            color: '#4299e1',
                                            fontSize: '0.75rem',
                                            fontWeight: 600
                                        }}
                                    >
                                        Available Now
                                    </Box>
                                </Button>

                                <Button
                                    variant="outlined"
                                    startIcon={<PhoneIcon />}
                                    href="tel:+1234567890"
                                    sx={{ 
                                        py: 2,
                                        justifyContent: 'flex-start',
                                        borderColor: '#e2e8f0',
                                        color: '#2d3748',
                                        borderRadius: 2,
                                        backgroundColor: '#f8fafc',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            borderColor: '#4299e1',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    Call Us: (123) 456-7890
                                </Button>

                                <Button
                                    variant="outlined"
                                    startIcon={<EmailIcon />}
                                    href="mailto:support@youredu.school"
                                    sx={{ 
                                        py: 2,
                                        justifyContent: 'flex-start',
                                        borderColor: '#e2e8f0',
                                        color: '#2d3748',
                                        borderRadius: 2,
                                        backgroundColor: '#f8fafc',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            borderColor: '#4299e1',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    Email: support@youredu.school
                                </Button>

                                <Button
                                    variant="outlined"
                                    startIcon={<CalendarIcon />}
                                    href="https://calendly.com/youredu"
                                    target="_blank"
                                    sx={{ 
                                        py: 2,
                                        justifyContent: 'flex-start',
                                        borderColor: '#e2e8f0',
                                        color: '#2d3748',
                                        borderRadius: 2,
                                        backgroundColor: '#f8fafc',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            borderColor: '#4299e1',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                        }
                                    }}
                                >
                                    Schedule a Consultation
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Message Form */}
                    <Grid item xs={12} md={6}>
                        <Paper 
                            elevation={0}
                            sx={{ 
                                p: 3,
                                borderRadius: 3,
                                backgroundColor: 'white',
                                border: '1px solid #e2e8f0'
                            }}
                        >
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    mb: 3, 
                                    color: '#2d3748',
                                    fontWeight: 600
                                }}
                            >
                                Send Us a Message
                            </Typography>

                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                                    <TextField
                                        label="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        fullWidth
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                backgroundColor: '#f8fafc',
                                                '& fieldset': {
                                                    borderColor: '#e2e8f0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#718096',
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        fullWidth
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                backgroundColor: '#f8fafc',
                                                '& fieldset': {
                                                    borderColor: '#e2e8f0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#718096',
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Message"
                                        multiline
                                        rows={4}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        fullWidth
                                        required
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: 2,
                                                backgroundColor: '#f8fafc',
                                                '& fieldset': {
                                                    borderColor: '#e2e8f0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#4299e1',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#718096',
                                            },
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ 
                                            mt: 1,
                                            py: 1.5,
                                            backgroundColor: '#4299e1',
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            fontWeight: 600,
                                            '&:hover': {
                                                backgroundColor: '#2b6cb0',
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 4px 12px rgba(66, 153, 225, 0.3)'
                                            }
                                        }}
                                    >
                                        Send Message
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            
            <ChatBot 
                isOpen={isChatOpen} 
                onClose={() => setIsChatOpen(false)} 
            />
        </Box>
    );
};

export default Support; 