import { supabase } from '../config/supabase'

export class CourseFileService {
  async uploadFile(file, courseId, category) {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser()
      if (!user) throw new Error('No authenticated user')

      // First verify the user has access to this course
      const { data: courseCheck, error: courseError } = await supabase
        .from('user_courses')
        .select('id')
        .eq('id', courseId)
        .eq('uid', user.id)
        .single()

      if (courseError || !courseCheck) {
        throw new Error('You do not have access to this course')
      }

      const filePath = `${courseId}/${category}/${file.name}`
      const { data: storageData, error: storageError } = await supabase.storage
        .from('course-files')
        .upload(filePath, file, {
          upsert: false,
          cacheControl: '3600',
        })

      if (storageError) throw storageError

      const { data: fileData, error: fileError } = await supabase
        .from('course_files')
        .insert({
          name: file.name,
          file_path: filePath,
          size_kb: Math.round(file.size / 1024),
          mime_type: file.type,
          course_id: courseId,
          category,
          user_id: user.id,
        })
        .select()
        .single()

      if (fileError) {
        await supabase.storage.from('course-files').remove([filePath])
        throw fileError
      }

      return fileData
    } catch (error) {
      console.error('Error uploading file:', error)
      throw error
    }
  }

  async getFiles(courseId, category = null) {
    try {
      let query = supabase
        .from('course_files')
        .select('*')
        .eq('course_id', courseId)
        .order('created_at', { ascending: false })

      if (category) {
        query = query.eq('category', category)
      }

      const { data, error } = await query
      if (error) throw error
      return data
    } catch (error) {
      console.error('Error fetching files:', error)
      throw error
    }
  }

  async downloadFile(fileId) {
    try {
      const { data: file, error: fileError } = await supabase.from('course_files').select('*').eq('id', fileId).single()

      if (fileError) throw fileError

      const {
        data: { signedUrl },
        error: urlError,
      } = await supabase.storage.from('course-files').createSignedUrl(file.file_path, 60)

      if (urlError) throw urlError

      window.open(signedUrl, '_blank')
    } catch (error) {
      console.error('Error downloading file:', error)
      throw error
    }
  }

  async deleteFile(fileId) {
    try {
      const { data: file, error: fileError } = await supabase.from('course_files').select('*').eq('id', fileId).single()

      if (fileError) throw fileError

      const { error: storageError } = await supabase.storage.from('course-files').remove([file.file_path])

      if (storageError) throw storageError

      const { error: deleteError } = await supabase.from('course_files').delete().eq('id', fileId)

      if (deleteError) throw deleteError
    } catch (error) {
      console.error('Error deleting file:', error)
      throw error
    }
  }
}
