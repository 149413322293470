import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from '../config/supabase'
import CourseCard from '../components/courses/CourseCard'
import { Box, Typography, Button, Modal } from '@mui/material'
import RegistrationModal from '../components/RegistrationModal'
import { toast } from 'react-toastify'
import { useAuth } from '../utils/AuthContext'

const CoursePage = () => {
  const { college, courseCode } = useParams()
  const [courseInfo, setCourseInfo] = useState(null)
  const [sections, setSections] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
  const navigate = useNavigate()
  const { user } = useAuth()

  const termOrder = ['winter', 'spring', 'summer', 'fall']

  const sortSectionsByTerm = (sections) => {
    return sections.sort((a, b) => {
      const termA = a.term.toLowerCase()
      const termB = b.term.toLowerCase()

      const yearA = parseInt(termA.match(/\d+/)[0], 10)
      const yearB = parseInt(termB.match(/\d+/)[0], 10)

      const termIndexA = termOrder.findIndex((term) => termA.includes(term))
      const termIndexB = termOrder.findIndex((term) => termB.includes(term))

      if (yearA !== yearB) {
        return yearA - yearB
      }
      return termIndexA - termIndexB
    })
  }

  useEffect(() => {
    const loadCourseData = async () => {
      console.log('Loading course data for:', { college, courseCode })
      setIsLoading(true)
      setError(null)

      try {
        // First try to get from college_courses
        const { data: courseData, error: courseError } = await supabase
          .from('college_courses')
          .select('*')
          .eq('code', courseCode)
          .eq('college', college)
          .single()

        if (!courseError && courseData) {
          setCourseInfo(courseData)

          const { data: sectionData, error: sectionError } = await supabase
            .from('college_courses_schedules')
            .select('*')
            .eq('course_code', courseCode)
            .eq('college', college)

          if (sectionError) throw sectionError
          setSections(sortSectionsByTerm(sectionData))
        } else {
          // If not found, try youredu_courses
          const { data: youreduData, error: youreduError } = await supabase
            .from('youredu_courses')
            .select('*')
            .eq('id', courseCode) // Using courseCode param as the ID
            .single()

          if (youreduError) throw youreduError

          // Transform youredu course data to match expected format
          const transformedData = {
            code: `YE-${youreduData.id.slice(0, 8)}`,
            title: youreduData.title,
            description: youreduData.description,
            units: youreduData.units,
            hs_subject: youreduData.hs_subject,
            college: 'YourEdu',
            total_hours: youreduData.total_hours,
          }
          setCourseInfo(transformedData)

          // Create a section object from youredu course data
          const sectionData = [
            {
              crn: youreduData.id,
              course_code: transformedData.code,
              instructor: 'YourEdu Instructor',
              section_times: youreduData.days && youreduData.times ? [youreduData.days, youreduData.times] : null,
              section_dates: youreduData.dates ? [youreduData.dates] : null,
              section_locations: youreduData.location ? [youreduData.location] : null,
              term: youreduData.term_start || null,
              max_students: youreduData.enrollment_capacity || null,
              enrolled: (youreduData.students || []).length,
              waitlisted: 0,
              term_duration: youreduData.term_duration || null,
            },
          ]
          setSections(sectionData)
        }
      } catch (error) {
        console.error('Error loading course data:', error)
        setError(error.message)
      } finally {
        setIsLoading(false)
        console.log('Loading complete')
      }
    }

    loadCourseData()
  }, [college, courseCode])

  const handleRegisterClick = async (course) => {
    try {
      if (!courseInfo || !user) {
        throw new Error('Missing course information or user not logged in')
      }

      // Check if user is already enrolled in this course
      const { data: existingEnrollments, error: checkError } = await supabase
        .from('user_courses')
        .select('*')
        .eq('uid', user.id)
        .eq('college', courseInfo.college)
        .eq('course_code', courseCode)

      if (checkError) throw checkError

      if (existingEnrollments && existingEnrollments.length > 0) {
        toast.warning('You are already enrolled in this course')
        return
      }

      setSelectedCourse(course)
      setIsRegistrationModalOpen(true)

      const isYourEduCourse = course.crn && course.crn.length > 10

      if (isYourEduCourse) {
        const { data: youreduCourse, error: youreduError } = await supabase
          .from('youredu_courses')
          .select('students')
          .eq('id', course.crn)
          .single()

        if (youreduError) throw youreduError

        // Check if already enrolled in YourEDU course
        const currentStudents = youreduCourse.students || []
        if (currentStudents.includes(user.id)) {
          toast.warning('You are already enrolled in this course')
          return
        }

        // Add student to the students array
        const { error: updateError } = await supabase
          .from('youredu_courses')
          .update({ students: [...currentStudents, user.id] })
          .eq('id', course.crn)

        if (updateError) throw updateError
      } else {
        const userCourseData = {
          uid: user.id,
          title: courseInfo.title || null,
          college: courseInfo.college || null,
          hs_subject: courseInfo.hs_subject || null,
          units: courseInfo.units || null,
          total_hours: courseInfo.total_hours || null,
          description: courseInfo.description || null,
          days: course.section_times?.[0] || null,
          times: course.section_times?.[1] || null,
          dates: course.section_dates?.[0] || null,
          year: parseInt(course.term?.match(/\d+/)?.[0] || new Date().getFullYear()),
          term_start: course.term || null,
          term_duration: course.term_duration || null,
          location: course.section_locations?.[0] || null,
          instruction_method: course.section_locations?.[0]?.includes('ONLINE') ? 'Online' : 'In Person',
          teacher: course.instructor || null,
          is_college_level: true,
          materials: null,
          evaluation_method: null,
          final_grade: null,
          course_code: courseCode,
          description: `Course Code: ${courseCode}\n${courseInfo.description || ''}`,
        }

        const { error } = await supabase.from('user_courses').insert([userCourseData]).select()
        if (error) throw error
      }

      toast.success('Added to My Courses')
    } catch (error) {
      console.error('Error in handleRegisterClick:', error)
      toast.error('Failed to add course. Please try again.')
    }
  }

  const handleCloseModal = () => {
    setSelectedCourse(null)
    setIsRegistrationModalOpen(false)
  }

  const formatSchedule = (schedule) => {
    if (!schedule) return 'Not set'
    if (schedule === 'Asynchronous') return schedule

    const dayMap = {
      M: 'Monday',
      T: 'Tuesday',
      W: 'Wednesday',
      R: 'Thursday',
      F: 'Friday',
      S: 'Saturday',
      U: 'Sunday',
    }

    return schedule
      .trim()
      .split(/\s+/)
      .filter((day) => day)
      .map((day) => dayMap[day] || day)
      .join(', ')
  }

  const formatTimes = (times) => {
    if (!times) return ''
    const timePattern = /(\d{1,2}:\d{2}[ap]m)\s*-\s*(\d{1,2}:\d{2}[ap]m)/i
    const match = times.match(timePattern)
    if (match) {
      return `${match[1]} - ${match[2]}`
    }
    return ''
  }

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button onClick={() => navigate('/course-search')} sx={{ mr: 2, fontSize: '1.25rem' }}>
          ← Back
        </Button>
        <Typography variant="h5">
          {courseInfo?.title} ({courseCode}) at {college}
        </Typography>
      </Box>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {courseInfo && (
        <CourseCard
          courseGroup={[
            courseInfo.title,
            [
              {
                institution: courseInfo.college,
                code: courseInfo.code,
                courseTitle: courseInfo.title,
                credits: courseInfo.units,
                hsSubject: courseInfo.hs_subject,
                description: courseInfo.description,
                totalHours: courseInfo.total_hours,
                preventViewMore: true,
                showFullDescription: true,
              },
            ],
          ]}
          expandedCourses={{}}
          toggleCourseExpansion={() => {}}
          handleRegisterClick={() => {}}
          tags={[
            { title: courseInfo.college, color: 'hsl(var(--brand-primary))' },
            { title: courseInfo.hs_subject, color: 'hsl(var(--brand-primary))' },
          ]}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {sections && sections.length > 0 ? (
          sections.reduce((acc, section, index) => {
            if (!section) return acc // Skip null sections

            const previousSection = sections[index - 1]
            const currentTerm = section.term
            const previousTerm = previousSection?.term

            if (currentTerm !== previousTerm) {
              acc.push(
                <Typography key={`header-${currentTerm}`} variant="h6" sx={{ mt: 2 }}>
                  {currentTerm || 'Term Not Set'}
                </Typography>
              )
            }

            acc.push(
              <Box
                key={section.crn}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: 'grey.400',
                  borderRadius: 2,
                  bgcolor: 'grey.200',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography>Professor: {section.instructor || 'Not assigned'}</Typography>
                    <Typography>
                      Schedule:{' '}
                      {section.section_times?.[0]
                        ? formatSchedule(section.section_times[0]) +
                          ' ' +
                          (section.section_times[1] ? formatTimes(section.section_times[1]) : '')
                        : 'Schedule not set'}
                    </Typography>
                    <Typography>Location: {section.section_locations?.[0] || 'Location not set'}</Typography>
                    <Typography>Term: {section.term || 'Term not set'}</Typography>
                    <Typography>Date: {section.section_dates?.[0] || 'Dates not set'}</Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'flex-end' }}>
                    <Typography>Capacity: {section.max_students || 'Not set'}</Typography>
                    <Typography>Enrolled: {section.enrolled || 0}</Typography>
                    <Typography>Waitlist: {section.waitlisted || 0}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleRegisterClick(section)}
                      disabled={section.enrolled >= section.max_students}
                      sx={{ mt: 1 }}
                    >
                      {section.enrolled >= section.max_students ? 'Full' : 'Enroll'}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )

            return acc
          }, [])
        ) : (
          <Typography color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
            No sections available for this course
          </Typography>
        )}
      </Box>

      {/* Registration Modal */}
      <Modal open={isRegistrationModalOpen} onClose={handleCloseModal}>
        <div>
          <RegistrationModal course={selectedCourse} onClose={handleCloseModal} />
        </div>
      </Modal>
    </div>
  )
}

export default CoursePage
