import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton,
  Box, 
  Typography, 
  FormControl, 
  FormLabel, 
  FormControlLabel, 
  RadioGroup, 
  Radio, 
  Stepper, 
  Step, 
  StepLabel, 
  TextField, 
  Tooltip, 
  Link,
  Alert,
  Button,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { supabase } from '../utils/supabaseClient';
import { debounce } from 'lodash';
import { useAuth } from '../utils/AuthContext';
import LinearProgress from '@mui/material/LinearProgress';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { sendEmail } from '../utils/emailService';

// Move styles to the top of the file, before the component
const styles = {
  container: {
    padding: '24px',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
  headerContainer: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  backButton: {
    padding: '8px 16px',
    backgroundColor: 'transparent',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#2d3748',
    '&:hover': {
      backgroundColor: '#f7fafc',
    },
  },
  content: {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  tabContainer: {
    width: '280px',
    backgroundColor: '#f8fafc',
    padding: '16px',
    borderRadius: '8px 0 0 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    borderRight: '1px solid #e2e8f0',
  },
  tabButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '12px 16px',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    textAlign: 'left',
    width: '100%',
    '&:hover': {
      backgroundColor: '#e2e8f0',
    },
  },
  tabIcon: {
    fontSize: '18px',
    width: '24px',
    textAlign: 'center',
  },
  mainContent: {
    flex: 1,
    padding: '32px',
    maxWidth: 'calc(100% - 280px)',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a202c',
    marginBottom: '16px',
  },
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#2d3748',
  },
  input: {
    padding: '8px 12px',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
      borderColor: '#4299e1',
    },
  },
  select: {
    padding: '8px 12px',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    fontSize: '14px',
    backgroundColor: 'white',
  },
  finalButtons: {
    display: 'flex',
    gap: '16px',
    marginTop: '32px',
    justifyContent: 'flex-end',
  },
  submitAffidavitButton: {
    padding: '12px 24px',
    backgroundColor: '#00356b',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#002548',
  },
  },
  printButton: {
    padding: '12px 24px',
    backgroundColor: 'white',
    color: '#2d3748',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7fafc',
    },
  },
  tabStep: {
    fontSize: '14px',
    width: '60px',
    textAlign: 'left',
    fontWeight: '600',
  },
  printPreview: {
    padding: '20px',
    backgroundColor: 'white',
    '@media print': {
      padding: '0',
    },
  },
  printTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#00356b',
    textAlign: 'center',
  },
  printSection: {
    marginBottom: '30px',
    pageBreakInside: 'avoid',
  },
  printField: {
    marginBottom: '10px',
    fontSize: '14px',
    lineHeight: '1.5',
  },
  printTotal: {
    marginTop: '10px',
    paddingTop: '10px',
    borderTop: '1px solid #e2e8f0',
    fontWeight: '500',
  },
  printFooter: {
    marginTop: '40px',
    paddingTop: '20px',
    borderTop: '1px solid #e2e8f0',
    fontSize: '12px',
    color: '#718096',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
  previewButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '20px',
    '@media print': {
      display: 'none',
    },
  },
  numberInput: {
    padding: '8px 12px',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    fontSize: '14px',
    width: '120px',
    backgroundColor: 'white',
    '&:focus': {
      outline: 'none',
      borderColor: '#4299e1',
      boxShadow: '0 0 0 1px #4299e1',
    },
  },
  ageInputGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '24px',
    marginBottom: '32px',
  },
  enrollmentGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '24px',
    marginBottom: '32px',
  },
  staffGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '24px',
    marginBottom: '32px',
  },
  totalEnrollment: {
    marginTop: '16px',
    padding: '16px',
    backgroundColor: '#f7fafc',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#2d3748',
  },
  totalStaff: {
    marginTop: '16px',
    padding: '16px',
    backgroundColor: '#f7fafc',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#2d3748',
  },
  subSectionTitle: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#2d3748',
      marginBottom: '16px',
    marginTop: '32px',
  },
  sectionDescription: {
    fontSize: '14px',
    color: '#4a5568',
    marginBottom: '24px',
    lineHeight: '1.5',
  },
  progressContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    minWidth: '200px',
  },
  progressBar: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
  },
  progressText: {
    fontSize: '14px',
    color: '#4a5568',
    whiteSpace: 'nowrap',
  },
  statusChip: {
    padding: '4px 12px',
    borderRadius: '16px',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '8px',
  },
  formField: {
    marginBottom: '24px',
  },
  pageTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a202c',
    marginBottom: '16px',
  },
  stepper: {
    marginBottom: '24px',
  },
  helperText: {
    fontSize: '14px',
    color: '#4a5568',
    marginBottom: '16px',
    lineHeight: '1.5',
    display: 'block' // Add this to ensure it's treated as a block element
  },
};

// Add these styles to your existing styles object
const additionalStyles = {
  printPreview: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif'
  },
  printTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#2d3748'
  },
  printSection: {
    marginBottom: '24px',
    padding: '16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
  },
  printSectionTitle: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '16px',
    color: '#2d3748',
    borderBottom: '1px solid #e2e8f0',
    paddingBottom: '8px'
  },
  printRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
    borderBottom: '1px solid #f7fafc'
  },
  printLabel: {
    fontWeight: '500',
    color: '#4a5568',
    flex: '0 0 40%'
  },
  printValue: {
    color: '#2d3748',
    flex: '0 0 55%',
    textAlign: 'right'
  },
  printAcknowledgment: {
    fontSize: '14px',
    color: '#4a5568',
    fontStyle: 'italic'
  }
};

// Merge the additional styles with existing styles
Object.assign(styles, additionalStyles);

// Move PrintPreview component outside of CaliforniaPSA
export const PrintPreview = ({ formData, calculateTotalStaff }) => {
  if (!formData) return null;

  const renderSection = (title, data, formatter = (key, value) => ({ label: key, value })) => {
    if (!data) return null;
    return (
      <div style={styles.printSection}>
        <Typography variant="h3" component="div" style={styles.printSectionTitle}>
          {title}
        </Typography>
        {Object.entries(data).map(([key, value]) => {
          const { label, value: formattedValue } = formatter(key, value);
          if (!value && value !== 0) return null;
          return (
            <div key={key} style={styles.printRow}>
              <Typography component="span" style={styles.printLabel}>
                {label}:
              </Typography>
              <Typography component="span" style={styles.printValue}>
                {formattedValue}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={styles.printPreview}>
      <Typography variant="h1" component="div" style={styles.printTitle}>
        California Private School Affidavit
      </Typography>
      
      {renderSection('School Information', {
        'School Name': formData.school_name,
        'CDS Code': formData.cds_code,
        'County': formData.county,
        'District': formData.district,
        'Website': formData.website
      })}

      {renderSection('Physical Address', {
        'Street': formData.physical_street,
        'City': formData.physical_city,
        'State': formData.physical_state,
        'ZIP': formData.physical_zip,
        'Phone': formData.phone,
        'Email': formData.primary_email
      })}

      {renderSection('Site Administrator', {
        'Name': `${formData.site_admin_first_name || ''} ${formData.site_admin_last_name || ''}`.trim(),
        'Title': formData.site_admin_title,
        'Phone': formData.site_admin_phone,
        'Email': formData.site_admin_email
      })}

      {renderSection('Enrollment Data', formData.enrollment, (grade, count) => ({
        label: grade.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
        value: count || 0
      }))}

      {renderSection('Staff Information', {
        'Full-time Teachers': formData.full_time_teachers || 0,
        'Part-time Teachers': formData.part_time_teachers || 0,
        'Administrators': formData.administrators || 0,
        'Other Staff': formData.other_staff || 0,
        'Total Staff': calculateTotalStaff(formData)
      })}

      {renderSection('Tax Status', formData.tax_status, (status, isChecked) => ({
        label: status.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
        value: isChecked ? 'Yes' : 'No'
      }))}

      {formData.statutory_acknowledgment && (
        <div style={styles.printSection}>
          <h3 style={styles.printSectionTitle}>Acknowledgment</h3>
          <p style={styles.printAcknowledgment}>
            I acknowledge that I have read and understand the Statutory Notices and assure the school's compliance.
          </p>
        </div>
      )}
    </div>
  );
};

const CaliforniaPSA = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [signature, setSignature] = useState({ name: '', date: '' });
  const [activeTab, setActiveTab] = useState(1);
  const [isPrintPreviewOpen, setIsPrintPreviewOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [completionStatus, setCompletionStatus] = useState({ percent: 0, status: 'Not Started' });
  const [message, setMessage] = useState({ type: null, content: '' });
  const [submissionProgress, setSubmissionProgress] = useState({
    status: '',
    details: ''
  });
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [emailPreview, setEmailPreview] = useState({
    to: '',
    subject: '',
    content: '',
    attachment: ''
  });
  const [formData, setFormData] = useState({
    school_name: '',
    cds_code: '',
    county: '',
    district: '',
    is_full_time_private: '',
    school_type: '',
    special_education: '',
    high_school_diploma: '',
    low_grade: '',
    high_grade: '',
    classification: '',
    physical_street: '',
    physical_city: '',
    physical_state: '',
    physical_zip: '',
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip: '',
    phone: '',
    fax_number: '',
    primary_email: '',
    website: '',
    name_changed: false,
    previous_name: '',
    district_changed: false,
    previous_district: '',
    youngest_years: '',
    youngest_months: '',
    oldest_years: '',
    enrollment: {
      kindergarten: '',
      grade1: '',
      grade2: '',
      grade3: '',
      grade4: '',
      grade5: '',
      grade6: '',
      grade7: '',
      grade8: '',
      grade9: '',
      grade10: '',
      grade11: '',
      grade12: ''
    },
    previous_year_graduates: '',
    full_time_teachers: '',
    part_time_teachers: '',
    administrators: '',
    other_staff: '',
    site_admin_salutation: '',
    site_admin_first_name: '',
    site_admin_last_name: '',
    site_admin_title: '',
    site_admin_phone: '',
    site_admin_extension: '',
    site_admin_email: '',
    site_admin_street: '',
    site_admin_city: '',
    site_admin_state: '',
    site_admin_zip: '',
    site_admin_zip4: '',
    tax_status: {
      section501c3: false,
      section23701d: false,
      section214: false,
      none: false
    },
    statutory_acknowledgment: false
  });

  // Add submitPSA function
  const submitPSA = async (finalFormData) => {
    try {
      const { data, error } = await supabase
        .from('california_psa')
        .upsert({
          ...finalFormData,
          user_id: user.id,
          status: 'submitted',
          submitted_at: new Date().toISOString()
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error submitting PSA:', error);
      throw error;
    }
  };

  // Move all useEffect hooks here, at the top level
  useEffect(() => {
    if (user) {
      loadFormData();
      loadProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    // Load user data from MyAccount
    const loadUserData = async () => {
      try {
        const { data: userData } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user?.id)
          .single();

        if (userData) {
          setFormData(prevData => ({
            ...prevData,
            mailingAddress: userData.address,
            mailingCity: userData.city,
            mailingState: userData.state,
            mailingZip: userData.zip,
            directorName: userData.fullName,
            directorPhone: userData.phone,
            directorEmail: userData.email,
            recordsContactName: userData.fullName,
            recordsContactPhone: userData.phone,
            recordsContactEmail: userData.email,
            recordsAddress: userData.address,
            recordsCity: userData.city,
            recordsState: userData.state,
            recordsZip: userData.zip
          }));
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };

    if (user) {
      loadUserData();
    }
  }, [user]);

  const loadProfileData = async () => {
    try {
      const { data: profile, error } = await supabase
        .from('account_profiles')
        .select('*')
        .eq('id', user?.id)
        .single();

      if (error) throw error;

      if (profile) {
        setFormData(prev => ({
          ...prev,
          // Auto-populate physical address
          physical_street: profile.street_address || '',
          physical_city: profile.city || '',
          physical_state: profile.state || '',
          physical_zip: profile.zip || '',
          // Auto-populate mailing address
          mailing_street: profile.street_address || '',
          mailing_city: profile.city || '',
          mailing_state: profile.state || '',
          mailing_zip: profile.zip || '',
          // Other fields
          site_admin_first_name: profile.name?.split(' ')[0] || '',
          site_admin_last_name: profile.name?.split(' ').slice(1).join(' ') || '',
          site_admin_email: profile.email || '',
          site_admin_phone: profile.phone_number || '',
          site_admin_street: profile.street_address || '',
          site_admin_city: profile.city || '',
          site_admin_state: profile.state || '',
          site_admin_zip: profile.zip || '',
          primary_email: profile.email || '',
          phone: profile.phone_number || '',
        }));
      }
    } catch (error) {
      console.error('Error loading profile data:', error);
      setSnackbar({ open: true, message: 'Failed to load profile data', severity: 'error' });
    }
  };

  const loadFormData = async () => {
    try {
      setLoading(true);
      const { data: psaData, error } = await supabase
        .from('california_psa')
        .select('*')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) throw error;

      if (psaData) {
        setFormData(prev => ({
          ...prev,
          ...psaData,
          // Preserve any profile data if PSA fields are empty
          physical_street: psaData.physical_street || prev.physical_street,
          physical_city: psaData.physical_city || prev.physical_city,
          physical_state: psaData.physical_state || prev.physical_state,
          physical_zip: psaData.physical_zip || prev.physical_zip,
          phone: psaData.phone || prev.phone,
          primary_email: psaData.primary_email || prev.primary_email,
          site_admin_first_name: psaData.site_admin_first_name || prev.site_admin_first_name,
          site_admin_last_name: psaData.site_admin_last_name || prev.site_admin_last_name,
          site_admin_email: psaData.site_admin_email || prev.site_admin_email,
          site_admin_phone: psaData.site_admin_phone || prev.site_admin_phone,
          site_admin_street: psaData.site_admin_street || prev.site_admin_street,
          site_admin_city: psaData.site_admin_city || prev.site_admin_city,
          site_admin_state: psaData.site_admin_state || prev.site_admin_state,
          site_admin_zip: psaData.site_admin_zip || prev.site_admin_zip,
        }));
      }
    } catch (error) {
      console.error('Error loading PSA data:', error);
      setSnackbar({ open: true, message: 'Failed to load PSA data', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const saveFormData = useCallback(
    debounce(async (data) => {
      if (!user) return;

      setFormData(prev => ({
        ...prev,
        ...data,
        updated_at: new Date().toISOString()
      }));

      try {
        const { error } = await supabase
          .from('california_psa')
          .upsert({
            ...data,
            user_id: user.id,
            updated_at: new Date().toISOString()
          }, {
            onConflict: 'user_id',
            returning: 'minimal'
          });

        if (error) throw error;

        setSnackbar({ open: true, message: 'PSA data saved successfully', severity: 'success' });
      } catch (error) {
        console.error('Error saving PSA data:', error.message);
        setSnackbar({ open: true, message: 'Error saving PSA data', severity: 'error' });
      }
    }, 1000),
    [user]
  );

  useEffect(() => {
    if (formData.usePhysicalForMailing) {
      handleCopyAddress();
    }
  }, [formData.usePhysicalForMailing]);

  useEffect(() => {
    if (formData.useSiteAdminForDirector) {
      handleCopyAdminInfo();
    }
  }, [formData.useSiteAdminForDirector]);

  useEffect(() => {
    if (formData.useCustodianAddress) {
      handleCopyCustodianAddress();
    }
  }, [formData.useCustodianAddress]);

  // Update the calculateProgress useEffect to prevent infinite loops
  useEffect(() => {
    const calculateProgress = () => {
      const requiredFields = [
        'school_name',
        'cds_code',
        'county',
        'district',
        'is_full_time_private',
        'physical_street',
        'physical_city',
        'physical_state',
        'physical_zip',
        'phone',
        'primary_email',
        'youngest_years',
        'youngest_months',
        'oldest_years',
        'site_admin_first_name',
        'site_admin_last_name',
        'site_admin_title',
        'site_admin_phone',
        'site_admin_email',
        'statutory_acknowledgment'
      ];

      let filledFields = 0;
      requiredFields.forEach(field => {
        if (field.includes('.')) {
          const [parent, child] = field.split('.');
          if (formData[parent]?.[child]) filledFields++;
        } else if (formData[field]) filledFields++;
      });

      const enrollmentFields = Object.values(formData.enrollment || {}).filter(value => value > 0).length;
      if (enrollmentFields > 0) filledFields++;

      const taxStatusSelected = Object.values(formData.tax_status || {}).some(value => value === true);
      if (taxStatusSelected) filledFields++;

      const percent = Math.round((filledFields / (requiredFields.length + 2)) * 100);
      let status = 'Not Started';
      if (percent === 100) status = 'Completed';
      else if (percent > 0) status = 'In Progress';

      setCompletionStatus({ percent, status });
    };

    calculateProgress();
  }, [formData]);

  // Show loading state while checking auth
  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading...
      </div>
    );
  }

  // Don't render the form if not authenticated
  if (!user) {
    return null;
  }

  // Update the handleInputChange function to handle null values
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle nested fields (if name contains dots)
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => {
        const newData = {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: type === 'checkbox' ? checked : (value || '')
          }
        };
        saveFormData(newData);
        return newData;
      });
    } else {
      // Handle regular fields
      setFormData(prev => {
        const newData = {
          ...prev,
          [name]: type === 'checkbox' ? checked : (value || '')
        };
        saveFormData(newData);
        return newData;
      });
    }
  };

  const handleSubmit = async () => {
    setOpenSignatureDialog(true);
  };

  const handleSignAndSubmit = async () => {
    try {
      // Add signature to form data
      const finalFormData = {
        ...formData,
        signature: signature.name,
        signature_date: signature.date,
        status: 'submitted',
        submitted_at: new Date().toISOString(),
        user_id: user.id
      };

      // Submit the form data
      const { data: submittedPsa, error: psaError } = await supabase
        .from('california_psa')
        .upsert(finalFormData, {
          onConflict: 'user_id',
          returning: 'minimal'
        });

      if (psaError) throw psaError;

      // Generate PDF content using the review section format
      const pdfContent = `
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              .section { margin-bottom: 20px; }
              .heading { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
              .item { margin: 5px 0; }
              .label { font-weight: bold; }
            </style>
          </head>
          <body>
            <h1>California Private School Affidavit - ${finalFormData.school_name}</h1>
            <div class="section">
              <div class="heading">School Information</div>
              <div class="item"><span class="label">Full-time Private School:</span> ${finalFormData.is_full_time_private}</div>
              <div class="item"><span class="label">School Name:</span> ${finalFormData.school_name}</div>
              <div class="item"><span class="label">County:</span> ${finalFormData.county}</div>
              <div class="item"><span class="label">District:</span> ${finalFormData.district}</div>
              <div class="item"><span class="label">School Type:</span> ${finalFormData.school_type}</div>
              <div class="item"><span class="label">Accommodations:</span> ${finalFormData.accommodations}</div>
              <div class="item"><span class="label">Special Education:</span> ${finalFormData.special_education}</div>
              <div class="item"><span class="label">High School Diploma:</span> ${finalFormData.high_school_diploma}</div>
              <div class="item"><span class="label">Low Grade:</span> ${finalFormData.low_grade}</div>
              <div class="item"><span class="label">High Grade:</span> ${finalFormData.high_grade}</div>
              <div class="item"><span class="label">Classification:</span> ${finalFormData.classification}</div>
            </div>

            <div class="section">
              <div class="heading">School Address</div>
              <div class="item"><span class="label">Physical Address:</span></div>
              <div class="item">${finalFormData.physical_street}</div>
              <div class="item">${finalFormData.physical_city}, ${finalFormData.physical_state} ${finalFormData.physical_zip}</div>
              <div class="item"><span class="label">Mailing Address:</span></div>
              <div class="item">${finalFormData.mailing_street}</div>
              <div class="item">${finalFormData.mailing_city}, ${finalFormData.mailing_state} ${finalFormData.mailing_zip}</div>
            </div>

            <div class="section">
              <div class="heading">Contact Information</div>
              <div class="item"><span class="label">Phone:</span> ${finalFormData.phone}</div>
              <div class="item"><span class="label">Fax:</span> ${finalFormData.fax_number || 'N/A'}</div>
              <div class="item"><span class="label">Email:</span> ${finalFormData.primary_email}</div>
              <div class="item"><span class="label">Website:</span> ${finalFormData.website || 'N/A'}</div>
            </div>

            <div class="section">
              <div class="heading">Statistical Information</div>
              <div class="item"><span class="label">Student Ages:</span> ${finalFormData.youngest_years} years, ${finalFormData.youngest_months} months to ${finalFormData.oldest_years} years</div>
              <div class="item"><span class="label">Total Enrollment:</span> ${calculateTotalEnrollment()}</div>
              <div class="item"><span class="label">Total Staff:</span> ${calculateTotalStaff()}</div>
            </div>

            <div class="section">
              <div class="heading">Site Administrator</div>
              <div class="item"><span class="label">Name:</span> ${finalFormData.site_admin_salutation || ''} ${finalFormData.site_admin_first_name} ${finalFormData.site_admin_last_name}</div>
              <div class="item"><span class="label">Title:</span> ${finalFormData.site_admin_title}</div>
              <div class="item"><span class="label">Phone:</span> ${finalFormData.site_admin_phone}</div>
              <div class="item"><span class="label">Email:</span> ${finalFormData.site_admin_email}</div>
            </div>

            <div class="section">
              <div class="heading">Submission Details</div>
              <div class="item"><span class="label">Submitted By:</span> ${signature.name}</div>
              <div class="item"><span class="label">Date:</span> ${signature.date}</div>
            </div>
          </body>
        </html>
      `;

      // Convert HTML to PDF using html-to-pdf API
      console.log('Starting PDF generation with HTML2PDF API');
      console.log('API Key being used:', process.env.REACT_APP_HTML2PDF_API_KEY ? 'Present (length: ' + process.env.REACT_APP_HTML2PDF_API_KEY.length + ')' : 'Missing');
      
      const apiConfig = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_HTML2PDF_API_KEY}`
        },
        body: JSON.stringify({
          html: pdfContent,
          apiKey: process.env.REACT_APP_HTML2PDF_API_KEY,
          options: {
            format: 'Letter',
            margin: '20mm'
          }
        })
      };

      console.log('Request configuration:', {
        url: 'https://api.html2pdf.app/v1/generate',
        method: apiConfig.method,
        headers: {
          ...apiConfig.headers,
          'Authorization': 'Bearer [HIDDEN]'  // Hide the actual key in logs
        }
      });

      const response = await fetch('https://api.html2pdf.app/v1/generate', apiConfig);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('HTML2PDF API error details:', {
          status: response.status,
          statusText: response.statusText,
          responseBody: errorText,
          headers: Object.fromEntries([...response.headers.entries()])
        });
        throw new Error(`HTML2PDF API error: ${response.status} ${response.statusText} - ${errorText}`);
      }

      console.log('PDF generation successful');

      const pdfBlob = await response.blob();
      const fileName = `psa_submission_${user.id}_${new Date().getTime()}.pdf`;

      // Upload PDF to Supabase storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('compliance_documents')
        .upload(fileName, pdfBlob, {
          contentType: 'application/pdf',
          upsert: true,
          cacheControl: '3600'
        });

      if (uploadError) {
        console.error('Storage upload error:', uploadError);
        throw uploadError;
      }

      // Get the public URL of the uploaded PDF
      const { data: { publicUrl } } = supabase.storage
        .from('compliance_documents')
        .getPublicUrl(fileName);

      // Create a submission record
      const { error: submissionError } = await supabase
        .from('psa_submissions')
        .insert({
          user_id: user.id,
          school_year: '2024-2025',
          submission_name: '2024-2025 Private School Affidavit',
          status: 'Submitted, pending state confirmation',
          submitted_at: new Date().toISOString(),
          psa_data: {
            ...finalFormData,
            pdf_url: publicUrl
          }
        });

      if (submissionError) throw submissionError;

      // Send confirmation email using emailService
      const emailContent = {
        to: formData.site_admin_email,
        email: formData.site_admin_email, // Add this as the Edge Function expects 'email'
        userId: user.id,
        state: 'California',
        name: `${formData.site_admin_first_name} ${formData.site_admin_last_name}`,
        pdfUrl: publicUrl
      };

      const { success: emailSuccess, error: emailError } = await sendEmail(emailContent);
      
      setOpenSignatureDialog(false);
      setSnackbar({
        open: true,
        message: emailSuccess 
          ? 'PSA submitted successfully' 
          : 'PSA submitted successfully. Confirmation email may be delayed.',
        severity: emailSuccess ? 'success' : 'warning'
      });
      
      // Navigate back to StateComplianceFiling
      navigate('/state-compliance-filing');
    } catch (error) {
      console.error('Error submitting PSA:', error);
      setSnackbar({
        open: true,
        message: 'Error submitting PSA',
        severity: 'error'
      });
    }
  };

  const counties = [
    "Alameda", "Alpine", "Amador", "Butte", "Calaveras", "Colusa", 
    "Contra Costa", "Del Norte", "El Dorado", "Fresno", "Glenn", 
    "Humboldt", "Imperial", "Inyo", "Kern", "Kings", "Lake", "Lassen", 
    "Los Angeles", "Madera", "Marin", "Mariposa", "Mendocino", "Merced", 
    "Modoc", "Mono", "Monterey", "Napa", "Nevada", "Orange", "Placer", 
    "Plumas", "Riverside", "Sacramento", "San Benito", "San Bernardino", 
    "San Diego", "San Francisco", "San Joaquin", "San Luis Obispo", 
    "San Mateo", "Santa Barbara", "Santa Clara", "Santa Cruz", "Shasta", 
    "Sierra", "Siskiyou", "Solano", "Sonoma", "Stanislaus", "Sutter", 
    "Tehama", "Trinity", "Tulare", "Tuolumne", "Ventura", "Yolo", "Yuba"
  ];

  const grades = [
    "Kindergarten", "Grade One", "Grade Two", "Grade Three", "Grade Four",
    "Grade Five", "Grade Six", "Grade Seven", "Grade Eight", "Grade Nine",
    "Grade Ten", "Grade Eleven", "Grade Twelve"
  ];

  const classifications = [
    "Non-Religious", "Anglican", "Apostolic", "Assembly of God", "Baha'i Faith",
    "Baptist", "Buddhist", "Calvary Chapel", "Charismatic", "Catholic",
    "Chinese Folk/Confucians", "Christian Church (Disciples of Christ)",
    "Christian Science", "Church of Christ", "Church of God",
    "Church of the Brethren", "Church of the Nazarene", "Covenant",
    "Episcopal", "Evangelical", "Four Square Gospel", "Fundamentalist Christian",
    "Hindu", "Interdenominational", "Islamic (Muslim)", "Jehovah's Witnesses",
    "Jewish", "Mormon (Latter-day Saints)", "Lutheran", "Messianic Judaism",
    "Mennonite", "Methodist", "Native American", "New Age", "Nondenominational",
    "Orthodox (Eastern/Greek)", "Other", "Pentecostal", "Presbyterian",
    "Quaker (Friends)", "Reformed", "Scientology", "Seventh-day Adventist",
    "Shinto", "Sikh", "Taoist", "Unitarian (Universalist)", "Vietnamese Folk",
    "Wesleyan"
  ];

  const titles = [
    "Administrator",
    "Principal",
    "Director",
    "Head of School",
    "Superintendent",
    "Owner",
    "President",
    "Other"
  ];

  const securityQuestions = [
    "What is your favorite color?",
    "What is your mother's maiden name?",
    "What was the name of your first pet?",
    "In what city were you born?",
    "What was your first car?",
    "What elementary school did you attend?",
    "What is your favorite book?",
    "What is your favorite movie?",
  ];

  const tabs = [
    { id: 1, title: "School Information", step: "Step 1" },
    { id: 2, title: "School Address", step: "Step 2" },
    { id: 3, title: "Contact Information", step: "Step 3" },
    { id: 4, title: "Prior Year Changes", step: "Step 4" },
    { id: 5, title: "Statistical Information", step: "Step 5" },
    { id: 6, title: "Administrative Staff", step: "Step 6" },
    { id: 7, title: "School Records", step: "Step 7" },
    { id: 8, title: "Tax Status", step: "Step 8" },
    { id: 9, title: "Acknowledgment", step: "Step 9" },
    { id: 10, title: "Review & Submit", step: "Step 10" }
  ];

  const handleCopyAddress = () => {
    if (formData.usePhysicalForMailing) {
      setFormData(prev => ({
        ...prev,
        mailingStreet: prev.physicalStreet,
        mailingCity: prev.physicalCity,
        mailingState: prev.physicalState,
        mailingZip: prev.physicalZip,
        mailingZip4: prev.physicalZip4,
      }));
    }
  };

  const handleCopyAdminInfo = () => {
    if (formData.useSiteAdminForDirector) {
      setFormData(prev => ({
        ...prev,
        directorSalutation: prev.siteAdminSalutation,
        directorFirstName: prev.siteAdminFirstName,
        directorLastName: prev.siteAdminLastName,
        directorPosition: prev.siteAdminTitle,
        directorPhone: prev.siteAdminPhone,
        directorExtension: prev.siteAdminExtension,
        directorEmail: prev.siteAdminEmail,
        directorStreet: prev.siteAdminStreet,
        directorCity: prev.siteAdminCity,
        directorState: prev.siteAdminState,
        directorZip: prev.siteAdminZip,
        directorZip4: prev.siteAdminZip4,
      }));
    }
  };

  const handleCopyCustodianAddress = () => {
    if (formData.useCustodianAddress) {
      setFormData(prev => ({
        ...prev,
        recordsStreet: prev.custodianStreet,
        recordsCity: prev.custodianCity,
        recordsState: prev.custodianState,
        recordsZip: prev.custodianZip,
        recordsZip4: prev.custodianZip4,
      }));
    }
  };

  const handleTaxStatusChange = (field) => {
    setFormData(prev => ({
      ...prev,
      tax_status: {
        ...prev.tax_status,
        [field]: !prev.tax_status[field],
        none: false // Uncheck "none" if any other option is selected
      }
    }));
  };

  const handleNoneSelected = () => {
    setFormData(prev => ({
      ...prev,
      tax_status: {
        section501c3: false,
        section23701d: false,
        section214: false,
        none: !prev.tax_status.none
      }
    }));
  };

  const calculateTotalEnrollment = () => {
    if (!formData.enrollment) return 0;
    return Object.values(formData.enrollment).reduce(
      (total, current) => total + parseInt(current || 0), 0
    );
  };

  const calculateTotalStaff = (data = formData) => {
    return (
      parseInt(data.full_time_teachers || 0) +
      parseInt(data.part_time_teachers || 0) +
      parseInt(data.administrators || 0) +
      parseInt(data.other_staff || 0)
    );
  };

  const renderTabContent = () => {
    switch(activeTab) {
      case 1:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>School Information</h2>
            
            {/* Add Full-time Private School question */}
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Full-time Private School:*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="is_full_time_private"
                      value="yes"
                      checked={formData.is_full_time_private === 'yes'}
                      onChange={handleInputChange}
                      required
                    /> Yes
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="is_full_time_private"
                      value="no"
                      checked={formData.is_full_time_private === 'no'}
                      onChange={handleInputChange}
                      required
                    /> No
                  </label>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Name of School:*
                <input
                  type="text"
                  name="school_name"
                  value={formData.school_name}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                County:*
                <select
                  name="county"
                  value={formData.county}
                  onChange={handleInputChange}
                  style={styles.select}
                  required
                >
                  <option value="">Select County</option>
                  {counties.map(county => (
                    <option key={county} value={county}>{county}</option>
                  ))}
                </select>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                District:*
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    name="district"
                    value={formData.district}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                  <Tooltip title="Don't know your school district? No worries! Click here to look it up!">
                    <IconButton 
                      onClick={() => window.open('https://www.greatschools.org/school-district-boundaries-map/', '_blank')}
                      size="small"
                      style={{ marginLeft: '8px' }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                School Type:*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="school_type"
                      value="coeducational"
                      checked={formData.school_type === 'coeducational'}
                      onChange={handleInputChange}
                      required
                    /> Coeducational
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="school_type"
                      value="boysOnly"
                      checked={formData.school_type === 'boysOnly'}
                      onChange={handleInputChange}
                    /> Boys Only
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="school_type"
                      value="girlsOnly"
                      checked={formData.school_type === 'girlsOnly'}
                      onChange={handleInputChange}
                    /> Girls Only
                  </label>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                School Accommodations:*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="accommodations"
                      value="dayOnly"
                      checked={formData.accommodations === 'dayOnly'}
                      onChange={handleInputChange}
                      required
                    /> Day Only
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="accommodations"
                      value="residentialOnly"
                      checked={formData.accommodations === 'residentialOnly'}
                      onChange={handleInputChange}
                    /> Residential Boarding Only
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="accommodations"
                      value="both"
                      checked={formData.accommodations === 'both'}
                      onChange={handleInputChange}
                    /> Both
                  </label>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Does the school provide special education?:*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="special_education"
                      value="yes"
                      checked={formData.special_education === 'yes'}
                      onChange={handleInputChange}
                      required
                    /> Yes
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="special_education"
                      value="no"
                      checked={formData.special_education === 'no'}
                      onChange={handleInputChange}
                    /> No
                  </label>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Does the school provide a high school diploma?:*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="high_school_diploma"
                      value="yes"
                      checked={formData.high_school_diploma === 'yes'}
                      onChange={handleInputChange}
                      required
                    /> Yes
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="high_school_diploma"
                      value="no"
                      checked={formData.high_school_diploma === 'no'}
                      onChange={handleInputChange}
                    /> No
                  </label>
                </div>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Low Grade:*
                <select
                  name="low_grade"
                  value={formData.low_grade}
                  onChange={handleInputChange}
                  style={styles.select}
                  required
                >
                  <option value="">-- Select --</option>
                  {grades.map(grade => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </select>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                High Grade:*
                <select
                  name="high_grade"
                  value={formData.high_grade}
                  onChange={handleInputChange}
                  style={styles.select}
                  required
                >
                  <option value="">-- Select --</option>
                  {grades.map(grade => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </select>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Classification of school:*
                <select
                  name="classification"
                  value={formData.classification}
                  onChange={handleInputChange}
                  style={styles.select}
                  required
                >
                  <option value="">-- Select --</option>
                  {classifications.map(classification => (
                    <option key={classification} value={classification}>
                      {classification}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        );
      case 2:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>School Address</h2>
            
            <h3 style={styles.subSectionTitle}>Physical Address</h3>
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Street:*
                <input
                  type="text"
                  name="physical_street"
                  value={formData.physical_street}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                City:*
                <input
                  type="text"
                  name="physical_city"
                  value={formData.physical_city}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                State:*
                <input
                  type="text"
                  name="physical_state"
                  value={formData.physical_state}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                ZIP:*
                <input
                  type="text"
                  name="physical_zip"
                  value={formData.physical_zip}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  pattern="[0-9]{5}"
                />
              </label>
            </div>

            <h3 style={styles.subSectionTitle}>Mailing Address</h3>
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Street:*
                <input
                  type="text"
                  name="mailing_street"
                  value={formData.mailing_street}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                City:*
                <input
                  type="text"
                  name="mailing_city"
                  value={formData.mailing_city}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                State:*
                <input
                  type="text"
                  name="mailing_state"
                  value={formData.mailing_state}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                ZIP:*
                <input
                  type="text"
                  name="mailing_zip"
                  value={formData.mailing_zip}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  pattern="[0-9]{5}"
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Fax Number:
                <input
                  type="tel"
                  name="fax_number"
                  value={formData.fax_number}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Primary Email:*
                <input
                  type="email"
                  name="primary_email"
                  value={formData.primary_email}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Website:
                <input
                  type="url"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </label>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Contact Information</h2>
            {/* Step 3 content */}
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Phone:*
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  title="Please enter a valid phone number in format: 123-456-7890"
                  placeholder="123-456-7890"
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Fax Number:
                <input
                  type="tel"
                  name="fax_number"
                  value={formData.fax_number}
                  onChange={handleInputChange}
                  style={styles.input}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  title="Please enter a valid fax number in format: 123-456-7890"
                  placeholder="123-456-7890"
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Primary Email:*
                <input
                  type="email"
                  name="primary_email"
                  value={formData.primary_email}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  placeholder="school@example.com"
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Website:
                <input
                  type="url"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  style={styles.input}
                  placeholder="https://www.example.com"
                  pattern="https?://.+"
                  title="Please include http:// or https:// in your URL"
                />
              </label>
            </div>
          </div>
        );
      case 4:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>School Changes</h2>
            
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Has the school's name changed since last year's filing?*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="name_changed"
                      value="true"
                      checked={formData.name_changed === true}
                      onChange={(e) => handleInputChange({
                        target: {
                          name: 'name_changed',
                          value: e.target.value === 'true'
                        }
                      })}
                      required
                    /> Yes
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="name_changed"
                      value="false"
                      checked={formData.name_changed === false}
                      onChange={(e) => handleInputChange({
                        target: {
                          name: 'name_changed',
                          value: e.target.value === 'true'
                        }
                      })}
                      required
                    /> No
                  </label>
                </div>
              </label>
            </div>

            {formData.name_changed && (
              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  If yes, enter the name that the school last filed under:
                  <input
                    type="text"
                    name="previous_name"
                    value={formData.previous_name}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </label>
              </div>
            )}

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Has the school's district changed since last year's filing?*
                <div style={styles.radioGroup}>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="district_changed"
                      value="true"
                      checked={formData.district_changed === true}
                      onChange={(e) => handleInputChange({
                        target: {
                          name: 'district_changed',
                          value: e.target.value === 'true'
                        }
                      })}
                      required
                    /> Yes
                  </label>
                  <label style={styles.radioLabel}>
                    <input
                      type="radio"
                      name="district_changed"
                      value="false"
                      checked={formData.district_changed === false}
                      onChange={(e) => handleInputChange({
                        target: {
                          name: 'district_changed',
                          value: e.target.value === 'true'
                        }
                      })}
                      required
                    /> No
                  </label>
                </div>
              </label>
            </div>

            {formData.district_changed && (
              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  If yes, enter the district that the school last filed under:
                  <input
                    type="text"
                    name="previous_district"
                    value={formData.previous_district}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </label>
              </div>
            )}
          </div>
        );
      case 5:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Statistical Information</h2>
            
            <h3 style={styles.subSectionTitle}>Student Ages</h3>
            <p style={styles.sectionDescription}>
              Report the age range of the students enrolled in kindergarten through grade twelve. 
              Note, the youngest age may not be younger than 4 years and 9 months old.
            </p>
            
            <div style={styles.ageInputGroup}>
              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Youngest Years:*
                  <input
                    type="number"
                    name="youngest_years"
                    value={formData.youngest_years}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="4"
                    max="21"
                    required
                  />
                </label>
              </div>

              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Youngest Months:*
                  <input
                    type="number"
                    name="youngest_months"
                    value={formData.youngest_months}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="0"
                    max="11"
                    required
                  />
                </label>
              </div>

              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Oldest Years:*
                  <input
                    type="number"
                    name="oldest_years"
                    value={formData.oldest_years}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="4"
                    max="21"
                    required
                  />
                </label>
              </div>
            </div>

            <h3 style={styles.subSectionTitle}>Enrollment</h3>
            <p style={styles.sectionDescription}>
              Report the number of students enrolled in each grade level on or around the date 
              the affidavit is filed. Do not include pre-school enrollment; schools that only 
              offer preschool should not file an affidavit.
            </p>

            <div style={styles.enrollmentGrid}>
              {[
                { key: 'kindergarten', label: 'Kindergarten', number: '37' },
                { key: 'grade1', label: 'Grade 1', number: '38' },
                { key: 'grade2', label: 'Grade 2', number: '39' },
                { key: 'grade3', label: 'Grade 3', number: '40' },
                { key: 'grade4', label: 'Grade 4', number: '41' },
                { key: 'grade5', label: 'Grade 5', number: '42' },
                { key: 'grade6', label: 'Grade 6', number: '43' },
                { key: 'grade7', label: 'Grade 7', number: '44' },
                { key: 'grade8', label: 'Grade 8', number: '45' },
                { key: 'grade9', label: 'Grade 9', number: '46' },
                { key: 'grade10', label: 'Grade 10', number: '47' },
                { key: 'grade11', label: 'Grade 11', number: '48' },
                { key: 'grade12', label: 'Grade 12', number: '49' }
              ].map(({ key, label, number }) => (
                <div key={key} style={styles.fieldGroup}>
                  <label style={styles.label}>
                    {label}:
                    <input
                      type="number"
                      name={`enrollment.${key}`}
                      value={formData.enrollment[key]}
                      onChange={handleInputChange}
                      style={styles.numberInput}
                      min="0"
                      required
                    />
                  </label>
                </div>
              ))}
            </div>

            <div style={styles.totalEnrollment}>
              Total Enrollment Count: {calculateTotalEnrollment()}
            </div>

            <h3 style={styles.subSectionTitle}>Graduates</h3>
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Report the number of students who graduated from twelfth grade in the previous school year:*
                <input
                  type="number"
                  name="previous_year_graduates"
                  value={formData.previous_year_graduates}
                  onChange={handleInputChange}
                  style={styles.numberInput}
                  min="0"
                  required
                />
              </label>
            </div>

            <h3 style={styles.subSectionTitle}>Number of Staff</h3>
            <p style={styles.sectionDescription}>
              Report the number of staff employed by the school.
            </p>

            <div style={styles.staffGrid}>
              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Full-time Teachers:
                  <input
                    type="number"
                    name="full_time_teachers"
                    value={formData.full_time_teachers}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="0"
                    required
                  />
                </label>
              </div>

              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Part-time Teachers:
                  <input
                    type="number"
                    name="part_time_teachers"
                    value={formData.part_time_teachers}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="0"
                    required
                  />
                </label>
              </div>

              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Administrators:
                  <input
                    type="number"
                    name="administrators"
                    value={formData.administrators}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="0"
                    required
                  />
                </label>
              </div>

              <div style={styles.fieldGroup}>
                <label style={styles.label}>
                  Other Staff:
                  <input
                    type="number"
                    name="other_staff"
                    value={formData.other_staff}
                    onChange={handleInputChange}
                    style={styles.numberInput}
                    min="0"
                    required
                  />
                </label>
              </div>
            </div>

            <div style={styles.totalStaff}>
              Total Staff Count: {calculateTotalStaff()}
            </div>
          </div>
        );
      case 6:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Site Administrator Information</h2>
            
            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Salutation:
                <select
                  name="site_admin_salutation"
                  value={formData.site_admin_salutation}
                  onChange={handleInputChange}
                  style={styles.select}
                >
                  <option value="">Select Salutation</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Dr.">Dr.</option>
                </select>
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                First Name:*
                <input
                  type="text"
                  name="site_admin_first_name"
                  value={formData.site_admin_first_name}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Last Name:*
                <input
                  type="text"
                  name="site_admin_last_name"
                  value={formData.site_admin_last_name}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Title:*
                <input
                  type="text"
                  name="site_admin_title"
                  value={formData.site_admin_title}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Phone:*
                <input
                  type="tel"
                  name="site_admin_phone"
                  value={formData.site_admin_phone}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Extension:
                <input
                  type="text"
                  name="site_admin_extension"
                  value={formData.site_admin_extension}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Email:*
                <input
                  type="email"
                  name="site_admin_email"
                  value={formData.site_admin_email}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Street:*
                <input
                  type="text"
                  name="site_admin_street"
                  value={formData.site_admin_street}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                City:*
                <input
                  type="text"
                  name="site_admin_city"
                  value={formData.site_admin_city}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                State:*
                <input
                  type="text"
                  name="site_admin_state"
                  value={formData.site_admin_state}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                ZIP:*
                <input
                  type="text"
                  name="site_admin_zip"
                  value={formData.site_admin_zip}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  pattern="[0-9]{5}"
                />
              </label>
            </div>
          </div>
        );
      case 7: // Step 8
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Records Contact Information</h2>
            <p style={styles.sectionDescription}>
              Enter the contact information for the person responsible for maintaining school records.
            </p>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Contact Name:*
                <input
                  type="text"
                  name="records_contact_name"
                  value={formData.records_contact_name || `${formData.site_admin_first_name} ${formData.site_admin_last_name}`}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Phone:*
                <input
                  type="tel"
                  name="records_contact_phone"
                  value={formData.records_contact_phone || formData.site_admin_phone}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Email:*
                <input
                  type="email"
                  name="records_contact_email"
                  value={formData.records_contact_email || formData.site_admin_email}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <h3 style={styles.subSectionTitle}>Records Location</h3>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                Street:*
                <input
                  type="text"
                  name="records_street"
                  value={formData.records_street || formData.site_admin_street}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                City:*
                <input
                  type="text"
                  name="records_city"
                  value={formData.records_city || formData.site_admin_city}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                State:*
                <input
                  type="text"
                  name="records_state"
                  value={formData.records_state || formData.site_admin_state}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                />
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.label}>
                ZIP:*
                <input
                  type="text"
                  name="records_zip"
                  value={formData.records_zip || formData.site_admin_zip}
                  onChange={handleInputChange}
                  style={styles.input}
                  required
                  pattern="[0-9]{5}"
                />
              </label>
            </div>
          </div>
        );
      case 8: // Step 9
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Tax Status</h2>
            <p style={styles.sectionDescription}>
              Indicate which tax exemption(s) apply to the school. Note: This section is to help identify incorporated, nonprofit, tax exempt schools. If the school is not confirmed by the federal or state governments as nonprofit/tax exempt, which includes homeschools in almost every case, select "None of the above."
            </p>

            <div style={styles.fieldGroup}>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="tax_status.section501c3"
                  checked={formData.tax_status?.section501c3}
                  onChange={() => handleTaxStatusChange('section501c3')}
                />
                Section 501(c)(3) of the Internal Revenue Code
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="tax_status.section23701d"
                  checked={formData.tax_status?.section23701d}
                  onChange={() => handleTaxStatusChange('section23701d')}
                />
                Section 23701d of the California Revenue and Taxation Code
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="tax_status.section214"
                  checked={formData.tax_status?.section214}
                  onChange={() => handleTaxStatusChange('section214')}
                />
                Section 214 of the California Revenue and Taxation Code
              </label>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="tax_status.none"
                  checked={formData.tax_status?.none}
                  onChange={handleNoneSelected}
                />
                None of the above
              </label>
            </div>
          </div>
        );
      case 9:
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Acknowledgment and Statutory Notices</h2>
            {/* Step 9 content */}
            <p style={styles.sectionDescription}>
              Please read and acknowledge the following:
            </p>

            <div style={styles.noticesList}>
              <ul style={styles.bulletList}>
                <li>All Private School Affidavits are public documents viewable by the public.</li>
                
                <li>The Private School Affidavit must be filed by persons, firms, associations, partnerships, 
                or corporations offering or conducting full-time day school at the elementary or high school 
                level for students between the ages of six and eighteen years of age.</li>
                
                <li>Preschools should contact the Community Care Licensing Division (CCLD) of the California 
                Department of Social Services. Contact CCLD at 916-229-4530 or contact a regional office.</li>
                
                <li>The Affidavit is not a license or authorization to operate a private school.</li>
                
                <li>The Private School Affidavit does not indicate approval, recognition, or endorsement by 
                the state. Filing of this Affidavit shall not be interpreted to mean, and it shall be unlawful 
                for any school to expressly or impliedly represent by any means whatsoever, that the State of 
                California, the Superintendent of Public Instruction, the State Board of Education, the CDE, or 
                any division or bureau of the Department, or any accrediting agency has made any evaluation, 
                recognition, approval, or endorsement of the school or course unless this is an actual fact.</li>
                
                <li>Private school authorities are responsible for initiating contact with the appropriate local 
                authorities (city and/or county) regarding compliance with ordinances governing health, safety 
                and fire standards, business licensing, and zoning requirements applicable to private schools.</li>
                
                <li>When a school ceases operation, every effort shall be made to give a copy of pupils' permanent 
                records to parents or guardians. If records cannot be given to the parents or guardians, it is 
                recommended that the school's custodian of records retain the records permanently so that former 
                pupils may obtain copies when needed for future education, employment, or other purposes.</li>
                
                <li>You shall retain a copy of this document for a period of three years.</li>
                
                <li>A private school shall not employ a person who has been convicted of a violent or serious 
                felony or a person who would be prohibited from employment by a public school district pursuant 
                to EC Section 44237. This school is in compliance with EC Section 44237 to the extent that it applies.</li>
                
                <li>The students enrolled in this private school and included in the school's enrollment total 
                are full-time students in this school and are not enrolled in any other public or private 
                elementary or secondary school on a full-time basis.</li>
              </ul>
            </div>

            <div style={styles.fieldGroup}>
              <label style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="statutory_acknowledgment"
                  checked={formData.statutory_acknowledgment}
                  onChange={(e) => {
                    handleInputChange({
                      target: {
                        name: 'statutory_acknowledgment',
                        value: e.target.checked
                      }
                    });
                  }}
                  required
                />
                I acknowledge that I have read and understand the above Statutory Notices and assure 
                the school's compliance.
              </label>
            </div>
          </div>
        );
      case 10: // Step 11
        return (
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>Review & Submit</h2>
            <p style={styles.sectionDescription}>
              Please review all information before submitting. Make sure all required fields are filled out correctly.
            </p>

            {/* School Information */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>School Information</h4>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Full-time Private School:</span>
                <span style={styles.reviewValue}>{formData.is_full_time_private || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>School Name:</span>
                <span style={styles.reviewValue}>{formData.school_name || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>County:</span>
                <span style={styles.reviewValue}>{formData.county || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>District:</span>
                <span style={styles.reviewValue}>{formData.district || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>School Type:</span>
                <span style={styles.reviewValue}>{formData.school_type || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Accommodations:</span>
                <span style={styles.reviewValue}>{formData.accommodations || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Special Education:</span>
                <span style={styles.reviewValue}>{formData.special_education || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>High School Diploma:</span>
                <span style={styles.reviewValue}>{formData.high_school_diploma || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Low Grade:</span>
                <span style={styles.reviewValue}>{formData.low_grade || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>High Grade:</span>
                <span style={styles.reviewValue}>{formData.high_grade || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Classification:</span>
                <span style={styles.reviewValue}>{formData.classification || 'Not specified'}</span>
              </div>
            </div>

            {/* School Address */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>School Address</h4>
              <h5 style={styles.reviewSubheading}>Physical Address</h5>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Street:</span>
                <span style={styles.reviewValue}>{formData.physical_street || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>City:</span>
                <span style={styles.reviewValue}>{formData.physical_city || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>State:</span>
                <span style={styles.reviewValue}>{formData.physical_state || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>ZIP:</span>
                <span style={styles.reviewValue}>{formData.physical_zip || 'Not specified'}</span>
              </div>

              <h5 style={styles.reviewSubheading}>Mailing Address</h5>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Street:</span>
                <span style={styles.reviewValue}>{formData.mailing_street || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>City:</span>
                <span style={styles.reviewValue}>{formData.mailing_city || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>State:</span>
                <span style={styles.reviewValue}>{formData.mailing_state || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>ZIP:</span>
                <span style={styles.reviewValue}>{formData.mailing_zip || 'Not specified'}</span>
              </div>
            </div>

            {/* Contact Information */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Contact Information</h4>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Phone:</span>
                <span style={styles.reviewValue}>{formData.phone || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Fax Number:</span>
                <span style={styles.reviewValue}>{formData.fax_number || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Primary Email:</span>
                <span style={styles.reviewValue}>{formData.primary_email || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Website:</span>
                <span style={styles.reviewValue}>{formData.website || 'Not specified'}</span>
              </div>
            </div>

            {/* Prior Year Changes */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Prior Year Changes</h4>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Name Changed:</span>
                <span style={styles.reviewValue}>{formData.name_changed ? 'Yes' : 'No'}</span>
              </div>
              {formData.name_changed && (
                <div style={styles.reviewItem}>
                  <span style={styles.reviewLabel}>Previous Name:</span>
                  <span style={styles.reviewValue}>{formData.previous_name || 'Not specified'}</span>
                </div>
              )}
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>District Changed:</span>
                <span style={styles.reviewValue}>{formData.district_changed ? 'Yes' : 'No'}</span>
              </div>
              {formData.district_changed && (
                <div style={styles.reviewItem}>
                  <span style={styles.reviewLabel}>Previous District:</span>
                  <span style={styles.reviewValue}>{formData.previous_district || 'Not specified'}</span>
                </div>
              )}
            </div>

            {/* Statistical Information */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Statistical Information</h4>
              
              <h5 style={styles.reviewSubheading}>Student Ages</h5>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Youngest:</span>
                <span style={styles.reviewValue}>{formData.youngest_years} years, {formData.youngest_months} months</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Oldest:</span>
                <span style={styles.reviewValue}>{formData.oldest_years} years</span>
              </div>

              <h5 style={styles.reviewSubheading}>Enrollment Data</h5>
              {formData?.enrollment && typeof formData.enrollment === 'object' && (
                <div>
                  {Object.entries(formData.enrollment).map(([grade, count]) => (
                    <div key={grade} style={styles.reviewItem}>
                      <span style={styles.reviewLabel}>
                        {grade.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                      </span>
                      <span style={styles.reviewValue}>{count || 0}</span>
                    </div>
                  ))}
                  <div style={styles.reviewTotal}>
                    <span style={styles.reviewLabel}>Total Enrollment:</span>
                    <span style={styles.reviewValue}>{calculateTotalEnrollment()}</span>
                  </div>
                </div>
              )}

              <h5 style={styles.reviewSubheading}>Staff Information</h5>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Full-time Teachers:</span>
                <span style={styles.reviewValue}>{formData.full_time_teachers || 0}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Part-time Teachers:</span>
                <span style={styles.reviewValue}>{formData.part_time_teachers || 0}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Administrators:</span>
                <span style={styles.reviewValue}>{formData.administrators || 0}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Other Staff:</span>
                <span style={styles.reviewValue}>{formData.other_staff || 0}</span>
              </div>
              <div style={styles.reviewTotal}>
                <span style={styles.reviewLabel}>Total Staff:</span>
                <span style={styles.reviewValue}>{calculateTotalStaff()}</span>
              </div>
            </div>

            {/* Administrative Staff */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Site Administrator Information</h4>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Salutation:</span>
                <span style={styles.reviewValue}>{formData.site_admin_salutation || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>First Name:</span>
                <span style={styles.reviewValue}>{formData.site_admin_first_name || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Last Name:</span>
                <span style={styles.reviewValue}>{formData.site_admin_last_name || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Title:</span>
                <span style={styles.reviewValue}>{formData.site_admin_title || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Phone:</span>
                <span style={styles.reviewValue}>{formData.site_admin_phone || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Email:</span>
                <span style={styles.reviewValue}>{formData.site_admin_email || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Street:</span>
                <span style={styles.reviewValue}>{formData.site_admin_street || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>City:</span>
                <span style={styles.reviewValue}>{formData.site_admin_city || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>State:</span>
                <span style={styles.reviewValue}>{formData.site_admin_state || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>ZIP:</span>
                <span style={styles.reviewValue}>{formData.site_admin_zip || 'Not specified'}</span>
              </div>
            </div>

            {/* Records Information */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Records Contact Information</h4>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Contact Name:</span>
                <span style={styles.reviewValue}>{formData.records_contact_name || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Phone:</span>
                <span style={styles.reviewValue}>{formData.records_contact_phone || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Email:</span>
                <span style={styles.reviewValue}>{formData.records_contact_email || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>Street:</span>
                <span style={styles.reviewValue}>{formData.records_street || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>City:</span>
                <span style={styles.reviewValue}>{formData.records_city || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>State:</span>
                <span style={styles.reviewValue}>{formData.records_state || 'Not specified'}</span>
              </div>
              <div style={styles.reviewItem}>
                <span style={styles.reviewLabel}>ZIP:</span>
                <span style={styles.reviewValue}>{formData.records_zip || 'Not specified'}</span>
              </div>
            </div>

            {/* Tax Status */}
            <div style={styles.reviewSection}>
              <h4 style={styles.reviewSubheading}>Tax Status</h4>
              {formData?.tax_status && typeof formData.tax_status === 'object' && (
                <div>
                  {Object.entries(formData.tax_status).map(([status, isChecked]) => (
                    isChecked && (
                      <div key={status} style={styles.reviewItem}>
                        <span style={styles.reviewLabel}>
                          {status.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
                        </span>
                        <span style={styles.reviewValue}>Yes</span>
                      </div>
                    )
                  ))}
                </div>
              )}
            </div>

            <div style={styles.finalButtons}>
              <button 
                type="button"
                style={styles.submitAffidavitButton}
                onClick={handleSubmit}
              >
                Submit Affidavit
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Add loading overlay component
  const LoadingOverlay = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '32px',
        borderRadius: '8px',
        maxWidth: '400px',
        width: '90%',
        textAlign: 'center',
      }}>
        <div style={{ marginBottom: '20px' }}>
          <div style={{
            border: '4px solid #00356b',
            borderTop: '4px solid transparent',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            animation: 'spin 1s linear infinite',
            margin: '0 auto 20px',
          }} />
          <h3 style={{ 
            color: '#00356b', 
            marginBottom: '8px',
            fontSize: '20px',
            fontWeight: '600'
          }}>
            {submissionProgress.status || 'Submitting PSA...'}
          </h3>
          <p style={{ 
            color: '#4a5568',
            whiteSpace: 'pre-line',
            fontSize: '14px',
            lineHeight: '1.5'
          }}>
            {submissionProgress.details}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      {loading && <LoadingOverlay />}
      <div style={styles.headerContainer}>
        <button 
          onClick={() => navigate('/state-compliance-filing')}
          style={styles.backButton}
        >
          ← Back to Compliance
        </button>
        <PageHeader title="2024-2025 California Private School Affidavit (PSA) Form" />
        <div style={styles.progressContainer}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" style={styles.progressText}>
                Status: 
                <span style={{
                  ...styles.statusChip,
                  backgroundColor: completionStatus.status === 'Completed' ? '#C6F6D5' :
                                 completionStatus.status === 'In Progress' ? '#FEEBC8' : '#FED7D7',
                  color: completionStatus.status === 'Completed' ? '#22543D' :
                         completionStatus.status === 'In Progress' ? '#744210' : '#822727'
                }}>
                  {completionStatus.status}
                </span>
              </Typography>
              <Typography variant="body2" style={styles.progressText}>
                {completionStatus.percent}%
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={completionStatus.percent || 0}
              style={styles.progressBar}
              sx={{
                backgroundColor: '#E2E8F0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: completionStatus.status === 'Completed' ? '#48BB78' :
                                 completionStatus.status === 'In Progress' ? '#ED8936' : '#F56565'
                }
              }}
            />
          </Box>
        </div>
        {snackbar.open && (
          <div style={{
            padding: '8px 16px',
            backgroundColor: snackbar.severity === 'success' ? '#48BB78' : '#F6E05E',
            color: snackbar.severity === 'success' ? 'white' : 'black',
            borderRadius: '4px',
            marginLeft: '16px',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center'
          }}>
            {snackbar.message}
          </div>
        )}
      </div>

      {/* Add note text bubble */}
      <Alert severity="info" style={{ marginBottom: '24px', backgroundColor: '#EBF8FF', color: '#2C5282' }}>
        Note: This filing is the same that traditional private schools use in the state, and as such, some of the questions may not seem relevant for homeschool families. Please answer all questions to the best of your ability, and don't hesitate to reach out to our support team should you have any questions.
      </Alert>

      <div style={{...styles.content, display: 'flex', gap: '24px'}}>
        {/* Left sidebar with tabs */}
        <div style={styles.tabContainer}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              style={{
                ...styles.tabButton,
                backgroundColor: activeTab === tab.id ? '#00356b' : 'transparent',
                color: activeTab === tab.id ? 'white' : '#2d3748',
              }}
            >
              <span style={styles.tabStep}>{tab.step}</span>
              {tab.title}
            </button>
          ))}
        </div>

        {/* Right side content */}
        <div style={styles.mainContent}>
          <form onSubmit={handleSubmit} style={styles.form}>
            {renderTabContent()}
          </form>
        </div>
      </div>

      {/* Keep Print Preview Dialog */}
      <Dialog
        open={isPrintPreviewOpen}
        onClose={() => setIsPrintPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Print Preview
          <IconButton
            aria-label="close"
            onClick={() => setIsPrintPreviewOpen(false)}
            style={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PrintPreview formData={formData} calculateTotalStaff={calculateTotalStaff} />
          <div style={styles.previewButtons}>
            <button
              onClick={() => window.print()}
              style={styles.printButton}
            >
              Print
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Signature Dialog */}
      <Dialog
        open={openSignatureDialog}
        onClose={() => setOpenSignatureDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Please electronically sign below</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            I approve the submission of this affidavit, completed to the best of my ability, and approve YourEDU to submit on my behalf.
          </Typography>
          <TextField
            fullWidth
            label="Full Name"
            value={signature.name}
            onChange={(e) => setSignature({ ...signature, name: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            type="date"
            label="Date"
            value={signature.date}
            onChange={(e) => setSignature({ ...signature, date: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSignatureDialog(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSignAndSubmit}
            disabled={!signature.name || !signature.date}
          >
            Sign & Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Add keyframe animation to styles
const keyframeStyles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Add style tag to head
if (!document.getElementById('psa-keyframes')) {
  const styleTag = document.createElement('style');
  styleTag.id = 'psa-keyframes';
  styleTag.innerHTML = keyframeStyles;
  document.head.appendChild(styleTag);
}

export default CaliforniaPSA; 