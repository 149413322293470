// src/components/CourseCard.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card } from '@mui/material'
import { FeatureHeader, SupportingText } from '../ui/typography'

const getConsistentColor = (courseId) => {
  // Use theme colors instead of hardcoded values
  const colors = [
    'hsl(342, 84%, 49%)', // pink from courseCards.pink
    'hsl(120, 100%, 26%)', // green from courseCards.green
    'hsl(221, 86%, 55%)', // blue from courseCards.blue
    'hsl(24, 86%, 50%)', // orange from courseCards.orange
  ]

  const hash = String(courseId || '')
    .split('')
    .reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc)
    }, 0)

  return colors[Math.abs(hash) % colors.length]
}

const CourseCard = ({ courseGroup = [] }) => {
  const navigate = useNavigate()

  if (!courseGroup || !Array.isArray(courseGroup) || courseGroup.length < 2) {
    return null
  }

  const [term, courses] = courseGroup
  const coursesArray = Array.isArray(courses) ? courses : [courses]
  const mainCourse = coursesArray[0] || {}

  const cardColor = getConsistentColor(mainCourse?.code || mainCourse?.id)

  return (
    <Card
      onClick={() => mainCourse?.id && !mainCourse?.preventViewMore && navigate(`/user-course/${mainCourse.id}`)}
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
        },
        boxShadow: 'none',
      }}
    >
      <Box sx={{ height: '128px', bgcolor: cardColor }} />
      <Box sx={{ p: 2 }}>
        <FeatureHeader
          sx={{
            color: cardColor,
            fontSize: '1rem',
            fontWeight: 500,
            mb: 1,
          }}
        >
          {mainCourse?.courseTitle || mainCourse?.title || 'Untitled Course'}
        </FeatureHeader>
        <SupportingText sx={{ fontSize: '0.875rem', color: 'text.secondary', mb: 0.5 }}>
          {mainCourse?.term_start || term || 'Winter'} {new Date().getFullYear().toString().slice(-2)}
        </SupportingText>
        <SupportingText sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
          {mainCourse?.college || mainCourse?.institution || 'Unknown Institution'}
        </SupportingText>
      </Box>
    </Card>
  )
}

export default CourseCard
