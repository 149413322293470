import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
    Container, 
    Typography, 
    Paper, 
    Box,
    Button,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Alert,
    Snackbar
} from '@mui/material';
import { 
    CloudUpload as CloudUploadIcon,
    Close as CloseIcon,
    Download as DownloadIcon,
    Delete as DeleteIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from '../utils/AuthContext';
import { format } from 'date-fns';
import { PageHeader, SectionHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography';

const StateComplianceFiling = () => {
    const navigate = useNavigate();
    const [userState, setUserState] = useState('California');
    const [forms, setForms] = useState([]);
    const { user } = useAuth();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadType, setUploadType] = useState('');
    const [message, setMessage] = useState({ type: '', content: '' });
    const [documents, setDocuments] = useState([]);
    const [recentSubmissions, setRecentSubmissions] = useState([]);

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    const loadPSAProgress = async () => {
        if (!user || userState !== 'California') return;

        try {
            const { data, error } = await supabase
                .from('california_psa')
                .select('*')
                .eq('user_id', user.id)
                .maybeSingle();

            if (error) throw error;

            // Calculate progress based on filled fields
            const requiredFields = [
                'school_name', 'cds_code', 'county', 'district',
                'is_full_time_private', 'physical_street',
                'physical_city', 'physical_state', 'physical_zip', 'phone',
                'primary_email', 'youngest_years', 'youngest_months',
                'oldest_years', 'site_admin_first_name', 'site_admin_last_name',
                'site_admin_title', 'site_admin_phone', 'site_admin_email',
                'statutory_acknowledgment'
            ];

            let progress = 0;
            if (data) {
                let filledFields = 0;
                requiredFields.forEach(field => {
                    if (data[field]) filledFields++;
                });

                // Check enrollment fields
                const enrollmentFields = Object.values(data.enrollment || {}).filter(value => value > 0).length;
                if (enrollmentFields > 0) filledFields++;

                // Check tax status
                const taxStatusSelected = Object.values(data.tax_status || {}).some(value => value === true);
                if (taxStatusSelected) filledFields++;

                progress = Math.round((filledFields / (requiredFields.length + 2)) * 100);
            }

            let status = 'not_started';
            if (progress === 100) {
                status = 'submitted';
            } else if (progress > 0) {
                status = 'started';
            }

            setForms([{
                id: 'psa',
                name: 'Private School Affidavit Form',
                dueDate: 'Rolling',
                status,
                progress,
                description: 'Annual filing requirement for home-based private schools'
            }]);
        } catch (error) {
            console.error('Error loading PSA progress:', error.message);
        }
    };

    useEffect(() => {
        if (user) {
            loadPSAProgress();
            loadDocuments();
        }
    }, [user, userState]);

    const loadDocuments = async () => {
        try {
            const { data, error } = await supabase
                .from('compliance_documents')
                .select('*')
                .eq('user_id', user.id)
                .eq('state', userState);

            if (error) throw error;
            setDocuments(data || []);
        } catch (error) {
            console.error('Error loading documents:', error);
            setMessage({ type: 'error', content: 'Failed to load documents' });
        }
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 10 * 1024 * 1024) { // 10MB limit
            setSelectedFile(file);
        } else {
            setMessage({ type: 'error', content: 'File size must be less than 10MB' });
        }
    };

    const handleUpload = async () => {
        if (!selectedFile || !uploadType) return;

        try {
            const fileExt = selectedFile.name.split('.').pop();
            const fileName = `${user.id}/${uploadType}_${Date.now()}.${fileExt}`;
            
            // Upload file to storage
            const { error: uploadError } = await supabase.storage
                .from('compliance_documents')
                .upload(fileName, selectedFile);

            if (uploadError) throw uploadError;

            // Get public URL
            const { data: { publicUrl } } = supabase.storage
                .from('compliance_documents')
                .getPublicUrl(fileName);

            // Save document metadata
            const { error: dbError } = await supabase
                .from('compliance_documents')
                .insert([{
                    user_id: user.id,
                    state: userState,
                    document_type: uploadType,
                    file_name: selectedFile.name,
                    file_path: fileName,
                    file_url: publicUrl,
                    status: 'pending',
                    uploaded_at: new Date().toISOString()
                }]);

            if (dbError) throw dbError;

            // If this is a completed PSA, send email
            if (uploadType === 'completed_psa') {
                await sendPSAEmail(publicUrl);
            }

            setMessage({ type: 'success', content: 'Document uploaded successfully' });
            setUploadOpen(false);
            setSelectedFile(null);
            loadDocuments();
        } catch (error) {
            console.error('Error uploading document:', error);
            setMessage({ type: 'error', content: 'Failed to upload document' });
        }
    };

    const sendPSAEmail = async (fileUrl) => {
        try {
            const { error } = await supabase.functions.invoke('send-psa-email', {
                body: {
                    userId: user.id,
                    fileUrl: fileUrl,
                    email: user.email
                }
            });

            if (error) throw error;
        } catch (error) {
            console.error('Error sending PSA email:', error);
            setMessage({ type: 'warning', content: 'Document uploaded but email notification failed' });
        }
    };

    const handleDelete = async (documentId) => {
        try {
            const document = documents.find(d => d.id === documentId);
            
            // Delete from storage
            const { error: storageError } = await supabase.storage
                .from('compliance_documents')
                .remove([document.file_path]);

            if (storageError) throw storageError;

            // Delete from database
            const { error: dbError } = await supabase
                .from('compliance_documents')
                .delete()
                .eq('id', documentId);

            if (dbError) throw dbError;

            setMessage({ type: 'success', content: 'Document deleted successfully' });
            loadDocuments();
        } catch (error) {
            console.error('Error deleting document:', error);
            setMessage({ type: 'error', content: 'Failed to delete document' });
        }
    };

    const handleStateChange = (event) => {
        setUserState(event.target.value);
    };

    const getStatusColor = (status) => {
        switch(status) {
            case 'submitted':
                return 'success.main';
            case 'pending':
                return 'warning.main';
            case 'started':
                return 'info.main';
            default:
                return 'text.secondary';
        }
    };

    const getStatusProgress = (form) => {
        return form.progress || 0;
    };

    const getStatusText = (status) => {
        return status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ');
    };

    useEffect(() => {
        const loadSubmissions = async () => {
            if (!user) return;
            
            const { data, error } = await supabase
                .from('psa_submissions')
                .select('*')
                .eq('user_id', user.id)
                .order('submitted_at', { ascending: false });
            
            if (error) {
                console.error('Error loading submissions:', error);
                return;
            }
            
            setRecentSubmissions(data || []);
        };
        
        loadSubmissions();
    }, [user]);

    const additionalStyles = {
        submissionsList: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },
        submissionItem: {
            padding: '16px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        },
        submissionHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
        },
        submissionTitle: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#2D3748',
            margin: 0,
        },
        submissionDate: {
            fontSize: '14px',
            color: '#718096',
        },
        submissionStatus: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        statusBadge: {
            padding: '4px 12px',
            borderRadius: '16px',
            fontSize: '14px',
            fontWeight: '500',
        },
        accessButton: {
            padding: '6px 16px',
            backgroundColor: '#00356B',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#002548',
            },
        },
        emptyMessage: {
            color: '#718096',
            fontStyle: 'italic',
            textAlign: 'center',
            padding: '24px',
        },
    };

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
            {/* Back Button Section */}
            <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2, mb: 3 }}>
                <Container maxWidth="lg">
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate('/compliance')}
                        sx={{
                            color: '#00356b',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 53, 107, 0.08)',
                            },
                        }}
                    >
                        Back to Compliance
                    </Button>
                </Container>
            </Box>
            {/* Hero Section */}
            <Box 
                sx={{ 
                    position: 'relative',
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
                        backgroundSize: '20px 20px',
                        opacity: 0.1,
                    },
                    pt: 'var(--spacing-8)',
                    pb: 'var(--spacing-6)',
                }}
            >
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (max-width: 768px)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <Box>
                        <PageHeader>State Compliance Filing</PageHeader>
                        <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                            Submit and track your state compliance requirements for homeschooling.
                        </DescriptiveText>
                    </Box>
                </Container>
            </Box>

            {/* Main Content */}
            <Container 
                maxWidth="var(--container-max-width)"
                sx={{ 
                    py: 'var(--spacing-8)',
                    px: 'var(--container-padding-x)',
                    '@media (max-width: 768px)': {
                        px: 'var(--container-padding-x-mobile)',
                    },
                }}
            >
                <Snackbar 
                    open={!!message.content} 
                    autoHideDuration={6000} 
                    onClose={() => setMessage({ type: '', content: '' })}
                >
                    <Alert severity={message.type} onClose={() => setMessage({ type: '', content: '' })}>
                        {message.content}
                    </Alert>
                </Snackbar>

                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 'var(--spacing-4)', 
                        mb: 'var(--spacing-6)', 
                        backgroundColor: 'hsl(var(--muted))',
                        border: '1px solid hsl(var(--border))',
                        borderRadius: 'var(--radius-lg)'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="state-select-label">Select State</InputLabel>
                            <Select
                                labelId="state-select-label"
                                value={userState}
                                label="Select State"
                                onChange={handleStateChange}
                                sx={{
                                    backgroundColor: 'hsl(var(--background))',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--border))',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                }}
                            >
                                {states.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <BodyText>View compliance requirements for your state</BodyText>
                    </Box>
                </Paper>

                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 'var(--spacing-4)', 
                        mb: 'var(--spacing-4)', 
                        backgroundColor: 'hsl(var(--muted))',
                        border: '1px solid hsl(var(--border))',
                        borderRadius: 'var(--radius-lg)'
                    }}
                >
                    <DescriptiveText sx={{ fontStyle: 'italic' }}>
                        We're here to help you streamline your homeschool compliance process and provide peace of mind. 
                        Our service is designed to support parents while maintaining your privacy - we do not share your 
                        information with state agencies.
                    </DescriptiveText>
                </Paper>

                <Box sx={{ mb: 'var(--spacing-6)' }}>
                    <SectionHeader>Required Submissions</SectionHeader>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-3)' }}>
                        {forms.map((form) => (
                            <Paper
                                key={form.id}
                                elevation={0}
                                sx={{ 
                                    p: 'var(--spacing-4)',
                                    backgroundColor: 'hsl(var(--muted))',
                                    border: '1px solid hsl(var(--border))',
                                    borderRadius: 'var(--radius-lg)'
                                }}
                            >
                                <Box sx={{ mb: 'var(--spacing-3)' }}>
                                    <SectionHeader sx={{ fontSize: '1.1rem', mb: 'var(--spacing-2)' }}>
                                        {form.name}
                                    </SectionHeader>
                                    <Box sx={{ display: 'flex', gap: 'var(--spacing-4)', mb: 'var(--spacing-3)' }}>
                                        <SupportingText>Due: {form.dueDate}</SupportingText>
                                        <SupportingText>{form.description}</SupportingText>
                                    </Box>
                                    <Box sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 'var(--spacing-2)', 
                                        mb: 'var(--spacing-3)'
                                    }}>
                                        <SupportingText sx={{ 
                                            color: form.status === 'submitted' 
                                                ? 'hsl(var(--success))' 
                                                : form.status === 'started'
                                                ? 'hsl(var(--info))'
                                                : 'hsl(var(--text-secondary))'
                                        }}>
                                            Status: {getStatusText(form.status)}
                                        </SupportingText>
                                        <SupportingText sx={{ 
                                            color: form.status === 'submitted' 
                                                ? 'hsl(var(--success))' 
                                                : form.status === 'started'
                                                ? 'hsl(var(--info))'
                                                : 'hsl(var(--text-secondary))'
                                        }}>
                                            ({getStatusProgress(form)}%)
                                        </SupportingText>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress 
                                            variant="determinate" 
                                            value={getStatusProgress(form)}
                                            sx={{ 
                                                height: 8, 
                                                borderRadius: 'var(--radius-full)',
                                                backgroundColor: 'hsl(var(--muted-foreground))',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: form.status === 'submitted' 
                                                        ? 'hsl(var(--success))' 
                                                        : form.status === 'started'
                                                        ? 'hsl(var(--info))'
                                                        : 'hsl(var(--text-secondary))'
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button 
                                        variant="contained"
                                        component={Link}
                                        to="/california-psa"
                                        disabled={form.status === 'submitted'}
                                        sx={{
                                            backgroundColor: 'hsl(var(--brand-primary))',
                                            color: 'hsl(var(--background))',
                                            '&:hover': {
                                                backgroundColor: 'hsl(var(--brand-primary-dark))'
                                            },
                                            '&:disabled': {
                                                backgroundColor: 'hsl(var(--muted-foreground))',
                                                color: 'hsl(var(--muted))'
                                            }
                                        }}
                                    >
                                        {form.status === 'not_started' ? 'Begin New Submission' : 
                                         form.status === 'submitted' ? 'Submitted' : 'Edit Submission'}
                                    </Button>
                                </Box>
                            </Paper>
                        ))}
                    </List>
                </Box>

                <Box sx={{ mb: 'var(--spacing-6)' }}>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        mb: 'var(--spacing-4)'
                    }}>
                        <SectionHeader>Documents</SectionHeader>
                        <Button
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            onClick={() => setUploadOpen(true)}
                            sx={{
                                backgroundColor: 'hsl(var(--brand-primary))',
                                color: 'hsl(var(--background))',
                                '&:hover': {
                                    backgroundColor: 'hsl(var(--brand-primary-dark))'
                                }
                            }}
                        >
                            Upload Document
                        </Button>
                    </Box>

                    {userState === 'California' && (
                        <DescriptiveText sx={{ mb: 'var(--spacing-4)', fontStyle: 'italic' }}>
                            Note: California does not currently require any additional documents with your PSA submission.
                        </DescriptiveText>
                    )}

                    <List sx={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-3)' }}>
                        {documents.map((doc) => (
                            <Paper
                                key={doc.id}
                                elevation={0}
                                sx={{ 
                                    p: 'var(--spacing-4)',
                                    backgroundColor: 'hsl(var(--muted))',
                                    border: '1px solid hsl(var(--border))',
                                    borderRadius: 'var(--radius-lg)'
                                }}
                            >
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center'
                                }}>
                                    <Box>
                                        <SectionHeader sx={{ fontSize: '1.1rem', mb: 'var(--spacing-1)' }}>
                                            {doc.file_name}
                                        </SectionHeader>
                                        <SupportingText>
                                            Type: {doc.document_type} | Status: {doc.status} | 
                                            Uploaded: {new Date(doc.uploaded_at).toLocaleDateString()}
                                        </SupportingText>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 'var(--spacing-2)' }}>
                                        <IconButton 
                                            onClick={() => window.open(doc.file_url, '_blank')}
                                            sx={{ 
                                                color: 'hsl(var(--text-secondary))',
                                                '&:hover': {
                                                    color: 'hsl(var(--brand-primary))'
                                                }
                                            }}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                        <IconButton 
                                            onClick={() => handleDelete(doc.id)}
                                            sx={{ 
                                                color: 'hsl(var(--text-secondary))',
                                                '&:hover': {
                                                    color: 'hsl(var(--error))'
                                                }
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                        {documents.length === 0 && (
                            <Paper
                                elevation={0}
                                sx={{ 
                                    p: 'var(--spacing-4)',
                                    backgroundColor: 'hsl(var(--muted))',
                                    border: '1px solid hsl(var(--border))',
                                    borderRadius: 'var(--radius-lg)',
                                    textAlign: 'center'
                                }}
                            >
                                <SupportingText>
                                    No documents uploaded. Click the Upload Document button to add documents.
                                </SupportingText>
                            </Paper>
                        )}
                    </List>
                </Box>

                <Dialog 
                    open={uploadOpen} 
                    onClose={() => setUploadOpen(false)}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'hsl(var(--background))',
                            borderRadius: 'var(--radius-lg)'
                        }
                    }}
                >
                    <DialogTitle>
                        <SectionHeader>Upload Document</SectionHeader>
                        <IconButton
                            onClick={() => setUploadOpen(false)}
                            sx={{ 
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'hsl(var(--text-secondary))'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth sx={{ mb: 'var(--spacing-3)' }}>
                            <InputLabel>Document Type</InputLabel>
                            <Select
                                value={uploadType}
                                onChange={(e) => setUploadType(e.target.value)}
                                label="Document Type"
                                sx={{
                                    backgroundColor: 'hsl(var(--background))',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--border))',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                    },
                                }}
                            >
                                <MenuItem value="completed_psa">Completed PSA</MenuItem>
                                <MenuItem value="supporting_document">Supporting Document</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </FormControl>
                        <input
                            accept="application/pdf,.doc,.docx"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            type="file"
                            onChange={handleFileSelect}
                        />
                        <label htmlFor="raised-button-file">
                            <Button
                                variant="outlined"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                fullWidth
                                sx={{
                                    borderColor: 'hsl(var(--border))',
                                    color: 'hsl(var(--text-primary))',
                                    '&:hover': {
                                        borderColor: 'hsl(var(--brand-primary))',
                                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                                    }
                                }}
                            >
                                {selectedFile ? selectedFile.name : 'Choose File'}
                            </Button>
                        </label>
                        <SupportingText sx={{ mt: 'var(--spacing-2)' }}>
                            Maximum file size: 10MB
                        </SupportingText>
                    </DialogContent>
                    <DialogActions sx={{ p: 'var(--spacing-4)' }}>
                        <Button 
                            onClick={() => setUploadOpen(false)}
                            sx={{
                                color: 'hsl(var(--text-primary))'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleUpload}
                            disabled={!selectedFile || !uploadType}
                            variant="contained"
                            sx={{
                                backgroundColor: 'hsl(var(--brand-primary))',
                                color: 'hsl(var(--background))',
                                '&:hover': {
                                    backgroundColor: 'hsl(var(--brand-primary-dark))'
                                },
                                '&:disabled': {
                                    backgroundColor: 'hsl(var(--muted-foreground))',
                                    color: 'hsl(var(--muted))'
                                }
                            }}
                        >
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
};

export default StateComplianceFiling; 