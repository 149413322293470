import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { useAuth } from '../utils/AuthContext';
import OpenAI from 'openai';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { CourseDescriptionService } from '../services/CourseDescriptionService';
import { toast } from 'react-toastify';
import { savePDFToStorage } from '../utils/pdfStorage';
import { modalStyles } from '../styles/modalStyles';

// Set up Modal
Modal.setAppElement('#root');

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// Define default form data structure
const defaultDescriptions = {
  freshman: [],
  sophomore: [],
  junior: [],
  senior: [],
};

const CourseDescriptions = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const saveTimeoutRef = useRef(null);

  // Add missing state definitions
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    freshman: true,
    sophomore: false,
    junior: false,
    senior: false
  });
  const [descriptions, setDescriptions] = useState(() => {
    const cachedData = user?.id ? localStorage.getItem(`courseDescriptions_${user.id}`) : null;
    return cachedData ? JSON.parse(cachedData) : defaultDescriptions;
  });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dataStatus, setDataStatus] = useState('Data saved');

  // Add closeModal function
  const closeModal = () => setIsModalOpen(false);

  // Modal handlers
  const handlePreview = async () => {
    try {
      setIsModalOpen(true);
      
      // Generate PDF blob directly using @react-pdf/renderer
      const pdfBlob = await pdf(
        <CourseDescriptionsPDF descriptions={descriptions} />
      ).toBlob();
      
      if (user?.id) {
        await savePDFToStorage(user.id, pdfBlob, 'course-descriptions');
      }
    } catch (error) {
      console.error('Error handling preview:', error);
      toast.error('Failed to generate preview');
    }
  };

  const handleDownload = async () => {
    try {
      // Generate PDF blob directly using @react-pdf/renderer
      const pdfBlob = await pdf(
        <CourseDescriptionsPDF descriptions={descriptions} />
      ).toBlob();
      
      if (user?.id) {
        await savePDFToStorage(user.id, pdfBlob, 'course-descriptions');
      }

      // Trigger download
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'course_descriptions.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error handling download:', error);
      toast.error('Failed to download document');
    }
  };

  // Dropdown toggle handler
  const toggleDropdown = useCallback((grade) => {
    setDropdowns(prev => ({
      ...prev,
      [grade]: !prev[grade]
    }));
  }, []);

  // Update fetchDescriptions to use CourseDescriptionService
  const fetchDescriptions = useCallback(async () => {
    if (!user?.id) return;

    try {
      setLoading(true);
      setDataStatus('Loading data...');
      
      const data = await CourseDescriptionService.getCourseDescriptions();
      
      setDescriptions(data);
      localStorage.setItem(`courseDescriptions_${user.id}`, JSON.stringify(data));
      setDataStatus('Data loaded');
    } catch (error) {
      console.error('Error fetching descriptions:', error);
      setDataStatus('Error loading data');
      toast.error('Failed to load course descriptions');
      
      // Try to load from localStorage as fallback
      const cachedData = localStorage.getItem(`courseDescriptions_${user.id}`);
      if (cachedData) {
        setDescriptions(JSON.parse(cachedData));
        setDataStatus('Loaded from cache');
      }
    } finally {
      setLoading(false);
    }
  }, [user]);

  // Update saveDescriptions to use CourseDescriptionService
  const saveDescriptions = useCallback(async (updatedDescriptions) => {
    if (!user?.id) {
      console.error('Cannot save: missing user ID');
      return;
    }
    
    try {
      setSaving(true);
      setDataStatus('Saving data...');
      
      await CourseDescriptionService.saveCourseDescriptions(updatedDescriptions);
      
      localStorage.setItem(`courseDescriptions_${user.id}`, JSON.stringify(updatedDescriptions));
      setDescriptions(updatedDescriptions);
      setDataStatus('Data saved');
      toast.success('Course descriptions saved successfully');
    } catch (error) {
      console.error('Error saving descriptions:', error);
      setDataStatus('Error saving data');
      toast.error('Failed to save course descriptions');
      // Revert to backend data on save error
      fetchDescriptions();
    } finally {
      setSaving(false);
    }
  }, [user, fetchDescriptions]);

  // Add debouncedSave function
  const debouncedSave = useCallback((updatedDescriptions) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveDescriptions(updatedDescriptions);
    }, 1000);
  }, [saveDescriptions]);

  // Update handleDescriptionChange
  const handleDescriptionChange = useCallback((e, grade, index) => {
    const { name, value } = e.target;
    const updatedDescriptions = { ...descriptions };
    if (!updatedDescriptions[grade]) {
      updatedDescriptions[grade] = [];
    }
    updatedDescriptions[grade][index] = {
      ...updatedDescriptions[grade][index],
      [name]: value,
    };
    
    setDescriptions(updatedDescriptions);
    setDataStatus('Saving to server...');
    setSaving(true);
    debouncedSave(updatedDescriptions);
  }, [descriptions, debouncedSave]);

  // Always fetch from backend on mount
  useEffect(() => {
    if (user?.id) {
      setDataStatus('Loading data...');
      fetchDescriptions();
    }
  }, [user, fetchDescriptions]);

  const addNewCourse = useCallback((grade) => {
    const updatedDescriptions = { ...descriptions };
    if (!updatedDescriptions[grade]) {
      updatedDescriptions[grade] = [];
    }
    updatedDescriptions[grade].push({
      method: '',
      courseTitle: '',
      textbook: '',
      assignments: '',
      goals: '',
      aiDescription: '',
    });
    
    setDataStatus('Saving to server...');
    setSaving(true);
    debouncedSave(updatedDescriptions);
  }, [descriptions, debouncedSave]);

  const removeCourse = useCallback((grade, index) => {
    const updatedDescriptions = { ...descriptions };
    updatedDescriptions[grade].splice(index, 1);
    
    setDataStatus('Saving to server...');
    setSaving(true);
    debouncedSave(updatedDescriptions);
  }, [descriptions, debouncedSave]);

  const generateAIDescription = async (grade, index) => {
    const course = descriptions[grade][index];
    const context = await fetchContext();
    const prompt = `${context}\nCreate a concise, one-paragraph course description for the following course:\nCourse Title: ${course.courseTitle}\nTextbook and Materials Used: ${course.textbook}\nMajor Assignments: ${course.assignments}\nLearning Goals Achieved: ${course.goals}`;

    try {
      const response = await openai.chat.completions.create({
        model: 'ft:gpt-4o-mini-2024-07-18:personal:course-descriptions:AHEWZXxq',
        messages: [{ role: 'system', content: prompt }],
        temperature: 0.3,
        max_tokens: 1024,
      });

      const updatedDescriptions = { ...descriptions };
      updatedDescriptions[grade][index].aiDescription = response.choices[0].message.content.trim();
      setDescriptions(updatedDescriptions);
      console.log(`Generated AI description for ${grade}, course ${index}:`, updatedDescriptions[grade][index]);

      debouncedSave(updatedDescriptions);
    } catch (error) {
      console.error('Error generating AI description:', error);
    }
  };

  const fetchContext = async () => {
    try {
      const response = await fetch('/context.txt');
      const text = await response.text();
      return text;
    } catch (error) {
      console.error('Error fetching context:', error);
      return '';
    }
  };

  const CourseDescriptionsPDF = ({ descriptions }) => {
    const storedName = localStorage.getItem('studentName');
    const studentName = storedName && storedName.length > 0 ? storedName : 'Student Name';

    // Create Table of Contents
    const renderTableOfContents = () => (
      <View style={{ 
        marginBottom: 20,
        border: 1,
        borderColor: 'black',
        borderRadius: 8,
        padding: 10,
      }}>
        <Text style={{ fontSize: 14, textAlign: 'left', marginBottom: 10 }}>Table of Contents</Text>
        <View style={{ 
          flexDirection: 'row', 
          justifyContent: 'space-between',
          marginLeft: 20,
          marginRight: 20
        }}>
          {/* Left Column - Freshman and Sophomore */}
          <View style={{ flex: 1 }}>
            {['freshman', 'sophomore'].map((grade) => (
              <View key={grade} style={{ marginBottom: 5 }}>
                <Text style={{ fontSize: 14 }}>{`${grade.charAt(0).toUpperCase() + grade.slice(1)} Year`}</Text>
                {descriptions[grade]?.map((course, index) => (
                  <Text key={index} style={{ 
                    marginLeft: 10, 
                    fontSize: 11,
                    fontWeight: 'normal' 
                  }}>{course.courseTitle || 'Course Title Not Provided'}</Text>
                ))}
              </View>
            ))}
          </View>

          {/* Right Column - Junior and Senior */}
          <View style={{ flex: 1 }}>
            {['junior', 'senior'].map((grade) => (
              <View key={grade} style={{ marginBottom: 5 }}>
                <Text style={{ fontSize: 14 }}>{`${grade.charAt(0).toUpperCase() + grade.slice(1)} Year`}</Text>
                {descriptions[grade]?.map((course, index) => (
                  <Text key={index} style={{ 
                    marginLeft: 10, 
                    fontSize: 11,
                    fontWeight: 'normal' 
                  }}>{course.courseTitle || 'Course Title Not Provided'}</Text>
                ))}
              </View>
            ))}
          </View>
        </View>
      </View>
    );

    return (
      <Document>
        <Page style={pdfStyles.page}>
          <Text style={pdfStyles.studentName}>{studentName}</Text>
          <Text style={pdfStyles.title}>Course Descriptions</Text>
          
          {renderTableOfContents()} {/* Add Table of Contents here */}

          {['freshman', 'sophomore', 'junior', 'senior'].map((grade) => (
            <View key={grade} style={pdfStyles.section}>
              <Text style={pdfStyles.gradeHeader}>{`${grade.charAt(0).toUpperCase() + grade.slice(1)} Year Courses`}</Text>
              {descriptions[grade]?.map((course, index) => (
                <View key={index} style={pdfStyles.course}>
                  <Text style={pdfStyles.courseTitle}>{course.courseTitle || 'Course Title Not Provided'}</Text>
                  
                  <Text style={pdfStyles.courseDescription}>{course.aiDescription || 'Description Not Provided'}</Text>
                  <Text style={pdfStyles.field}>
                    <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Course Instruction Method:</Text> {course.method}
                  </Text>
                  <Text style={pdfStyles.field}>
                    <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Textbooks and/or materials used:</Text> {course.textbook}
                  </Text>
                  <Text style={pdfStyles.field}>
                    <Text style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Grading and Evaluation Method:</Text> {course.assignments}
                  </Text>
                  
                  <Text style={{ margin: '10px 0' }}></Text> {/* Add space between courses */}
                  <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', marginVertical: 10 }} /> {/* Line between courses */}
                </View>
              ))}
            </View>
          ))}
          <View style={pdfStyles.copyrightContainer} fixed>
            <Text style={pdfStyles.stamp}>Created by YourEDU ©</Text>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <button style={styles.backButton} onClick={() => navigate('/admin-materials')}>Back</button>
        
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
          <h2 style={styles.header}>Course Descriptions</h2>
        </div>
        
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
          {saving ? (
            <div style={{ color: '#28a745' }}>Saving data...</div>
          ) : (
            <div style={{ color: '#28a745' }}>{dataStatus}</div>
          )}
        </div>
        
        <div style={styles.buttonGroup}>
          <button style={styles.button} onClick={handlePreview}>
            Preview
          </button>
          <button style={styles.button} onClick={handleDownload}>
            Download
          </button>
        </div>
      </div>
      {['freshman', 'sophomore', 'junior', 'senior'].map((grade) => (
        <div key={grade}>
          <button
            style={styles.sectionButton}
            onClick={() => toggleDropdown(grade)}
          >
            {grade.charAt(0).toUpperCase() + grade.slice(1)}
            <span style={{ marginLeft: '8px' }}>{dropdowns[grade] ? '▲' : '▼'}</span>
          </button>
          {dropdowns[grade] && (
            <>
              {descriptions[grade] && descriptions[grade].length > 0 ? (
                descriptions[grade].map((course, index) => (
                  <div key={index} style={styles.courseContainer}>
                    <div style={styles.splitContainer}>
                      <div style={styles.leftColumn}>
                        <div style={styles.inputContainer}>
                          <label style={styles.label}>Course Title:</label>
                          <input
                            type="text"
                            name="courseTitle"
                            value={course.courseTitle}
                            onChange={(e) => handleDescriptionChange(e, grade, index)}
                            style={{ ...styles.input, fontWeight: 'bold' }}
                            placeholder="Enter Course Title"
                          />
                        </div>
                        <div style={styles.inputContainer}>
                          <label style={styles.label}>
                            Course Instruction Method (How was the course taught, and if applicable, the institution or program that the course was taken at):
                          </label>
                          <input
                            type="text"
                            name="method"
                            value={course.method}
                            onChange={(e) => handleDescriptionChange(e, grade, index)}
                            style={styles.input}
                          />
                        </div>
                        <div style={styles.inputContainer}>
                          <label style={styles.label}>Textbook and Materials Used:</label>
                          <input
                            type="text"
                            name="textbook"
                            value={course.textbook}
                            onChange={(e) => handleDescriptionChange(e, grade, index)}
                            style={styles.input}
                          />
                        </div>
                        <div style={styles.inputContainer}>
                          <label style={styles.label}>Major Assignments Conducted:</label>
                          <input
                            type="text"
                            name="assignments"
                            value={course.assignments}
                            onChange={(e) => handleDescriptionChange(e, grade, index)}
                            style={styles.input}
                          />
                        </div>
                        <div style={styles.inputContainer}>
                          <label style={styles.label}>Learning Goals Achieved:</label>
                          <input
                            type="text"
                            name="goals"
                            value={course.goals}
                            onChange={(e) => handleDescriptionChange(e, grade, index)}
                            style={styles.input}
                          />
                        </div>
                      </div>
                      <div style={styles.rightColumn}>
                        <button type="button" onClick={() => generateAIDescription(grade, index)} style={styles.button}>
                          Generate AI Description
                        </button>
                        <br />
                        <textarea
                          name="aiDescription"
                          value={course.aiDescription}
                          onChange={(e) => handleDescriptionChange(e, grade, index)}
                          rows="10"
                          cols="50"
                          style={{ 
                            ...styles.textarea, 
                            fontSize: '14px', 
                            resize: 'none', 
                            backgroundColor: '#e6f3ff',
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}
                        />
                        <button type="button" onClick={() => removeCourse(grade, index)} style={styles.removeButton}>
                          Remove Course
                        </button>
                      </div>
                    </div>
                    <hr style={{ border: '1px solid black', margin: '20px 0' }} />
                  </div>
                ))
              ) : (
                <p>{descriptions[grade] && descriptions[grade].length === 0 ? 'No courses available' : `${descriptions[grade].length} courses added`}</p>
              )}
              <button type="button" onClick={() => addNewCourse(grade)} style={styles.button}>
                Add New Course
              </button>
            </>
          )}
        </div>
      ))}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            ...modalStyles.overlay,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '95%',
            width: '1000px',
            height: '90vh',
            margin: '0 auto',
            padding: '20px',
            border: 'none',
            background: '#fff',
            overflow: 'hidden',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
          }
        }}
        contentLabel="Course Descriptions Preview"
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <button 
            onClick={closeModal} 
            style={{ 
              ...styles.button, 
              marginBottom: '10px',
              alignSelf: 'flex-end'
            }}
          >
            Close
          </button>
          <div style={{ flex: 1, minHeight: 0 }}>
            <PDFViewer 
              width="100%" 
              height="100%" 
              style={{ 
                border: 'none',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
            >
              <CourseDescriptionsPDF descriptions={descriptions} />
            </PDFViewer>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '20px auto',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    flexGrow: 1,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '10px',
    textDecoration: 'none',
    fontFamily: 'Arial',
  },
  sectionButton: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#f1f1f1',
    border: 'none',
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  courseContainer: {
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  splitContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftColumn: {
    flex: 1,
    marginRight: '10px',
  },
  rightColumn: {
    flex: 1,
    marginLeft: '10px',
  },
  inputContainer: {
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #CED4DA',
  },
  textarea: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #CED4DA',
    fontFamily: 'Arial',
  },
  courseTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  removeButton: {
    marginTop: '10px',
    backgroundColor: '#DC3545',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
  },
  modal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  },
};

const pdfStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
  },
  studentName: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
  },
  gradeHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  course: {
    marginBottom: 15,
  },
  courseTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  courseDescription: {
    fontSize: 10,
    marginBottom: 5,
  },
  field: {
    fontSize: 10,
    marginBottom: 3,
  },
  copyrightContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  stamp: {
    fontSize: 8,
    textAlign: 'right',
  },
});

export default CourseDescriptions;
