import React, { useState, useEffect } from 'react'
import { FiDownload, FiChevronLeft, FiChevronRight, FiTrash2 } from 'react-icons/fi'

const WeeklySchedule = ({
  startHour = 0, // Default to midnight
  endHour = 24, // Default to midnight next day
  previewMode = false,
  previewCourses = [],
  readOnly = false,
  scheduleData = [],
  ...otherProps
}) => {
  const [currentTerm, setCurrentTerm] = useState('Fall 2024-2025')
  const [selectedCourses, setSelectedCourses] = useState([])

  // Use preview courses if in preview mode, otherwise use stored courses
  const displayCourses = scheduleData || selectedCourses

  // Generate time slots based on props
  const generateTimeSlots = (start, end) => {
    const slots = []
    for (let hour = start; hour < end; hour++) {
      slots.push(hour === 0 ? '12 AM' : hour === 12 ? '12 PM' : hour > 12 ? `${hour - 12} PM` : `${hour} AM`)
    }
    return slots
  }

  // Add function to adjust display hours based on course times
  const adjustDisplayHours = (courses) => {
    let minHour = startHour
    let maxHour = endHour

    courses.forEach((course) => {
      const [courseStartHour] = course.startTime.split(':').map(Number)
      const [courseEndHour] = course.endTime.split(':').map(Number)

      minHour = Math.min(minHour, courseStartHour)
      maxHour = Math.max(maxHour, courseEndHour + 1) // Add 1 hour for padding
    })

    return { adjustedStartHour: minHour, adjustedEndHour: maxHour }
  }

  // Use adjusted hours for display
  const { adjustedStartHour, adjustedEndHour } = adjustDisplayHours(displayCourses)
  const timeSlots = generateTimeSlots(adjustedStartHour, adjustedEndHour)

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']

  const terms = ['Fall 2024-2025', 'Winter 2024-2025', 'Spring 2024-2025', 'Summer 2024-2025']

  // Only load from localStorage if not in preview mode
  useEffect(() => {
    if (!previewMode) {
      const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}')
      if (savedScheduleData[currentTerm]) {
        const uniqueCourses = savedScheduleData[currentTerm].filter(
          (course, index, self) => index === self.findIndex((c) => c.id === course.id)
        )
        setSelectedCourses(uniqueCourses)
      }
    }
  }, [currentTerm, previewMode])

  const handleTermChange = (direction) => {
    // Implement term switching logic
    const currentIndex = terms.indexOf(currentTerm)
    if (direction === 'next' && currentIndex < terms.length - 1) {
      setCurrentTerm(terms[currentIndex + 1])
    } else if (direction === 'prev' && currentIndex > 0) {
      setCurrentTerm(terms[currentIndex - 1])
    }
  }

  const toggleCourse = (courseId) => {
    setSelectedCourses((courses) =>
      courses.map((course) => (course.id === courseId ? { ...course, selected: !course.selected } : course))
    )
  }

  const downloadSchedule = () => {
    const icsContent = generateICS(selectedCourses)
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `schedule_${currentTerm.replace(/\s+/g, '_')}.ics`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Add function to check for overlapping courses
  const getOverlappingCourses = (course, day) => {
    return displayCourses.filter((otherCourse) => {
      if (otherCourse.id === course.id) return false
      if (!otherCourse.days.includes(day)) return false

      const courseStart = timeToMinutes(course.startTime)
      const courseEnd = timeToMinutes(course.endTime)
      const otherStart = timeToMinutes(otherCourse.startTime)
      const otherEnd = timeToMinutes(otherCourse.endTime)

      return courseStart < otherEnd && courseEnd > otherStart
    }).length
  }

  const timeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number)
    return hours * 60 + minutes
  }

  // Update renderCourseBlocks to handle overlaps
  const renderCourseBlocks = () => {
    return displayCourses.map((course) => {
      return course.days.map((day) => {
        const startPosition = timeToGridPosition(course.startTime)
        const endPosition = timeToGridPosition(course.endTime)
        const duration = endPosition - startPosition
        const shortDay = day.substring(0, 3)
        const dayIndex = days.indexOf(shortDay)
        const overlappingCount = getOverlappingCourses(course, day)

        if (dayIndex === -1) return null

        return (
          <div
            key={`${course.id}-${shortDay}`}
            style={{
              ...styles.courseBlock,
              top: `${startPosition}%`,
              left: `${dayIndex * 20}%`,
              width: '19%',
              height: `${duration}%`,
              backgroundColor: `${course.color}${overlappingCount > 0 ? '80' : 'CC'}`,
              zIndex: overlappingCount > 0 ? 1 : 0,
              ...(overlappingCount > 0 && {
                border: `2px solid ${course.color}`,
              }),
            }}
          >
            <div style={{ fontWeight: '500' }}>{course.id}</div>
            {overlappingCount > 0 && (
              <div
                style={{
                  fontSize: '10px',
                  color: 'var(--warning)',
                  fontWeight: 'bold',
                }}
              >
                Conflict!
              </div>
            )}
          </div>
        )
      })
    })
  }

  const handleDeleteCourse = (courseId) => {
    // Remove from selectedCourses state
    setSelectedCourses((prev) => prev.filter((course) => course.id !== courseId))

    // Remove from localStorage
    const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}')
    if (savedScheduleData[currentTerm]) {
      savedScheduleData[currentTerm] = savedScheduleData[currentTerm].filter((course) => course.id !== courseId)
      localStorage.setItem('scheduleData', JSON.stringify(savedScheduleData))
    }
  }

  // Update timeToGridPosition to work with any time range
  const timeToGridPosition = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number)
    const position = (((hours - adjustedStartHour) * 60 + minutes) / ((adjustedEndHour - adjustedStartHour) * 60)) * 100
    return position
  }

  // Move styles inside component to access props
  const styles = {
    container: {
      height: '100%',
      backgroundColor: 'white',
      color: '#333',
    },
    scheduleGrid: {
      display: 'grid',
      gridTemplateColumns: '56px 1fr',
      height: '100%',
    },
    timeColumn: {
      display: 'grid',
      gridTemplateRows: `repeat(${adjustedEndHour - adjustedStartHour}, 1fr)`,
      paddingTop: '30px',
    },
    timeSlot: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'end',
      padding: '4px 8px',
      color: '#666',
      fontSize: '12px',
      fontWeight: '400',
      whiteSpace: 'nowrap',
    },
    daysGrid: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      height: '100%',
    },
    daysHeader: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      height: '30px',
    },
    dayCell: {
      padding: '4px',
      textAlign: 'center',
      color: '#666',
      fontSize: '12px',
      fontWeight: '400',
    },
    scheduleBody: {
      display: 'grid',
      gridTemplateRows: `repeat(${adjustedEndHour - adjustedStartHour}, 1fr)`,
      gridTemplateColumns: 'repeat(5, 1fr)',
      position: 'relative',
      borderLeft: '1px solid #edf2f7',
      '& > div': {
        borderRight: '1px solid #edf2f7',
        borderBottom: '1px solid #edf2f7',
      },
    },
    courseBlock: {
      position: 'absolute',
      padding: '4px 6px',
      borderRadius: '4px',
      fontSize: '11px',
      color: 'white',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'opacity 0.2s',
      '&:hover': {
        opacity: 0.9,
      },
      backdropFilter: 'blur(2px)', // Add blur effect for overlapping blocks
    },
    termNavigation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      padding: '8px',
    },
    navButton: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '4px',
      cursor: 'pointer',
      color: '#666',
      display: 'flex',
      alignItems: 'center',
    },
    termText: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#333',
    },
    termStats: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      marginBottom: '24px',
      color: '#4a5568',
    },
    courseList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      marginBottom: '24px',
    },
    courseItem: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '12px',
      padding: '12px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px solid #e2e8f0',
    },
    courseInfo: {
      flex: 1,
    },
    courseHeader: {
      display: 'flex',
      gap: '8px',
      marginBottom: '4px',
    },
    courseId: {
      fontWeight: '600',
      color: '#00356b',
    },
    courseName: {
      color: '#2d3748',
    },
    courseDetails: {
      fontSize: '14px',
      color: '#4a5568',
    },
    downloadButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '12px 24px',
      backgroundColor: '#00356b',
      color: '#fff',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontWeight: '500',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#002548',
      },
    },
    deleteButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#4a5568',
      cursor: 'pointer',
      padding: '8px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f7fafc',
      },
    },
  }

  return (
    <div style={styles.container}>
      <div style={styles.scheduleGrid}>
        <div style={styles.timeColumn}>
          {timeSlots.map((time) => (
            <div key={time} style={styles.timeSlot}>
              {time}
            </div>
          ))}
        </div>

        <div style={styles.daysGrid}>
          <div style={styles.daysHeader}>
            {days.map((day) => (
              <div key={day} style={styles.dayCell}>
                {day}
              </div>
            ))}
          </div>

          <div style={styles.scheduleBody}>
            {/* Create grid lines */}
            {Array(50)
              .fill(null)
              .map((_, i) => (
                <div key={i} />
              ))}
            {renderCourseBlocks()}
          </div>
        </div>
      </div>

      {!previewMode && (
        <>
          {/* Term Navigation */}
          <div style={styles.termNavigation}>
            <button onClick={() => handleTermChange('prev')} style={styles.navButton}>
              <FiChevronLeft />
            </button>
            <span style={styles.termText}>{currentTerm}</span>
            <button onClick={() => handleTermChange('next')} style={styles.navButton}>
              <FiChevronRight />
            </button>
          </div>

          {/* Term Stats */}
          <div style={styles.termStats}>
            <div>Pinned units: 17</div>
            <div>Estimated weekly workload: 60 hours</div>
          </div>

          {/* Course List */}
          <div style={styles.courseList}>
            {selectedCourses.map((course) => (
              <div key={course.id} style={styles.courseItem}>
                <input type="checkbox" checked={course.selected} onChange={() => toggleCourse(course.id)} />
                <div style={styles.courseInfo}>
                  <div style={styles.courseHeader}>
                    <span style={styles.courseId}>{course.id}</span>
                    <span style={styles.courseName}>{course.name}</span>
                  </div>
                  <div style={styles.courseDetails}>
                    Section {course.section} - {course.days.join(',')}
                    <br />
                    {course.startTime}-{course.endTime}
                  </div>
                </div>
                <button onClick={() => handleDeleteCourse(course.id)} style={styles.deleteButton}>
                  <FiTrash2 />
                </button>
              </div>
            ))}
          </div>

          {/* Download Button */}
          <button onClick={downloadSchedule} style={styles.downloadButton}>
            <FiDownload /> Download Schedule
          </button>
        </>
      )}
    </div>
  )
}

const generateICS = (courses) => {
  let icsContent = ['BEGIN:VCALENDAR', 'VERSION:2.0', 'PRODID:-//YourEdu//Schedule//EN']

  courses.forEach((course) => {
    if (course.selected) {
      course.days.forEach((day) => {
        icsContent = icsContent.concat([
          'BEGIN:VEVENT',
          `SUMMARY:${course.id} - ${course.name}`,
          `DESCRIPTION:Section ${course.section}`,
          // Add more event details here
          'END:VEVENT',
        ])
      })
    }
  })

  icsContent.push('END:VCALENDAR')
  return icsContent.join('\r\n')
}

export default WeeklySchedule
