import React, { useState, useRef, useEffect } from 'react'
import { Box, Container, Grid, Typography, Button } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageHeader, DescriptiveText } from '../components/ui/typography'
import { City, State } from 'country-state-city'

// Quick search categories
const CORE_SUBJECTS = [
  'Mathematics',
  'Science',
  'English',
  'History',
  'Foreign Language',
  'Computer Science'
]

const ELECTIVE_SUBJECTS = [
  'Art',
  'Music',
  'Theater',
  'Photography',
  'Creative Writing',
  'Psychology'
]

const EXTRACURRICULARS = [
  'Sports',
  'Clubs',
  'Volunteer Work',
  'Leadership',
  'Debate',
  'Model UN'
]

const PROVIDERS = [
  'Community Colleges',
  'Universities',
  'Microschools',
  'Co-ops',
  'Online Schools'
]

const CourseMarketplace = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '')
  const [locationQuery, setLocationQuery] = useState(searchParams.get('location') || '')
  const [selectedLocation, setSelectedLocation] = useState(searchParams.get('location') || '')
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false)
  const [locationSuggestions, setLocationSuggestions] = useState([])
  const locationSearchRef = useRef(null)
  const [searchDebounceTimeout, setSearchDebounceTimeout] = useState(null)

  // Location search debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      if (locationQuery) {
        fetchLocationSuggestions(locationQuery)
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [locationQuery])

  // Click outside to close location suggestions
  useEffect(() => {
    function handleClickOutside(event) {
      if (locationSearchRef.current && !locationSearchRef.current.contains(event.target)) {
        setShowLocationSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const fetchLocationSuggestions = async (query) => {
    try {
      // Get all US states
      const usStates = State.getStatesOfCountry('US')
      
      // Get cities for all states
      const allCities = usStates.flatMap(state => {
        const stateCities = City.getCitiesOfState('US', state.isoCode)
        return stateCities.map(city => ({
          name: city.name,
          state: state.name,
          stateCode: state.isoCode
        }))
      })
      
      // Filter cities based on the query
      const filteredCities = allCities
        .filter(city => {
          const cityState = `${city.name}, ${city.stateCode}`.toLowerCase()
          return cityState.includes(query.toLowerCase())
        })
        .slice(0, 8) // Limit to 8 suggestions
      
      setLocationSuggestions(filteredCities)
      setShowLocationSuggestions(true)
    } catch (error) {
      console.error('Error fetching location suggestions:', error)
    }
  }

  const handleSearch = (e) => {
    const query = e.target.value
    setSearchQuery(query)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && searchQuery.trim()) {
      navigate(`/course-search/results?q=${encodeURIComponent(searchQuery)}${selectedLocation ? `&location=${encodeURIComponent(selectedLocation)}` : ''}`)
    }
  }

  const handleQuickSearch = (query) => {
    setSearchQuery(query)
    navigate(`/course-search/results?q=${encodeURIComponent(query)}${selectedLocation ? `&location=${encodeURIComponent(selectedLocation)}` : ''}`)
  }

  const handleLocationSelect = (location) => {
    const locationString = `${location.name}, ${location.stateCode}`
    setSelectedLocation(locationString)
    setLocationQuery(locationString)
    setShowLocationSuggestions(false)
  }

  const clearLocation = () => {
    setSelectedLocation('')
    setLocationQuery('')
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box>
            <PageHeader>
              Marketplace
            </PageHeader>
            <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)', mb: 'var(--spacing-6)' }}>
              Find and enroll in courses that match your interests and academic goals.
            </DescriptiveText>

            {/* Search Section */}
            <Box sx={{ 
              display: 'flex', 
              gap: 'var(--spacing-4)',
              alignItems: 'flex-start',
            }}>
              {/* Main Search Bar */}
              <Box sx={{ flex: '0 0 75%' }}>
                <input
                  type="text"
                  placeholder="Search courses by title, subject, or provider..."
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyPress={handleKeyPress}
                  style={{
                    width: '100%',
                    padding: 'var(--spacing-4)',
                    fontSize: '1rem',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: 'var(--radius-md)',
                    backgroundColor: 'hsl(var(--background))',
                    color: 'hsl(var(--text-primary))',
                  }}
                />
              </Box>

              {/* Location Search */}
              <Box 
                ref={locationSearchRef}
                sx={{ 
                  flex: '0 0 25%',
                  position: 'relative',
                }}
              >
                <Box sx={{
                  position: 'relative',
                  '&:hover': {
                    '& input': {
                      borderColor: 'hsl(var(--brand-primary))',
                    },
                  },
                }}>
                  <Box
                    component="span"
                    sx={{
                      position: 'absolute',
                      left: 'var(--spacing-4)',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'hsl(var(--text-secondary))',
                      fontSize: '1.2em',
                      pointerEvents: 'none',
                      zIndex: 1,
                    }}
                  >
                    📍
                  </Box>
                  <input
                    type="text"
                    placeholder="Set Location"
                    value={locationQuery || ''}
                    onChange={(e) => {
                      setLocationQuery(e.target.value);
                      if (e.target.value) {
                        setShowLocationSuggestions(true);
                        fetchLocationSuggestions(e.target.value);
                      } else {
                        setShowLocationSuggestions(false);
                      }
                    }}
                    style={{
                      width: '100%',
                      padding: 'var(--spacing-4)',
                      paddingLeft: 'var(--spacing-12)',
                      paddingRight: selectedLocation ? 'var(--spacing-12)' : 'var(--spacing-4)',
                      fontSize: '1rem',
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 'var(--radius-md)',
                      backgroundColor: 'hsl(var(--background))',
                      color: 'hsl(var(--text-primary))',
                      transition: 'all 0.2s ease',
                      outline: 'none',
                    }}
                  />
                  {selectedLocation && (
                    <Box 
                      sx={{
                        position: 'absolute',
                        right: 'var(--spacing-3)',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: 'hsl(var(--text-secondary))',
                        '&:hover': {
                          color: 'hsl(var(--error))',
                        },
                      }}
                      onClick={() => {
                        setLocationQuery('');
                        setSelectedLocation('');
                      }}
                    >
                      ×
                    </Box>
                  )}
                </Box>
                
                {/* Location Suggestions Dropdown */}
                {showLocationSuggestions && locationQuery && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 'calc(100% + var(--spacing-2))',
                      left: 0,
                      right: 0,
                      maxHeight: '300px',
                      overflowY: 'auto',
                      backgroundColor: 'hsl(var(--background))',
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 'var(--radius-md)',
                      boxShadow: 'var(--shadow-lg)',
                      zIndex: 1000,
                      animation: 'fadeIn 0.2s ease',
                      '@keyframes fadeIn': {
                        from: {
                          opacity: 0,
                          transform: 'translateY(-10px)',
                        },
                        to: {
                          opacity: 1,
                          transform: 'translateY(0)',
                        },
                      },
                    }}
                  >
                    {locationSuggestions.map((location, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 'var(--spacing-3)',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                          },
                          borderBottom: index < locationSuggestions.length - 1 ? '1px solid hsl(var(--border))' : 'none',
                        }}
                        onClick={() => handleLocationSelect(location)}
                      >
                        <Typography sx={{ 
                          color: 'hsl(var(--text-primary))',
                        }}>
                          {location.name}, {location.stateCode}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Quick Search Sections */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          pt: 'var(--spacing-4)',
          px: 'var(--container-padding-x)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={4}>
          {/* Core Subjects */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 'var(--spacing-6)',
                borderRadius: 'var(--radius-lg)',
                backgroundColor: 'hsl(var(--muted))',
                border: '1px solid hsl(var(--border))',
                height: '100%',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 'var(--spacing-4)', 
                  color: 'hsl(var(--text-primary))',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-2)',
                  '&::before': {
                    content: '"📚"',
                    fontSize: '1.2em',
                  }
                }}
              >
                Core Subjects
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 'var(--spacing-2)'
              }}>
                {CORE_SUBJECTS.map((subject) => (
                  <Button
                    key={subject}
                    onClick={() => handleQuickSearch(subject)}
                    variant="outlined"
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--text-primary))',
                      backgroundColor: 'hsl(var(--background))',
                      textTransform: 'none',
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-2)',
                      '&:hover': {
                        borderColor: 'hsl(var(--brand-primary))',
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                      }
                    }}
                  >
                    {subject}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Elective Subjects */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 'var(--spacing-6)',
                borderRadius: 'var(--radius-lg)',
                backgroundColor: 'hsl(var(--muted))',
                border: '1px solid hsl(var(--border))',
                height: '100%',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 'var(--spacing-4)', 
                  color: 'hsl(var(--text-primary))',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-2)',
                  '&::before': {
                    content: '"🎨"',
                    fontSize: '1.2em',
                  }
                }}
              >
                Elective Subjects
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 'var(--spacing-2)'
              }}>
                {ELECTIVE_SUBJECTS.map((subject) => (
                  <Button
                    key={subject}
                    onClick={() => handleQuickSearch(subject)}
                    variant="outlined"
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--text-primary))',
                      backgroundColor: 'hsl(var(--background))',
                      textTransform: 'none',
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-2)',
                      '&:hover': {
                        borderColor: 'hsl(var(--brand-primary))',
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                      }
                    }}
                  >
                    {subject}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Extracurriculars */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 'var(--spacing-6)',
                borderRadius: 'var(--radius-lg)',
                backgroundColor: 'hsl(var(--muted))',
                border: '1px solid hsl(var(--border))',
                height: '100%',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 'var(--spacing-4)', 
                  color: 'hsl(var(--text-primary))',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-2)',
                  '&::before': {
                    content: '"🏆"',
                    fontSize: '1.2em',
                  }
                }}
              >
                Extracurriculars
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 'var(--spacing-2)'
              }}>
                {EXTRACURRICULARS.map((activity) => (
                  <Button
                    key={activity}
                    onClick={() => handleQuickSearch(activity)}
                    variant="outlined"
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--text-primary))',
                      backgroundColor: 'hsl(var(--background))',
                      textTransform: 'none',
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-2)',
                      '&:hover': {
                        borderColor: 'hsl(var(--brand-primary))',
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                      }
                    }}
                  >
                    {activity}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Providers */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 'var(--spacing-6)',
                borderRadius: 'var(--radius-lg)',
                backgroundColor: 'hsl(var(--muted))',
                border: '1px solid hsl(var(--border))',
                height: '100%',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 'var(--spacing-4)', 
                  color: 'hsl(var(--text-primary))',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-2)',
                  '&::before': {
                    content: '"🏫"',
                    fontSize: '1.2em',
                  }
                }}
              >
                Providers
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 'var(--spacing-2)'
              }}>
                {PROVIDERS.map((provider) => (
                  <Button
                    key={provider}
                    onClick={() => handleQuickSearch(provider)}
                    variant="outlined"
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--text-primary))',
                      backgroundColor: 'hsl(var(--background))',
                      textTransform: 'none',
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-2)',
                      '&:hover': {
                        borderColor: 'hsl(var(--brand-primary))',
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                      }
                    }}
                  >
                    {provider}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CourseMarketplace 