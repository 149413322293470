import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageHeader, DescriptiveText } from '../components/ui/typography.jsx';
import { cardStyles } from '../styles/theme/components/cards';
import {
  Container,
  Box,
  Button,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const StudentMaterials = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/college')}
            sx={{ 
              color: 'hsl(var(--text-primary))',
              mb: 'var(--spacing-4)',
              '&:hover': {
                backgroundColor: 'hsla(var(--brand-primary-light), 0.1)',
              }
            }}
          >
            Back to College
          </Button>
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Student Materials
          </PageHeader>
          <DescriptiveText 
            sx={{ maxWidth: 'var(--text-max-width)' }}
          >
            Access and organize your student materials, including essays, test scores, and extracurricular activities for college applications.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        // ... existing code ...
      </Container>
    </Box>
  );
};

export default StudentMaterials;