import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useAuth } from './utils/AuthContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Box } from '@mui/material'
import { theme } from './theme/theme' // Import our new theme

// Components
import ScrollToTop from './components/ScrollToTop'
import TopBar from './components/TopBar'
import Navbar from './components/Navbar'
import CollegeNavbar from './components/CollegeNavbar'
import LAFireNavbar from './components/LAFireNavbar'
import Login from './pages/Login'
import LoginSelection from './pages/LoginSelection'
import Home from './pages/Home'
import HomeParentAcademics from './pages/Home-Parent-Academics'
import HomeCollege from './pages/Home-College'
import LAFireHome from './pages/LAFireHome'
import CourseSearch from './pages/CourseSearch'
import LACourseSearch from './pages/LACourseSearch'
import RecordKeeping from './pages/RecordKeeping'
import SchoolPhilosophy from './pages/SchoolPhilosophy'
import GuidanceLetter from './pages/GuidanceCounselorLetter'
import CourseDescription from './pages/CourseDescriptions'
import GradingRubric from './pages/GradingRubric'
import Transcript from './pages/Transcript'
import StateComplianceFiling from './pages/StateComplianceFiling'
import CaliforniaPSA from './pages/CaliforniaPSA'
import CaliforniaPSAVerify from './pages/CaliforniaPSAVerify'
import IdGeneration from './pages/IdGeneration'
import WorkPermit from './pages/WorkPermits'
import AdminMaterials from './pages/AdminMaterials'
import MyCourses from './pages/MyCourses'
import CourseDetail from './pages/CourseDetail'
import CoursePlanning from './pages/CoursePlanning'
import Extracurriculars from './pages/Extracurriculars'
import Scholarships from './pages/Scholarships'
import Colleges from './pages/Colleges'
import Internships from './pages/Internships'
import StateRegulationOverview from './pages/StateRegulationOverview'
import StateFundingOpportunities from './pages/StateFundingOpportunities'
import IdGenerationView from './pages/IdGenerationView'
import WorkPermitsView from './pages/WorkPermitsView'
import Community from './pages/Community'
import Groups from './pages/Groups'
import GroupPage from './pages/GroupPage'
import Events from './pages/Events'
import Marketplace from './pages/Marketplace'
import Registration from './pages/Registration'
import CollegePrep from './pages/CollegePrep'
import StudentMaterials from './pages/StudentMaterials'
import CareerExploration from './pages/CareerExploration'
import Calendar from './pages/Calendar'
import MyAccount from './pages/MyAccount'
import TestingResources from './pages/TestingResources'
import Support from './pages/Support'
import HomeStudent from './pages/Home-Student'
import PublicEvent from './pages/PublicEvent'
import Pricing from './pages/Pricing'
import CoursePage from './pages/CoursePage'
import Ledger from './pages/Ledger'
import UserCoursePage from './pages/UserCoursePage'
import MarketplaceDetail from './pages/MarketplaceDetail'
import MyHomeschool from './pages/MyHomeschool'
import Compliance from './pages/Compliance'
import College from './pages/College'
import Careers from './pages/Careers'
import CourseMarketplace from './pages/CourseMarketplace'
import ProviderPage from './pages/ProviderPage'

// Separate component for the app content that needs Router context
const AppContent = () => {
  const location = useLocation()
  const { user } = useAuth()
  const userType = localStorage.getItem('userType') || 'highschool'

  // Check if current page is a public page (events or groups)
  const isPublicPage = location.pathname.startsWith('/events/') || location.pathname.startsWith('/groups/')

  // Check if current page is a full-screen page (login, registration, etc.)
  const isFullScreenPage = !user && ['/login', '/', '/pricing'].includes(location.pathname)

  const getNavbar = () => {
    switch (userType) {
      case 'college':
        return <CollegeNavbar />
      case 'lafire':
        return <LAFireNavbar />
      case 'student':
        return <Navbar />
      default:
        return <Navbar />
    }
  }

  const getHomePage = () => {
    switch (userType) {
      case 'college':
        return <HomeCollege />
      case 'lafire':
        return <LAFireHome />
      default:
        return <Home />
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Only show TopBar for authenticated users or public pages that need it */}
      {(user || isPublicPage) && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200 }}>
          <TopBar />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          mt: user || isPublicPage ? '64px' : 0,
        }}
      >
        {/* Only show Navbar for authenticated users or public pages that need it */}
        {(user || isPublicPage) && (
          <Box
            sx={{
              width: 240,
              flexShrink: 0,
              position: 'fixed',
              left: 0,
              top: 64,
              bottom: 0,
              overflowY: 'auto',
              bgcolor: 'hsl(var(--brand-primary-light))',
              borderRight: '1px solid',
              borderColor: 'divider',
            }}
          >
            {getNavbar()}
          </Box>
        )}

        {/* Main content area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ml: user || isPublicPage ? '240px' : 0,
            p: 0, // Remove default padding
            bgcolor: 'background.default',
            minHeight: '100vh',
          }}
        >
          <Routes>
            {/* Public Routes (always accessible) */}
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/events/:eventId" element={<PublicEvent />} />
            <Route path="/groups/:groupId" element={<GroupPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/course-detail/:college/:courseCode" element={<CoursePage />} />

            {user ? (
              <>
                <Route path="/" element={getHomePage()} />
                {/* High School Routes */}
                {userType === 'highschool' && (
                  <>
                    <Route path="/parent-academics" element={<HomeParentAcademics />} />
                    <Route path="/course-search" element={<CourseMarketplace />} />
                    <Route path="/course-search/results" element={<CourseSearch />} />
                    <Route path="/my-courses" element={<MyCourses />} />
                    <Route path="/my-homeschool" element={<MyHomeschool />} />
                    <Route path="/compliance" element={<Compliance />} />
                    <Route path="/college" element={<College />} />
                    <Route path="/record-keeping" element={<RecordKeeping />} />
                    <Route path="/admin-materials" element={<AdminMaterials />} />
                    <Route path="/admin-materials/school-philosophy" element={<SchoolPhilosophy />} />
                    <Route path="/admin-materials/transcript" element={<Transcript />} />
                    <Route path="/admin-materials/course-descriptions" element={<CourseDescription />} />
                    <Route path="/admin-materials/grading-rubric" element={<GradingRubric />} />
                    <Route path="/admin-materials/guidance-counselor-letter" element={<GuidanceLetter />} />
                    <Route path="/school-philosophy" element={<SchoolPhilosophy />} />
                    <Route path="/guidance-letter" element={<GuidanceLetter />} />
                    <Route path="/course-description" element={<CourseDescription />} />
                    <Route path="/grading-rubric" element={<GradingRubric />} />
                    <Route path="/transcript" element={<Transcript />} />
                    <Route path="/state-compliance-filing" element={<StateComplianceFiling />} />
                    <Route path="/california-psa" element={<CaliforniaPSA />} />
                    <Route path="/california-psa-verify" element={<CaliforniaPSAVerify />} />
                    <Route path="/id-generation" element={<IdGeneration />} />
                    <Route path="/id-generation/view" element={<IdGenerationView />} />
                    <Route path="/work-permit" element={<WorkPermit />} />
                    <Route path="/work-permit/view" element={<WorkPermitsView />} />
                    <Route path="/course-detail/:courseId" element={<CourseDetail />} />
                    <Route path="/course-planning" element={<CoursePlanning />} />
                    <Route path="/extracurriculars" element={<Extracurriculars />} />
                    <Route path="/scholarships" element={<Scholarships />} />
                    <Route path="/colleges" element={<Colleges />} />
                    <Route path="/internships" element={<Internships />} />
                    <Route path="/state-regulation-overview" element={<StateRegulationOverview />} />
                    <Route path="/state-funding-opportunities" element={<StateFundingOpportunities />} />
                    <Route path="/community" element={<Community />} />
                    <Route path="/groups" element={<Groups />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/marketplace" element={<Marketplace />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/college-prep" element={<CollegePrep />} />
                    <Route path="/student-materials" element={<StudentMaterials />} />
                    <Route path="/career-exploration" element={<CareerExploration />} />
                    <Route path="/student-home" element={<HomeStudent />} />
                    <Route path="/ledger" element={<Ledger />} />
                    <Route path="/testing-resources" element={<TestingResources />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/user-course/:courseId" element={<UserCoursePage />} />
                    <Route path="/marketplace/:providerId" element={<MarketplaceDetail />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/provider/:providerId" element={<ProviderPage />} />
                  </>
                )}

                {/* LA Fire Routes */}
                {userType === 'lafire' && (
                  <>
                    <Route path="/courses" element={<LACourseSearch />} />
                    <Route path="/compliance/regulations" element={<StateRegulationOverview />} />
                    <Route path="/compliance/filing" element={<StateComplianceFiling />} />
                    <Route path="/compliance/funding" element={<StateFundingOpportunities />} />
                    <Route path="/testing" element={<TestingResources />} />
                    <Route path="/support" element={<Support />} />
                  </>
                )}

                {/* College Routes */}
                {userType === 'college' && (
                  <>
                    <Route path="/admin-materials" element={<AdminMaterials />} />
                    <Route path="/school-search" element={<Colleges />} />
                    <Route path="/scholarships" element={<Scholarships />} />
                    <Route path="/admin-materials/school-philosophy" element={<SchoolPhilosophy />} />
                    <Route path="/admin-materials/transcript" element={<Transcript />} />
                    <Route path="/admin-materials/course-descriptions" element={<CourseDescription />} />
                    <Route path="/admin-materials/grading-rubric" element={<GradingRubric />} />
                    <Route path="/admin-materials/guidance-counselor-letter" element={<GuidanceLetter />} />
                  </>
                )}

                {/* Common Protected Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/support" element={<Support />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            ) : (
              <>
                {/* Public Routes (login) */}
                <Route path="/" element={<LoginSelection />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </Box>
      </Box>
    </Box>
  )
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="font-sans">
        <ScrollToTop />
        <AppContent />
        <ToastContainer position="top-right" />
      </div>
    </ThemeProvider>
  )
}

export default App
