import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useAuth } from '../utils/AuthContext'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { Card, CardContent, Button, Box, Collapse, Divider, Grid, Alert, Modal } from '@mui/material'
import { supabase } from '../config/supabase'
import CourseCard from '../components/courses/CourseCard'
import RegistrationModal from '../components/RegistrationModal'
import PlanningModal from '../components/PlanningModal'
import { PageHeader, SectionHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography'
import { Container } from '@mui/material'
import { City, State } from 'country-state-city'

const ALLOW_DROPDOWN = true

const AVAILABLE_SUBJECTS = [
  'History',
  'English',
  'Mathematics',
  'Science',
  'Language Other Than English',
  'Visual & Performing Arts',
  'College-Preparatory Elective',
]

const AVAILABLE_TERMS = ['Spring 2025', 'Summer 2025']

const AVAILABLE_PROVIDERS = [
  'Community Colleges',
  'College/University',
  'Microschool',
  'Co-op',
  'Online School'
]

const AVAILABLE_EXTRACURRICULARS = {
  Sports: [
    'Baseball',
    'Basketball',
    'Football',
    'Soccer',
    'Swimming',
    'Tennis',
    'Track & Field',
    'Volleyball'
  ],
  Clubs: [
    'Art Club',
    'Chess Club',
    'Debate Club',
    'Drama Club',
    'Music Band',
    'Robotics Club',
    'Science Club',
    'Student Government'
  ]
}

const PRICE_RANGES = [
  'Free',
  'Under $100',
  '$100 - $250',
  '$250 - $500',
  '$500+'
]

const RATING_FILTERS = [
  '4★ & Up',
  '3★ & Up',
  '2★ & Up',
  '1★ & Up'
]

const SUBJECT_DISPLAY_NAMES = {
  History: 'A. History',
  English: 'B. English',
  Mathematics: 'C. Mathematics',
  Science: 'D. Science',
  'Language Other Than English': 'E. Language',
  'Visual & Performing Arts': 'F. Visual & Performing Arts',
  'College-Preparatory Elective': 'G. Elective',
}

const CourseSearch = () => {
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [courses, setCourses] = useState([])
  const [filteredCourses, setFilteredCourses] = useState([])
  const [subjectFilters, setSubjectFilters] = useState([])
  const [termFilters, setTermFilters] = useState([])
  const [providerFilters, setProviderFilters] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '')
  const [showSearchResults, setShowSearchResults] = useState(true)
  const [expandedSections, setExpandedSections] = useState({
    subjects: false,
    terms: false,
    providers: false,
    extracurricular: false,
    price: false,
    rating: false
  })
  const [planningCourse, setPlanningCourse] = useState(null)
  const [sortConfig, setSortConfig] = useState({
    column: 'courseCode',
    ascending: true,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const ITEMS_PER_PAGE = 50
  const [expandedCourses, setExpandedCourses] = useState({})
  const [showFilters, setShowFilters] = useState(false)
  const navigate = useNavigate()
  const [locationQuery, setLocationQuery] = useState(searchParams.get('location') || '')
  const [selectedLocation, setSelectedLocation] = useState(searchParams.get('location') || '')
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false)
  const [locationSuggestions, setLocationSuggestions] = useState([])
  const locationSearchRef = useRef(null)

  const modalRef = useRef(null)
  const [priceFilter, setPriceFilter] = useState('')
  const [ratingFilter, setRatingFilter] = useState('')
  const [extracurricularFilters, setExtracurricularFilters] = useState([])
  const [searchDebounceTimeout, setSearchDebounceTimeout] = useState(null)

  const loadInitialData = async () => {
    setIsLoading(true)
    setError(null)

    try {
      // Get total count
      const { count, error: countError } = await supabase
        .from('course_offerings')
        .select('course_code', { count: 'exact' })

      if (countError) throw countError
      setTotalCount(count)

      // Get initial data
      const { data, error } = await supabase
        .from('course_offerings')
        .select('*')
        .range(0, ITEMS_PER_PAGE - 1)
        .order('course_code', { ascending: true })

      if (error) throw error

      const transformedData = data.map((course) => ({
        id: course.crn,
        courseCode: course.course_code,
        courseTitle: course.title,
        institution: course.college,
        instructor: course.instructor,
        prerequisites: course.prerequisites,
        advisory: course.advisory,
        courseSchedule: course.section_times,
        courseDates: course.section_dates,
        location: course.section_locations,
        instructionMethod: course.section_locations?.includes('ONLINE') ? 'Online' : 'In Person',
        credits: course.units,
        totalHours: course.total_hours,
        description: course.description,
        status: course.status,
        enrolled: course.enrolled,
        maxStudents: course.max_students,
        waitlisted: course.waitlisted,
        hsSubject: course.hs_subject,
        extraNotes: course.extra_notes,
        term: course.term,
        weeks: course.weeks,
      }))

      setCourses(transformedData)
      setFilteredCourses(transformedData)
      setHasMore(data.length === ITEMS_PER_PAGE)
    } catch (error) {
      console.error('Error loading initial data:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      handleCloseModal()
    }
  }, [])

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal()
    }
  }, [])

  useEffect(() => {
    if (isRegistrationModalOpen) {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isRegistrationModalOpen, handleKeyDown, handleClickOutside])

  // Location search debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      if (locationQuery) {
        fetchLocationSuggestions(locationQuery);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [locationQuery]);

  // Click outside to close location suggestions
  useEffect(() => {
    function handleClickOutside(event) {
      if (locationSearchRef.current && !locationSearchRef.current.contains(event.target)) {
        setShowLocationSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchLocationSuggestions = async (query) => {
    try {
      // Get all US states
      const usStates = State.getStatesOfCountry('US');
      
      // Get cities for all states
      const allCities = usStates.flatMap(state => {
        const stateCities = City.getCitiesOfState('US', state.isoCode);
        return stateCities.map(city => ({
          name: city.name,
          state: state.name,
          stateCode: state.isoCode
        }));
      });
      
      // Filter cities based on the query
      const filteredCities = allCities
        .filter(city => {
          const cityState = `${city.name}, ${city.stateCode}`.toLowerCase();
          return cityState.includes(query.toLowerCase());
        })
        .map(city => `${city.name}, ${city.stateCode}`)
        .slice(0, 8); // Limit to 8 suggestions for better UX
      
      setLocationSuggestions(filteredCities);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
      setLocationSuggestions([]);
    }
  };

  // Helper function to toggle a filter value
  const toggleFilter = (value, currentFilters, setFilters) => {
    if (currentFilters.includes(value)) {
      setFilters(currentFilters.filter((item) => item !== value))
    } else {
      setFilters([...currentFilters, value])
    }
  }

  // Add this helper function to group courses
  const groupCoursesByTitle = (courses) => {
    return courses.reduce((acc, course) => {
      const fullTitle = `${course.courseCode} (${[course.institution].filter(Boolean).join(' - ')})`
      if (!acc[fullTitle]) {
        acc[fullTitle] = []
      }
      acc[fullTitle].push(course)
      return acc
    }, {})
  }

  const toggleCourseExpansion = (courseCode) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [courseCode]: !prev[courseCode],
    }))
  }

  const handleRegisterClick = (course) => {
    setSelectedCourse(course)
    setIsRegistrationModalOpen(true)
  }

  const handleCloseModal = () => {
    setSelectedCourse(null)
    setIsRegistrationModalOpen(false)
  }

  const handleSearch = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    
    // Clear any existing timeout
    if (searchDebounceTimeout) {
      clearTimeout(searchDebounceTimeout)
    }
    
    // Set new timeout
    const timeoutId = setTimeout(() => {
      // If query is empty, clear the courses
      if (!query.trim()) {
        setCourses([])
        setFilteredCourses([])
        setHasMore(false)
      }
      setSearchParams({ 
        q: query,
        location: selectedLocation 
      })
    }, 500) // 500ms delay
    
    setSearchDebounceTimeout(timeoutId)
  }

  const handleLocationSelect = (location) => {
    setSelectedLocation(location)
    setLocationQuery(location)
    setShowLocationSuggestions(false)
    setSearchParams({
      q: searchQuery,
      location
    })
  }

  const clearLocation = () => {
    setSelectedLocation('')
    setLocationQuery('')
    setSearchParams({
      q: searchQuery
    })
  }

  const handlePlanCourse = (planningDetails) => {
    const { course, year, term, subject } = planningDetails

    // Add to planner data
    const savedCourseData = JSON.parse(localStorage.getItem('coursePlanningData') || '{}')

    // Initialize the structure if it doesn't exist
    if (!savedCourseData[subject]) {
      savedCourseData[subject] = {
        '9th': [],
        '10th': [],
        '11th': [],
        '12th': [],
      }
    }

    // Ensure the year array exists and add course if it's not already there
    if (!savedCourseData[subject][year]) {
      savedCourseData[subject][year] = []
    }

    // Check if course already exists
    const courseExists = savedCourseData[subject][year].some((c) => c.id === course.id)

    if (!courseExists) {
      savedCourseData[subject][year].push(course)
    }

    localStorage.setItem('coursePlanningData', JSON.stringify(savedCourseData))

    // Add to schedule data (similar duplicate check)
    const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}')
    if (!savedScheduleData[term]) {
      savedScheduleData[term] = []
    }

    const scheduleExists = savedScheduleData[term].some((c) => c.id === course.id)

    if (!scheduleExists) {
      savedScheduleData[term].push(course)
    }

    localStorage.setItem('scheduleData', JSON.stringify(savedScheduleData))
  }

  // Add this useEffect to load initial data
  useEffect(() => {
    loadInitialData()
  }, []) // Empty dependency array means this runs once on mount

  const loadFilteredCourses = async () => {
    setIsLoading(true)
    setError(null)

    try {
      // First, get total count from both tables
      const [collegeCount, youreduCount] = await Promise.all([
        supabase
          .from('course_offerings')
          .select('course_code', { count: 'exact' })
          .then(({ count, error }) => {
            if (error) throw error
            return count || 0
          }),
        supabase
          .from('youredu_courses')
          .select('id', { count: 'exact' })
          .eq('is_published', true)
          .then(({ count, error }) => {
            if (error) throw error
            return count || 0
          }),
      ])

      setTotalCount(collegeCount + youreduCount)

      // Fetch data from both tables
      let query = supabase
        .from('course_offerings')
        .select('*')
        .range(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE - 1)

      let youreduQuery = supabase
        .from('youredu_courses')
        .select('*')
        .eq('is_published', true)
        .range(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE - 1)

      // Apply text search if exists
      if (searchQuery) {
        query = query.or(
          `title.ilike.%${searchQuery}%,` + `course_code.ilike.%${searchQuery}%,` + `hs_subject.ilike.%${searchQuery}%`
        )
        youreduQuery = youreduQuery.or(`title.ilike.%${searchQuery}%,` + `hs_subject.ilike.%${searchQuery}%`)
      }

      // Apply filters
      if (subjectFilters.length > 0) {
        query = query.in('hs_subject', subjectFilters)
        youreduQuery = youreduQuery.in('hs_subject', subjectFilters)
      }
      if (termFilters.length > 0) {
        query = query.in('term', termFilters)
        youreduQuery = youreduQuery.in('term_start', termFilters)
      }
      if (providerFilters.length > 0) {
        query = query.in('college', providerFilters)
        // Note: YouredU courses don't have a college filter
      }

      // Fetch data from both sources
      const [{ data: collegeData, error: collegeError }, { data: youreduData, error: youreduError }] =
        await Promise.all([query, youreduQuery])

      if (collegeError) throw collegeError
      if (youreduError) throw youreduError

      // Transform college courses
      const transformedCollegeData = collegeData.map((course) => ({
        id: course.crn,
        courseCode: course.course_code,
        courseTitle: course.title,
        institution: course.college,
        instructor: course.instructor,
        prerequisites: course.prerequisites,
        advisory: course.advisory,
        courseSchedule: course.section_times,
        courseDates: course.section_dates,
        location: course.section_locations,
        instructionMethod: course.section_locations?.includes('ONLINE') ? 'Online' : 'In Person',
        credits: course.units,
        totalHours: course.total_hours,
        description: course.description,
        status: course.status,
        enrolled: course.enrolled,
        maxStudents: course.max_students,
        waitlisted: course.waitlisted,
        hsSubject: course.hs_subject,
        extraNotes: course.extra_notes,
        term: course.term,
        weeks: course.weeks,
        isYourEduCourse: false,
      }))

      // Transform YouredU courses
      const transformedYoureduData = youreduData.map((course) => ({
        id: course.id,
        courseCode: course.id,
        courseTitle: course.title,
        institution: 'YourEdu',
        instructor: 'YourEdu Instructor',
        prerequisites: null,
        advisory: null,
        courseSchedule: [course.days, course.times],
        courseDates: [course.dates],
        location: course.location ? [course.location] : null,
        instructionMethod: course.instruction_method || 'Not specified',
        credits: course.units,
        totalHours: course.total_hours,
        description: course.description,
        status: 'Active',
        enrolled: (course.students || []).length,
        maxStudents: null,
        waitlisted: 0,
        hsSubject: course.hs_subject,
        extraNotes: null,
        term: course.term_start,
        weeks: null,
        isYourEduCourse: true,
      }))

      // Combine and sort the data
      const combinedData = [...transformedCollegeData, ...transformedYoureduData]

      if (currentPage === 0) {
        setCourses(combinedData)
        setFilteredCourses(combinedData)
      } else {
        setCourses((prev) => [...prev, ...combinedData])
        setFilteredCourses((prev) => [...prev, ...combinedData])
      }

      setHasMore(combinedData.length === ITEMS_PER_PAGE)
    } catch (error) {
      console.error('Detailed error:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  // Add this useEffect after the loadFilteredCourses function definition
  useEffect(() => {
    console.log('useEffect for loadFilteredCourses triggered')
    // Update URL with current page
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set('page', currentPage.toString())
      return newParams
    })

    // Reset to first page when searching
    if (searchQuery && currentPage !== 0) {
      setCurrentPage(0)
    } else {
      loadFilteredCourses()
    }
  }, [searchQuery, subjectFilters, termFilters, providerFilters, sortConfig, currentPage, setSearchParams])

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box>
            <PageHeader>
              Marketplace
            </PageHeader>
            <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)', mb: 'var(--spacing-6)' }}>
              Find and enroll in courses that match your interests and academic goals.
            </DescriptiveText>

            {/* Search Section */}
            <Box sx={{ 
              display: 'flex', 
              gap: 'var(--spacing-4)',
              alignItems: 'flex-start',
            }}>
              {/* Main Search Bar */}
              <Box sx={{ flex: '0 0 75%' }}>
                <input
                  type="text"
                  placeholder="Search courses by title, subject, or provider..."
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{
                    width: '100%',
                    padding: 'var(--spacing-4)',
                    fontSize: '1rem',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: 'var(--radius-md)',
                    backgroundColor: 'hsl(var(--background))',
                    color: 'hsl(var(--text-primary))',
                  }}
                />
              </Box>

              {/* Location Search */}
              <Box 
                ref={locationSearchRef}
                sx={{ 
                  flex: '0 0 25%',
                  position: 'relative',
                }}
              >
                <Box sx={{
                  position: 'relative',
                  '&:hover': {
                    '& input': {
                      borderColor: 'hsl(var(--brand-primary))',
                    },
                  },
                }}>
                  <Box
                    component="span"
                    sx={{
                      position: 'absolute',
                      left: 'var(--spacing-4)',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      color: 'hsl(var(--text-secondary))',
                      fontSize: '1.2em',
                      pointerEvents: 'none',
                      zIndex: 1,
                    }}
                  >
                    📍
                  </Box>
                  <input
                    type="text"
                    placeholder="Set Location"
                    value={locationQuery || ''}
                    onChange={(e) => {
                      setLocationQuery(e.target.value);
                      if (e.target.value) {
                        setShowLocationSuggestions(true);
                        fetchLocationSuggestions(e.target.value);
                      } else {
                        setShowLocationSuggestions(false);
                      }
                    }}
                    style={{
                      width: '100%',
                      padding: 'var(--spacing-4)',
                      paddingLeft: 'var(--spacing-12)',
                      paddingRight: selectedLocation ? 'var(--spacing-12)' : 'var(--spacing-4)',
                      fontSize: '1rem',
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 'var(--radius-md)',
                      backgroundColor: 'hsl(var(--background))',
                      color: 'hsl(var(--text-primary))',
                      transition: 'all 0.2s ease',
                      outline: 'none',
                    }}
                  />
                  {selectedLocation && (
                    <Box 
                      sx={{
                        position: 'absolute',
                        right: 'var(--spacing-3)',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: 'hsl(var(--text-secondary))',
                        '&:hover': {
                          color: 'hsl(var(--error))',
                        },
                      }}
                      onClick={() => {
                        setLocationQuery('');
                        setSelectedLocation('');
                      }}
                    >
                      ×
                    </Box>
                  )}
                </Box>
                
                {/* Location Suggestions Dropdown */}
                {showLocationSuggestions && locationQuery && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 'calc(100% + var(--spacing-2))',
                      left: 0,
                      right: 0,
                      maxHeight: '300px',
                      overflowY: 'auto',
                      backgroundColor: 'hsl(var(--background))',
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 'var(--radius-md)',
                      boxShadow: 'var(--shadow-lg)',
                      zIndex: 1000,
                      animation: 'fadeIn 0.2s ease',
                      '@keyframes fadeIn': {
                        from: {
                          opacity: 0,
                          transform: 'translateY(-10px)',
                        },
                        to: {
                          opacity: 1,
                          transform: 'translateY(0)',
                        },
                      },
                    }}
                  >
                    {locationSuggestions.map((location, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 'var(--spacing-3)',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                          },
                          borderBottom: index < locationSuggestions.length - 1 ? '1px solid hsl(var(--border))' : 'none',
                        }}
                        onClick={() => {
                          setLocationQuery(location);
                          setSelectedLocation(location);
                          setShowLocationSuggestions(false);
                        }}
                      >
                        <BodyText sx={{ 
                          color: 'hsl(var(--text-primary))',
                        }}>
                          {location}
                        </BodyText>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          pt: 'var(--spacing-4)',
          px: 'var(--container-padding-x)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Filters Section */}
        <Box sx={{ display: 'flex', gap: 'var(--spacing-6)' }}>
          {/* Left Sidebar - Filters */}
          <Box sx={{ width: 280, flexShrink: 0 }}>
            <Box sx={{ position: 'sticky', top: 'var(--spacing-4)' }}>
              <Box 
                sx={{ 
                  p: 'var(--spacing-4)',
                  backgroundColor: 'hsl(var(--muted))',
                  border: '1px solid hsl(var(--border))',
                  borderRadius: 'var(--radius-lg)',
                  mb: 'var(--spacing-4)'
                }}
              >
                <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>Filters</SectionHeader>
                
                {/* Subject Filters */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, subjects: !expandedSections.subjects })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Subject</BodyText>
                    <span>{expandedSections.subjects ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.subjects}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 'var(--spacing-2)',
                      p: 'var(--spacing-2)'
                    }}>
                      {AVAILABLE_SUBJECTS.map((subject) => (
                        <Button
                          key={subject}
                          onClick={() => toggleFilter(subject, subjectFilters, setSubjectFilters)}
                          variant={subjectFilters.includes(subject) ? "contained" : "outlined"}
                          sx={{
                            justifyContent: 'flex-start',
                            backgroundColor: subjectFilters.includes(subject) 
                              ? 'hsl(var(--brand-primary))' 
                              : 'transparent',
                            color: subjectFilters.includes(subject) 
                              ? 'hsl(var(--background))' 
                              : 'hsl(var(--text-primary))',
                            borderColor: subjectFilters.includes(subject)
                              ? 'hsl(var(--brand-primary))'
                              : 'hsl(var(--border))',
                            '&:hover': {
                              backgroundColor: subjectFilters.includes(subject)
                                ? 'hsl(var(--brand-primary-dark))'
                                : 'hsla(var(--brand-primary), 0.1)',
                              borderColor: 'hsl(var(--brand-primary))'
                            },
                            textTransform: 'none'
                          }}
                        >
                          {SUBJECT_DISPLAY_NAMES[subject] || subject}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </Box>

                {/* Term Filters */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, terms: !expandedSections.terms })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Term</BodyText>
                    <span>{expandedSections.terms ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.terms}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 'var(--spacing-2)',
                      p: 'var(--spacing-2)'
                    }}>
                      {AVAILABLE_TERMS.map((term) => (
                        <Button
                          key={term}
                          onClick={() => toggleFilter(term, termFilters, setTermFilters)}
                          variant={termFilters.includes(term) ? "contained" : "outlined"}
                          sx={{
                            justifyContent: 'flex-start',
                            backgroundColor: termFilters.includes(term) 
                              ? 'hsl(var(--brand-primary))' 
                              : 'transparent',
                            color: termFilters.includes(term) 
                              ? 'hsl(var(--background))' 
                              : 'hsl(var(--text-primary))',
                            borderColor: termFilters.includes(term)
                              ? 'hsl(var(--brand-primary))'
                              : 'hsl(var(--border))',
                            '&:hover': {
                              backgroundColor: termFilters.includes(term)
                                ? 'hsl(var(--brand-primary-dark))'
                                : 'hsla(var(--brand-primary), 0.1)',
                              borderColor: 'hsl(var(--brand-primary))'
                            },
                            textTransform: 'none'
                          }}
                        >
                          {term}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </Box>

                {/* Providers Filter */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, providers: !expandedSections.providers })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Providers</BodyText>
                    <span>{expandedSections.providers ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.providers}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 'var(--spacing-2)',
                      p: 'var(--spacing-2)'
                    }}>
                      {AVAILABLE_PROVIDERS.map((provider) => (
                        <Button
                          key={provider}
                          onClick={() => toggleFilter(provider, providerFilters, setProviderFilters)}
                          variant={providerFilters.includes(provider) ? "contained" : "outlined"}
                          sx={{
                            justifyContent: 'flex-start',
                            backgroundColor: providerFilters.includes(provider) 
                              ? 'hsl(var(--brand-primary))' 
                              : 'transparent',
                            color: providerFilters.includes(provider) 
                              ? 'hsl(var(--background))' 
                              : 'hsl(var(--text-primary))',
                            borderColor: providerFilters.includes(provider)
                              ? 'hsl(var(--brand-primary))'
                              : 'hsl(var(--border))',
                            '&:hover': {
                              backgroundColor: providerFilters.includes(provider)
                                ? 'hsl(var(--brand-primary-dark))'
                                : 'hsla(var(--brand-primary), 0.1)',
                              borderColor: 'hsl(var(--brand-primary))'
                            },
                            textTransform: 'none'
                          }}
                        >
                          {provider}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </Box>

                {/* Extracurricular Filter */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, extracurricular: !expandedSections.extracurricular })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Extracurricular</BodyText>
                    <span>{expandedSections.extracurricular ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.extracurricular}>
                    <Box sx={{ p: 'var(--spacing-2)' }}>
                      {Object.entries(AVAILABLE_EXTRACURRICULARS).map(([category, activities]) => (
                        <Box key={category} sx={{ mb: 'var(--spacing-3)' }}>
                          <BodyText sx={{ mb: 'var(--spacing-2)', fontWeight: 600 }}>{category}</BodyText>
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            gap: 'var(--spacing-2)'
                          }}>
                            {activities.map((activity) => (
                              <Button
                                key={activity}
                                onClick={() => toggleFilter(activity, extracurricularFilters, setExtracurricularFilters)}
                                variant={extracurricularFilters.includes(activity) ? "contained" : "outlined"}
                                sx={{
                                  justifyContent: 'flex-start',
                                  backgroundColor: extracurricularFilters.includes(activity) 
                                    ? 'hsl(var(--brand-primary))' 
                                    : 'transparent',
                                  color: extracurricularFilters.includes(activity) 
                                    ? 'hsl(var(--background))' 
                                    : 'hsl(var(--text-primary))',
                                  borderColor: extracurricularFilters.includes(activity)
                                    ? 'hsl(var(--brand-primary))'
                                    : 'hsl(var(--border))',
                                  '&:hover': {
                                    backgroundColor: extracurricularFilters.includes(activity)
                                      ? 'hsl(var(--brand-primary-dark))'
                                      : 'hsla(var(--brand-primary), 0.1)',
                                    borderColor: 'hsl(var(--brand-primary))'
                                  },
                                  textTransform: 'none'
                                }}
                              >
                                {activity}
                              </Button>
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Collapse>
                </Box>

                {/* Price Range Filter */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, price: !expandedSections.price })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Price Range</BodyText>
                    <span>{expandedSections.price ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.price}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 'var(--spacing-2)',
                      p: 'var(--spacing-2)'
                    }}>
                      {PRICE_RANGES.map((range) => (
                        <Button
                          key={range}
                          onClick={() => setPriceFilter(priceFilter === range ? '' : range)}
                          variant={priceFilter === range ? "contained" : "outlined"}
                          sx={{
                            justifyContent: 'flex-start',
                            backgroundColor: priceFilter === range 
                              ? 'hsl(var(--brand-primary))' 
                              : 'transparent',
                            color: priceFilter === range 
                              ? 'hsl(var(--background))' 
                              : 'hsl(var(--text-primary))',
                            borderColor: priceFilter === range
                              ? 'hsl(var(--brand-primary))'
                              : 'hsl(var(--border))',
                            '&:hover': {
                              backgroundColor: priceFilter === range
                                ? 'hsl(var(--brand-primary-dark))'
                                : 'hsla(var(--brand-primary), 0.1)',
                              borderColor: 'hsl(var(--brand-primary))'
                            },
                            textTransform: 'none'
                          }}
                        >
                          {range}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </Box>

                {/* Rating Filter */}
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Button
                    onClick={() => setExpandedSections({ ...expandedSections, rating: !expandedSections.rating })}
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      p: 'var(--spacing-2)',
                      color: 'hsl(var(--text-primary))',
                      textTransform: 'none',
                      '&:hover': { 
                        backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                      },
                    }}
                  >
                    <BodyText>Rating</BodyText>
                    <span>{expandedSections.rating ? '−' : '+'}</span>
                  </Button>
                  <Collapse in={expandedSections.rating}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 'var(--spacing-2)',
                      p: 'var(--spacing-2)'
                    }}>
                      {RATING_FILTERS.map((rating) => (
                        <Button
                          key={rating}
                          onClick={() => setRatingFilter(ratingFilter === rating ? '' : rating)}
                          variant={ratingFilter === rating ? "contained" : "outlined"}
                          sx={{
                            justifyContent: 'flex-start',
                            backgroundColor: ratingFilter === rating 
                              ? 'hsl(var(--brand-primary))' 
                              : 'transparent',
                            color: ratingFilter === rating 
                              ? 'hsl(var(--background))' 
                              : 'hsl(var(--text-primary))',
                            borderColor: ratingFilter === rating
                              ? 'hsl(var(--brand-primary))'
                              : 'hsl(var(--border))',
                            '&:hover': {
                              backgroundColor: ratingFilter === rating
                                ? 'hsl(var(--brand-primary-dark))'
                                : 'hsla(var(--brand-primary), 0.1)',
                              borderColor: 'hsl(var(--brand-primary))'
                            },
                            textTransform: 'none'
                          }}
                        >
                          {rating}
                        </Button>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Main Content Area - Course List */}
          <Box sx={{ flex: 1 }}>
            {isLoading ? (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                p: 'var(--spacing-8)'
              }}>
                <CircularProgress sx={{ color: 'hsl(var(--brand-primary))' }} />
              </Box>
            ) : error ? (
              <Alert 
                severity="error"
                sx={{ 
                  backgroundColor: 'hsla(var(--error), 0.1)',
                  color: 'hsl(var(--error))',
                  border: '1px solid hsl(var(--error))'
                }}
              >
                {error}
              </Alert>
            ) : !searchQuery.trim() || filteredCourses.length === 0 ? (
              <Box 
                sx={{ 
                  p: 'var(--spacing-4)',
                  backgroundColor: 'hsl(var(--muted))',
                  border: '1px solid hsl(var(--border))',
                  borderRadius: 'var(--radius-lg)',
                  textAlign: 'center'
                }}
              >
                <SupportingText>
                  {!searchQuery.trim() ? 'Enter a search term to find courses.' : 'No courses found matching your criteria.'}
                </SupportingText>
              </Box>
            ) : (
              <Grid container spacing={3}>
                {Object.entries(groupCoursesByTitle(filteredCourses)).map(([title, courses]) => (
                  <Grid item xs={12} key={title}>
                    <CourseCard 
                      courseGroup={[title, courses]}
                      expanded={expandedCourses[courses[0]?.courseCode]}
                      onExpand={() => courses[0]?.courseCode && toggleCourseExpansion(courses[0].courseCode)}
                      onRegister={() => courses[0] && handleRegisterClick(courses[0])}
                      onPlan={() => courses[0] && setPlanningCourse(courses[0])}
                      tags={courses[0] ? [
                        { title: courses[0].institution || 'Unknown Institution', color: 'hsl(var(--brand-primary))' },
                        { title: courses[0].hsSubject || 'General', color: 'hsl(var(--brand-primary))' }
                      ] : []}
                      expandedCourses={expandedCourses}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {/* Pagination Controls */}
            {!isLoading && filteredCourses.length > 0 && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  gap: 'var(--spacing-2)',
                  mt: 'var(--spacing-6)'
                }}
              >
                <Button
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  sx={{
                    color: 'hsl(var(--text-primary))',
                    borderColor: 'hsl(var(--border))',
                    '&:hover': {
                      borderColor: 'hsl(var(--brand-primary))',
                      backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                    }
                  }}
                >
                  Previous
                </Button>
                <Button
                  disabled={!hasMore}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  sx={{
                    color: 'hsl(var(--text-primary))',
                    borderColor: 'hsl(var(--border))',
                    '&:hover': {
                      borderColor: 'hsl(var(--brand-primary))',
                      backgroundColor: 'hsla(var(--brand-primary), 0.1)'
                    }
                  }}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>

      {/* Modals */}
      {selectedCourse && (
        <RegistrationModal
          open={isRegistrationModalOpen}
          onClose={handleCloseModal}
          course={selectedCourse}
        />
      )}
      {planningCourse && (
        <PlanningModal
          open={!!planningCourse}
          onClose={() => setPlanningCourse(null)}
          course={planningCourse}
          onPlan={handlePlanCourse}
        />
      )}
    </Box>
  )
}

export default CourseSearch
