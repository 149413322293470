import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get initial session
    const initializeAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        setUser(session?.user ?? null);

        // Listen for auth state changes
        const {
          data: { subscription },
        } = supabase.auth.onAuthStateChange(async (event, session) => {
          if (event === 'SIGNED_IN') {
            setUser(session?.user ?? null);
            
            // Get the target path from user metadata
            const targetPath = session?.user?.user_metadata?.targetPath;
            
            // Only navigate if this is a fresh login (not a refresh or tab change)
            if (targetPath && location.pathname === '/') {
              navigate(targetPath);
            }
          } else if (event === 'SIGNED_OUT') {
            setUser(null);
            // Only navigate to home if we're on a protected route
            if (!location.pathname.startsWith('/events/') && 
                !location.pathname.startsWith('/groups/') &&
                !location.pathname.startsWith('/pricing')) {
              navigate('/');
            }
          }
        });

        return () => {
          subscription?.unsubscribe();
        };
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [navigate, location.pathname]);

  const login = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      if (!data?.user) {
        throw new Error('No user data returned');
      }

      setUser(data.user);
      return data.user;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const register = async (email, password, name) => {
    try {
      // First, sign up the user
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
          data: {
            name: name,
          }
        }
      });

      if (authError) {
        console.error('Auth error during signup:', authError);
        throw authError;
      }

      if (!authData?.user) {
        throw new Error('No user data returned from signup');
      }

      console.log('User created successfully:', authData.user);

      // Sign in immediately after registration
      const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        console.error('Error signing in after registration:', signInError);
        throw signInError;
      }

      // Then, create their profile
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .insert({
          id: authData.user.id,
          name,
          email,
          user_type: localStorage.getItem('userType') || 'highschool',
        })
        .select()
        .single();

      if (profileError) {
        console.error('Error creating profile:', profileError);
        console.error('Profile creation failed with details:', {
          userId: authData.user.id,
          name,
          email,
          userType: localStorage.getItem('userType'),
          error: profileError
        });
        throw new Error(`Failed to create user profile: ${profileError.message}`);
      }

      console.log('Profile created successfully:', profileData);

      setUser(signInData.user);
      return signInData.user;
    } catch (error) {
      console.error('Failed to register:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
