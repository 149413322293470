import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { supabase } from '../config/supabase'
import { useAuth } from '../utils/AuthContext'
import {
  Box,
  Container,
  Paper,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Switch,
  FormGroup,
  Button,
} from '@mui/material'
import {
  Edit as EditIcon,
  CheckCircle as CheckIcon,
  Cancel as XIcon,
  Add as AddIcon,
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  ArrowBack,
  Visibility as ViewIcon,
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import CourseFiles from '../components/CourseFiles'
import { CourseFileService } from '../services/CourseFileService'
import CoursePeople from '../components/CoursePeople'
import AssignmentModal from '../components/AssignmentModal'
import SubmissionModal from '../components/SubmissionModal'
import {
  PageHeader,
  SectionHeader,
  FeatureHeader,
  DescriptiveText,
  BodyText,
  SupportingText,
} from '../components/ui/typography'

const getAvailableSections = (isTeacher, isLocal) => {
  const baseSections = [
    { id: 'info', label: 'Course Info' },
    { id: 'files', label: 'Files' },
  ]

  if (!isLocal) {
    baseSections.push({ id: 'assignments', label: 'Assignments' })
  }

  baseSections.push({ id: 'grades', label: 'Grades' })

  if (!isLocal) {
    baseSections.push({ id: 'announcements', label: 'Announcements' })
  }

  if (isTeacher && !isLocal) {
    baseSections.push({ id: 'people', label: 'People' })
  }

  return baseSections
}

// Move fileService outside component to avoid recreating on each render
const fileService = new CourseFileService()

// These components can stay outside since they don't need state
const InfoSection = ({ title, children, isLast }) => (
  <Box
    sx={{
      mb: isLast ? 0 : 'var(--spacing-12)',
      borderBottom: isLast ? 'none' : '1px solid',
      borderColor: 'hsl(var(--foreground))',
      pb: isLast ? 0 : 'var(--spacing-12)',
    }}
  >
    <FeatureHeader
      sx={{
        fontSize: 36,
        fontWeight: 700,
        color: 'hsl(var(--foreground))',
        mb: 'var(--spacing-4)',
      }}
    >
      {title}
    </FeatureHeader>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--spacing-2)',
      }}
    >
      {children}
    </Box>
  </Box>
)

const SidebarMenuItem = ({ label, isActive, onClick }) => (
  <Button
    fullWidth
    onClick={onClick}
    sx={{
      py: 1.5,
      px: 2.5,
      height: 'auto',
      justifyContent: 'flex-start',
      backgroundColor: isActive ? 'filter.active' : 'filter.inactive',
      color: isActive ? 'common.white' : 'text.primary',
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'none',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: isActive ? 'filter.active' : 'filter.inactive',
        opacity: 0.9,
      },
    }}
  >
    {label}
  </Button>
)

const ViewToggleButton = ({ isTeacherView, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      px: 2.5,
      py: 1.5,
      backgroundColor: 'filter.inactive',
      border: '1px solid',
      borderColor: 'text.primary',
      borderRadius: '8px',
      color: 'text.primary',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: 'filter.inactive',
        opacity: 0.9,
      },
    }}
  >
    {isTeacherView ? 'Enter Student View' : 'Enter Teacher View'}
  </Button>
)

const UserCoursePage = () => {
  const { courseId } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [courseData, setCourseData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeSection, setActiveSection] = useState('info')
  const [editingField, setEditingField] = useState(null)
  const [editValue, setEditValue] = useState('')
  const [materialsCount, setMaterialsCount] = useState(0)
  const [isTeacherView, setIsTeacherView] = useState(false)
  const [isLocal, setIsLocal] = useState(true)
  const location = useLocation()
  const [assignments, setAssignments] = useState([])
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false)

  // Get sections based on current view
  const sections = getAvailableSections(isTeacherView, isLocal)

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        // First try to fetch from user_courses
        let { data, error } = await supabase.from('user_courses').select('*').eq('id', courseId).single()

        if (error || !data) {
          // If not found in user_courses, try youredu_courses
          const { data: youreduData, error: youreduError } = await supabase
            .from('youredu_courses')
            .select('*') // Simplified query
            .eq('id', courseId)
            .single()

          if (youreduError) throw youreduError

          // Transform the data to match expected format
          data = {
            ...youreduData,
            uid: user.id,
            title: youreduData.title,
            college: 'YourEdu',
            teacher: 'YourEDU Instructor',
            is_youredu_course: true,
            term_start: youreduData.term_start || null,
          }

          // Set isLocal to false for YouredU courses
          setIsLocal(false)

          // Set initial teacher view if user is the creator
          setIsTeacherView(youreduData.creator_id === user.id)
        } else {
          // It's a local course
          setIsLocal(true)
          setIsTeacherView(false)
        }

        setCourseData(data)
      } catch (err) {
        console.error('Error fetching course:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (user) {
      fetchCourseData()
    }
  }, [courseId, user])

  useEffect(() => {
    const fetchMaterialsCount = async () => {
      if (courseId && user) {
        // Add user check
        try {
          const files = await fileService.getFiles(courseId, 'materials')
          setMaterialsCount(files?.length || 0) // Add null check
        } catch (err) {
          console.error('Error fetching materials count:', err)
          setMaterialsCount(0) // Set to 0 on error
        }
      }
    }

    fetchMaterialsCount()
  }, [courseId, user]) // Add user to dependencies

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const { data, error } = await supabase
          .from('youredu_courses_assignments')
          .select('*')
          .eq('course_id', courseId)
          .order('due_date', { ascending: true })

        if (error) throw error
        setAssignments(data)
      } catch (err) {
        console.error('Error fetching assignments:', err)
      }
    }

    if (courseId) {
      fetchAssignments()
    }
  }, [courseId])

  const formatSchedule = (schedule) => {
    if (!schedule || schedule === 'Asynchronous') return schedule

    const dayMap = {
      M: 'Monday',
      T: 'Tuesday',
      W: 'Wednesday',
      R: 'Thursday',
      F: 'Friday',
      S: 'Saturday',
      U: 'Sunday',
    }

    return schedule
      .trim()
      .split(/\s+/)
      .filter((day) => day)
      .map((day) => dayMap[day] || day)
      .join(', ')
  }

  const formatTimes = (times) => {
    if (!times) return times
    const timePattern = /(\d{1,2}:\d{2}[ap]m)\s*-\s*(\d{1,2}:\d{2}[ap]m)/i
    const match = times.match(timePattern)
    if (match) {
      return `${match[1]} - ${match[2]}`
    }
    return times
  }

  const formatSchoolYear = (year) => {
    if (!year) return year
    return `${year}-${parseInt(year) + 1}`
  }

  const formatTermStart = (termStart) => {
    if (!termStart) return 'Not set'

    // Extract just the term name, removing any year if present
    const termNames = ['Fall', 'Winter', 'Spring', 'Summer']
    const termWord = termStart.split(/\s+/)[0].toLowerCase()

    // Find matching term name (case-insensitive)
    const matchedTerm = termNames.find((term) => term.toLowerCase() === termWord)
    return matchedTerm || termStart
  }

  const formatTermDuration = (duration) => {
    if (!duration) return 'Not set'

    const durationMap = {
      quarter: 'Quarter',
      semester: 'Semester',
      'school year': 'School Year',
      schoolyear: 'School Year',
      summer: 'Summer',
    }

    return durationMap[duration.toLowerCase()] || duration
  }

  const getDisplayValue = (field, value) => {
    if (value === null) return 'Not set'
    switch (field) {
      case 'days':
        return formatSchedule(value)
      case 'times':
        return formatTimes(value)
      case 'year':
        return formatSchoolYear(value)
      case 'term_start':
        return formatTermStart(value)
      case 'term_duration':
        return formatTermDuration(value)
      default:
        return String(value)
    }
  }

  const handleEditClick = (field, value) => {
    setEditingField(field)
    setEditValue(value || '')
  }

  const handleSave = async (field) => {
    try {
      const { error } = await supabase
        .from('user_courses')
        .update({ [field]: editValue })
        .eq('id', courseId)
        .eq('uid', user.id)

      if (error) throw error

      setCourseData((prev) => ({
        ...prev,
        [field]: editValue,
      }))

      setEditingField(null)
      setEditValue('')
      toast.success('Course updated successfully')
    } catch (error) {
      console.error('Error updating course:', error)
      toast.error('Failed to update course')
    }
  }

  const handleCancel = () => {
    setEditingField(null)
    setEditValue('')
  }

  const handleCreateAssignment = async (assignmentData) => {
    try {
      const { data, error } = await supabase
        .from('youredu_courses_assignments')
        .insert([
          {
            course_id: courseId,
            ...assignmentData,
          },
        ])
        .select()
        .single()

      if (error) throw error

      setAssignments((prev) => [...prev, data])
      setIsAssignmentModalOpen(false)
      toast.success('Assignment created successfully')
    } catch (err) {
      console.error('Error creating assignment:', err)
      toast.error('Failed to create assignment')
    }
  }

  const handleAssignmentAction = (assignment) => {
    if (isTeacherView) {
      // Handle edit action
      // TODO: Implement edit functionality
      toast.info('Edit functionality coming soon')
    } else {
      // Handle submit action
      setSelectedAssignment(assignment)
      setIsSubmissionModalOpen(true)
    }
  }

  const renderEditableField = (field, value) => {
    const isEditing = editingField === field

    if (isEditing) {
      return (
        <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
          {field === 'is_college_level' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={editValue === 'true'}
                  onChange={(e) => setEditValue(e.target.checked.toString())}
                  sx={{
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-checked': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  }}
                />
              }
              label="College Level Course"
            />
          ) : field === 'instruction_method' ? (
            <Select
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              size="small"
              sx={{
                backgroundColor: 'hsl(var(--background))',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'hsl(var(--border))',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'hsl(var(--border))',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'hsl(var(--brand-primary))',
                },
              }}
            >
              <MenuItem value="in-person">In Person</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="hybrid">Hybrid</MenuItem>
            </Select>
          ) : (
            <TextField
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              size="small"
              multiline={field === 'description'}
              rows={field === 'description' ? 4 : 1}
              sx={{
                backgroundColor: 'hsl(var(--background))',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'hsl(var(--border))',
                  },
                  '&:hover fieldset': {
                    borderColor: 'hsl(var(--border))',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'hsl(var(--brand-primary))',
                  },
                },
              }}
            />
          )}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={() => handleSave(field)}
              size="small"
              sx={{
                color: 'hsl(var(--success))',
                backgroundColor: 'hsl(var(--success) / 0.1)',
                '&:hover': {
                  backgroundColor: 'hsl(var(--success) / 0.2)',
                },
              }}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              onClick={handleCancel}
              size="small"
              sx={{
                color: 'hsl(var(--destructive))',
                backgroundColor: 'hsl(var(--destructive) / 0.1)',
                '&:hover': {
                  backgroundColor: 'hsl(var(--destructive) / 0.2)',
                },
              }}
            >
              <XIcon />
            </IconButton>
          </Box>
        </Box>
      )
    }

    return (
      <Box sx={{ display: 'flex', gap: 2, flex: 1, alignItems: 'center' }}>
        <BodyText sx={{ flex: 1 }}>{field === 'is_college_level' ? (value === 'true' ? 'Yes' : 'No') : value}</BodyText>
        {isTeacherView && (
          <IconButton
            onClick={() => {
              setEditingField(field)
              setEditValue(value)
            }}
            size="small"
            className="edit-button"
            sx={{
              opacity: 0,
              color: 'hsl(var(--muted-foreground))',
              transition: 'opacity 0.2s',
              '&:hover': {
                backgroundColor: 'hsl(var(--accent))',
                color: 'hsl(var(--accent-foreground))',
              },
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    )
  }

  const renderGroup = (title, fields) => {
    const groupFields = fields.filter((field) => courseData[field] !== undefined)
    if (groupFields.length === 0) return null

    return (
      <Box key={title} sx={{ mb: 6, '&:last-child': { mb: 0 } }}>
        <FeatureHeader
          sx={{
            mb: 3,
            pb: 2,
            borderBottom: '1px solid hsl(var(--border))',
          }}
        >
          {title}
        </FeatureHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {groupFields.map((field) => (
            <Box
              key={field}
              sx={{
                display: 'flex',
                gap: 3,
                '&:hover': {
                  '& .edit-button': {
                    opacity: 1,
                  },
                },
              }}
            >
              <BodyText
                sx={{
                  minWidth: '200px',
                  fontWeight: 500,
                  color: 'hsl(var(--muted-foreground))',
                  textTransform: 'capitalize',
                }}
              >
                {field.replace(/_/g, ' ')}
              </BodyText>
              {renderEditableField(field, courseData[field])}
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  const renderContent = () => {
    switch (activeSection) {
      case 'info':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <InfoSection title="General Information">
              <InfoRow label="Title" value={courseData?.title} field="title" />
              <InfoRow label="College" value={courseData?.college || 'None (YourEDU course)'} field="college" />
              <InfoRow label="High School Subject" value={courseData?.subject} field="subject" />
              <InfoRow label="Teacher" value={courseData?.teacher} field="teacher" />
              <InfoRow label="Description" value={courseData?.description} field="description" />
            </InfoSection>

            <InfoSection title="Schedule">
              <InfoRow label="Year" value={courseData?.year} field="year" />
              <InfoRow label="Term Start" value={courseData?.term_start} field="term_start" />
              <InfoRow label="Term Duration" value={courseData?.term_duration} field="term_duration" />
              <InfoRow label="Days" value={courseData?.days?.join(', ')} field="days" />
              <InfoRow label="Times" value={courseData?.times} field="times" />
            </InfoSection>

            <InfoSection title="Course Details" isLast>
              <InfoRow label="Units" value={courseData?.units} field="units" />
              <InfoRow label="Total Hours" value={courseData?.total_hours} field="total_hours" />
              <InfoRow label="Instruction Method" value={courseData?.instruction_method} field="instruction_method" />
              <InfoRow label="Textbooks" value={courseData?.textbooks} field="textbooks" />
              <InfoRow label="Materials" value={courseData?.materials} field="materials" />
              <InfoRow label="Evaluation Method" value={courseData?.evaluation_method} field="evaluation_method" />
            </InfoSection>
          </Box>
        )

      case 'files':
        return <CourseFiles courseId={courseId} />

      case 'assignments':
        return (
          <Box>
            {isTeacherView && (
              <Box sx={{ mb: 4 }}>
                <Button
                  onClick={() => setIsAssignmentModalOpen(true)}
                  startIcon={<AddIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: 'hsl(var(--brand-primary))',
                    color: 'hsl(var(--background))',
                    '&:hover': {
                      backgroundColor: 'hsl(var(--brand-primary-dark))',
                    },
                  }}
                >
                  Create Assignment
                </Button>
              </Box>
            )}

            {assignments.length > 0 ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {assignments.map((assignment) => (
                  <Paper
                    key={assignment.id}
                    elevation={0}
                    sx={{
                      p: 3,
                      border: '1px solid hsl(var(--border))',
                      borderRadius: 2,
                      backgroundColor: 'hsl(var(--card))',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                      <Box>
                        <FeatureHeader sx={{ mb: 1 }}>{assignment.title}</FeatureHeader>
                        <DescriptiveText sx={{ color: 'hsl(var(--muted-foreground))' }}>
                          Due: {new Date(assignment.due_date).toLocaleDateString()}
                        </DescriptiveText>
                      </Box>
                      <Button
                        variant={isTeacherView ? 'outlined' : 'contained'}
                        onClick={() => handleAssignmentAction(assignment)}
                        sx={
                          isTeacherView
                            ? {
                                borderColor: 'hsl(var(--border))',
                                color: 'hsl(var(--foreground))',
                                '&:hover': {
                                  backgroundColor: 'hsl(var(--accent))',
                                  borderColor: 'hsl(var(--border))',
                                },
                              }
                            : {
                                backgroundColor: 'hsl(var(--brand-primary))',
                                color: 'hsl(var(--background))',
                                '&:hover': {
                                  backgroundColor: 'hsl(var(--brand-primary-dark))',
                                },
                              }
                        }
                      >
                        {isTeacherView ? 'Edit' : 'Submit'}
                      </Button>
                    </Box>
                    <BodyText>{assignment.description}</BodyText>
                  </Paper>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  backgroundColor: 'hsl(var(--muted))',
                  borderRadius: 2,
                }}
              >
                <SupportingText>
                  {isTeacherView
                    ? "No assignments created yet. Click 'Create Assignment' to add one."
                    : 'No assignments available yet.'}
                </SupportingText>
              </Box>
            )}

            <AssignmentModal
              open={isAssignmentModalOpen}
              onClose={() => setIsAssignmentModalOpen(false)}
              onSubmit={handleCreateAssignment}
            />

            <SubmissionModal
              open={isSubmissionModalOpen}
              onClose={() => {
                setSelectedAssignment(null)
                setIsSubmissionModalOpen(false)
              }}
              assignment={selectedAssignment}
              userId={user.id}
            />
          </Box>
        )

      case 'grades':
        return (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              backgroundColor: 'hsl(var(--muted))',
              borderRadius: 2,
            }}
          >
            <SupportingText>Grades section coming soon...</SupportingText>
          </Box>
        )

      case 'announcements':
        return (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              backgroundColor: 'hsl(var(--muted))',
              borderRadius: 2,
            }}
          >
            <SupportingText>Announcements section coming soon...</SupportingText>
          </Box>
        )

      case 'people':
        return <CoursePeople courseId={courseId} />

      default:
        return null
    }
  }

  // Move InfoRow inside UserCoursePage
  const InfoRow = ({ label, value, field }) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 'var(--spacing-4)',
        py: 'var(--spacing-1)',
      }}
    >
      <Box
        sx={{
          minWidth: 200,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BodyText
          sx={{
            fontSize: 20,
            fontWeight: 700,
            color: 'hsl(var(--foreground))',
          }}
        >
          {label}
        </BodyText>
      </Box>
      {editingField === field ? (
        <Box sx={{ flex: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
          {field === 'is_college_level' ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={editValue === 'true'}
                  onChange={(e) => setEditValue(e.target.checked.toString())}
                  sx={{
                    color: 'text.secondary',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}
                />
              }
              label="College Level Course"
            />
          ) : field === 'instruction_method' ? (
            <Select
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              size="small"
              sx={{
                backgroundColor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  fontSize: '20px',
                  fontWeight: 500,
                },
              }}
            >
              <MenuItem value="in-person">In Person</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="hybrid">Hybrid</MenuItem>
            </Select>
          ) : field === 'term_duration' ? (
            <Select
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              size="small"
              sx={{
                backgroundColor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  fontSize: '20px',
                  fontWeight: 500,
                },
              }}
            >
              <MenuItem value="quarter">Quarter</MenuItem>
              <MenuItem value="semester">Semester</MenuItem>
              <MenuItem value="school year">School Year</MenuItem>
              <MenuItem value="summer">Summer</MenuItem>
            </Select>
          ) : (
            <TextField
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              size="small"
              multiline={field === 'description'}
              rows={field === 'description' ? 4 : 1}
              sx={{
                backgroundColor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  fontSize: '20px',
                  fontWeight: 500,
                },
              }}
              autoFocus
            />
          )}
          <IconButton onClick={() => handleSave(field)} size="small" sx={{ color: 'success.main' }}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleCancel} size="small" sx={{ color: 'error.main' }}>
            <XIcon />
          </IconButton>
        </Box>
      ) : (
        <Box
          onDoubleClick={() => {
            if (isTeacherView) {
              setEditingField(field)
              setEditValue(value || '')
            }
          }}
          sx={{
            flex: 1,
            cursor: isTeacherView ? 'pointer' : 'default',
          }}
        >
          <BodyText
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: 'text.primary',
            }}
          >
            {field === 'is_college_level' ? (value === 'true' ? 'Yes' : 'No') : getDisplayValue(field, value)}
          </BodyText>
        </Box>
      )}
    </Box>
  )

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '400px',
          backgroundColor: 'hsl(var(--background))',
        }}
      >
        <CircularProgress sx={{ color: 'hsl(var(--brand-primary))' }} />
      </Box>
    )
  }

  if (error) {
    return (
      <Box sx={{ backgroundColor: 'hsl(var(--background))', minHeight: '100vh', py: 4 }}>
        <Container maxWidth="lg">
          <Box sx={{ mb: 4 }}>
            <Button
              onClick={() => navigate('/my-courses')}
              sx={{
                mb: 2,
                color: 'hsl(var(--foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              Back to My Courses
            </Button>
            <Box
              sx={{
                backgroundColor: 'hsl(var(--destructive) / 0.1)',
                p: 3,
                borderRadius: 1,
                border: '1px solid hsl(var(--destructive))',
              }}
            >
              <BodyText sx={{ color: 'hsl(var(--destructive))' }}>Error: {error}</BodyText>
            </Box>
          </Box>
        </Container>
      </Box>
    )
  }

  return (
    <Box sx={{ backgroundColor: 'hsl(var(--background))', minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container maxWidth="lg">
          <Button
            onClick={() => navigate('/my-courses')}
            sx={{
              mb: 4,
              color: 'hsl(var(--foreground))',
              '&:hover': {
                backgroundColor: 'hsl(var(--accent))',
              },
            }}
          >
            Back to My Courses
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 4 }}>
            <Box>
              <PageHeader>{courseData?.title || 'Course Details'}</PageHeader>
              <DescriptiveText sx={{ mt: 2, color: 'hsl(var(--muted-foreground))' }}>
                {courseData?.description || 'View and manage your course details, materials, assignments, and more.'}
              </DescriptiveText>
            </Box>
            {!isLocal && (
              <ViewToggleButton isTeacherView={isTeacherView} onClick={() => setIsTeacherView(!isTeacherView)} />
            )}
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {/* Sidebar Navigation */}
          <Box
            sx={{
              width: 280,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {sections.map(({ id, label }) => (
              <SidebarMenuItem
                key={id}
                label={label}
                isActive={activeSection === id}
                onClick={() => setActiveSection(id)}
              />
            ))}
          </Box>

          {/* Content Area */}
          <Box sx={{ flex: 1 }}>{renderContent()}</Box>
        </Box>
      </Container>
    </Box>
  )
}

export default UserCoursePage
