import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../utils/AuthContext'
import ChatBot from '../components/ChatBot'
import { PageHeader, FeatureHeader, BodyText, SupportingText, DescriptiveText } from '../components/ui/typography.jsx'
import theme from '../styles/theme'
import { cardStyles } from '../styles/theme/components/cards'
import {
  Container,
  Grid,
  Paper,
  Box,
  List,
  IconButton,
} from '@mui/material'
import {
  NotificationsOutlined as NotificationsIcon,
  CalendarToday as CalendarIcon,
  Chat as ChatIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  School as SchoolIcon,
} from '@mui/icons-material'

const mockAnnouncements = [
  {
    id: 1,
    title: 'New Community Event',
    subtitle: 'Join our upcoming virtual meetup this weekend',
    date: 'Just now',
  },
  {
    id: 2,
    title: 'New Perk Available',
    subtitle: 'Access to Khan Academy Pro - Click to learn more',
    date: '1 day ago',
  },
  {
    id: 3,
    title: 'Community Achievement',
    subtitle: 'Your recent post received 10 helpful votes',
    date: '2 days ago',
  },
  {
    id: 4,
    title: 'Resource Library Update',
    subtitle: 'New SAT prep materials added',
    date: '3 days ago',
  },
]

const mockCalendarEvents = [
  {
    id: 1,
    title: 'Webinar: Homeschool to College',
    subtitle: 'Join us for expert insights on college admissions',
    date: 'Jan 15, 1:00 PM',
  },
  {
    id: 2,
    title: 'Compliance Reminder: PSA Filing',
    subtitle: 'Private School Affidavit Due',
    date: 'Jan 1, 2025',
  },
  {
    id: 3,
    title: 'Math Course Deadline',
    date: 'Tomorrow, 3:00 PM',
  },
  {
    id: 4,
    title: 'Community Meetup',
    date: 'Jan 25, 2:00 PM',
  },
]

const mockTodos = [
  { text: 'Complete Math Module 3 Assessment', dueDate: '2024-01-25' },
  { text: 'Submit Science Lab Report', dueDate: '2024-01-28' },
  { text: 'Register for Spring Semester', dueDate: '2024-02-01' },
]

const Home = () => {
  const { user } = useAuth()
  const firstName = (user?.user_metadata?.name || user?.email?.split('@')[0] || 'Parent')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  const [isChatOpen, setIsChatOpen] = useState(false)

  const formatDeadline = (date) => {
    const deadline = new Date(date)
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (deadline.toDateString() === today.toDateString()) {
      return 'Today'
    } else if (deadline.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow'
    } else {
      return deadline.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
  }

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Parent Dashboard
          </PageHeader>
          <DescriptiveText 
            sx={{ maxWidth: 'var(--text-max-width)' }}
          >
            Welcome to your personalized dashboard. Access all your homeschool tools and resources, 
            track progress, and manage your homeschool journey in one place.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Parent Tools */}
        <Grid container spacing={3}>
          {[
            {
              title: 'Academic Overview',
              subtitle: 'Track progress',
              icon: SchoolIcon,
              color: `hsl(var(--brand-primary))`,
              bgColor: `hsl(var(--brand-primary-light))`,
              path: '/parent-academics',
            },
            {
              title: 'Calendar',
              subtitle: 'Schedule',
              icon: CalendarIcon,
              color: `hsl(var(--secondary-green))`,
              bgColor: `hsl(var(--secondary-green-light))`,
              path: '/calendar',
            },
            {
              title: 'Messages',
              subtitle: 'Community',
              icon: ChatIcon,
              color: `hsl(var(--secondary-orange))`,
              bgColor: `hsl(var(--secondary-orange-light))`,
              path: '/community',
            },
            {
              title: 'Support',
              subtitle: 'Help',
              icon: PhoneIcon,
              color: `hsl(var(--secondary-purple))`,
              bgColor: `hsl(var(--secondary-purple-light))`,
              path: '/support',
            },
          ].map((action) => (
            <Grid item xs={6} sm={3} key={action.title}>
              <Paper
                component={Link}
                to={action.path}
                elevation={0}
                sx={{
                  ...cardStyles.action,
                  '& .icon': {
                    backgroundColor: action.bgColor,
                    color: action.color,
                  },
                }}
              >
                <Box className="icon">
                  <action.icon />
                </Box>
                <Box>
                  <FeatureHeader>{action.title}</FeatureHeader>
                  <SupportingText>{action.subtitle}</SupportingText>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Main Content Grid */}
        <Grid container spacing={3} sx={{ mt: 'var(--spacing-4)' }}>
          {/* Calendar Events */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '100%',
                borderRadius: 'var(--radius-card)',
                backgroundColor: 'white',
                border: '1px solid hsl(var(--neutral-200))',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FeatureHeader>Events</FeatureHeader>
                <IconButton 
                  component={Link} 
                  to="/calendar"
                  size="small"
                  sx={{ 
                    color: `hsl(var(--brand-primary))`,
                    '&:hover': {
                      backgroundColor: `hsl(var(--brand-primary-light))`,
                    }
                  }}
                >
                  <CalendarIcon />
                </IconButton>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {mockCalendarEvents.slice(0, 3).map((event) => (
                  <Paper
                    key={event.id}
                    elevation={0}
                    sx={{
                      p: 2,
                      borderRadius: 'var(--radius-card)',
                      backgroundColor: `hsl(var(--neutral-50))`,
                      border: '1px solid hsl(var(--neutral-200))',
                      '&:hover': {
                        backgroundColor: 'white',
                        borderColor: `hsl(var(--brand-primary))`,
                      },
                    }}
                  >
                    <FeatureHeader>{event.title}</FeatureHeader>
                    <SupportingText>{event.date}</SupportingText>
                  </Paper>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Tasks */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '100%',
                borderRadius: 'var(--radius-card)',
                backgroundColor: 'white',
                border: '1px solid hsl(var(--neutral-200))',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FeatureHeader>Tasks</FeatureHeader>
                <IconButton
                  size="small"
                  sx={{
                    color: `hsl(var(--brand-primary))`,
                    '&:hover': {
                      backgroundColor: `hsl(var(--brand-primary-light))`,
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {mockTodos.map((todo, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      p: 2,
                      borderRadius: 'var(--radius-card)',
                      backgroundColor: `hsl(var(--neutral-50))`,
                      border: '1px solid hsl(var(--neutral-200))',
                      '&:hover': {
                        backgroundColor: 'white',
                        borderColor: `hsl(var(--brand-primary))`,
                      },
                    }}
                  >
                    <FeatureHeader>{todo.text}</FeatureHeader>
                    <SupportingText>Due: {formatDeadline(todo.dueDate)}</SupportingText>
                  </Paper>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* Announcements */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                height: '100%',
                borderRadius: 'var(--radius-card)',
                backgroundColor: 'white',
                border: '1px solid hsl(var(--neutral-200))',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FeatureHeader>Updates</FeatureHeader>
                <IconButton
                  size="small"
                  sx={{
                    color: `hsl(var(--brand-primary))`,
                    '&:hover': {
                      backgroundColor: `hsl(var(--brand-primary-light))`,
                    },
                  }}
                >
                  <NotificationsIcon />
                </IconButton>
              </Box>
              <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {mockAnnouncements.slice(0, 3).map((announcement) => (
                  <Paper
                    key={announcement.id}
                    elevation={0}
                    sx={{
                      p: 2,
                      borderRadius: 'var(--radius-card)',
                      backgroundColor: `hsl(var(--neutral-50))`,
                      border: '1px solid hsl(var(--neutral-200))',
                      '&:hover': {
                        backgroundColor: 'white',
                        borderColor: `hsl(var(--brand-primary))`,
                      },
                    }}
                  >
                    <FeatureHeader>{announcement.title}</FeatureHeader>
                    <SupportingText>{announcement.date}</SupportingText>
                  </Paper>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <ChatBot isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </Box>
  )
}

export default Home
