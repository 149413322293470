import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import { AssignmentFileService } from '../services/AssignmentFileService'
import { supabase } from '../config/supabase'
import { toast } from 'react-toastify'
import { SectionHeader, FeatureHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography'

const fileService = new AssignmentFileService()

const SubmissionModal = ({ open, onClose, assignment, userId }) => {
  const [textContent, setTextContent] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let fileId = null

      // Handle file upload if present
      if (file && assignment.submission_type === 'file') {
        const fileData = await fileService.uploadFile(assignment.id, userId, file)
        fileId = fileData.path
      }

      // Create submission record
      const { error } = await supabase.from('youredu_courses_assignments_submissions').insert([
        {
          assignment_id: assignment.id,
          user_id: userId,
          text_content: assignment.submission_type === 'text' ? textContent : null,
          file_id: fileId,
        },
      ])

      if (error) throw error

      toast.success('Assignment submitted successfully')
      onClose()
    } catch (err) {
      console.error('Error submitting assignment:', err)
      toast.error('Failed to submit assignment')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'hsl(var(--background))',
          border: '1px solid hsl(var(--border))',
        },
      }}
    >
      <DialogTitle>
        <FeatureHeader>Submit Assignment: {assignment?.name}</FeatureHeader>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          {assignment?.submission_type === 'text' ? (
            <TextField
              label="Your Submission"
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
              multiline
              rows={6}
              fullWidth
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'hsl(var(--background))',
                  '& fieldset': {
                    borderColor: 'hsl(var(--border))',
                  },
                  '&:hover fieldset': {
                    borderColor: 'hsl(var(--brand-primary))',
                  },
                },
              }}
            />
          ) : (
            <Box>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: 'none' }}
                id="assignment-file-input"
              />
              <label htmlFor="assignment-file-input">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    borderColor: 'hsl(var(--border))',
                    color: 'hsl(var(--brand-primary))',
                    '&:hover': {
                      borderColor: 'hsl(var(--brand-primary))',
                      backgroundColor: 'hsl(var(--brand-primary-light))',
                    },
                  }}
                >
                  Choose File
                </Button>
              </label>
              {file && <SupportingText sx={{ mt: 1 }}>Selected file: {file.name}</SupportingText>}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, borderTop: '1px solid hsl(var(--border))' }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'hsl(var(--muted-foreground))',
            '&:hover': {
              backgroundColor: 'hsl(var(--muted))',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading || (!textContent && !file)}
          sx={{
            backgroundColor: 'hsl(var(--brand-primary))',
            '&:hover': {
              backgroundColor: 'hsl(var(--brand-primary-dark))',
            },
            '&:disabled': {
              backgroundColor: 'hsl(var(--muted))',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmissionModal
