import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  Chip,
} from '@mui/material'
import { PageHeader, SectionHeader, FeatureHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography'
import yourEduLogo from '../assets/logo.png'

const Internships = () => {
  const navigate = useNavigate()
  const [savedInternships, setSavedInternships] = useState([])

  const internships = [
    {
      id: 1,
      company: "YawAI",
      logo: yourEduLogo,
      sector: "Artificial Intelligence",
      description: "Join our AI team to help develop cutting-edge language models and AI applications. Work directly with senior engineers on real-world AI projects.",
      role: "AI Research Apprentice",
      duration: "6 months",
    },
    {
      id: 2,
      company: "YourEDU",
      logo: yourEduLogo,
      sector: "Education Technology",
      description: "Help shape the future of education by working on our platform. Learn full-stack development and educational technology implementation.",
      role: "EdTech Developer Intern",
      duration: "6 months",
    },
    {
      id: 3,
      company: "TechStart",
      logo: yourEduLogo,
      sector: "Technology",
      description: "Join our dynamic tech team and learn software development hands-on with modern web technologies.",
      role: "Junior Developer Apprentice",
      duration: "3 months",
    },
    {
      id: 4,
      company: "GreenEarth",
      logo: yourEduLogo,
      sector: "Environmental",
      description: "Help develop sustainable solutions while learning project management and environmental impact analysis.",
      role: "Sustainability Coordinator",
      duration: "3 months",
    },
    {
      id: 5,
      company: "FinTech Solutions",
      logo: yourEduLogo,
      sector: "Finance",
      description: "Learn financial technology and data analysis in a fast-paced environment while working with blockchain technology.",
      role: "Financial Analysis Intern",
      duration: "1 year",
    },
  ];

  const handleSave = (internship) => {
    if (!savedInternships.find(saved => saved.id === internship.id)) {
      setSavedInternships([...savedInternships, internship])
    }
  }

  const handleRemove = (internshipId) => {
    setSavedInternships(savedInternships.filter(internship => internship.id !== internshipId))
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
            <Box>
              <PageHeader>YourEDU Internships</PageHeader>
              <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                Discover unique apprenticeship opportunities with leading companies. Gain valuable experience,
                receive mentorship, and earn high school credit through our diverse internship programs.
              </DescriptiveText>
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate('/internships/apply')}
              sx={{
                backgroundColor: 'hsl(var(--brand-primary))',
                color: 'hsl(var(--brand-primary-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--brand-primary-dark))',
                },
              }}
            >
              Apply Now
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Description Section */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            mb: 4,
            backgroundColor: 'hsl(var(--card))',
            border: '1px solid hsl(var(--border))',
            borderRadius: 'var(--radius-lg)',
          }}
        >
          <BodyText>
            Welcome to YourEDU's Internship Program! We offer unique apprenticeship opportunities 
            with leading companies where homeschoolers and high school students can gain valuable 
            experience, receive mentorship, and earn high school credit. Our positions span from 
            technical to business roles, providing diverse learning experiences.
          </BodyText>
        </Paper>

        {/* Saved Internships Section */}
        {savedInternships.length > 0 && (
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 4,
              backgroundColor: 'hsl(var(--card))',
              border: '1px solid hsl(var(--border))',
              borderRadius: 'var(--radius-lg)',
            }}
          >
            <SectionHeader sx={{ mb: 3 }}>Saved for Later</SectionHeader>
            <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', pb: 2 }}>
              {savedInternships.map(internship => (
                <Paper
                  key={internship.id}
                  elevation={0}
                  sx={{
                    p: 2,
                    minWidth: 300,
                    backgroundColor: 'hsl(var(--muted))',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: 'var(--radius)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={internship.logo}
                    alt={internship.company}
                    sx={{
                      width: 40,
                      height: 40,
                      objectFit: 'contain',
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <FeatureHeader>{internship.company}</FeatureHeader>
                    <SupportingText>{internship.role}</SupportingText>
                  </Box>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleRemove(internship.id)}
                    sx={{
                      borderColor: 'hsl(var(--destructive))',
                      color: 'hsl(var(--destructive))',
                      '&:hover': {
                        backgroundColor: 'hsl(var(--destructive) / 0.1)',
                        borderColor: 'hsl(var(--destructive))',
                      },
                    }}
                  >
                    Remove
                  </Button>
                </Paper>
              ))}
            </Box>
          </Paper>
        )}

        {/* Internships Grid */}
        <Grid container spacing={3}>
          {internships.map(internship => (
            <Grid item xs={12} md={6} lg={4} key={internship.id}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  height: '100%',
                  backgroundColor: 'hsl(var(--card))',
                  border: '1px solid hsl(var(--border))',
                  borderRadius: 'var(--radius)',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Box
                    component="img"
                    src={internship.logo}
                    alt={internship.company}
                    sx={{
                      width: 50,
                      height: 50,
                      objectFit: 'contain',
                    }}
                  />
                  <Chip
                    label={internship.sector}
                    size="small"
                    sx={{
                      backgroundColor: 'hsl(var(--accent))',
                      color: 'hsl(var(--accent-foreground))',
                    }}
                  />
                </Box>

                <FeatureHeader sx={{ mb: 1 }}>{internship.company}</FeatureHeader>
                <SectionHeader sx={{ mb: 2, color: 'hsl(var(--muted-foreground))' }}>
                  {internship.role}
                </SectionHeader>

                <BodyText sx={{ mb: 3, flexGrow: 1 }}>
                  {internship.description}
                </BodyText>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                  <SupportingText>Duration: {internship.duration}</SupportingText>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleSave(internship)}
                    disabled={savedInternships.some(saved => saved.id === internship.id)}
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--muted-foreground))',
                      '&:hover': {
                        backgroundColor: 'hsl(var(--accent))',
                        borderColor: 'hsl(var(--border))',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: 'hsl(var(--accent))',
                        borderColor: 'hsl(var(--border))',
                        color: 'hsl(var(--muted-foreground))',
                      },
                    }}
                  >
                    {savedInternships.some(saved => saved.id === internship.id) ? 'Saved' : 'Save'}
                  </Button>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Internships 