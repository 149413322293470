import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Button, Divider } from '@mui/material';
import { ArrowBack as ArrowBackIcon, LocationOn as LocationIcon, LocalOffer as DiscountIcon, HowToReg as RedeemIcon } from '@mui/icons-material';
import { PageHeader, SectionHeader, DescriptiveText, SupportingText } from '../components/ui/typography';
import { cardStyles } from '../styles/theme/components/cards';

const MarketplaceDetail = () => {
  const { providerId } = useParams();
  const navigate = useNavigate();

  // Mock provider data - replace with actual data fetching
  const providerDetails = {
    1: {
      name: "Math-U-See",
      logo: "https://placeholder.com/300",
      description: "Math-U-See is a complete K-12 math curriculum focused on mastery learning through hands-on manipulatives and systematic instruction.",
      longDescription: "Math-U-See is a complete K-12 math curriculum focused on mastery learning through hands-on manipulatives and systematic instruction. The program uses multi-sensory teaching to help students understand, master, and love math.",
      discount: "15% off all curriculum packages",
      website: "https://mathusee.com",
      location: "161 Discovery Dr, Greencastle, PA 17225",
      howToRedeem: "Use your YourEDU member ID (found in your account settings) as the discount code during checkout on the Math-U-See website.",
      features: [
        "Video instruction for every lesson",
        "Manipulative-based learning",
        "Mastery approach",
        "Teacher guides included",
        "Online support resources"
      ]
    }
    // Add more provider details as needed
  };

  const provider = providerDetails[providerId];

  if (!provider) {
    return <div>Provider not found</div>;
  }

  return (
    <Box sx={{ minHeight: '100vh', margin: 0 }}>
      {/* Hero Section */}
      <Box sx={cardStyles.hero}>
        <Container sx={{ maxWidth: 'var(--container-max-width) !important' }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/marketplace')}
            sx={{
              color: 'hsl(var(--text-primary))',
              mb: 'var(--spacing-4)',
              '&:hover': {
                backgroundColor: 'hsla(var(--brand-primary), 0.06)'
              }
            }}
          >
            Back to All Perks
          </Button>
          <PageHeader>{provider.name}</PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            {provider.description}
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container sx={{ 
        maxWidth: 'var(--container-max-width) !important',
        py: 'var(--spacing-6)',
        px: 'var(--container-padding-x)'
      }}>
        <Grid container spacing={4}>
          {/* Main Content */}
          <Grid item xs={12} md={8}>
            <Box sx={cardStyles.feature}>
              {/* Logo and Basic Info */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mb: 'var(--spacing-4)'
              }}>
                <img 
                  src={provider.logo} 
                  alt={provider.name} 
                  style={{ 
                    width: '120px', 
                    height: '120px', 
                    objectFit: 'contain',
                    marginRight: 'var(--spacing-4)'
                  }} 
                />
                <Box>
                  <SectionHeader sx={{ mb: 1 }}>
                    {provider.name}
                  </SectionHeader>
                  <DescriptiveText>
                    {provider.description}
                  </DescriptiveText>
                </Box>
              </Box>

              <Divider sx={{ my: 'var(--spacing-4)' }} />

              {/* Detailed Description */}
              <SectionHeader sx={{ mb: 'var(--spacing-2)' }}>
                About {provider.name}
              </SectionHeader>
              <DescriptiveText sx={{ mb: 'var(--spacing-4)' }}>
                {provider.longDescription}
              </DescriptiveText>

              {/* Features */}
              <SectionHeader sx={{ mb: 'var(--spacing-2)' }}>
                Features & Benefits
              </SectionHeader>
              <Box sx={{ mb: 'var(--spacing-4)' }}>
                {provider.features.map((feature, index) => (
                  <Box 
                    key={index}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      mb: 'var(--spacing-2)',
                      '&:last-child': {
                        mb: 0
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: '6px',
                        height: '6px',
                        borderRadius: 'var(--radius-full)',
                        backgroundColor: 'hsl(var(--brand-primary))',
                        mr: 'var(--spacing-2)'
                      }}
                    />
                    <DescriptiveText>
                      {feature}
                    </DescriptiveText>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Sidebar */}
          <Grid item xs={12} md={4}>
            <Box sx={{
              ...cardStyles.feature,
              backgroundColor: 'hsla(var(--brand-primary), 0.03)'
            }}>
              {/* Discount Badge */}
              <Box sx={{ 
                backgroundColor: 'hsla(var(--brand-primary), 0.06)',
                p: 'var(--spacing-3)',
                borderRadius: 'var(--radius-lg)',
                mb: 'var(--spacing-4)',
                border: '1px solid hsla(var(--brand-primary), 0.12)'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 'var(--spacing-2)'
                }}>
                  <DiscountIcon sx={{ 
                    mr: 'var(--spacing-2)',
                    color: 'hsl(var(--brand-primary))'
                  }} />
                  <SectionHeader sx={{ margin: 0 }}>
                    Special Offer
                  </SectionHeader>
                </Box>
                <DescriptiveText sx={{ color: 'hsl(var(--brand-primary))' }}>
                  {provider.discount}
                </DescriptiveText>
              </Box>

              {/* Location if available */}
              {provider.location && (
                <Box sx={{ mb: 'var(--spacing-4)' }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 'var(--spacing-2)'
                  }}>
                    <LocationIcon sx={{ 
                      mr: 'var(--spacing-2)',
                      color: 'hsl(var(--text-secondary))'
                    }} />
                    <SectionHeader sx={{ margin: 0 }}>
                      Location
                    </SectionHeader>
                  </Box>
                  <SupportingText>
                    {provider.location}
                  </SupportingText>
                </Box>
              )}

              {/* How to Redeem */}
              <Box sx={{ mb: 'var(--spacing-4)' }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 'var(--spacing-2)'
                }}>
                  <RedeemIcon sx={{ 
                    mr: 'var(--spacing-2)',
                    color: 'hsl(var(--text-secondary))'
                  }} />
                  <SectionHeader sx={{ margin: 0 }}>
                    How to Redeem
                  </SectionHeader>
                </Box>
                <SupportingText>
                  {provider.howToRedeem}
                </SupportingText>
              </Box>

              {/* CTA Button */}
              <Button
                variant="contained"
                fullWidth
                href={provider.website}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  backgroundColor: 'hsl(var(--brand-primary))',
                  '&:hover': {
                    backgroundColor: 'hsl(var(--brand-primary-dark))'
                  }
                }}
              >
                Visit Website
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MarketplaceDetail; 