import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { supabase } from '../supabase/utils/supabaseClient';
import {
    Box,
    Container,
    Typography,
    Button,
    Avatar,
    Paper,
    Tabs,
    Tab,
    Grid,
    TextField,
    IconButton,
    Divider,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import {
    Lock as LockIcon,
    Public as PublicIcon,
    Group as GroupIcon,
    Event as EventIcon,
    Description as DescriptionIcon,
    Send as SendIcon,
    ArrowBack as ArrowBackIcon,
    Share as ShareIcon,
} from '@mui/icons-material';
import { fetchGroupById, fetchGroupPosts, createGroupPost } from '../utils/supabase/groups';
import AuthWrapper from '../components/AuthWrapper';

const GroupPage = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState('discussions');
    const [group, setGroup] = useState(null);
    const [posts, setPosts] = useState([]);
    const [newPost, setNewPost] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [showRegistrationPrompt, setShowRegistrationPrompt] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [targetPath, setTargetPath] = useState('');

    useEffect(() => {
        fetchGroupData();
    }, [groupId]);

    const fetchGroupData = async () => {
        setLoading(true);
        try {
            const groupData = await fetchGroupById(groupId);
            setGroup(groupData);
            
            if (currentTab === 'discussions') {
                const postsData = await fetchGroupPosts(groupId);
                setPosts(postsData);
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
            setError('Failed to load group data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleInteraction = (path) => {
        if (!user) {
            setShowRegistrationPrompt(true);
            return true;
        }
        return false;
    };

    const handleTabChange = (event, newValue) => {
        if (handleInteraction(`/groups/${groupId}?tab=${newValue}`)) return;
        setCurrentTab(newValue);
        if (newValue === 'discussions') {
            fetchGroupData();
        }
    };

    const handleCreatePost = async () => {
        if (!newPost.trim()) return;

        try {
            const post = await createGroupPost(groupId, newPost);
            setPosts([post, ...posts]);
            setNewPost('');
            setSnackbar({
                open: true,
                message: 'Post created successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error creating post:', error);
            setSnackbar({
                open: true,
                message: 'Failed to create post. Please try again.',
                severity: 'error'
            });
        }
    };

    const handleShare = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        setSnackbar({
            open: true,
            message: 'Group link copied to clipboard!',
            severity: 'success'
        });
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) return;
        
        setIsLoading(true);
        try {
            const { error } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo: `${window.location.origin}/my-account`,
                    data: {
                        targetPath: window.location.pathname
                    }
                }
            });

            if (error) throw error;

            setSnackbar({
                open: true,
                message: 'Check your email for a login link!',
                severity: 'success'
            });
            handleClose();
        } catch (error) {
            console.error('Error sending magic link:', error);
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        if (!isLoading) {
            setShowRegistrationPrompt(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!group) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Typography variant="h6" color="error">
                    {error || 'Group not found'}
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', bgcolor: '#f8fafc' }}>
            <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2 }}>
                <Container maxWidth="lg">
                    <AuthWrapper>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            onClick={() => navigate('/groups')}
                            sx={{ color: '#00356b' }}
                        >
                            Back to Groups
                        </Button>
                    </AuthWrapper>
                </Container>
            </Box>

            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ p: 3, borderRadius: 2, mb: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                                <Avatar
                                    src={group.profile_image}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        bgcolor: '#00356b',
                                        fontSize: '2rem'
                                    }}
                                >
                                    {group.name.charAt(0)}
                                </Avatar>
                                <Box sx={{ ml: 2, flex: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        {group.privacy === 'private' ? (
                                            <LockIcon sx={{ mr: 1, color: '#718096' }} />
                                        ) : (
                                            <PublicIcon sx={{ mr: 1, color: '#718096' }} />
                                        )}
                                        <Typography variant="h4" sx={{ fontWeight: 700, color: '#1a365d' }}>
                                            {group.name}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                                        {group.description}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <GroupIcon sx={{ mr: 0.5, color: '#718096' }} />
                                        <Typography variant="body2" color="text.secondary">
                                            {group.memberCount} members
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                                <AuthWrapper>
                                    <Button
                                        variant="contained"
                                        startIcon={<GroupIcon />}
                                        onClick={() => {/* Join group logic */}}
                                        sx={{
                                            backgroundColor: '#00356b',
                                            '&:hover': { backgroundColor: '#002548' },
                                        }}
                                    >
                                        Join Group
                                    </Button>
                                </AuthWrapper>
                                <AuthWrapper>
                                    <Button
                                        variant="outlined"
                                        startIcon={<ShareIcon />}
                                        onClick={handleShare}
                                    >
                                        Share
                                    </Button>
                                </AuthWrapper>
                            </Box>

                            <AuthWrapper>
                                <Tabs
                                    value={currentTab}
                                    onChange={(_, newValue) => setCurrentTab(newValue)}
                                    sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
                                >
                                    <Tab label="About" value="about" />
                                    <Tab label="Discussion" value="discussion" />
                                    <Tab label="Events" value="events" />
                                    <Tab label="Members" value="members" />
                                </Tabs>
                            </AuthWrapper>

                            {currentTab === 'discussion' && (
                                <Box>
                                    <AuthWrapper>
                                        <Paper sx={{ p: 2, mb: 3, borderRadius: '8px' }}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={3}
                                                placeholder="Start a discussion..."
                                                value={newPost}
                                                onChange={(e) => setNewPost(e.target.value)}
                                                sx={{ mb: 2 }}
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="contained"
                                                    endIcon={<SendIcon />}
                                                    onClick={handleCreatePost}
                                                    disabled={!newPost.trim()}
                                                    sx={{
                                                        bgcolor: '#00356b',
                                                        '&:hover': { bgcolor: '#002548' },
                                                    }}
                                                >
                                                    Post
                                                </Button>
                                            </Box>
                                        </Paper>
                                    </AuthWrapper>

                                    {posts.map((post) => (
                                        <Paper key={post.id} sx={{ p: 2, mb: 2, borderRadius: '8px' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                                <Avatar
                                                    sx={{
                                                        width: 40,
                                                        height: 40,
                                                        bgcolor: post.author?.avatar_color || '#00356b',
                                                        mr: 1
                                                    }}
                                                >
                                                    {post.author?.name?.charAt(0)}
                                                </Avatar>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                                        {post.author?.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {new Date(post.created_at).toLocaleDateString()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography variant="body1">{post.content}</Typography>
                                        </Paper>
                                    ))}
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default GroupPage; 