import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { PageHeader, DescriptiveText } from '../components/ui/typography.jsx';
import { BsUpload, BsPersonBadge } from 'react-icons/bs';
import { IdCardService } from '../services/IdCardService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import Barcode from 'react-barcode';
import { cardStyles } from '../styles/theme/components/cards';

const IdGeneration = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
        return;
      }
      if (user) {
        setUserId(user.id);
      }
    };
    fetchUserId();
  }, []);

  const [formData, setFormData] = useState({
    type: 'student',
    firstName: '',
    lastName: '',
    grade: '',
    schoolName: '',
    schoolLogo: null,
    schoolAddress: '',
    schoolPhone: '',
    photo: null,
    expirationDate: '',
    userId: null,
  });

  const [previewUrl, setPreviewUrl] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef();
  const logoInputRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        photo: file
      }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        schoolLogo: file
      }));
      setLogoPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const submitData = {
        ...formData,
        userId: userId,
      };
      await IdCardService.createIdCard(submitData);
      toast.success('ID card generated successfully!');
      navigate('/id-generation/view');
    } catch (error) {
      toast.error('Failed to generate ID card');
      console.error('Error generating ID card:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f8fafc' }}>
      {/* Back button section */}
      <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2 }}>
        <Container maxWidth="lg">
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/id-generation/view')}
            sx={{ color: '#00356b' }}
          >
            BACK TO GENERATED IDS
          </Button>
        </Container>
      </Box>

      {/* Title section with gray background */}
      <Box sx={{ backgroundColor: '#f8fafc', py: 6, borderBottom: '1px solid #e2e8f0' }}>
        <Container maxWidth="lg">
          <PageHeader sx={{ color: '#1a202c', mb: 2 }}>
            Student ID Generation
          </PageHeader>
          <DescriptiveText sx={{ color: '#4a5568', maxWidth: '65ch' }}>
            Generate official student identification cards for your homeschool students. These IDs can be used for educational discounts, library access, and other student services.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <div style={styles.content}>
          <div style={styles.previewSection}>
            <div style={styles.idPreview}>
              <h3 style={styles.previewTitle}>ID Preview</h3>
              <div style={styles.idCard}>
                {logoPreviewUrl && (
                  <img 
                    src={logoPreviewUrl} 
                    alt="School Logo" 
                    style={styles.previewLogo}
                  />
                )}
                {previewUrl ? (
                  <img 
                    src={previewUrl} 
                    alt="ID Photo" 
                    style={styles.previewPhoto}
                  />
                ) : (
                  <div style={styles.photoPlaceholder}>
                    <BsPersonBadge size={40} />
                  </div>
                )}
                <div style={styles.idInfo}>
                  <h4>{formData.schoolName || 'School Name'}</h4>
                  <p>{formData.firstName} {formData.lastName}</p>
                  {formData.type === 'student' && (
                    <p>Grade: {formData.grade || 'N/A'}</p>
                  )}
                  <p>{formData.type.toUpperCase()}</p>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>ID Type</h3>
              <div style={styles.radioGroup}>
                <label style={styles.radio}>
                  <input
                    type="radio"
                    name="type"
                    value="student"
                    checked={formData.type === 'student'}
                    onChange={handleInputChange}
                  />
                  Student ID
                </label>
                <label style={styles.radio}>
                  <input
                    type="radio"
                    name="type"
                    value="teacher"
                    checked={formData.type === 'teacher'}
                    onChange={handleInputChange}
                  />
                  Teacher ID
                </label>
              </div>
            </div>

            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>Personal Information</h3>
              <div style={styles.formGrid}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                {formData.type === 'student' && (
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Grade</label>
                    <input
                      type="text"
                      name="grade"
                      value={formData.grade}
                      onChange={handleInputChange}
                      style={styles.input}
                      required
                    />
                  </div>
                )}
              </div>
            </div>

            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>School Information</h3>
              <div style={styles.formGrid}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>School Name</label>
                  <input
                    type="text"
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>School Address</label>
                  <input
                    type="text"
                    name="schoolAddress"
                    value={formData.schoolAddress}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>School Phone</label>
                  <input
                    type="tel"
                    name="schoolPhone"
                    value={formData.schoolPhone}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Expiration Date</label>
                  <input
                    type="date"
                    name="expirationDate"
                    value={formData.expirationDate}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>

            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>Upload Files</h3>
              <div style={styles.uploadGrid}>
                <div style={styles.uploadGroup}>
                  <label style={styles.uploadLabel}>
                    <div style={styles.uploadArea}>
                      <BsUpload size={24} />
                      <span>Upload Photo</span>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handlePhotoUpload}
                        accept="image/*"
                        style={styles.hiddenInput}
                      />
                    </div>
                  </label>
                  {previewUrl && (
                    <button
                      type="button"
                      onClick={() => {
                        setPreviewUrl(null);
                        setFormData(prev => ({ ...prev, photo: null }));
                      }}
                      style={styles.removeButton}
                    >
                      Remove Photo
                    </button>
                  )}
                </div>

                <div style={styles.uploadGroup}>
                  <label style={styles.uploadLabel}>
                    <div style={styles.uploadArea}>
                      <BsUpload size={24} />
                      <span>Upload School Logo</span>
                      <input
                        type="file"
                        ref={logoInputRef}
                        onChange={handleLogoUpload}
                        accept="image/*"
                        style={styles.hiddenInput}
                      />
                    </div>
                  </label>
                  {logoPreviewUrl && (
                    <button
                      type="button"
                      onClick={() => {
                        setLogoPreviewUrl(null);
                        setFormData(prev => ({ ...prev, schoolLogo: null }));
                      }}
                      style={styles.removeButton}
                    >
                      Remove Logo
                    </button>
                  )}
                </div>
              </div>
            </div>

            <button 
              type="submit" 
              style={{
                ...styles.submitButton,
                opacity: isLoading ? 0.7 : 1,
                cursor: isLoading ? 'not-allowed' : 'pointer'
              }}
              disabled={isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate ID Card'}
            </button>
          </form>
        </div>
      </Container>
    </Box>
  );
};

const styles = {
  container: {
    padding: '24px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gap: '32px',
    marginTop: '24px',
  },
  previewSection: {
    position: 'sticky',
    top: '24px',
  },
  idPreview: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  previewTitle: {
    margin: '0 0 16px 0',
    fontSize: '16px',
    fontWeight: '600',
    color: '#2D3748',
  },
  idCard: {
    width: '100%',
    aspectRatio: '1.586',
    backgroundColor: '#f7fafc',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    border: '1px solid #E2E8F0',
  },
  previewLogo: {
    width: '60px',
    height: '60px',
    objectFit: 'contain',
  },
  previewPhoto: {
    width: '120px',
    height: '120px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  photoPlaceholder: {
    width: '120px',
    height: '120px',
    borderRadius: '8px',
    backgroundColor: '#EDF2F7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#A0AEC0',
  },
  idInfo: {
    textAlign: 'center',
    width: '100%',
  },
  form: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  formSection: {
    marginBottom: '32px',
  },
  sectionTitle: {
    margin: '0 0 16px 0',
    fontSize: '16px',
    fontWeight: '600',
    color: '#2D3748',
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '16px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  label: {
    fontSize: '14px',
    color: '#4A5568',
    fontWeight: '500',
  },
  input: {
    padding: '8px 12px',
    borderRadius: '6px',
    border: '1px solid #E2E8F0',
    fontSize: '14px',
    color: '#2D3748',
    '&:focus': {
      outline: 'none',
      borderColor: '#3182CE',
      boxShadow: '0 0 0 1px #3182CE',
    },
  },
  radioGroup: {
    display: 'flex',
    gap: '16px',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#4A5568',
    cursor: 'pointer',
  },
  uploadGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '16px',
  },
  uploadGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  uploadLabel: {
    cursor: 'pointer',
  },
  uploadArea: {
    border: '2px dashed #E2E8F0',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    color: '#4A5568',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: '#3182CE',
      color: '#3182CE',
    },
  },
  hiddenInput: {
    display: 'none',
  },
  removeButton: {
    padding: '8px',
    backgroundColor: '#FED7D7',
    color: '#C53030',
    border: 'none',
    borderRadius: '4px',
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FEB2B2',
    },
  },
  submitButton: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#3182CE',
    color: '#ffffff',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#2C5282',
    },
  },
};

export default IdGeneration; 