import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { PageHeader, DescriptiveText } from '../components/ui/typography.jsx';
import { BsDownload } from 'react-icons/bs';
import { WorkPermitService } from '../services/WorkPermitService';
import { toast } from 'react-toastify';
import { cardStyles } from '../styles/theme/components/cards';

const WorkPermits = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentName: '',
    dateOfBirth: '',
    address: '',
    phoneNumber: '',
    employerName: '',
    employerAddress: '',
    employerPhone: '',
    jobTitle: '',
    workSchedule: '',
    startDate: '',
    parentName: '',
    parentPhone: '',
    parentEmail: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await WorkPermitService.createWorkPermit(formData);
      toast.success('Work permit generated successfully!');
      navigate('/work-permit/view');
    } catch (error) {
      toast.error('Failed to generate work permit');
      console.error('Error generating work permit:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f8fafc' }}>
      {/* Back button section */}
      <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2 }}>
        <Container maxWidth="lg">
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/work-permit/view')}
            sx={{ color: '#00356b' }}
          >
            BACK TO GENERATED WORK PERMITS
          </Button>
        </Container>
      </Box>

      {/* Title section with gray background */}
      <Box sx={{ backgroundColor: '#f8fafc', py: 6, borderBottom: '1px solid #e2e8f0' }}>
        <Container maxWidth="lg">
          <PageHeader sx={{ color: '#1a202c', mb: 2 }}>
            Work Permit Applications
          </PageHeader>
          <DescriptiveText sx={{ color: '#4a5568', maxWidth: '65ch' }}>
            Apply for and manage work permits for your homeschool students. Generate the necessary documentation for student employment opportunities.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <div style={styles.content}>
          <div style={styles.previewSection}>
            <div style={styles.permitPreview}>
              <h3 style={styles.previewTitle}>Permit Preview</h3>
              <div style={styles.previewContent}>
                <h4 style={styles.previewHeader}>STUDENT WORK PERMIT</h4>
                <div style={styles.previewField}>
                  <span style={styles.previewLabel}>Student Name:</span>
                  <span style={styles.previewValue}>{formData.studentName || '_______________'}</span>
                </div>
                <div style={styles.previewField}>
                  <span style={styles.previewLabel}>Employer:</span>
                  <span style={styles.previewValue}>{formData.employerName || '_______________'}</span>
                </div>
                <div style={styles.previewField}>
                  <span style={styles.previewLabel}>Job Title:</span>
                  <span style={styles.previewValue}>{formData.jobTitle || '_______________'}</span>
                </div>
                <div style={styles.previewField}>
                  <span style={styles.previewLabel}>Start Date:</span>
                  <span style={styles.previewValue}>
                    {formData.startDate ? new Date(formData.startDate).toLocaleDateString() : '_______________'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>Student Information</h3>
              <div style={styles.formGrid}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Full Name</label>
                  <input
                    type="text"
                    name="studentName"
                    value={formData.studentName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Date of Birth</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Phone Number</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>

            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>Employer Information</h3>
              <div style={styles.formGrid}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Employer Name</label>
                  <input
                    type="text"
                    name="employerName"
                    value={formData.employerName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Employer Address</label>
                  <input
                    type="text"
                    name="employerAddress"
                    value={formData.employerAddress}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Employer Phone</label>
                  <input
                    type="tel"
                    name="employerPhone"
                    value={formData.employerPhone}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Job Title</label>
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Work Schedule</label>
                  <input
                    type="text"
                    name="workSchedule"
                    value={formData.workSchedule}
                    onChange={handleInputChange}
                    style={styles.input}
                    placeholder="e.g., Mon-Fri 4pm-8pm"
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Start Date</label>
                  <input
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>

            <div style={styles.formSection}>
              <h3 style={styles.sectionTitle}>Parent/Guardian Information</h3>
              <div style={styles.formGrid}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Parent/Guardian Name</label>
                  <input
                    type="text"
                    name="parentName"
                    value={formData.parentName}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Parent/Guardian Phone</label>
                  <input
                    type="tel"
                    name="parentPhone"
                    value={formData.parentPhone}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Parent/Guardian Email</label>
                  <input
                    type="email"
                    name="parentEmail"
                    value={formData.parentEmail}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>

            <button 
              type="submit" 
              style={{
                ...styles.submitButton,
                opacity: isLoading ? 0.7 : 1,
                cursor: isLoading ? 'not-allowed' : 'pointer'
              }}
              disabled={isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate Work Permit'}
            </button>
          </form>
        </div>
      </Container>
    </Box>
  );
};

const styles = {
  container: {
    padding: '24px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gap: '32px',
    marginTop: '24px',
  },
  previewSection: {
    position: 'sticky',
    top: '24px',
  },
  permitPreview: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  previewTitle: {
    margin: '0 0 16px 0',
    fontSize: '16px',
    fontWeight: '600',
    color: '#2D3748',
  },
  previewContent: {
    backgroundColor: '#f7fafc',
    padding: '24px',
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
  },
  previewHeader: {
    textAlign: 'center',
    margin: '0 0 24px 0',
    fontSize: '18px',
    fontWeight: '600',
    color: '#2D3748',
  },
  previewField: {
    marginBottom: '16px',
    borderBottom: '1px dashed #E2E8F0',
    paddingBottom: '8px',
  },
  previewLabel: {
    fontSize: '14px',
    color: '#4A5568',
    fontWeight: '500',
    marginRight: '8px',
  },
  previewValue: {
    fontSize: '14px',
    color: '#2D3748',
  },
  form: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  formSection: {
    marginBottom: '32px',
  },
  sectionTitle: {
    margin: '0 0 16px 0',
    fontSize: '16px',
    fontWeight: '600',
    color: '#2D3748',
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '16px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  label: {
    fontSize: '14px',
    color: '#4A5568',
    fontWeight: '500',
  },
  input: {
    padding: '8px 12px',
    borderRadius: '6px',
    border: '1px solid #E2E8F0',
    fontSize: '14px',
    color: '#2D3748',
    '&:focus': {
      outline: 'none',
      borderColor: '#3182CE',
      boxShadow: '0 0 0 1px #3182CE',
    },
  },
  submitButton: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#3182CE',
    color: '#ffffff',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#2C5282',
    },
  },
};

export default WorkPermits; 