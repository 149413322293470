import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Paper } from '@mui/material';
import theme from '../styles/theme';
import { cardStyles } from '../styles/theme/components/cards';
import {
  PageHeader,
  FeatureHeader,
  DescriptiveText,
  SupportingText,
} from '../components/ui/typography.jsx';
import {
  School as SchoolIcon,
  Description as DocumentIcon,
  Badge as IdCardIcon,
  Work as WorkIcon,
  Assessment as TranscriptIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

const MyHomeschool = () => {
  const navigate = useNavigate();

  const features = [
    {
      id: 'record-keeping',
      title: 'Record Keeping',
      description: 'Organize and manage your homeschool documents and records in one place.',
      icon: SchoolIcon,
      path: '/record-keeping',
      color: `hsl(var(--secondary-green))`,
      bgColor: `hsl(var(--secondary-green-light))`,
      stats: [
        { label: 'Documents', value: '150+' },
        { label: 'Categories', value: '8' },
      ],
    },
    {
      id: 'document-generation',
      title: 'Document Generation',
      description: 'Generate essential documents for your homeschool journey.',
      icon: DocumentIcon,
      color: `hsl(var(--brand-primary))`,
      bgColor: `hsl(var(--brand-primary-light))`,
      subFeatures: [
        {
          id: 'student-teacher-ids',
          title: 'Student/Teacher IDs',
          description: 'Create official identification cards for students and teachers.',
          icon: IdCardIcon,
          path: '/id-generation/view',
          color: `hsl(var(--secondary-orange))`,
          bgColor: `hsl(var(--secondary-orange-light))`,
          highlight: 'Most Popular',
        },
        {
          id: 'work-permits',
          title: 'Work Permits',
          description: 'Generate and manage student work permits.',
          icon: WorkIcon,
          path: '/work-permit/view',
          color: `hsl(var(--secondary-purple))`,
          bgColor: `hsl(var(--secondary-purple-light))`,
        },
        {
          id: 'transcript',
          title: 'Transcript',
          description: 'Create and maintain official academic transcripts.',
          icon: TranscriptIcon,
          path: '/transcript',
          color: `hsl(var(--brand-primary))`,
          bgColor: `hsl(var(--brand-primary-light))`,
          highlight: 'Essential',
        },
      ],
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh', margin: 0 }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            My Homeschool
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Access and manage all your homeschool resources in one place. From record keeping to document generation,
            everything you need is right here.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={3}>
          {features.map((feature) => (
            <Grid item xs={12} md={6} key={feature.id}>
              <Paper
                elevation={0}
                sx={{
                  ...cardStyles.feature,
                }}
              >
                {/* Card Header */}
                <Box sx={{ p: 'var(--spacing-6)', pb: 'var(--spacing-4)' }}>
                  <Box sx={{ display: 'flex', alignItems: 'start', gap: 'var(--spacing-4)' }}>
                    <Box
                      sx={{
                        p: 'var(--spacing-3)',
                        borderRadius: 'var(--radius-lg)',
                        backgroundColor: feature.bgColor,
                        color: feature.color,
                        transition: 'all 0.2s',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      <feature.icon />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <FeatureHeader>{feature.title}</FeatureHeader>
                      <SupportingText>{feature.description}</SupportingText>
                    </Box>
                  </Box>

                  {/* Stats Section */}
                  {feature.stats && (
                    <Box sx={{ mt: 'var(--spacing-6)', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--spacing-3)' }}>
                      {feature.stats.map((stat) => (
                        <Box
                          key={stat.label}
                          sx={{
                            p: 'var(--spacing-3)',
                            borderRadius: 'var(--radius-sm)',
                            backgroundColor: `hsl(var(--neutral-50))`,
                          }}
                        >
                          <Box sx={{ fontSize: '1.5rem', fontWeight: 600, lineHeight: 1, mb: 'var(--spacing-1)' }}>
                            {stat.value}
                          </Box>
                          <SupportingText>{stat.label}</SupportingText>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {/* Sub-features Section */}
                {feature.subFeatures && (
                  <Box sx={{ borderTop: '1px solid', borderColor: `hsl(var(--neutral-100))` }}>
                    <Box sx={{ p: 'var(--spacing-6)', display: 'flex', flexDirection: 'column', gap: 'var(--spacing-4)' }}>
                      {feature.subFeatures.map((subFeature) => (
                        <Paper
                          key={subFeature.id}
                          elevation={0}
                          onClick={() => navigate(subFeature.path)}
                          sx={{
                            ...cardStyles.subFeature,
                          }}
                        >
                          <Box
                            sx={{
                              p: 'var(--spacing-2)',
                              borderRadius: 'var(--radius-icon)',
                              backgroundColor: subFeature.bgColor,
                              color: subFeature.color,
                            }}
                          >
                            <subFeature.icon />
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-2)' }}>
                              <FeatureHeader>{subFeature.title}</FeatureHeader>
                              {subFeature.highlight && (
                                <Box
                                  sx={{
                                    fontSize: '0.75rem',
                                    px: 'var(--spacing-2)',
                                    py: 'var(--spacing-1)',
                                    borderRadius: 'var(--radius-badge)',
                                    backgroundColor: subFeature.bgColor,
                                    color: subFeature.color,
                                  }}
                                >
                                  {subFeature.highlight}
                                </Box>
                              )}
                            </Box>
                            <SupportingText>{subFeature.description}</SupportingText>
                          </Box>
                          <ArrowForwardIcon
                            className="arrow-icon"
                            sx={{
                              color: `hsl(var(--text-secondary))`,
                            }}
                          />
                        </Paper>
                      ))}
                    </Box>
                  </Box>
                )}

                {/* Direct Access Button */}
                {feature.path && (
                  <Box sx={{ p: 'var(--spacing-6)' }}>
                    <Paper
                      elevation={0}
                      onClick={() => navigate(feature.path)}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 'var(--spacing-2)',
                        p: 'var(--spacing-2) var(--spacing-4)',
                        borderRadius: 'var(--radius-button)',
                        backgroundColor: `hsl(var(--brand-primary))`,
                        color: 'white',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: `hsl(var(--brand-primary-dark))`,
                        },
                      }}
                    >
                      Access {feature.title}
                      <ArrowForwardIcon />
                    </Paper>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MyHomeschool; 