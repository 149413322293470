import { supabase } from '../supabase/client';

export const CourseDescriptionService = {
  async getCourseDescriptions() {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('course_descriptions')
        .select('*')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;

      if (!data) {
        return {
          freshman: [],
          sophomore: [],
          junior: [],
          senior: []
        };
      }

      return {
        freshman: data.freshman || [],
        sophomore: data.sophomore || [],
        junior: data.junior || [],
        senior: data.senior || []
      };
    } catch (error) {
      console.error('Error in getCourseDescriptions:', error);
      throw error;
    }
  },

  async saveCourseDescriptions(descriptions) {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No user found');

      const { error } = await supabase
        .from('course_descriptions')
        .upsert({
          user_id: user.id,
          freshman: descriptions.freshman || [],
          sophomore: descriptions.sophomore || [],
          junior: descriptions.junior || [],
          senior: descriptions.senior || []
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      return descriptions;
    } catch (error) {
      console.error('Error in saveCourseDescriptions:', error);
      throw error;
    }
  }
}; 