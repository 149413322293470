import React, { useState } from 'react'
import {
  Container,
  Grid,
  Paper,
  Box,
  Button,
  Chip,
  LinearProgress,
  IconButton,
} from '@mui/material'
import {
  School as SchoolIcon,
  Assessment as AssessmentIcon,
  MenuBook as MenuBookIcon,
  ChevronRight as ChevronRightIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useAuth } from '../utils/AuthContext'
import { PageHeader, SectionHeader, FeatureHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography'
import { cardStyles } from '../styles/theme/components/cards'

// Mock data for students
const mockStudents = [
  {
    id: 1,
    name: 'OBI-WAN KENOBI',
    grade: '9TH GRADE',
    currentCourses: [
      { name: 'Algebra I', progress: 85, status: 'on-track' },
      { name: 'Biology', progress: 92, status: 'on-track' },
      { name: 'World History', progress: 78, status: 'attention' },
      { name: 'English Literature', progress: 88, status: 'on-track' },
    ],
    recentGrades: [
      { assignment: 'Math Quiz 3', grade: 'A-', date: '2024-01-20' },
      { assignment: 'Biology Lab Report', grade: 'A', date: '2024-01-18' },
      { assignment: 'History Essay', grade: 'B+', date: '2024-01-15' },
    ],
    upcomingAssignments: [
      { name: 'Math Chapter 4 Test', due: '2024-01-25' },
      { name: 'Biology Midterm', due: '2024-01-28' },
      { name: 'History Project', due: '2024-02-01' },
    ],
  },
  {
    id: 2,
    name: 'ANAKIN SKYWALKER',
    grade: '11TH GRADE',
    currentCourses: [
      { name: 'Pre-Calculus', progress: 90, status: 'on-track' },
      { name: 'Physics', progress: 95, status: 'on-track' },
      { name: 'American History', progress: 82, status: 'on-track' },
      { name: 'AP English', progress: 88, status: 'on-track' },
    ],
    recentGrades: [
      { assignment: 'Physics Lab', grade: 'A', date: '2024-01-19' },
      { assignment: 'Calculus Quiz', grade: 'A-', date: '2024-01-17' },
      { assignment: 'English Essay', grade: 'B+', date: '2024-01-14' },
    ],
    upcomingAssignments: [
      { name: 'Physics Midterm', due: '2024-01-26' },
      { name: 'English Research Paper', due: '2024-01-29' },
      { name: 'Math Final', due: '2024-02-02' },
    ],
  },
]

const HomeParentAcademics = () => {
  const { user } = useAuth()
  const [selectedStudent, setSelectedStudent] = useState(mockStudents[0])

  const getStatusColor = (status) => {
    switch (status) {
      case 'on-track':
        return 'hsl(var(--secondary-green))'
      case 'attention':
        return 'hsl(var(--secondary-orange))'
      case 'behind':
        return 'hsl(var(--destructive))'
      default:
        return 'hsl(var(--muted-foreground))'
    }
  }

  const getStatusBgColor = (status) => {
    switch (status) {
      case 'on-track':
        return 'hsl(var(--secondary-green-light))'
      case 'attention':
        return 'hsl(var(--secondary-orange-light))'
      case 'behind':
        return 'hsl(var(--destructive) / 0.1)'
      default:
        return 'hsl(var(--muted))'
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
  }

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Academic Overview
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Track your students' academic progress, view upcoming assignments, and monitor their performance across all
            subjects.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={3}>
          {/* Student Selection */}
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                ...cardStyles.feature,
                p: 'var(--spacing-4)',
                mb: 'var(--spacing-4)',
              }}
            >
              <Box sx={{ display: 'flex', gap: 'var(--spacing-2)' }}>
                {mockStudents.map((student) => (
                  <Button
                    key={student.id}
                    variant={selectedStudent.id === student.id ? 'contained' : 'outlined'}
                    onClick={() => setSelectedStudent(student)}
                    sx={{
                      borderRadius: 'var(--radius-lg)',
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-2)',
                      backgroundColor: selectedStudent.id === student.id ? 'hsl(var(--brand-primary))' : 'transparent',
                      borderColor: 'hsl(var(--brand-primary))',
                      color: selectedStudent.id === student.id ? 'hsl(var(--background))' : 'hsl(var(--brand-primary))',
                      '&:hover': {
                        backgroundColor:
                          selectedStudent.id === student.id
                            ? 'hsl(var(--brand-primary-dark))'
                            : 'hsl(var(--brand-primary-light))',
                      },
                    }}
                  >
                    <Box sx={{ textAlign: 'left' }}>
                      <FeatureHeader sx={{ 
                        color: selectedStudent.id === student.id ? 'inherit' : 'hsl(var(--text-primary))',
                        mb: 'var(--spacing-1)'
                      }}>
                        {student.name}
                      </FeatureHeader>
                      <SupportingText sx={{ 
                        color: selectedStudent.id === student.id ? 'inherit' : 'hsl(var(--text-secondary))',
                        opacity: 0.8 
                      }}>
                        {student.grade}
                      </SupportingText>
                    </Box>
                  </Button>
                ))}
              </Box>
            </Paper>
          </Grid>

          {/* Main Content Area */}
          <Grid item xs={12} md={8}>
            {/* Current Courses */}
            <Paper
              elevation={0}
              sx={{
                ...cardStyles.feature,
                mb: 'var(--spacing-4)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 'var(--spacing-4)',
                  px: 'var(--spacing-4)',
                  pt: 'var(--spacing-4)',
                }}
              >
                <SectionHeader>Current Courses</SectionHeader>
                <Button
                  startIcon={<MenuBookIcon />}
                  component={Link}
                  to="/courses"
                  sx={{
                    color: 'hsl(var(--brand-primary))',
                    '&:hover': { backgroundColor: 'hsl(var(--brand-primary-light))' },
                  }}
                >
                  View All Courses
                </Button>
              </Box>
              <Box sx={{ px: 'var(--spacing-4)', pb: 'var(--spacing-4)' }}>
                <Grid container spacing={3}>
                  {selectedStudent.currentCourses.map((course, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Paper
                        sx={{
                          p: 'var(--spacing-4)',
                          borderRadius: 'var(--radius-lg)',
                          border: '1px solid hsl(var(--border))',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: 'var(--shadow-lg)',
                            borderColor: getStatusColor(course.status),
                          },
                        }}
                      >
                        <Box sx={{ mb: 'var(--spacing-3)' }}>
                          <FeatureHeader sx={{ mb: 'var(--spacing-2)' }}>{course.name}</FeatureHeader>
                          <Chip
                            label={course.status === 'on-track' ? 'On Track' : 'Needs Attention'}
                            size="small"
                            sx={{
                              backgroundColor: getStatusBgColor(course.status),
                              color: getStatusColor(course.status),
                              fontWeight: 600,
                              borderRadius: 'var(--radius-full)',
                            }}
                          />
                        </Box>
                        <Box sx={{ mb: 'var(--spacing-2)' }}>
                          <SupportingText sx={{ mb: 'var(--spacing-2)' }}>
                            Progress
                          </SupportingText>
                          <LinearProgress
                            variant="determinate"
                            value={course.progress}
                            sx={{
                              height: 8,
                              borderRadius: 'var(--radius-full)',
                              backgroundColor: 'hsl(var(--background-alt))',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: getStatusColor(course.status),
                              },
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <BodyText sx={{ fontWeight: 600 }}>
                            {course.progress}% Complete
                          </BodyText>
                          <IconButton 
                            size="small"
                            sx={{
                              color: 'hsl(var(--text-secondary))',
                              '&:hover': {
                                backgroundColor: 'hsl(var(--background-alt))',
                              }
                            }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Paper>

            {/* Recent Grades */}
            <Paper
              elevation={0}
              sx={{
                ...cardStyles.feature,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 'var(--spacing-4)',
                  px: 'var(--spacing-4)',
                  pt: 'var(--spacing-4)',
                }}
              >
                <SectionHeader>Recent Grades</SectionHeader>
                <Button
                  startIcon={<AssessmentIcon />}
                  component={Link}
                  to="/grades"
                  sx={{
                    color: 'hsl(var(--brand-primary))',
                    '&:hover': { backgroundColor: 'hsl(var(--brand-primary-light))' },
                  }}
                >
                  View All Grades
                </Button>
              </Box>
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 'var(--spacing-2)',
                  px: 'var(--spacing-4)',
                  pb: 'var(--spacing-4)',
                }}
              >
                {selectedStudent.recentGrades.map((grade, index) => (
                  <Paper
                    key={index}
                    sx={{
                      p: 'var(--spacing-3)',
                      borderRadius: 'var(--radius-lg)',
                      backgroundColor: 'hsl(var(--background))',
                      border: '1px solid hsl(var(--border))',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'hsl(var(--background))',
                        transform: 'translateX(4px)',
                        borderColor: 'hsl(var(--brand-primary))',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box>
                        <FeatureHeader sx={{ mb: 'var(--spacing-1)' }}>{grade.assignment}</FeatureHeader>
                        <SupportingText>{formatDate(grade.date)}</SupportingText>
                      </Box>
                      <Chip
                        label={grade.grade}
                        size="small"
                        sx={{
                          backgroundColor: 'hsl(var(--brand-primary-light))',
                          color: 'hsl(var(--brand-primary))',
                          fontWeight: 600,
                          minWidth: '48px',
                          borderRadius: 'var(--radius-full)',
                        }}
                      />
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>

          {/* Right Sidebar */}
          <Grid item xs={12} md={4}>
            {/* Upcoming Assignments */}
            <Paper
              elevation={0}
              sx={{
                ...cardStyles.feature,
              }}
            >
              <Box sx={{ px: 'var(--spacing-4)', pt: 'var(--spacing-4)', pb: 'var(--spacing-2)' }}>
                <SectionHeader>Upcoming Assignments</SectionHeader>
              </Box>
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 'var(--spacing-2)',
                  px: 'var(--spacing-4)',
                  pb: 'var(--spacing-4)',
                }}
              >
                {selectedStudent.upcomingAssignments.map((assignment, index) => (
                  <Paper
                    key={index}
                    sx={{
                      p: 'var(--spacing-3)',
                      borderRadius: 'var(--radius-lg)',
                      backgroundColor: 'hsl(var(--background))',
                      border: '1px solid hsl(var(--border))',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'hsl(var(--background))',
                        transform: 'translateX(4px)',
                        borderColor: 'hsl(var(--brand-primary))',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <Box>
                        <FeatureHeader sx={{ mb: 'var(--spacing-2)' }}>{assignment.name}</FeatureHeader>
                        <Chip
                          icon={<ScheduleIcon sx={{ fontSize: '16px !important' }} />}
                          label={`Due ${formatDate(assignment.due)}`}
                          size="small"
                          sx={{
                            backgroundColor: 'hsl(var(--brand-primary-light))',
                            color: 'hsl(var(--brand-primary))',
                            fontWeight: 500,
                            borderRadius: 'var(--radius-full)',
                          }}
                        />
                      </Box>
                      <IconButton 
                        size="small"
                        sx={{
                          color: 'hsl(var(--text-secondary))',
                          '&:hover': {
                            backgroundColor: 'hsl(var(--background-alt))',
                          }
                        }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeParentAcademics
