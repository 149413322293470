import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../utils/AuthContext'
import { supabase } from '../supabase/utils/supabaseClient'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material'
import { GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import logo from '../assets/logo.png'
import {
  AiOutlineHome,
  AiOutlineFileText,
  AiOutlineSearch,
  AiOutlineSchedule,
  AiOutlineShop,
  AiOutlineSetting,
  AiOutlineDown,
  AiOutlineRight,
  AiOutlineIdcard,
} from 'react-icons/ai'
import {
  BsCalendar,
  BsBook,
  BsBuilding,
  BsCash,
  BsListCheck,
  BsTrophy,
  BsBriefcase,
  BsMortarboard,
  BsCompass,
  BsShieldCheck,
  BsChatDots,
  BsFileText,
  BsPeople,
  BsGear,
  BsHouseDoor,
  BsFolder,
  BsPersonBadge,
  BsFileEarmarkText,
} from 'react-icons/bs'
import { hslToVar } from '../styles/theme'
import { BodyText, SupportingText } from './ui/typography'
import RegistrationPrompt from './RegistrationPrompt'
import AuthWrapper from '../components/AuthWrapper'
import { theme } from '../theme/theme'

const NavItem = ({ icon: Icon, label, to, isActive, onClick, hasChildren, isOpen }) => {
  const navigate = useNavigate()

  return (
    <AuthWrapper>
      <Box
        sx={{
          cursor: 'pointer',
          backgroundColor: isActive ? 'rgba(65, 105, 225, 0.12)' : 'transparent',
          '&:hover': {
            backgroundColor: isActive ? 'rgba(65, 105, 225, 0.12)' : 'rgba(65, 105, 225, 0.06)',
          },
          transition: 'background-color 0.2s',
        }}
        onClick={(e) => {
          e.preventDefault()
          if (to) {
            navigate(to)
          } else if (onClick) {
            onClick()
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--spacing-3)',
            px: 'var(--spacing-4)',
            py: 'var(--spacing-2)',
            color: isActive ? '#4169E1' : 'hsl(var(--text-primary))',
          }}
        >
          {Icon && <Icon style={{ fontSize: 20 }} />}
          <BodyText
            sx={{
              color: 'inherit',
              fontWeight: isActive ? 600 : 400,
            }}
          >
            {label}
          </BodyText>
          {hasChildren && <Box sx={{ ml: 'auto' }}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>}
        </Box>
      </Box>
    </AuthWrapper>
  )
}

const NavSection = ({ section, isActive, toggleSection, openSections }) => {
  const isOpen = openSections[section.id] === true

  if (section.children) {
    return (
      <Box>
        <AuthWrapper>
          <Box
            onClick={() => toggleSection(section.id)}
            sx={{
              cursor: 'pointer',
              backgroundColor: isActive(section.to) ? 'rgba(65, 105, 225, 0.12)' : 'transparent',
              '&:hover': {
                backgroundColor: isActive(section.to) ? 'rgba(65, 105, 225, 0.12)' : 'rgba(65, 105, 225, 0.06)',
              },
              transition: 'background-color 0.2s',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--spacing-3)',
                px: 'var(--spacing-4)',
                py: 'var(--spacing-2)',
                color: isActive(section.to) ? '#4169E1' : 'hsl(var(--text-primary))',
              }}
            >
              {section.icon && <section.icon style={{ fontSize: 20 }} />}
              <BodyText
                sx={{
                  color: 'inherit',
                  fontWeight: isActive(section.to) ? 600 : 400,
                }}
              >
                {section.label}
              </BodyText>
              <Box sx={{ ml: 'auto' }}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
            </Box>
          </Box>
        </AuthWrapper>

        <Collapse in={isOpen}>
          <List
            sx={{
              pl: 'var(--spacing-4)',
              '& .MuiListItem-root': {
                p: 0,
              },
            }}
          >
            {section.children.map((child) => (
              <ListItem key={child.id} disablePadding>
                {child.children ? (
                  <NavSection
                    section={child}
                    isActive={isActive}
                    toggleSection={toggleSection}
                    openSections={openSections}
                  />
                ) : (
                  <NavItem icon={child.icon} label={child.label} to={child.to} isActive={isActive(child.to)} />
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Box>
    )
  }

  return <NavItem icon={section.icon} label={section.label} to={section.to} isActive={isActive(section.to)} />
}

const Navbar = () => {
  const { user, logout } = useAuth()
  const location = useLocation()
  const userType = localStorage.getItem('userType') || 'highschool'
  const [openSections, setOpenSections] = useState({})
  const [selectedStudent, setSelectedStudent] = useState(null)
  const navigate = useNavigate()
  const [showRegistrationPrompt, setShowRegistrationPrompt] = useState(false)
  const [targetPath, setTargetPath] = useState('')
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const mockStudents = [
    { name: 'OBI-WAN KENOBI', grade: '9TH GRADE' },
    { name: 'ANAKIN SKYWALKER', grade: '11TH GRADE' },
  ]

  // Check if current page is public event or group page
  const isPublicEventPage = location.pathname.startsWith('/events/')
  const isPublicGroupPage = location.pathname.startsWith('/groups/')
  const shouldShowFullNav = user || isPublicEventPage || isPublicGroupPage

  const toggleSection = (sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }))
  }

  const isActive = (path) => {
    if (!path) return false
    if (path === '/') {
      return location.pathname === path
    }
    return location.pathname.startsWith(path)
  }

  const adminNavSections = [
    {
      id: 'home',
      label: 'Home',
      icon: AiOutlineHome,
      to: '/',
    },
    {
      id: 'homeschool',
      label: 'My Homeschool',
      icon: BsBook,
      to: '/my-homeschool',
    },
    {
      id: 'courseSearch',
      label: 'Marketplace',
      icon: AiOutlineSearch,
      to: '/course-search',
    },
    {
      id: 'compliance',
      label: 'Compliance',
      icon: BsShieldCheck,
      to: '/compliance',
    },
    {
      id: 'community',
      label: 'Community',
      icon: BsChatDots,
      to: '/community',
    },
    {
      id: 'groups',
      label: 'Groups',
      icon: BsPeople,
      to: '/groups',
    },
    {
      id: 'events',
      label: 'Events',
      icon: BsCalendar,
      to: '/events',
    },
    {
      id: 'marketplace',
      label: 'Perks',
      icon: AiOutlineShop,
      to: '/marketplace',
    },
  ]

  const studentNavSections = [
    {
      id: 'parent-academics',
      label: 'Overview',
      icon: AiOutlineHome,
      to: '/parent-academics',
    },
    {
      id: 'myCourses',
      label: 'My Courses',
      icon: BsBook,
      to: '/my-courses',
    },
    {
      id: 'coursePlanning',
      label: 'Course Planning',
      icon: BsListCheck,
      to: '/course-planning',
    },
    {
      id: 'extracurriculars',
      label: 'Extracurriculars',
      icon: BsTrophy,
      to: '/extracurriculars',
    },
    {
      id: 'college',
      label: 'College',
      icon: BsMortarboard,
      to: '/college',
    },
    {
      id: 'career',
      label: 'Career',
      icon: BsBriefcase,
      to: '/careers',
    },
  ]

  const handleNavigation = (path) => {
    if (!user) {
      setTargetPath(path)
      setShowRegistrationPrompt(true)
    } else {
      navigate(path)
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'background.paper',
        borderRight: 1,
        borderColor: 'neutral.200',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiListItem-root': {
          transition: 'all 0.2s ease',
        },
      }}
    >
      {/* Navigation Sections */}
      <Box sx={{ flex: 1, py: 'var(--spacing-4)' }}>
        {shouldShowFullNav && (
          <List
            sx={{
              p: 0,
              '& .MuiListItem-root': {
                display: 'block',
                p: 0,
              },
            }}
          >
            {/* Admin Dashboard Section */}
            {userType === 'highschool' && (
              <>
                {/* User Info Block */}
                <ListItem>
                  <Box
                    sx={{
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-3)',
                      mb: 'var(--spacing-4)',
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderRadius: 'var(--radius-sm)',
                      color: 'primary.main',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        boxShadow: '0 2px 4px rgba(65, 105, 225, 0.1)',
                        transform: 'translateY(-1px)',
                      },
                    }}
                  >
                    <BodyText
                      sx={{
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        mb: '4px',
                      }}
                    >
                      {user?.user_metadata?.name || user?.email?.split('@')[0] || 'Guest User'}
                    </BodyText>
                    <SupportingText
                      sx={{
                        textTransform: 'uppercase',
                        opacity: 0.9,
                      }}
                    >
                      {user?.user_metadata?.years_homeschooling || '0'} YEARS HOMESCHOOLING
                    </SupportingText>
                  </Box>
                </ListItem>

                {adminNavSections.map((section) => (
                  <ListItem key={section.id} sx={{ mb: 'var(--spacing-1)' }}>
                    <NavSection
                      section={section}
                      isActive={isActive}
                      toggleSection={toggleSection}
                      openSections={openSections}
                    />
                  </ListItem>
                ))}

                {/* Student Toggle Block */}
                <ListItem sx={{ mt: 'var(--spacing-6)' }}>
                  <Box
                    sx={{
                      px: 'var(--spacing-4)',
                      py: 'var(--spacing-3)',
                      mb: 'var(--spacing-4)',
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderRadius: 'var(--radius-sm)',
                      color: 'primary.main',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#e8f0fe',
                        boxShadow: '0 2px 4px rgba(65, 105, 225, 0.1)',
                        transform: 'translateY(-1px)',
                      },
                    }}
                    onClick={() => toggleSection('studentToggle')}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <BodyText
                          sx={{
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            mb: '4px',
                          }}
                        >
                          {selectedStudent?.name || mockStudents[0].name}
                        </BodyText>
                        <SupportingText
                          sx={{
                            textTransform: 'uppercase',
                            opacity: 0.9,
                          }}
                        >
                          {selectedStudent?.grade || mockStudents[0].grade}
                        </SupportingText>
                      </Box>
                      {openSections.studentToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Box>
                  </Box>
                </ListItem>

                <Collapse in={openSections.studentToggle}>
                  <List
                    sx={{
                      pl: 'var(--spacing-4)',
                      pr: 'var(--spacing-4)',
                      mb: 'var(--spacing-4)',
                    }}
                  >
                    {mockStudents.map((student, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          backgroundColor: selectedStudent === student ? 'rgba(65, 105, 225, 0.12)' : 'transparent',
                          color: selectedStudent === student ? '#4169E1' : 'inherit',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: 'rgba(65, 105, 225, 0.12)',
                            color: '#4169E1',
                            transform: 'translateY(-1px)',
                          },
                          borderRadius: 'var(--radius-sm)',
                          mb: index < mockStudents.length - 1 ? 'var(--spacing-2)' : 0,
                          py: 'var(--spacing-2)',
                        }}
                        onClick={() => {
                          setSelectedStudent(student)
                          toggleSection('studentToggle')
                        }}
                      >
                        <Box sx={{ py: 'var(--spacing-1)' }}>
                          <BodyText
                            sx={{
                              fontWeight: selectedStudent === student ? 600 : 400,
                              textTransform: 'uppercase',
                              mb: '4px',
                            }}
                          >
                            {student.name}
                          </BodyText>
                          <SupportingText
                            sx={{
                              textTransform: 'uppercase',
                              opacity: selectedStudent === student ? 1 : 0.9,
                            }}
                          >
                            {student.grade}
                          </SupportingText>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>

                {studentNavSections.map((section) => (
                  <ListItem key={section.id} sx={{ mb: 'var(--spacing-1)' }}>
                    <NavSection
                      section={section}
                      isActive={isActive}
                      toggleSection={toggleSection}
                      openSections={openSections}
                    />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        )}
      </Box>
    </Box>
  )
}

export default Navbar
