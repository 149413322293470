import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Box } from '@mui/material';
import { PageHeader, SectionHeader, FeatureHeader, BodyText, SupportingText, DescriptiveText } from '../components/ui/typography';
import theme from '../styles/theme/index';
import { cardStyles } from '../styles/theme/components/cards';
import {
  School as SchoolIcon,
  Search as SearchIcon,
  Description as DocumentIcon,
  MenuBook as MaterialsIcon,
  EmojiEvents as ScholarshipIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

const College = () => {
  const navigate = useNavigate();

  const collegeFeatures = [
    {
      id: 'college-prep',
      title: 'College Prep',
      description: 'Get comprehensive guidance and resources for college preparation.',
      icon: SchoolIcon,
      path: '/college-prep',
      color: 'hsl(var(--brand-primary))',
      bgColor: 'hsl(var(--brand-primary-light))',
      highlight: 'Most Popular',
      stats: [
        { label: 'Resources', value: '50+' },
        { label: 'Guides', value: '12' },
      ],
    },
    {
      id: 'college-search',
      title: 'College Search',
      description: 'Explore and find the perfect college match for your future.',
      icon: SearchIcon,
      path: '/colleges',
      color: 'hsl(var(--secondary-green))',
      bgColor: 'hsl(var(--secondary-green-light))',
      stats: [
        { label: 'Colleges', value: '3000+' },
        { label: 'Filters', value: '20+' },
      ],
    },
    {
      id: 'admin-materials',
      title: 'Admin Materials',
      description: 'Access and manage administrative documents for college applications.',
      icon: DocumentIcon,
      path: '/admin-materials',
      color: 'hsl(var(--secondary-orange))',
      bgColor: 'hsl(var(--secondary-orange-light))',
      stats: [
        { label: 'Templates', value: '15+' },
        { label: 'Documents', value: '25+' },
      ],
    },
    {
      id: 'student-materials',
      title: 'Student Materials',
      description: 'Essential resources and materials for college-bound students.',
      icon: MaterialsIcon,
      path: '/student-materials',
      color: 'hsl(var(--secondary-purple))',
      bgColor: 'hsl(var(--secondary-purple-light))',
      stats: [
        { label: 'Resources', value: '100+' },
        { label: 'Categories', value: '8' },
      ],
    },
    {
      id: 'scholarships',
      title: 'Scholarships',
      description: 'Discover and track scholarship opportunities.',
      icon: ScholarshipIcon,
      path: '/scholarships',
      color: 'hsl(var(--brand-primary))',
      bgColor: 'hsl(var(--brand-primary-light))',
      highlight: 'High Value',
      stats: [
        { label: 'Available', value: '500+' },
        { label: 'Total Value', value: '$2M+' },
      ],
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            College Planning
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Plan your path to college with personalized guidance, resources, and tools for success.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={4}>
          {collegeFeatures.map((feature) => (
            <Grid item xs={12} md={4} key={feature.id}>
              <Paper
                elevation={0}
                onClick={() => navigate(feature.path)}
                sx={{
                  ...cardStyles.feature,
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: feature.color,
                    transform: 'translateY(-4px)',
                    boxShadow: 'var(--shadow-lg)',
                    '& .arrow-icon': {
                      transform: 'translateX(4px)',
                    },
                    '& .icon-container': {
                      transform: 'scale(1.1)',
                    }
                  },
                }}
              >
                {/* Card Header */}
                <Box sx={{ p: 'var(--spacing-6)' }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 'var(--spacing-4)', mb: 'var(--spacing-4)' }}>
                    <Box
                      className="icon-container"
                      sx={{
                        width: 'var(--spacing-12)',
                        height: 'var(--spacing-12)',
                        borderRadius: 'var(--radius-lg)',
                        backgroundColor: feature.bgColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'transform 0.2s ease-in-out',
                        flexShrink: 0,
                      }}
                    >
                      <feature.icon sx={{ fontSize: 'var(--spacing-6)', color: feature.color }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-2)', mb: 'var(--spacing-2)' }}>
                        <FeatureHeader sx={{ color: 'hsl(var(--text-primary))' }}>
                          {feature.title}
                        </FeatureHeader>
                        {feature.highlight && (
                          <Box
                            sx={{
                              px: 'var(--spacing-2)',
                              py: 'var(--spacing-1)',
                              borderRadius: 'var(--radius-full)',
                              backgroundColor: feature.bgColor,
                              color: feature.color,
                              fontSize: 'var(--font-size-xs)',
                              fontWeight: 600,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {feature.highlight}
                          </Box>
                        )}
                      </Box>
                      <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                        {feature.description}
                      </SupportingText>
                    </Box>
                  </Box>

                  {/* Stats Section */}
                  {feature.stats && (
                    <Box 
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 'var(--spacing-3)',
                        mt: 'var(--spacing-4)',
                      }}
                    >
                      {feature.stats.map((stat) => (
                        <Box
                          key={stat.label}
                          sx={{
                            p: 'var(--spacing-3)',
                            borderRadius: 'var(--radius-lg)',
                            backgroundColor: 'hsl(var(--background-alt))',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 'var(--spacing-1)',
                          }}
                        >
                          <Box 
                            sx={{ 
                              fontSize: 'var(--font-size-xl)',
                              fontWeight: 600,
                              color: feature.color,
                              lineHeight: 1,
                            }}
                          >
                            {stat.value}
                          </Box>
                          <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                            {stat.label}
                          </SupportingText>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {/* Card Footer */}
                <Box 
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'hsl(var(--border))',
                    p: 'var(--spacing-4)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SupportingText 
                    sx={{ 
                      color: feature.color,
                      fontWeight: 600,
                      fontSize: 'var(--font-size-sm)',
                    }}
                  >
                    Learn More
                  </SupportingText>
                  <ArrowForwardIcon 
                    className="arrow-icon"
                    sx={{ 
                      color: feature.color,
                      transition: 'transform 0.2s ease-in-out',
                      fontSize: 'var(--spacing-5)',
                    }} 
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Info Section */}
        <Paper
          elevation={0}
          sx={{
            ...cardStyles.info,
            mt: 'var(--spacing-6)',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <FeatureHeader sx={{ mb: 2, color: 'hsl(var(--brand-primary))' }}>
                Why College Planning Matters
              </FeatureHeader>
              <BodyText>
                Early college planning is crucial for homeschool students. Our tools and resources help you 
                navigate the college admissions process, from test preparation to scholarship applications, 
                ensuring you're well-prepared for your higher education journey.
              </BodyText>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default College; 