import React from 'react';
import { Container, Box } from '@mui/material';
import { PageHeader, DescriptiveText } from '../components/ui/typography';
import QandASection from '../components/community/QandASection';
import { cardStyles } from '../styles/theme/components/cards';

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

const Community = () => {
    return (
        <Box sx={{ minHeight: '100vh' }}>
            {/* Hero Section */}
            <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (--tablet)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <PageHeader 
                        sx={{ 
                            mb: 'var(--spacing-2)',
                        }}
                    >
                        Community
                    </PageHeader>
                    <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                        Connect with other homeschool families, share experiences, and build lasting relationships.
                    </DescriptiveText>
                </Container>
            </Box>

            {/* Main Content */}
            <Container 
                maxWidth="var(--container-max-width)"
                sx={{ 
                    px: 'var(--container-padding-x)',
                    py: 'var(--spacing-6)',
                    '@media (--tablet)': {
                        px: 'var(--container-padding-x-mobile)',
                    },
                }}
            >
                <QandASection />
            </Container>
        </Box>
    );
};

export default Community; 