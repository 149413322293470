import { supabase } from '../supabase/utils/supabaseClient';

export class RecordKeepingService {
  async getFolders() {
    try {
      const { data: folders, error } = await supabase
        .from('folders')
        .select(`
          *,
          documentCount:documents(count)
        `)
        .order('name');

      if (error) throw error;
      
      return folders.map(folder => ({
        ...folder,
        documentCount: folder.documentCount[0].count
      }));
    } catch (error) {
      console.error('Error fetching folders:', error);
      throw error;
    }
  }

  async createFolder(name, category) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('folders')
        .insert({
          name,
          category,
          user_id: user.id,
          is_default: false
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error creating folder:', error);
      throw error;
    }
  }

  async uploadDocument(file, folderId) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Upload file to storage
      const filePath = `${user.id}/${folderId}/${file.name}`;
      const { data: storageData, error: storageError } = await supabase.storage
        .from('record-keeping-documents')
        .upload(filePath, file);

      if (storageError) throw storageError;

      // Create document record in database
      const { data: documentData, error: documentError } = await supabase
        .from('documents')
        .insert({
          name: file.name,
          file_path: filePath,
          size_kb: Math.round(file.size / 1024),
          mime_type: file.type,
          folder_id: folderId,
          user_id: user.id
        })
        .select()
        .single();

      if (documentError) throw documentError;
      return documentData;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  }

  async getDocuments(folderId = null) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      let query = supabase
        .from('documents')
        .select('*')
        .eq('user_id', user.id);

      if (folderId) {
        query = query.eq('folder_id', folderId);
      }

      const { data, error } = await query.order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
  }

  async getTotalDocumentCount() {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { count, error } = await supabase
        .from('documents')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', user.id);

      if (error) throw error;
      return count;
    } catch (error) {
      console.error('Error getting document count:', error);
      throw error;
    }
  }

  async downloadDocument(documentId) {
    try {
      // First get the document details
      const { data: document, error: docError } = await supabase
        .from('documents')
        .select('*')
        .eq('id', documentId)
        .single();

      if (docError) throw docError;

      // Get the signed URL
      const { data: { signedUrl }, error: urlError } = await supabase.storage
        .from('record-keeping-documents')
        .createSignedUrl(document.file_path, 60); // URL valid for 60 seconds

      if (urlError) throw urlError;

      // Trigger download
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error downloading document:', error);
      throw error;
    }
  }

  async deleteDocument(documentId) {
    try {
      // First get the document details to know the file path
      const { data: document, error: docError } = await supabase
        .from('documents')
        .select('*')
        .eq('id', documentId)
        .single();

      if (docError) throw docError;

      // Delete the file from storage
      const { error: storageError } = await supabase.storage
        .from('record-keeping-documents')
        .remove([document.file_path]);

      if (storageError) throw storageError;

      // Delete the document record
      const { error: deleteError } = await supabase
        .from('documents')
        .delete()
        .eq('id', documentId);

      if (deleteError) throw deleteError;
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  }

  async deleteFolder(folderId) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // First get all documents in the folder
      const { data: documents, error: docError } = await supabase
        .from('documents')
        .select('*')
        .eq('folder_id', folderId);

      if (docError) throw docError;

      // Delete all documents in the folder
      for (const document of documents) {
        // Delete file from storage
        const { error: storageError } = await supabase.storage
          .from('record-keeping-documents')
          .remove([document.file_path]);

        if (storageError) throw storageError;
      }

      // Delete all document records in the folder
      if (documents.length > 0) {
        const { error: deleteDocsError } = await supabase
          .from('documents')
          .delete()
          .eq('folder_id', folderId);

        if (deleteDocsError) throw deleteDocsError;
      }

      // Finally delete the folder
      const { error: deleteFolderError } = await supabase
        .from('folders')
        .delete()
        .eq('id', folderId)
        .eq('user_id', user.id)
        .eq('is_default', false); // Prevent deletion of default folders

      if (deleteFolderError) throw deleteFolderError;
    } catch (error) {
      console.error('Error deleting folder:', error);
      throw error;
    }
  }
} 