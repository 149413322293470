import React from 'react'
import { Box, Container, Grid, Button, Typography, Link } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { PageHeader, DescriptiveText, SectionHeader } from '../components/ui/typography'
import { FiExternalLink } from 'react-icons/fi'
import { BiMap, BiPhone, BiEnvelope, BiGlobe } from 'react-icons/bi'
import { IoArrowBack } from 'react-icons/io5'
import SierraCollege from '../assets/College Logos/Sierra College.png'
import Polygence from '../assets/polygence.png'

const PROVIDER_DATA = {
  'sierra-college': {
    name: 'Sierra College',
    logo: SierraCollege,
    description: 'Sierra College is a public community college serving Placer and Nevada Counties, offering comprehensive academic programs, career education, and lifelong learning opportunities.',
    type: 'Community College',
    location: 'Rocklin, California',
    website: 'https://www.sierracollege.edu',
    phone: '(916) 624-3333',
    email: 'admissions@sierracollege.edu',
    accreditation: ['Western Association of Schools and Colleges'],
    features: [
      'Over 125 degree programs',
      'Transfer agreements with UC and CSU systems',
      'Career and technical education programs',
      'State-of-the-art facilities',
      'Comprehensive student support services',
      'Athletic programs'
    ],
    stats: [
      { label: 'Founded', value: '1936' },
      { label: 'Student Population', value: '18,000+' },
      { label: 'Student-Faculty Ratio', value: '20:1' },
      { label: 'Average Class Size', value: '25' }
    ]
  },
  'polygence': {
    name: 'Polygence',
    logo: Polygence,
    description: 'Polygence is a research academy that connects high school students with PhD mentors for personalized research projects. Students can explore their academic interests, develop research skills, and create original work under expert guidance.',
    type: 'Research Academy',
    location: 'San Francisco, California',
    website: 'https://www.polygence.org',
    phone: '(650) 308-4189',
    email: 'support@polygence.org',
    accreditation: ['International Association for College Admission Counseling'],
    features: [
      '1-on-1 mentorship with PhD researchers',
      'Personalized research projects',
      'Flexible online format',
      'Publication opportunities',
      'College application support',
      'Access to academic conferences'
    ],
    stats: [
      { label: 'Founded', value: '2019' },
      { label: 'PhD Mentors', value: '2000+' },
      { label: 'Research Fields', value: '100+' },
      { label: 'Project Success Rate', value: '95%' }
    ]
  }
}

const ProviderPage = () => {
  const { providerId } = useParams()
  const navigate = useNavigate()
  const provider = PROVIDER_DATA[providerId]

  if (!provider) {
    return (
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}>
        <Typography>Provider not found</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Back Button */}
      <Box sx={{ 
        backgroundColor: 'hsl(var(--background))',
        borderBottom: '1px solid hsl(var(--border))',
        py: 'var(--spacing-2)',
      }}>
        <Container maxWidth="var(--container-max-width)">
          <Button
            startIcon={<IoArrowBack />}
            onClick={() => navigate('/course-search/results')}
            sx={{
              color: 'hsl(var(--text-primary))',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'hsl(var(--muted))',
              }
            }}
          >
            Back to Course Search
          </Button>
        </Container>
      </Box>

      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 'var(--spacing-6)',
            mb: 'var(--spacing-6)'
          }}>
            <Box 
              component="img"
              src={provider.logo}
              alt={`${provider.name} logo`}
              sx={{ 
                width: 120,
                height: 120,
                objectFit: 'contain',
                p: 'var(--spacing-4)',
                backgroundColor: 'hsl(var(--background))',
                borderRadius: 'var(--radius-lg)',
                border: '1px solid hsl(var(--border))'
              }}
            />
            <Box>
              <PageHeader>{provider.name}</PageHeader>
              <DescriptiveText>{provider.type}</DescriptiveText>
            </Box>
          </Box>

          {/* Quick Info Cards */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                p: 'var(--spacing-4)',
                backgroundColor: 'hsla(var(--background), 0.8)',
                borderRadius: 'var(--radius-lg)',
                border: '1px solid hsl(var(--border))',
                backdropFilter: 'blur(8px)',
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--spacing-3)'
              }}>
                <BiMap size={24} />
                <Typography>{provider.location}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Link 
                href={provider.website}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <Box sx={{ 
                  p: 'var(--spacing-4)',
                  backgroundColor: 'hsla(var(--background), 0.8)',
                  borderRadius: 'var(--radius-lg)',
                  border: '1px solid hsl(var(--border))',
                  backdropFilter: 'blur(8px)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-3)',
                  color: 'hsl(var(--text-primary))',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    borderColor: 'hsl(var(--brand-primary))',
                    backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                  }
                }}>
                  <BiGlobe size={24} />
                  <Typography>Visit Website</Typography>
                  <FiExternalLink size={16} style={{ marginLeft: 'auto' }} />
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box 
                onClick={() => navigate(`/course-search/results?provider=${providerId}`)}
                sx={{ 
                  p: 'var(--spacing-4)',
                  backgroundColor: 'hsla(var(--background), 0.8)',
                  borderRadius: 'var(--radius-lg)',
                  border: '1px solid hsl(var(--border))',
                  backdropFilter: 'blur(8px)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--spacing-3)',
                  color: 'hsl(var(--text-primary))',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: 'hsl(var(--brand-primary))',
                    backgroundColor: 'hsla(var(--brand-primary), 0.1)',
                  }
                }}
              >
                <BiGlobe size={24} />
                <Typography>Search by this Provider</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          py: 'var(--spacing-8)',
          px: 'var(--container-padding-x)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={6}>
          {/* Left Column */}
          <Grid item xs={12} md={8}>
            {/* About Section */}
            <Box sx={{ mb: 'var(--spacing-8)' }}>
              <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>About</SectionHeader>
              <Typography sx={{ 
                color: 'hsl(var(--text-primary))',
                lineHeight: 1.7
              }}>
                {provider.description}
              </Typography>
            </Box>

            {/* Features Section */}
            <Box sx={{ mb: 'var(--spacing-8)' }}>
              <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>Features & Programs</SectionHeader>
              <Grid container spacing={3}>
                {provider.features.map((feature, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box sx={{
                      p: 'var(--spacing-4)',
                      backgroundColor: 'hsl(var(--muted))',
                      borderRadius: 'var(--radius-lg)',
                      border: '1px solid hsl(var(--border))',
                      height: '100%'
                    }}>
                      <Typography>{feature}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Accreditation Section */}
            <Box>
              <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>Accreditation</SectionHeader>
              <Box sx={{
                p: 'var(--spacing-4)',
                backgroundColor: 'hsl(var(--muted))',
                borderRadius: 'var(--radius-lg)',
                border: '1px solid hsl(var(--border))'
              }}>
                {provider.accreditation.map((item, index) => (
                  <Typography key={index} sx={{ mb: index < provider.accreditation.length - 1 ? 2 : 0 }}>
                    • {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={4}>
            {/* Contact Card */}
            <Box sx={{
              p: 'var(--spacing-6)',
              backgroundColor: 'hsl(var(--muted))',
              borderRadius: 'var(--radius-lg)',
              border: '1px solid hsl(var(--border))',
              position: 'sticky',
              top: 'var(--spacing-4)'
            }}>
              <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>Contact Information</SectionHeader>
              
              <Box sx={{ mb: 'var(--spacing-4)' }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 'var(--spacing-3)',
                  mb: 'var(--spacing-3)'
                }}>
                  <BiPhone size={20} />
                  <Typography>{provider.phone}</Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 'var(--spacing-3)',
                  mb: 'var(--spacing-3)'
                }}>
                  <BiEnvelope size={20} />
                  <Typography>{provider.email}</Typography>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 'var(--spacing-3)'
                }}>
                  <BiMap size={20} />
                  <Typography>{provider.location}</Typography>
                </Box>
              </Box>

              <Button
                variant="contained"
                href={provider.website}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: '100%',
                  backgroundColor: 'hsl(var(--brand-primary))',
                  color: 'hsl(var(--background))',
                  textTransform: 'none',
                  py: 'var(--spacing-3)',
                  '&:hover': {
                    backgroundColor: 'hsl(var(--brand-primary-dark))'
                  }
                }}
              >
                Visit Website
              </Button>
            </Box>

            {/* Stats Card */}
            <Box sx={{
              mt: 'var(--spacing-4)',
              p: 'var(--spacing-6)',
              backgroundColor: 'hsl(var(--muted))',
              borderRadius: 'var(--radius-lg)',
              border: '1px solid hsl(var(--border))'
            }}>
              <SectionHeader sx={{ mb: 'var(--spacing-4)' }}>Quick Facts</SectionHeader>
              <Grid container spacing={3}>
                {provider.stats.map((stat, index) => (
                  <Grid item xs={6} key={index}>
                    <Box>
                      <Typography sx={{ 
                        color: 'hsl(var(--text-secondary))',
                        fontSize: '0.875rem',
                        mb: 1
                      }}>
                        {stat.label}
                      </Typography>
                      <Typography sx={{ 
                        color: 'hsl(var(--text-primary))',
                        fontWeight: 600,
                        fontSize: '1.125rem'
                      }}>
                        {stat.value}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ProviderPage 