import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container,
  Typography, 
  Paper, 
  Grid, 
  Button, 
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Tooltip,
  Snackbar,
  Alert,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { 
  Add as AddIcon,
  Share as ShareIcon,
  Verified as VerifiedIcon,
  Link as LinkIcon,
  School as SchoolIcon,
  EmojiEvents as AchievementIcon,
  Code as ProjectIcon,
  CloudDownload as CloudDownloadIcon,
  Edit as EditIcon,
  Image as ImageIcon,
  Psychology as SkillIcon,
  Science as ScienceIcon,
  Brush as ArtIcon,
  Language as LanguageIcon,
  Lightbulb as InnovationIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { PageHeader, SectionHeader, FeatureHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography';
import LedgerPDF from '../components/LedgerPDF';
import { saveLedgerPDF } from '../utils/pdfStorage';
import {
  fetchLedgerEntries,
  createLedgerEntry,
  updateLedgerEntry,
  deleteLedgerEntry,
  fetchLedgerSettings,
  updateLedgerSettings,
  uploadLedgerImage
} from '../utils/supabase/ledger';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const Ledger = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isExampleLedger, setIsExampleLedger] = useState(false);
  const [editingHeader, setEditingHeader] = useState(false);
  const [ledgerHeader, setLedgerHeader] = useState({
    title: 'My Educational Journey',
    subtitle: 'A collection of achievements, skills, and experiences',
    profileImage: null,
    coverImage: null,
    themeColor: '#1976d2'
  });
  const [newEntry, setNewEntry] = useState({
    type: '',
    title: '',
    description: '',
    date: '',
    evidence: '',
    skills: '',
    image: null,
    icon: null
  });
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);

  const entryTypes = [
    { value: 'achievement', label: 'Achievement', icon: AchievementIcon, color: '#2196f3' },
    { value: 'project', label: 'Project', icon: ProjectIcon, color: '#4caf50' },
    { value: 'certification', label: 'Certification', icon: VerifiedIcon, color: '#9c27b0' },
    { value: 'skill', label: 'Skill', icon: SkillIcon, color: '#ff9800' },
    { value: 'course', label: 'Course', icon: SchoolIcon, color: '#f44336' },
    { value: 'research', label: 'Research', icon: ScienceIcon, color: '#3f51b5' },
    { value: 'art', label: 'Creative Work', icon: ArtIcon, color: '#e91e63' },
    { value: 'language', label: 'Language', icon: LanguageIcon, color: '#009688' },
    { value: 'innovation', label: 'Innovation', icon: InnovationIcon, color: '#ff5722' }
  ];

  const exampleEntries = [
    {
      id: 1,
      type: 'project',
      title: 'AI-Powered Environmental Monitor',
      description: 'Developed a machine learning model to analyze air quality data and predict pollution levels. The project won first place at the State Science Fair and was featured in Local Tech Magazine.',
      date: '2024-03-15',
      verified: true,
      image: 'https://example.com/project-image.jpg',
      skills: ['Python', 'Machine Learning', 'IoT', 'Data Analysis'],
      evidence: 'https://github.com/username/env-monitor'
    },
    {
      id: 2,
      type: 'certification',
      title: 'Google TensorFlow Developer Certificate',
      description: 'Earned professional certification in machine learning and deep learning implementation using TensorFlow. Completed advanced projects in computer vision and natural language processing.',
      date: '2024-02-20',
      verified: true,
      image: 'https://example.com/cert-image.jpg',
      skills: ['TensorFlow', 'Deep Learning', 'Neural Networks'],
      evidence: 'https://credential.net/example'
    },
    {
      id: 3,
      type: 'research',
      title: 'Quantum Computing Research Paper',
      description: 'Published a research paper on quantum computing applications in cryptography. Collaborated with university professors and presented findings at Youth Science Congress.',
      date: '2024-01-10',
      verified: true,
      skills: ['Quantum Computing', 'Cryptography', 'Research Writing'],
      evidence: 'https://arxiv.org/example'
    },
    {
      id: 4,
      type: 'skill',
      title: 'Full-Stack Web Development',
      description: 'Mastered modern web development technologies and best practices. Built multiple production-ready applications using React, Node.js, and cloud services.',
      date: '2024-03-01',
      verified: false,
      skills: ['React', 'Node.js', 'AWS', 'Database Design'],
      evidence: 'https://portfolio.example.com'
    },
    {
      id: 5,
      type: 'language',
      title: 'Japanese Language Proficiency',
      description: 'Achieved N2 level certification in Japanese Language Proficiency Test (JLPT). Participated in language exchange programs and cultural events.',
      date: '2024-02-15',
      verified: true,
      skills: ['Japanese', 'Cross-cultural Communication'],
      evidence: 'https://jlpt.example.com/certificate'
    }
  ];

  useEffect(() => {
    // Redirect to login if not authenticated
    if (!user && !isExampleLedger) {
      navigate('/login');
      return;
    }

    if (!isExampleLedger) {
      loadLedgerData();
    }
  }, [isExampleLedger, user, navigate]);

  const loadLedgerData = async () => {
    try {
      setLoading(true);
      const [entriesData, settingsData] = await Promise.all([
        fetchLedgerEntries(),
        fetchLedgerSettings()
      ]);
      
      setEntries(entriesData);
      setLedgerHeader({
        title: settingsData.title,
        subtitle: settingsData.subtitle,
        profileImage: settingsData.profile_image_url,
        coverImage: settingsData.cover_image_url,
        themeColor: settingsData.theme_color
      });
    } catch (error) {
      console.error('Error loading ledger data:', error);
      setSnackbar({
        open: true,
        message: 'Error loading ledger data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddEntry = async () => {
    try {
      if (!user) {
        setSnackbar({
          open: true,
          message: 'Please log in to add entries',
          severity: 'error'
        });
        navigate('/login');
        return;
      }

      // Upload image if provided
      let imageUrl = null;
      if (newEntry.image) {
        const file = await fetch(newEntry.image).then(r => r.blob());
        imageUrl = await uploadLedgerImage(file, 'entries');
      }

      // Process skills - ensure it's an array
      const skillsArray = typeof newEntry.skills === 'string' 
        ? newEntry.skills.split(',').map(s => s.trim()).filter(Boolean)
        : Array.isArray(newEntry.skills) 
          ? newEntry.skills 
          : [];

      const entry = {
        ...newEntry,
        image: imageUrl,
        skills: skillsArray
      };

      await createLedgerEntry(entry);

      setOpenAddDialog(false);
      setSnackbar({
        open: true,
        message: 'Entry added successfully',
        severity: 'success'
      });
      
      // Reset form and reload entries
      setNewEntry({
        type: '',
        title: '',
        description: '',
        date: '',
        evidence: '',
        skills: '',
        image: null,
        icon: null
      });
      
      if (!isExampleLedger) {
        loadLedgerData();
      }
    } catch (error) {
      console.error('Error adding entry:', error);
      if (error.message?.includes('authentication')) {
        setSnackbar({
          open: true,
          message: 'Please log in to add entries',
          severity: 'error'
        });
        navigate('/login');
      } else {
        setSnackbar({
          open: true,
          message: 'Error adding entry',
          severity: 'error'
        });
      }
    }
  };

  const handleUpdateHeader = async () => {
    try {
      if (!user) {
        setSnackbar({
          open: true,
          message: 'Please log in to update settings',
          severity: 'error'
        });
        navigate('/login');
        return;
      }

      const updatedSettings = await updateLedgerSettings({
        title: ledgerHeader.title,
        subtitle: ledgerHeader.subtitle,
        profileImage: ledgerHeader.profileImage,
        coverImage: ledgerHeader.coverImage,
        themeColor: ledgerHeader.themeColor
      });

      if (updatedSettings) {
        setLedgerHeader({
          title: updatedSettings.title,
          subtitle: updatedSettings.subtitle,
          profileImage: updatedSettings.profile_image_url,
          coverImage: updatedSettings.cover_image_url,
          themeColor: updatedSettings.theme_color
        });

        setSnackbar({
          open: true,
          message: 'Ledger settings updated successfully',
          severity: 'success'
        });
        setEditingHeader(false);
      }
    } catch (error) {
      console.error('Error updating header:', error);
      setSnackbar({
        open: true,
        message: 'Error updating ledger settings',
        severity: 'error'
      });
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbar({
      open: true,
      message: 'Ledger link copied to clipboard',
      severity: 'success'
    });
  };

  const handlePreview = async () => {
    try {
      setShowPdfPreview(true);
      
      // Generate PDF blob directly using @react-pdf/renderer
      const pdfBlob = await pdf(
        <LedgerPDF 
          entries={isExampleLedger ? exampleEntries : entries}
          header={ledgerHeader}
        />
      ).toBlob();
      
      if (user?.id && !isExampleLedger) {
        await saveLedgerPDF(user.id, pdfBlob);
      }
    } catch (error) {
      console.error('Error handling preview:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate preview',
        severity: 'error'
      });
    }
  };

  const handleExportPDF = async () => {
    try {
      // Generate PDF blob directly using @react-pdf/renderer
      const pdfBlob = await pdf(
        <LedgerPDF 
          entries={isExampleLedger ? exampleEntries : entries}
          header={ledgerHeader}
        />
      ).toBlob();
      
      if (user?.id && !isExampleLedger) {
        await saveLedgerPDF(user.id, pdfBlob);
      }

      // Trigger download
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'my-ledger.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setSnackbar({
        open: true,
        message: 'Ledger exported as PDF',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setSnackbar({
        open: true,
        message: 'Error exporting PDF',
        severity: 'error'
      });
    }
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === 'profile') {
          setLedgerHeader({ ...ledgerHeader, profileImage: reader.result });
        } else if (type === 'cover') {
          setLedgerHeader({ ...ledgerHeader, coverImage: reader.result });
        } else if (type === 'entry') {
          setNewEntry({ ...newEntry, image: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePreviewPDF = () => {
    setShowPdfPreview(true);
  };

  const handleDeleteEntry = async (entryId) => {
    try {
      await deleteLedgerEntry(entryId);
      setSnackbar({
        open: true,
        message: 'Entry deleted successfully',
        severity: 'success'
      });
      loadLedgerData(); // Reload entries after deletion
    } catch (error) {
      console.error('Error deleting entry:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting entry',
        severity: 'error'
      });
    }
  };

  const handleEditEntry = async () => {
    try {
      if (!editingEntry) return;

      // Upload image if provided and it's a new image (starts with data:)
      let imageUrl = editingEntry.image_url;
      if (editingEntry.image?.startsWith('data:')) {
        const file = await fetch(editingEntry.image).then(r => r.blob());
        imageUrl = await uploadLedgerImage(file, 'entries');
      }

      // Process skills - ensure it's an array
      const skillsArray = typeof editingEntry.skills === 'string' 
        ? editingEntry.skills.split(',').map(s => s.trim()).filter(Boolean)
        : Array.isArray(editingEntry.skills) 
          ? editingEntry.skills 
          : [];

      const updates = {
        type: editingEntry.type,
        title: editingEntry.title,
        description: editingEntry.description,
        date: editingEntry.date,
        evidence: editingEntry.evidence_url,
        image: imageUrl,
        skills: skillsArray.join(',')
      };

      await updateLedgerEntry(editingEntry.id, updates);
      setEditingEntry(null);
      setSnackbar({
        open: true,
        message: 'Entry updated successfully',
        severity: 'success'
      });
      loadLedgerData();
    } catch (error) {
      console.error('Error updating entry:', error);
      setSnackbar({
        open: true,
        message: 'Error updating entry',
        severity: 'error'
      });
    }
  };

  if (!isExampleLedger && loading) {
    return (
      <Box 
        sx={{ 
          minHeight: '100vh',
          backgroundColor: 'hsl(var(--background))',
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' 
        }}
      >
        <CircularProgress sx={{ color: 'hsl(var(--brand-primary))' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            opacity: 0.1,
          },
          pt: 'var(--spacing-8)',
          pb: 'var(--spacing-6)',
        }}
      >
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (max-width: 768px)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
            <Box>
              <PageHeader>Educational Journey Ledger</PageHeader>
              <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                Document and showcase your educational achievements, skills, and experiences in a professional portfolio.
              </DescriptiveText>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => setIsExampleLedger(!isExampleLedger)}
                sx={{
                  borderColor: 'hsl(var(--border))',
                  color: 'hsl(var(--muted-foreground))',
                  '&:hover': {
                    borderColor: 'hsl(var(--border))',
                    backgroundColor: 'hsl(var(--accent))',
                  },
                }}
              >
                {isExampleLedger ? "View My Ledger" : "View Example"}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenAddDialog(true)}
                disabled={isExampleLedger}
                sx={{
                  backgroundColor: 'hsl(var(--brand-primary))',
                  color: 'hsl(var(--brand-primary-foreground))',
                  '&:hover': {
                    backgroundColor: 'hsl(var(--brand-primary-dark))',
                  },
                }}
              >
                Add Entry
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (max-width: 768px)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Header Actions */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 1,
            mb: 4,
          }}
        >
          <Tooltip title="Preview PDF">
            <IconButton 
              onClick={handlePreviewPDF}
              sx={{ 
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export PDF">
            <IconButton 
              onClick={handleExportPDF}
              sx={{ 
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share your ledger">
            <IconButton 
              onClick={handleShare}
              sx={{ 
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Entries Grid */}
        <Grid container spacing={3}>
          {(isExampleLedger ? exampleEntries : entries).map((entry) => (
            <Grid item xs={12} key={entry.id}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  backgroundColor: 'hsl(var(--card))',
                  border: '1px solid hsl(var(--border))',
                  borderRadius: 'var(--radius)',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {React.createElement(
                      entryTypes.find(type => type.value === entry.type)?.icon || ProjectIcon,
                      { 
                        sx: {
                          fontSize: 24,
                          color: entryTypes.find(type => type.value === entry.type)?.color
                        }
                      }
                    )}
                    <Box>
                      <FeatureHeader>
                        {entry.title}
                        {entry.verified && (
                          <VerifiedIcon 
                            sx={{ 
                              ml: 1, 
                              fontSize: 20,
                              color: 'hsl(var(--brand-primary))',
                            }} 
                          />
                        )}
                      </FeatureHeader>
                      <SupportingText>
                        Added on {new Date(entry.date).toLocaleDateString()}
                      </SupportingText>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {entry.evidence_url && (
                      <Tooltip title="View Evidence">
                        <IconButton 
                          size="small"
                          onClick={() => window.open(entry.evidence_url, '_blank')}
                          sx={{ 
                            color: 'hsl(var(--muted-foreground))',
                            '&:hover': {
                              backgroundColor: 'hsl(var(--accent))',
                            },
                          }}
                        >
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!isExampleLedger && (
                      <>
                        <Tooltip title="Edit Entry">
                          <IconButton 
                            size="small"
                            onClick={() => setEditingEntry(entry)}
                            sx={{ 
                              color: 'hsl(var(--brand-primary))',
                              '&:hover': {
                                backgroundColor: 'hsl(var(--brand-primary) / 0.1)',
                              },
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Entry">
                          <IconButton 
                            size="small"
                            onClick={() => handleDeleteEntry(entry.id)}
                            sx={{ 
                              color: 'hsl(var(--destructive))',
                              '&:hover': {
                                backgroundColor: 'hsl(var(--destructive) / 0.1)',
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>

                <BodyText sx={{ mb: 3 }}>
                  {entry.description}
                </BodyText>

                {entry.image_url && (
                  <Box 
                    sx={{ 
                      mb: 3,
                      borderRadius: 'var(--radius)',
                      overflow: 'hidden',
                      maxWidth: 400,
                    }}
                  >
                    <img 
                      src={entry.image_url} 
                      alt={entry.title}
                      style={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                      }}
                    />
                  </Box>
                )}
                
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {entry.skills.map((skill) => (
                    <Chip
                      key={skill}
                      label={skill}
                      size="small"
                      sx={{
                        backgroundColor: 'hsl(var(--accent))',
                        color: 'hsl(var(--accent-foreground))',
                        fontWeight: 500,
                        '&:hover': {
                          backgroundColor: 'hsl(var(--accent-dark))',
                        },
                      }}
                    />
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* PDF Preview Dialog */}
        <Dialog
          open={showPdfPreview}
          onClose={() => setShowPdfPreview(false)}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              height: '90vh',
              maxHeight: '90vh',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'hsl(var(--background))',
              backgroundImage: 'none',
              borderRadius: 'var(--radius)',
              border: '1px solid hsl(var(--border))',
            }
          }}
        >
          <DialogTitle sx={{ px: 3, pt: 3, pb: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FeatureHeader>Preview Ledger</FeatureHeader>
            <IconButton
              onClick={() => setShowPdfPreview(false)}
              sx={{ 
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ flex: 1, overflow: 'hidden' }}>
            <PDFViewer 
              width="100%" 
              height="100%" 
              style={{ 
                border: 'none',
                borderRadius: 'var(--radius)',
                backgroundColor: 'hsl(var(--card))',
              }}
            >
              <LedgerPDF 
                entries={isExampleLedger ? exampleEntries : entries}
                header={ledgerHeader}
              />
            </PDFViewer>
          </DialogContent>
        </Dialog>

        {/* Add Entry Dialog */}
        <Dialog 
          open={openAddDialog} 
          onClose={() => setOpenAddDialog(false)} 
          maxWidth="md" 
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'hsl(var(--background))',
              backgroundImage: 'none',
              borderRadius: 'var(--radius)',
              border: '1px solid hsl(var(--border))',
            }
          }}
        >
          <DialogTitle sx={{ px: 3, pt: 3, pb: 0 }}>
            <FeatureHeader>Add New Entry</FeatureHeader>
          </DialogTitle>
          <DialogContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                select
                label="Entry Type"
                value={newEntry.type}
                onChange={(e) => setNewEntry({ ...newEntry, type: e.target.value })}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              >
                {entryTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <type.icon fontSize="small" sx={{ color: type.color }} />
                      <BodyText>{type.label}</BodyText>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
              
              <TextField
                label="Title"
                value={newEntry.title}
                onChange={(e) => setNewEntry({ ...newEntry, title: e.target.value })}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              />
              
              <TextField
                label="Description"
                value={newEntry.description}
                onChange={(e) => setNewEntry({ ...newEntry, description: e.target.value })}
                multiline
                rows={3}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              />

              <TextField
                label="Date"
                type="date"
                value={newEntry.date}
                onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              />

              <TextField
                label="Evidence URL"
                value={newEntry.evidence}
                onChange={(e) => setNewEntry({ ...newEntry, evidence: e.target.value })}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              />

              <TextField
                label="Skills (comma separated)"
                value={newEntry.skills}
                onChange={(e) => setNewEntry({ ...newEntry, skills: e.target.value })}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'hsl(var(--background))',
                    '& fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&:hover fieldset': {
                      borderColor: 'hsl(var(--input))',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'hsl(var(--brand-primary))',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'hsl(var(--muted-foreground))',
                    '&.Mui-focused': {
                      color: 'hsl(var(--brand-primary))',
                    },
                  },
                }}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <input
                  accept="image/*"
                  type="file"
                  id="entry-image-upload"
                  hidden
                  onChange={(e) => handleImageUpload(e, 'entry')}
                />
                <label htmlFor="entry-image-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<ImageIcon />}
                    fullWidth
                    sx={{
                      borderColor: 'hsl(var(--border))',
                      color: 'hsl(var(--muted-foreground))',
                      '&:hover': {
                        borderColor: 'hsl(var(--border))',
                        backgroundColor: 'hsl(var(--accent))',
                      },
                    }}
                  >
                    Upload Image
                  </Button>
                </label>
                {newEntry.image && (
                  <Box 
                    sx={{ 
                      borderRadius: 'var(--radius)',
                      overflow: 'hidden',
                      maxWidth: 400,
                      mx: 'auto',
                    }}
                  >
                    <img 
                      src={newEntry.image} 
                      alt="Entry preview" 
                      style={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3, gap: 2 }}>
            <Button 
              onClick={() => setOpenAddDialog(false)}
              variant="outlined"
              sx={{
                borderColor: 'hsl(var(--border))',
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  borderColor: 'hsl(var(--border))',
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleAddEntry} 
              variant="contained"
              sx={{
                backgroundColor: 'hsl(var(--brand-primary))',
                color: 'hsl(var(--brand-primary-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--brand-primary-dark))',
                },
              }}
            >
              Add Entry
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Entry Dialog */}
        <Dialog 
          open={!!editingEntry} 
          onClose={() => setEditingEntry(null)} 
          maxWidth="md" 
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'hsl(var(--background))',
              backgroundImage: 'none',
              borderRadius: 'var(--radius)',
              border: '1px solid hsl(var(--border))',
            }
          }}
        >
          <DialogTitle sx={{ px: 3, pt: 3, pb: 0 }}>
            <FeatureHeader>Edit Entry</FeatureHeader>
          </DialogTitle>
          <DialogContent sx={{ p: 3 }}>
            {editingEntry && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <TextField
                  select
                  label="Entry Type"
                  value={editingEntry.type}
                  onChange={(e) => setEditingEntry({ ...editingEntry, type: e.target.value })}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                >
                  {entryTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <type.icon fontSize="small" sx={{ color: type.color }} />
                        <BodyText>{type.label}</BodyText>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
                
                <TextField
                  label="Title"
                  value={editingEntry.title}
                  onChange={(e) => setEditingEntry({ ...editingEntry, title: e.target.value })}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
                
                <TextField
                  label="Description"
                  value={editingEntry.description}
                  onChange={(e) => setEditingEntry({ ...editingEntry, description: e.target.value })}
                  multiline
                  rows={3}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
                
                <TextField
                  label="Date"
                  type="date"
                  value={editingEntry.date}
                  onChange={(e) => setEditingEntry({ ...editingEntry, date: e.target.value })}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
                
                <TextField
                  label="Evidence URL"
                  value={editingEntry.evidence_url}
                  onChange={(e) => setEditingEntry({ ...editingEntry, evidence_url: e.target.value })}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
                
                <TextField
                  label="Skills (comma-separated)"
                  value={Array.isArray(editingEntry.skills) ? editingEntry.skills.join(', ') : editingEntry.skills}
                  onChange={(e) => setEditingEntry({ ...editingEntry, skills: e.target.value })}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'hsl(var(--background))',
                      '& fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--input))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                        borderWidth: '1px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'hsl(var(--muted-foreground))',
                      '&.Mui-focused': {
                        color: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <input
                    accept="image/*"
                    type="file"
                    id="edit-entry-image-upload"
                    hidden
                    onChange={(e) => handleImageUpload(e, 'entry')}
                  />
                  <label htmlFor="edit-entry-image-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<ImageIcon />}
                      fullWidth
                      sx={{
                        borderColor: 'hsl(var(--border))',
                        color: 'hsl(var(--muted-foreground))',
                        '&:hover': {
                          borderColor: 'hsl(var(--border))',
                          backgroundColor: 'hsl(var(--accent))',
                        },
                      }}
                    >
                      Change Image
                    </Button>
                  </label>
                  {(editingEntry.image || editingEntry.image_url) && (
                    <Box 
                      sx={{ 
                        borderRadius: 'var(--radius)',
                        overflow: 'hidden',
                        maxWidth: 400,
                        mx: 'auto',
                      }}
                    >
                      <img 
                        src={editingEntry.image || editingEntry.image_url} 
                        alt="Entry preview" 
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 3, gap: 2 }}>
            <Button 
              onClick={() => setEditingEntry(null)}
              variant="outlined"
              sx={{
                borderColor: 'hsl(var(--border))',
                color: 'hsl(var(--muted-foreground))',
                '&:hover': {
                  borderColor: 'hsl(var(--border))',
                  backgroundColor: 'hsl(var(--accent))',
                },
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleEditEntry} 
              variant="contained"
              sx={{
                backgroundColor: 'hsl(var(--brand-primary))',
                color: 'hsl(var(--brand-primary-foreground))',
                '&:hover': {
                  backgroundColor: 'hsl(var(--brand-primary-dark))',
                },
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            severity={snackbar.severity} 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            sx={{
              width: '100%',
              backgroundColor: snackbar.severity === 'success' 
                ? 'hsl(var(--success))' 
                : 'hsl(var(--destructive))',
              color: snackbar.severity === 'success'
                ? 'hsl(var(--success-foreground))'
                : 'hsl(var(--destructive-foreground))',
              '& .MuiAlert-message': {
                flex: 1,
              },
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

const styles = {
  container: {
    maxWidth: 1200,
    mx: 'auto',
    p: 3,
    height: 'calc(100vh - 88px)',
    overflow: 'auto'
  },
  header: {
    mb: 4,
    overflow: 'hidden',
    position: 'relative'
  },
  coverImage: {
    height: 200,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)'
    }
  },
  headerContent: {
    p: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 2
  },
  titleSection: {
    flex: 1,
    minWidth: 300
  },
  headerView: {
    cursor: 'pointer',
    '&:hover': {
      '& .MuiTypography-root': {
        color: 'primary.main'
      }
    }
  },
  headerEdit: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  headerInput: {
    '& .MuiInput-root': {
      fontSize: '2rem',
      fontWeight: 600
    }
  },
  title: {
    fontWeight: 700,
    mb: 1,
    color: 'text.primary',
    transition: 'color 0.2s'
  },
  subtitle: {
    fontWeight: 400,
    color: 'text.secondary',
    transition: 'color 0.2s'
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    flexWrap: 'wrap'
  },
  ledgerToggle: {
    mr: 2
  },
  actionButton: {
    bgcolor: 'primary.light',
    color: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.main',
      color: 'white'
    }
  },
  addButton: {
    px: 3,
    bgcolor: 'primary.main',
    '&:hover': {
      bgcolor: 'primary.dark'
    }
  },
  entriesGrid: {
    mt: 4
  },
  entryCard: {
    p: 3,
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: 3
    }
  },
  entryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mb: 2,
    gap: 2
  },
  entryTitleSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 2,
    flex: 1
  },
  entryIcon: {
    fontSize: 32,
    p: 1,
    borderRadius: 1,
    bgcolor: (theme) => theme.palette.grey[100]
  },
  entryTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    fontWeight: 600,
    color: 'text.primary'
  },
  verifiedIcon: {
    fontSize: 20,
    color: 'success.main'
  },
  entryImageContainer: {
    width: 120,
    height: 120,
    borderRadius: 1,
    overflow: 'hidden',
    flexShrink: 0,
    ml: 2
  },
  entryImageContent: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  entryDescription: {
    color: 'text.secondary',
    mb: 2,
    lineHeight: 1.6
  },
  skillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1
  },
  skillChip: {
    fontWeight: 500,
    transition: 'all 0.2s'
  },
  imageUpload: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  uploadButton: {
    width: '100%'
  },
  imagePreview: {
    mt: 1,
    borderRadius: 1,
    overflow: 'hidden'
  },
  alert: {
    width: '100%',
    '& .MuiAlert-message': {
      flex: 1
    }
  },
  viewToggle: {
    p: 2,
    mr: 2,
    bgcolor: (theme) => theme.palette.grey[50],
    border: '1px solid',
    borderColor: 'divider',
    borderRadius: 1
  },
  switchButton: {
    minWidth: 150
  }
};

export default Ledger; 