import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Box } from '@mui/material';
import { PageHeader, SectionHeader, FeatureHeader, BodyText, SupportingText, DescriptiveText } from '../components/ui/typography';
import theme from '../styles/theme/index';
import { cardStyles } from '../styles/theme/components/cards';
import {
  Description as DocumentIcon,
  AccountBalance as RegulationIcon,
  AttachMoney as FundingIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

const Compliance = () => {
  const navigate = useNavigate();

  const complianceModules = [
    {
      id: 'regulations',
      title: 'State Regulation Overview',
      description: 'Understand your state\'s homeschooling requirements and regulations.',
      icon: RegulationIcon,
      path: '/state-regulation-overview',
      color: 'hsl(var(--brand-primary))',
      bgColor: 'hsl(var(--brand-primary-light))',
      stats: [
        { label: 'States Covered', value: '50' },
        { label: 'Requirements', value: '100+' },
      ],
    },
    {
      id: 'filing',
      title: 'State & Local Filing',
      description: 'Submit and track your compliance documents and filings.',
      icon: DocumentIcon,
      path: '/state-compliance-filing',
      color: 'hsl(var(--secondary-green))',
      bgColor: 'hsl(var(--secondary-green-light))',
      highlight: 'Most Important',
      stats: [
        { label: 'Forms', value: '25+' },
        { label: 'Deadlines', value: '12' },
      ],
    },
    {
      id: 'funding',
      title: 'Funding Opportunities',
      description: 'Explore available funding and support for your homeschool.',
      icon: FundingIcon,
      path: '/state-funding-opportunities',
      color: 'hsl(var(--secondary-orange))',
      bgColor: 'hsl(var(--secondary-orange-light))',
      stats: [
        { label: 'Programs', value: '30+' },
        { label: 'Average Aid', value: '$2.5k' },
      ],
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Compliance Center
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Streamline your homeschool compliance journey with our comprehensive tools and resources.
            Stay informed about regulations, manage filings, and access funding opportunities all in one place.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={4}>
          {complianceModules.map((module) => (
            <Grid item xs={12} md={4} key={module.id}>
              <Paper
                elevation={0}
                onClick={() => navigate(module.path)}
                sx={{
                  ...cardStyles.feature,
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: module.color,
                    transform: 'translateY(-4px)',
                    boxShadow: 'var(--shadow-lg)',
                    '& .arrow-icon': {
                      transform: 'translateX(4px)',
                    },
                    '& .icon-container': {
                      transform: 'scale(1.1)',
                    }
                  },
                }}
              >
                {/* Card Header */}
                <Box sx={{ p: 'var(--spacing-6)' }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 'var(--spacing-4)', mb: 'var(--spacing-4)' }}>
                    <Box
                      className="icon-container"
                      sx={{
                        width: 'var(--spacing-12)',
                        height: 'var(--spacing-12)',
                        borderRadius: 'var(--radius-lg)',
                        backgroundColor: module.bgColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'transform 0.2s ease-in-out',
                        flexShrink: 0,
                      }}
                    >
                      <module.icon sx={{ fontSize: 'var(--spacing-6)', color: module.color }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-2)', mb: 'var(--spacing-2)' }}>
                        <FeatureHeader sx={{ color: 'hsl(var(--text-primary))' }}>
                          {module.title}
                        </FeatureHeader>
                        {module.highlight && (
                          <Box
                            sx={{
                              px: 'var(--spacing-2)',
                              py: 'var(--spacing-1)',
                              borderRadius: 'var(--radius-full)',
                              backgroundColor: module.bgColor,
                              color: module.color,
                              fontSize: 'var(--font-size-xs)',
                              fontWeight: 600,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {module.highlight}
                          </Box>
                        )}
                      </Box>
                      <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                        {module.description}
                      </SupportingText>
                    </Box>
                  </Box>

                  {/* Stats Section */}
                  {module.stats && (
                    <Box 
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 'var(--spacing-3)',
                        mt: 'var(--spacing-4)',
                      }}
                    >
                      {module.stats.map((stat) => (
                        <Box
                          key={stat.label}
                          sx={{
                            p: 'var(--spacing-3)',
                            borderRadius: 'var(--radius-lg)',
                            backgroundColor: 'hsl(var(--background-alt))',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 'var(--spacing-1)',
                          }}
                        >
                          <Box 
                            sx={{ 
                              fontSize: 'var(--font-size-xl)',
                              fontWeight: 600,
                              color: module.color,
                              lineHeight: 1,
                            }}
                          >
                            {stat.value}
                          </Box>
                          <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                            {stat.label}
                          </SupportingText>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {/* Card Footer */}
                <Box 
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'hsl(var(--border))',
                    p: 'var(--spacing-4)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SupportingText 
                    sx={{ 
                      color: module.color,
                      fontWeight: 600,
                      fontSize: 'var(--font-size-sm)',
                    }}
                  >
                    Learn More
                  </SupportingText>
                  <ArrowForwardIcon 
                    className="arrow-icon"
                    sx={{ 
                      color: module.color,
                      transition: 'transform 0.2s ease-in-out',
                      fontSize: 'var(--spacing-5)',
                    }} 
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Info Section */}
        <Paper
          elevation={0}
          sx={{
            ...cardStyles.info,
            mt: 'var(--spacing-6)',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <FeatureHeader sx={{ mb: 2, color: 'hsl(var(--brand-primary))' }}>
                Why Compliance Matters
              </FeatureHeader>
              <BodyText>
                Maintaining proper compliance with state homeschooling regulations is essential for ensuring 
                your child's educational journey is legally recognized and supported. Our tools help you stay 
                organized and informed, making compliance management straightforward and stress-free.
              </BodyText>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default Compliance; 