import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import babyYoda from '../assets/jedi/baby.png';
import babyColorful from '../assets/jedi/baby-colorful.png';
import babyForce from '../assets/jedi/baby-force.png';
import babyHeart from '../assets/jedi/baby-heart.png';
import babyLightsaber from '../assets/jedi/baby-lightsaber.png';
import babySticker from '../assets/jedi/baby-sticker.png';
import teenEating from '../assets/jedi/teen-eating.png';
import oldForce from '../assets/jedi/old-force.png';
import oldLightsaber from '../assets/jedi/old-lightsaber.png';
import oldThumbsup from '../assets/jedi/old-thumbsup.png';
import oldAttacking from '../assets/jedi/old-attacking.jpg';
import oldGrumpy from '../assets/jedi/old-grumpy.jpg';
import oldCircle from '../assets/jedi/old-circleportrait.png';
import youngYoda from '../assets/jedi/young.png';
import adultEating from '../assets/jedi/adult-eating.webp';
import lego from '../assets/jedi/lego.png';
import commonAppLogo from '../assets/common-app.png';
import { Box, Typography } from '@mui/material';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(!location.state?.showSignup);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login, register } = useAuth();
  const userType = localStorage.getItem('userType');
  const [loadingStage, setLoadingStage] = useState('Establishing connection...');

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    try {
      if (!isLogin) {
        // Handle registration
        if (password !== verifyPassword) {
          throw new Error('Passwords do not match');
        }
        
        setLoadingStage('Creating your account...');
        await register(email, password, name);
        
        setLoadingStage('Setting up your profile...');
        await new Promise(resolve => setTimeout(resolve, 600));
      } else {
        // Handle login
        setLoadingStage('Establishing connection...');
        await new Promise(resolve => setTimeout(resolve, 600));
        
        setLoadingStage('Verifying credentials...');
        const user = await login(email, password);
        
        if (!user) {
          throw new Error('Login failed - no user returned');
        }
        
        setLoadingStage('Loading your profile...');
        await new Promise(resolve => setTimeout(resolve, 600));
      }
      
      setLoadingStage('Preparing your dashboard...');
      await new Promise(resolve => setTimeout(resolve, 600));
      
      setLoadingStage('Almost there...');
      await new Promise(resolve => setTimeout(resolve, 600));

      navigate('/');
      
    } catch (error) {
      console.error('Auth error:', error);
      let errorMsg = 'Authentication failed. ';
      
      if (error.message.includes('Invalid login credentials')) {
        errorMsg += 'Invalid email or password.';
      } else if (error.message.includes('Email not confirmed')) {
        errorMsg += 'Please check your email to confirm your account.';
      } else {
        errorMsg += error.message;
      }
      
      setErrorMessage(errorMsg);
    } finally {
      setIsLoading(false);
      setLoadingStage('Establishing connection...');
    }
  };

  const getBackgroundStyle = () => {
    switch(userType) {
      case 'college':
        return {
          backgroundColor: '#1a365d',
        };
      case 'lafire':
        return {
          background: 'linear-gradient(135deg, #742a2a 0%, #9b2c2c 100%)',
        };
      case 'highschool':
        return {
          background: 'linear-gradient(135deg, #2c5282 0%, #2b6cb0 100%)', // Dark blue gradient for parents
        };
      case 'student':
        return {
          background: 'linear-gradient(135deg, #234e52 0%, #2f855a 100%)', // Keep the original green gradient for students
        };
      default:
        return {
          background: 'linear-gradient(135deg, #234e52 0%, #2f855a 100%)',
        };
    }
  };

  const getWelcomeMessage = () => {
    switch(userType) {
      case 'college':
        return 'Welcome to YourEDU College Platform';
      case 'lafire':
        return 'Welcome to LA Fire Education Aid';
      case 'highschool':
        return 'Welcome to YourEDU Parent Portal';
      case 'student':
        return 'Begin Your Educational Journey';
      default:
        return 'Welcome to YourEDU';
    }
  };

  const shouldShowYodaJourney = userType === 'student';  // Only show for students now

  return (
    <Box sx={{...styles.background, ...getBackgroundStyle()}}>
      <Box sx={styles.contentWrapper}>
        <Box sx={styles.welcomeSection}>
          <img src={logo} alt="Logo" style={styles.welcomeLogo} />
          <Typography variant="h4" sx={styles.welcomeTitle}>
            {getWelcomeMessage()}
          </Typography>
          
          {shouldShowYodaJourney && (
            <Box sx={styles.progressSteps}>
              <div style={styles.step}>
                <img src={babyYoda} alt="Start" style={styles.stepIcon} />
                <span style={styles.stepLabel}>Start</span>
              </div>
              <div style={styles.stepConnector} />
              <div style={styles.step}>
                <img src={youngYoda} alt="Learn" style={styles.stepIcon} />
                <span style={styles.stepLabel}>Learn</span>
              </div>
              <div style={styles.stepConnector} />
              <div style={styles.step}>
                <img src={oldForce} alt="Grow" style={styles.stepIcon} />
                <span style={styles.stepLabel}>Grow</span>
              </div>
              <div style={styles.stepConnector} />
              <div style={styles.step}>
                <img src={oldLightsaber} alt="Master" style={styles.stepIcon} />
                <span style={styles.stepLabel}>Master</span>
              </div>
            </Box>
          )}
          {userType === 'college' && (
            <Box sx={styles.collegeSection}>
              <img src={commonAppLogo} alt="Common App" style={styles.partnerLogo} />
              <Typography variant="body1" sx={styles.collegeMessage}>
                Your gateway to higher education excellence
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={styles.formSection}>
          <Box sx={styles.formContainer}>
            <Typography variant="h5" sx={styles.formTitle}>{isLogin ? 'Welcome Back' : 'Create Account'}</Typography>
            {errorMessage && <Typography variant="body2" sx={styles.error}>{errorMessage}</Typography>}
            
            <form onSubmit={handleSubmit} style={styles.form}>
              {!isLogin && (
                <Box sx={styles.inputGroup}>
                  <Typography variant="body2" sx={styles.label}>Name</Typography>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    style={styles.input}
                  />
                </Box>
              )}
              <Box sx={styles.inputGroup}>
                <Typography variant="body2" sx={styles.label}>Email</Typography>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={styles.input}
                />
              </Box>
              <Box sx={styles.inputGroup}>
                <Typography variant="body2" sx={styles.label}>Password</Typography>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={styles.input}
                />
                {isLogin && (
                  <Box sx={styles.extraOptions}>
                    <Typography variant="body2" sx={styles.showPassword}>
                      <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={(e) => setShowPassword(e.target.checked)}
                      />
                      Show password
                    </Typography>
                    <Link to="/password-reset" style={styles.forgotPassword}>Forgot password?</Link>
                  </Box>
                )}
              </Box>
              {!isLogin && (
                <Box sx={styles.inputGroup}>
                  <Typography variant="body2" sx={styles.label}>Verify Password</Typography>
                  <input
                    type="password"
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                    required
                    style={styles.input}
                  />
                </Box>
              )}
              <button type="submit" style={styles.button}>{isLogin ? 'Login' : 'Signup'}</button>
              <button 
                onClick={() => setIsLogin(!isLogin)} 
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#00356b',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginTop: '15px',
                  width: '100%',
                  fontSize: '14px'
                }}
              >
                {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Login'}
              </button>
            </form>
          </Box>
          
          {isLoading && (
            <Box sx={styles.loadingOverlay}>
              <Box sx={styles.loadingContent}>
                <div style={styles.loadingSpinner} />
                <Typography variant="body2" sx={styles.loadingText}>{loadingStage}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  background: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
  },
  welcomeSection: {
    flex: 1,
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    position: 'relative',
  },
  welcomeLogo: {
    width: '80px',
    marginBottom: '30px',
  },
  welcomeTitle: {
    fontSize: '36px',
    fontWeight: '600',
    marginBottom: '40px',
    textAlign: 'center',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  progressSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '600px',
    marginTop: '40px',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  stepIcon: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  stepLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(255, 255, 255, 0.9)',
  },
  stepConnector: {
    height: '2px',
    width: '60px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  formSection: {
    width: '450px',
    backgroundColor: 'white',
    padding: '60px 40px',
    position: 'relative',
  },
  formContainer: {
    width: '100%',
  },
  formTitle: {
    fontSize: '24px',
    color: '#1a365d',
    marginBottom: '30px',
    textAlign: 'center',
    fontWeight: '600',
  },
  collegeSection: {
    textAlign: 'center',
    marginTop: '40px',
  },
  partnerLogo: {
    width: '200px',
    marginBottom: '20px',
  },
  collegeMessage: {
    fontSize: '18px',
    color: 'rgba(255, 255, 255, 0.9)',
    maxWidth: '400px',
    lineHeight: '1.6',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  loadingContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  loadingSpinner: {
    width: '40px',
    height: '40px',
    border: '3px solid rgba(0, 0, 0, 0.1)',
    borderTopColor: '#1a365d',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  loadingText: {
    color: '#1a365d',
    fontSize: '16px',
    fontWeight: '500',
  },
  inputGroup: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  input: {
    width: '100%',
    padding: '12px 16px',
    fontSize: '16px',
    border: '1.5px solid #e2e8f0',
    borderRadius: '8px',
    marginBottom: '16px',
    transition: 'border-color 0.2s ease',
    fontFamily: "'Inter', sans-serif",
    '&:focus': {
      borderColor: '#3182ce',
      outline: 'none',
    },
  },
  extraOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
  },
  showPassword: {
    display: 'flex',
    alignItems: 'center',
  },
  forgotPassword: {
    color: '#00356b',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    padding: '14px',
    backgroundColor: '#1a365d',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    fontFamily: "'Inter', sans-serif",
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
  },
  error: {
    color: 'red',
    marginBottom: '10px',
    textAlign: 'center',
  },
};

const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-10px) rotate(3deg); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.8; transform: scale(1.05); }
  }
  
  @keyframes sparkle {
    0%, 100% { filter: brightness(1); }
    50% { filter: brightness(1.3); }
  }
  
  @keyframes gentleFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes gentlePulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.6; }
  }
`;
document.head.appendChild(styleSheet);

// Add font import to your HTML head
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default Login;
