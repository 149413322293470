import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Chip,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Search as SearchIcon,
  Share as ShareIcon,
  LocationOn as LocationIcon,
  CalendarToday as CalendarIcon,
  Group as GroupIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { PageHeader, SectionHeader, FeatureHeader, DescriptiveText, SupportingText } from '../components/ui/typography';
import { cardStyles } from '../styles/theme/components/cards';
import {
  fetchPublicEvents,
  fetchUserCreatedEvents,
  fetchUserRegisteredEvents,
  createEvent,
  registerForEvent,
  cancelRegistration,
} from '../utils/supabase/events';

// Event categories from before
const EVENT_CATEGORIES = [
  'General',
  'Homeschooling Meet-Ups',
  'In-Person Classes',
  'Online Classes',
  'Co-op Invitations',
  'College Webinars',
  'Study Groups',
  'Educational Workshops',
  'Parent Meetings',
  'Social Gatherings',
];

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

const Events = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  // State for tabs
  const [mainTab, setMainTab] = useState(0);
  const [myEventsTab, setMyEventsTab] = useState(0);
  const [timeFilter, setTimeFilter] = useState(0);
  
  // State for events and filters
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  
  // State for create event dialog
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    category: '',
    description: '',
    date: '',
    time: '',
    location: '',
    maxAttendees: '',
    isPublic: true,
  });
  
  // State for notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Memoize tab change handlers
  const handleMainTabChange = (e, newValue) => {
    if (newValue === mainTab) return; // Prevent unnecessary updates
    setMainTab(newValue);
  };

  const handleMyEventsTabChange = (e, newValue) => {
    if (newValue === myEventsTab) return; // Prevent unnecessary updates
    setMyEventsTab(newValue);
  };

  // Fetch events based on current view
  const fetchEvents = async () => {
    setLoading(true);
    try {
      let fetchedEvents = [];
      if (mainTab === 0) { // Explore Events
        console.log('Fetching public events...');
        fetchedEvents = await fetchPublicEvents();
        console.log('Fetched public events:', fetchedEvents);
      } else if (user) { // My Events
        if (myEventsTab === 0) { // Created Events
          console.log('Fetching created events...');
          fetchedEvents = await fetchUserCreatedEvents(user.id);
        } else { // Registered Events
          console.log('Fetching registered events...');
          fetchedEvents = await fetchUserRegisteredEvents(user.id);
        }
      }
      setEvents(fetchedEvents || []);
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: 'Error fetching events', severity: 'error' });
      setEvents([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch events when tabs change or after creating an event
  useEffect(() => {
    console.log('Effect triggered - mainTab:', mainTab, 'myEventsTab:', myEventsTab);
    fetchEvents();
  }, [mainTab, myEventsTab, user?.id]);

  // Filter events based on search and category
  const getFilteredEvents = () => {
    console.log('Filtering events:', events);
    const now = new Date();
    let filteredEvents = [...events];

    // Apply search and category filters
    filteredEvents = filteredEvents.filter(event => {
      const matchesSearch = event.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                           event.description?.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || event.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });

    // Apply time filter
    const eventDate = (event) => {
      if (!event.date || !event.time) return new Date(0);
      const [hours, minutes] = event.time.split(':');
      const date = new Date(event.date);
      date.setHours(parseInt(hours), parseInt(minutes));
      return date;
    };

    if (timeFilter === 0) { // Upcoming
      filteredEvents = filteredEvents.filter(event => eventDate(event) >= now);
    } else { // Past
      filteredEvents = filteredEvents.filter(event => eventDate(event) < now);
    }

    console.log('Filtered events:', filteredEvents);
    return filteredEvents;
  };

  const handleCreateEvent = async () => {
    try {
      console.log('Creating new event with data:', {
        ...newEvent,
        userId: user.id,
      });
      
      const createdEvent = await createEvent({
        ...newEvent,
        userId: user.id,
      });
      
      console.log('Event created successfully:', createdEvent);
      
      setOpenCreateDialog(false);
      setSnackbar({
        open: true,
        message: 'Event created successfully!',
        severity: 'success',
      });
      
      // Reset form
      setNewEvent({
        title: '',
        category: '',
        description: '',
        date: '',
        time: '',
        location: '',
        maxAttendees: '',
        isPublic: true,
      });
      
      // Set tab to "My Events" -> "Created Events"
      setMainTab(1);
      setMyEventsTab(0);
      
      // Immediately fetch events to update the list
      console.log('Fetching updated events list...');
      await fetchEvents();
      
    } catch (error) {
      console.error('Error creating event:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to create event. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleRegisterForEvent = async (eventId) => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      await registerForEvent(eventId, user.id);
      setSnackbar({
        open: true,
        message: 'Successfully registered for event!',
        severity: 'success',
      });
      fetchEvents();
    } catch (error) {
      console.error('Error registering for event:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to register for event. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCancelRegistration = async (eventId) => {
    try {
      await cancelRegistration(eventId, user.id);
      setSnackbar({
        open: true,
        message: 'Registration cancelled successfully!',
        severity: 'success',
      });
      fetchEvents();
    } catch (error) {
      console.error('Error cancelling registration:', error);
      setSnackbar({
        open: true,
        message: 'Failed to cancel registration. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleShareEvent = (eventId) => {
    const eventUrl = `${window.location.origin}/events/${eventId}`;
    navigator.clipboard.writeText(eventUrl);
    setSnackbar({
      open: true,
      message: 'Event link copied to clipboard!',
      severity: 'success',
    });
  };

  // Check if user is registered for an event
  const isRegistered = (event) => {
    return event.registrations?.some(reg => reg.user_id === user?.id);
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Events
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Discover and join homeschool events in your area. Connect with other families, learn together, and build community.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        {/* Tabs Section */}
        <Paper 
          elevation={0}
          sx={{
            ...cardStyles.feature,
            mb: 'var(--spacing-4)',
          }}
        >
          <Tabs 
            value={mainTab} 
            onChange={handleMainTabChange}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: 'var(--font-size-base)',
                fontWeight: 500,
                color: 'hsl(var(--text-secondary))',
                '&.Mui-selected': {
                  color: 'hsl(var(--brand-primary))',
                  fontWeight: 600
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'hsl(var(--brand-primary))'
              }
            }}
          >
            <Tab label="Explore Events" />
            <Tab label="My Events" />
          </Tabs>
        </Paper>

        {mainTab === 1 && (
          <Paper 
            elevation={0}
            sx={{
              ...cardStyles.feature,
              mb: 'var(--spacing-4)',
            }}
          >
            <Tabs 
              value={myEventsTab} 
              onChange={handleMyEventsTabChange}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: 'var(--font-size-sm)',
                  fontWeight: 500,
                  color: 'hsl(var(--text-secondary))',
                  '&.Mui-selected': {
                    color: 'hsl(var(--brand-primary))',
                    fontWeight: 600
                  }
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'hsl(var(--brand-primary))'
                }
              }}
            >
              <Tab label="Created Events" />
              <Tab label="Attending Events" />
            </Tabs>
          </Paper>
        )}

        {/* Filters Section */}
        <Paper 
          elevation={0}
          sx={{
            ...cardStyles.feature,
            mb: 'var(--spacing-4)',
          }}
        >
          <Box sx={{ p: 'var(--spacing-4)' }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  placeholder="Search events..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'hsl(var(--text-secondary))', mr: 1 }} />,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 'var(--radius-lg)',
                      '& fieldset': {
                        borderColor: 'hsl(var(--border))',
                      },
                      '&:hover fieldset': {
                        borderColor: 'hsl(var(--border-hover))',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'hsl(var(--brand-primary))',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    sx={{
                      borderRadius: 'var(--radius-lg)',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'hsl(var(--border))',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'hsl(var(--border-hover))',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'hsl(var(--brand-primary))',
                      },
                    }}
                  >
                    <MenuItem value="all">All Categories</MenuItem>
                    {EVENT_CATEGORIES.map((category) => (
                      <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenCreateDialog(true)}
                  sx={{
                    backgroundColor: 'hsl(var(--brand-primary))',
                    color: 'hsl(var(--background))',
                    borderRadius: 'var(--radius-lg)',
                    textTransform: 'none',
                    py: 'var(--spacing-2)',
                    '&:hover': {
                      backgroundColor: 'hsl(var(--brand-primary-dark))',
                    },
                  }}
                >
                  Create Event
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {/* Events Grid */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 'var(--spacing-6)' }}>
            <CircularProgress sx={{ color: 'hsl(var(--brand-primary))' }} />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {getFilteredEvents().map(event => (
              <Grid item xs={12} md={6} lg={4} key={event.id}>
                <Paper
                  elevation={0}
                  onClick={() => navigate(`/events/${event.id}`)}
                  sx={{ 
                    ...cardStyles.feature,
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 'var(--shadow-lg)',
                      borderColor: 'hsl(var(--brand-primary))',
                    },
                  }}
                >
                  <Box sx={{ p: 'var(--spacing-4)' }}>
                    <Box sx={{ mb: 'var(--spacing-3)' }}>
                      <FeatureHeader sx={{ mb: 'var(--spacing-2)' }}>
                        {event.title}
                      </FeatureHeader>
                      <Chip
                        label={event.category}
                        size="small"
                        sx={{
                          backgroundColor: 'hsl(var(--brand-primary-light))',
                          color: 'hsl(var(--brand-primary))',
                          fontWeight: 600,
                          borderRadius: 'var(--radius-full)',
                        }}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 'var(--spacing-2)' }}>
                      <CalendarIcon sx={{ mr: 'var(--spacing-2)', color: 'hsl(var(--text-secondary))', fontSize: 20 }} />
                      <SupportingText>
                        {new Date(event.date).toLocaleDateString()} at {event.time}
                      </SupportingText>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 'var(--spacing-2)' }}>
                      <LocationIcon sx={{ mr: 'var(--spacing-2)', color: 'hsl(var(--text-secondary))', fontSize: 20 }} />
                      <SupportingText>
                        {event.location}
                      </SupportingText>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 'var(--spacing-2)' }}>
                      <GroupIcon sx={{ mr: 'var(--spacing-2)', color: 'hsl(var(--text-secondary))', fontSize: 20 }} />
                      <SupportingText>
                        {event.current_attendees} / {event.max_attendees} attendees
                      </SupportingText>
                    </Box>
                  </Box>

                  <Box 
                    sx={{
                      mt: 'auto',
                      borderTop: '1px solid',
                      borderColor: 'hsl(var(--border))',
                      p: 'var(--spacing-4)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRegisterForEvent(event.id);
                      }}
                      sx={{
                        backgroundColor: 'hsl(var(--brand-primary))',
                        color: 'hsl(var(--background))',
                        borderRadius: 'var(--radius-lg)',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: 'hsl(var(--brand-primary-dark))',
                        },
                      }}
                    >
                      Register
                    </Button>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShareEvent(event.id);
                      }}
                      sx={{
                        color: 'hsl(var(--text-secondary))',
                        '&:hover': {
                          backgroundColor: 'hsl(var(--background-alt))',
                        },
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            borderRadius: 'var(--radius-lg)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Create Event Dialog */}
      <Dialog 
        open={openCreateDialog} 
        onClose={() => setOpenCreateDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle 
          sx={{ 
            pb: 1,
            fontFamily: "'Inter', sans-serif",
            fontSize: '20px',
            fontWeight: 600,
            color: '#2d3748',
          }}
        >
          Create New Event
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Event Title"
              fullWidth
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
              }}
            />
            
            <FormControl fullWidth>
              <InputLabel sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
                Category
              </InputLabel>
              <Select
                value={newEvent.category}
                onChange={(e) => setNewEvent({ ...newEvent, category: e.target.value })}
                label="Category"
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                }}
              >
                {EVENT_CATEGORIES.map(category => (
                  <MenuItem 
                    key={category} 
                    value={category}
                    sx={{
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '14px',
                    }}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
              }}
            />
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={newEvent.date}
                  onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '14px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Time"
                  type="time"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={newEvent.time}
                  onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '14px',
                    },
                  }}
                />
              </Grid>
            </Grid>
            
            <TextField
              label="Location"
              fullWidth
              value={newEvent.location}
              onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
              }}
            />
            
            <TextField
              label="Maximum Attendees"
              type="number"
              fullWidth
              value={newEvent.maxAttendees}
              onChange={(e) => setNewEvent({ ...newEvent, maxAttendees: e.target.value })}
              sx={{
                '& .MuiInputLabel-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-root': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                },
              }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={newEvent.isPublic}
                  onChange={(e) => setNewEvent({ ...newEvent, isPublic: e.target.checked })}
                />
              }
              label="Make this event public"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                  color: '#4a5568',
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={() => setOpenCreateDialog(false)}
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 500,
              fontSize: '14px',
              textTransform: 'none',
              color: '#4a5568',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateEvent}
            disabled={!newEvent.title || !newEvent.date || !newEvent.location}
            sx={{ 
              backgroundColor: '#00356b',
              fontFamily: "'Inter', sans-serif",
              fontWeight: 500,
              fontSize: '14px',
              textTransform: 'none',
              '&:hover': { 
                backgroundColor: '#002548',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e2e8f0',
                color: '#718096',
              },
            }}
          >
            Create Event
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Events; 