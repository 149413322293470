import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Container, 
    Typography, 
    Paper, 
    Box, 
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    TextField,
    IconButton,
    Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageHeader, SectionHeader, DescriptiveText, BodyText, SupportingText } from '../components/ui/typography';
import { useAuth } from '../utils/AuthContext';
import { supabase } from '../supabase/utils/supabaseClient.ts';

const StateRegulationOverview = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [userState, setUserState] = useState('California');
    const [expanded, setExpanded] = useState('overview');
    const [viewedSections, setViewedSections] = useState({
        overview: false,
        legalAvenues: false,
        recordKeeping: false,
        curriculum: false
    });
    const [viewedRecordKeepingSections, setViewedRecordKeepingSections] = useState({
        withdrawal: false,
        attendance: false,
        instructors: false,
        courses: false,
        immunization: false
    });
    const [stateData, setStateData] = useState({
        overview: '',
        legalAvenues: [],
        recordKeeping: '',
        curriculum: ''
    });
    const [userData, setUserData] = useState(null);

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    useEffect(() => {
        if (user) {
            fetchUserData();
        }
        // This will be replaced with actual API call to fetch state data
        if (userState === 'California') {
            setStateData({
                overview: `Homeschooling in California is a legally recognized educational option, but it comes with specific regulations and compliance requirements that parents must follow. In general, California law requires that all children between the ages of 6 and 18 be enrolled in a full-time public or private school, or receive instruction that is "at least equivalent" to what is taught in public schools.`,
                legalAvenues: [
                    {
                        title: 'Establishing a Private Home-Based School',
                        description: 'Parents may file a Private School Affidavit (PSA) annually with the California Department of Education (CDE). By doing so, the home becomes a private school, and the parent (or another qualified person) serves as the instructor.'
                    },
                    {
                        title: 'Private School Satellite Program (PSP)',
                        description: 'Another pathway is to enroll the child in a private school satellite program. This option allows families to join a private school that oversees the homeschooling process and often provides curriculum guidance and record-keeping services.'
                    },
                    {
                        title: 'Public Charter Schools or Independent Study Programs',
                        description: 'California has a robust network of charter schools and independent study programs that cater specifically to homeschooling families.'
                    }
                ],
                recordKeeping: 'Regardless of the chosen homeschooling route, maintaining thorough records is a key aspect of compliance. When families file a Private School Affidavit, they must keep attendance records, immunization records (or exemption documentation), and examples of student work.',
                curriculum: 'California does not mandate a specific curriculum for private home-based schools, so parents are free to choose instructional materials that align with their educational philosophy and goals. However, the law does require that home-based private schools cover the main subject areas offered in public schools.'
            });
        }
    }, [user, userState]);

    const fetchUserData = async () => {
        try {
            const { data, error } = await supabase
                .from('account_profiles')
                .select('*')
                .eq('id', user?.id)
                .single();

            if (error) throw error;
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleStateChange = (event) => {
        setUserState(event.target.value);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (!viewedSections[panel] && !isExpanded) {
            setViewedSections(prev => ({
                ...prev,
                [panel]: true
            }));
        }
    };

    const getCompletedRecordKeepingSections = () => {
        return Object.values(viewedRecordKeepingSections).filter(Boolean).length;
    };

    const renderSectionHeader = (title, sectionId) => {
        if (sectionId === 'recordKeeping') {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: '#2d3748', fontWeight: 600 }}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: '#4a5568' }}>
                        {getCompletedRecordKeepingSections()}/5
                    </Typography>
                </Box>
            );
        }
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ color: '#2d3748', fontWeight: 600 }}>
                    {title}
                </Typography>
                {viewedSections[sectionId] ? 
                    <CheckCircleIcon sx={{ color: '#48bb78' }} /> :
                    <RadioButtonUncheckedIcon sx={{ color: '#a0aec0' }} />
                }
            </Box>
        );
    };

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'hsl(var(--background))' }}>
            {/* Back Button Section */}
            <Box sx={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0', py: 2, mb: 3 }}>
                <Container maxWidth="lg">
                    <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate('/compliance')}
                        sx={{
                            color: '#00356b',
                            fontWeight: 500,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 53, 107, 0.08)',
                            },
                        }}
                    >
                        Back to Compliance
                    </Button>
                </Container>
            </Box>
            {/* Hero Section */}
            <Box 
                sx={{ 
                    position: 'relative',
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, hsl(var(--brand-primary-light)) 0%, hsl(var(--background)) 100%)`,
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        inset: 0,
                        backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(0,0,0,0.05) 1px, transparent 0)',
                        backgroundSize: '20px 20px',
                        opacity: 0.1,
                    },
                    pt: 'var(--spacing-8)',
                    pb: 'var(--spacing-6)',
                }}
            >
                <Container 
                    maxWidth="var(--container-max-width)"
                    sx={{ 
                        position: 'relative',
                        px: 'var(--container-padding-x)',
                        py: 'var(--container-padding-y)',
                        '@media (max-width: 768px)': {
                            px: 'var(--container-padding-x-mobile)',
                        },
                    }}
                >
                    <Box>
                        <PageHeader>State Regulation Overview</PageHeader>
                        <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
                            Learn about homeschooling regulations and requirements in your state.
                        </DescriptiveText>
                    </Box>
                </Container>
            </Box>

            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: '#f8fafc' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="state-select-label">Select State</InputLabel>
                            <Select
                                labelId="state-select-label"
                                value={userState}
                                label="Select State"
                                onChange={handleStateChange}
                                sx={{
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#00356b',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#00356b',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#00356b',
                                    },
                                }}
                            >
                                {states.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="body1" sx={{ color: '#4a5568' }}>
                            View homeschool regulations for your state
                        </Typography>
                    </Box>
                </Paper>

                <Box sx={{ mb: 4 }}>
                    <Accordion 
                        expanded={expanded === 'overview'} 
                        onChange={handleAccordionChange('overview')}
                        sx={{ 
                            backgroundColor: '#ffffff',
                            '&:before': { display: 'none' },
                            boxShadow: 3
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ 
                                borderBottom: expanded === 'overview' ? '1px solid #e2e8f0' : 'none',
                                backgroundColor: expanded === 'overview' ? '#f8fafc' : '#ffffff'
                            }}
                        >
                            {renderSectionHeader('Overview', 'overview')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph sx={{ color: '#4a5568' }}>
                                {stateData.overview}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion 
                        expanded={expanded === 'legalAvenues'} 
                        onChange={handleAccordionChange('legalAvenues')}
                        sx={{ 
                            backgroundColor: '#ffffff',
                            '&:before': { display: 'none' },
                            boxShadow: 3,
                            mt: 2
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ 
                                borderBottom: expanded === 'legalAvenues' ? '1px solid #e2e8f0' : 'none',
                                backgroundColor: expanded === 'legalAvenues' ? '#f8fafc' : '#ffffff'
                            }}
                        >
                            {renderSectionHeader('Legal Avenues for Homeschooling', 'legalAvenues')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {stateData.legalAvenues.map((avenue, index) => (
                                <Box key={index} sx={{ mb: 3 }}>
                                    <Typography variant="subtitle1" gutterBottom sx={{ color: '#2d3748', fontWeight: 500 }}>
                                        {avenue.title}
                                    </Typography>
                                    <Typography paragraph sx={{ color: '#4a5568' }}>
                                        {avenue.description}
                                    </Typography>
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion 
                        expanded={expanded === 'recordKeeping'} 
                        onChange={handleAccordionChange('recordKeeping')}
                        sx={{ 
                            backgroundColor: '#ffffff',
                            '&:before': { display: 'none' },
                            boxShadow: 3,
                            mt: 2
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ 
                                borderBottom: expanded === 'recordKeeping' ? '1px solid #e2e8f0' : 'none',
                                backgroundColor: expanded === 'recordKeeping' ? '#f8fafc' : '#ffffff'
                            }}
                        >
                            {renderSectionHeader('Record-Keeping and Compliance', 'recordKeeping')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                                {stateData.recordKeeping}
                            </Typography>
                            <RecordKeepingDetails 
                                viewedRecordKeepingSections={viewedRecordKeepingSections}
                                setViewedRecordKeepingSections={setViewedRecordKeepingSections}
                                userData={userData}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion 
                        expanded={expanded === 'curriculum'} 
                        onChange={handleAccordionChange('curriculum')}
                        sx={{ 
                            backgroundColor: '#ffffff',
                            '&:before': { display: 'none' },
                            boxShadow: 3,
                            mt: 2
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ 
                                borderBottom: expanded === 'curriculum' ? '1px solid #e2e8f0' : 'none',
                                backgroundColor: expanded === 'curriculum' ? '#f8fafc' : '#ffffff'
                            }}
                        >
                            {renderSectionHeader('Curricular Expectations', 'curriculum')}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography paragraph sx={{ color: '#4a5568' }}>
                                {stateData.curriculum}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Container>
        </Box>
    );
};

const RecordKeepingDetails = ({ 
    viewedRecordKeepingSections, 
    setViewedRecordKeepingSections,
    userData 
}) => {
    const [expandedDetail, setExpandedDetail] = useState(false);

    const handleDetailChange = (panel) => (event, isExpanded) => {
        setExpandedDetail(isExpanded ? panel : false);
        if (!viewedRecordKeepingSections[panel] && !isExpanded) {
            setViewedRecordKeepingSections(prev => ({
                ...prev,
                [panel]: true
            }));
        }
    };

    const renderSubsectionHeader = (title, sectionId) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#2d3748' }}>
                {title}
            </Typography>
            {viewedRecordKeepingSections[sectionId] ? 
                <CheckCircleIcon sx={{ color: '#48bb78', fontSize: 20 }} /> :
                <RadioButtonUncheckedIcon sx={{ color: '#a0aec0', fontSize: 20 }} />
            }
        </Box>
    );

    const withdrawalTemplate = `Subject line: Letter of Withdrawal of student

Dear [insert administrators name]:

Pursuant to California Education Code § 48222, this letter is formally notifying you of my intention to withdraw my child, [insert child's full name], from your school as of [insert withdrawal date] in order to homeschool them. 

Additionally, as the administrator of said homeschool, I am requesting that you provide me with your cumulative records for [insert child's full name].

Should you have any questions, please provide them in writing and I will be happy to provide them to the appropriate agency.

Best,
[Insert parent's full name]`;

    return (
        <Box sx={{ mt: 2 }}>
            <Accordion 
                expanded={expandedDetail === 'withdrawal'}
                onChange={handleDetailChange('withdrawal')}
                sx={{ mb: 2, boxShadow: 2 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                        backgroundColor: expandedDetail === 'withdrawal' ? '#f8fafc' : '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}
                >
                    {renderSubsectionHeader('Letter of Withdrawal', 'withdrawal')}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        If your child currently attends a public school, you are required to formally notify the public school of your intention to homeschool and to request their records for your child. If you're already homeschooling, no need to submit said letter.
                    </Typography>
                    <Box sx={{ 
                        backgroundColor: '#f8fafc', 
                        p: 3, 
                        borderRadius: 1,
                        border: '1px solid #e2e8f0'
                    }}>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                                fontFamily: 'monospace',
                                whiteSpace: 'pre-wrap',
                                mb: 2
                            }}
                        >
                            {withdrawalTemplate}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => navigator.clipboard.writeText(withdrawalTemplate)}
                            sx={{ 
                                mt: 2,
                                color: '#00356b',
                                borderColor: '#00356b',
                                '&:hover': {
                                    borderColor: '#00356b',
                                    backgroundColor: 'rgba(0, 53, 107, 0.04)'
                                }
                            }}
                        >
                            Copy Template
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion 
                expanded={expandedDetail === 'attendance'}
                onChange={handleDetailChange('attendance')}
                sx={{ mb: 2, boxShadow: 2 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                        backgroundColor: expandedDetail === 'attendance' ? '#f8fafc' : '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}
                >
                    {renderSubsectionHeader('Record of Attendance', 'attendance')}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        California Education Code Section 48222 states: "The attendance of the pupils shall be kept by private school authorities in a register, and the record of attendance shall indicate clearly every absence of the pupil from school for a half day or more during each day that school is maintained during the year."
                    </Typography>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        California requires that all schools must provide at least 180 days of instruction per year.
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        gap: 2,
                        mb: 3
                    }}>
                        <Button
                            variant="contained"
                            startIcon={<CalendarMonthIcon />}
                            sx={{ 
                                backgroundColor: '#00356b',
                                '&:hover': {
                                    backgroundColor: '#002548'
                                }
                            }}
                        >
                            Update Attendance Calendar
                        </Button>
                    </Box>
                    {/* Placeholder for attendance visualization */}
                    <Box sx={{ 
                        backgroundColor: '#f8fafc',
                        p: 3,
                        borderRadius: 1,
                        border: '1px solid #e2e8f0',
                        minHeight: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography sx={{ color: '#4a5568' }}>
                            Attendance Visualization Coming Soon
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion 
                expanded={expandedDetail === 'instructors'}
                onChange={handleDetailChange('instructors')}
                sx={{ mb: 2, boxShadow: 2 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                        backgroundColor: expandedDetail === 'instructors' ? '#f8fafc' : '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}
                >
                    {renderSubsectionHeader('Record of Instructor(s)', 'instructors')}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        California requires that all schools, including homeschools, maintain records of their instructors and their qualifications and credentials. For the purposes of homeschools, you only need a record of one instructor, which can be the parent.
                    </Typography>
                    <Box sx={{ 
                        backgroundColor: '#f8fafc',
                        p: 3,
                        borderRadius: 1,
                        border: '1px solid #e2e8f0'
                    }}>
                        <Box sx={{ display: 'grid', gap: 2 }}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={userData?.name || '[Auto-populated]'}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={userData?.email || '[Auto-populated]'}
                            />
                            <TextField
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={userData?.phone_number || '[Auto-populated]'}
                            />
                            <TextField
                                label="Address"
                                variant="outlined"
                                fullWidth
                                disabled
                                value={userData ? `${userData.street_address}, ${userData.city}, ${userData.state} ${userData.zip}` : '[Auto-populated]'}
                            />
                            <Button
                                variant="outlined"
                                component="label"
                                startIcon={<FileUploadIcon />}
                                sx={{ 
                                    color: '#00356b',
                                    borderColor: '#00356b',
                                    '&:hover': {
                                        borderColor: '#00356b',
                                        backgroundColor: 'rgba(0, 53, 107, 0.04)'
                                    }
                                }}
                            >
                                Upload Credentials
                                <input type="file" hidden />
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion 
                expanded={expandedDetail === 'courses'}
                onChange={handleDetailChange('courses')}
                sx={{ mb: 2, boxShadow: 2 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                        backgroundColor: expandedDetail === 'courses' ? '#f8fafc' : '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}
                >
                    {renderSubsectionHeader('Courses and Curriculum', 'courses')}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        California requires that homeschools generally provide instruction in courses commonly taught in public schools. Additionally, schools must maintain records of what courses each student enrolls in.
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        gap: 2,
                        mb: 3
                    }}>
                        <Button
                            variant="contained"
                            startIcon={<ArticleIcon />}
                            sx={{ 
                                backgroundColor: '#00356b',
                                '&:hover': {
                                    backgroundColor: '#002548'
                                }
                            }}
                        >
                            View/Edit Transcripts
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ 
                                color: '#00356b',
                                borderColor: '#00356b',
                                '&:hover': {
                                    borderColor: '#00356b',
                                    backgroundColor: 'rgba(0, 53, 107, 0.04)'
                                }
                            }}
                        >
                            View Common Courses
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion 
                expanded={expandedDetail === 'immunization'}
                onChange={handleDetailChange('immunization')}
                sx={{ mb: 2, boxShadow: 2 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ 
                        backgroundColor: expandedDetail === 'immunization' ? '#f8fafc' : '#ffffff',
                        borderBottom: '1px solid #e2e8f0'
                    }}
                >
                    {renderSubsectionHeader('Immunization Records', 'immunization')}
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph sx={{ color: '#4a5568', mb: 3 }}>
                        California requires that every school, including homeschools, maintain immunization records, or records of exemptions, for all of their students. YourEDU does not currently allow for uploading of student's immunization records.
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{ 
                            color: '#00356b',
                            borderColor: '#00356b',
                            '&:hover': {
                                borderColor: '#00356b',
                                backgroundColor: 'rgba(0, 53, 107, 0.04)'
                            }
                        }}
                    >
                        Learn More About Requirements
                    </Button>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default StateRegulationOverview; 