import * as colors from './colors'
import * as typography from './typography'
import * as spacing from './spacing'
import * as breakpoints from './breakpoints'
import { cssVariables } from './css-variables'

const theme = {
  colors: colors.colors,
  getColor: colors.getColor,
  hslToVar: colors.hslToVar,
  
  // Typography
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontWeight: typography.fontWeight,
  letterSpacing: typography.letterSpacing,
  lineHeight: typography.lineHeight,
  textStyles: typography.textStyles,
  
  // Spacing
  spacing: spacing.spacing,
  layout: spacing.layout,
  getSpacing: spacing.getSpacing,
  
  // Breakpoints
  breakpoints: breakpoints.breakpoints,
  mediaQueries: breakpoints.mediaQueries,
  getBreakpoint: breakpoints.getBreakpoint,
  createMediaQuery: breakpoints.createMediaQuery,

  // CSS Variables
  cssVariables: cssVariables,
}

// Inject CSS variables into :root
if (typeof document !== 'undefined') {
  const style = document.createElement('style')
  style.innerHTML = cssVariables.root
  document.head.appendChild(style)
}

export default theme 