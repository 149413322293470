import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Box } from '@mui/material';
import { PageHeader, SectionHeader, FeatureHeader, BodyText, SupportingText, DescriptiveText } from '../components/ui/typography';
import { cardStyles } from '../styles/theme/components/cards';
import {
  Work as CareerIcon,
  Business as InternshipIcon,
  Description as ResumeIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

const Careers = () => {
  const navigate = useNavigate();

  const careerModules = [
    {
      id: 'resume',
      title: 'Resume Builder',
      description: 'Create and manage your professional resume and portfolio.',
      icon: ResumeIcon,
      path: '/ledger',
      color: 'hsl(var(--secondary-orange))',
      bgColor: 'hsl(var(--secondary-orange-light))',
      stats: [
        { label: 'Templates', value: '10+' },
        { label: 'Skills', value: '50+' },
      ],
    },
    {
      id: 'internships',
      title: 'Internships',
      description: 'Find and apply for internship opportunities in your field of interest.',
      icon: InternshipIcon,
      path: '/internships',
      color: 'hsl(var(--secondary-green))',
      bgColor: 'hsl(var(--secondary-green-light))',
      highlight: 'Featured',
      stats: [
        { label: 'Opportunities', value: '50+' },
        { label: 'Partners', value: '30+' },
      ],
    },
    {
      id: 'careerExploration',
      title: 'Career Exploration',
      description: 'Discover potential career paths and learn about different industries.',
      icon: CareerIcon,
      path: '/career-exploration',
      color: 'hsl(var(--brand-primary))',
      bgColor: 'hsl(var(--brand-primary-light))',
      stats: [
        { label: 'Career Paths', value: '100+' },
        { label: 'Industries', value: '20+' },
      ],
    },
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ ...cardStyles.hero, pt: 'var(--spacing-8)', pb: 'var(--spacing-6)' }}>
        <Container 
          maxWidth="var(--container-max-width)"
          sx={{ 
            position: 'relative',
            px: 'var(--container-padding-x)',
            py: 'var(--container-padding-y)',
            '@media (--tablet)': {
              px: 'var(--container-padding-x-mobile)',
            },
          }}
        >
          <PageHeader 
            sx={{ 
              mb: 'var(--spacing-2)',
            }}
          >
            Career Center
          </PageHeader>
          <DescriptiveText sx={{ maxWidth: 'var(--text-max-width)' }}>
            Explore career paths, find internship opportunities, and build your professional profile.
            Your journey to a successful career starts here.
          </DescriptiveText>
        </Container>
      </Box>

      {/* Main Content */}
      <Container 
        maxWidth="var(--container-max-width)"
        sx={{ 
          px: 'var(--container-padding-x)',
          py: 'var(--spacing-6)',
          '@media (--tablet)': {
            px: 'var(--container-padding-x-mobile)',
          },
        }}
      >
        <Grid container spacing={4}>
          {careerModules.map((module) => (
            <Grid item xs={12} md={4} key={module.id}>
              <Paper
                elevation={0}
                onClick={() => navigate(module.path)}
                sx={{
                  ...cardStyles.feature,
                  height: '100%',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: module.color,
                    transform: 'translateY(-4px)',
                    boxShadow: 'var(--shadow-lg)',
                    '& .arrow-icon': {
                      transform: 'translateX(4px)',
                    },
                    '& .icon-container': {
                      transform: 'scale(1.1)',
                    }
                  },
                }}
              >
                {/* Card Header */}
                <Box sx={{ p: 'var(--spacing-6)' }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 'var(--spacing-4)', mb: 'var(--spacing-4)' }}>
                    <Box
                      className="icon-container"
                      sx={{
                        width: 'var(--spacing-12)',
                        height: 'var(--spacing-12)',
                        borderRadius: 'var(--radius-lg)',
                        backgroundColor: module.bgColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'transform 0.2s ease-in-out',
                        flexShrink: 0,
                      }}
                    >
                      <module.icon sx={{ fontSize: 'var(--spacing-6)', color: module.color }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 'var(--spacing-2)', mb: 'var(--spacing-2)' }}>
                        <FeatureHeader sx={{ color: 'hsl(var(--text-primary))' }}>
                          {module.title}
                        </FeatureHeader>
                        {module.highlight && (
                          <Box
                            sx={{
                              px: 'var(--spacing-2)',
                              py: 'var(--spacing-1)',
                              borderRadius: 'var(--radius-full)',
                              backgroundColor: module.bgColor,
                              color: module.color,
                              fontSize: 'var(--font-size-xs)',
                              fontWeight: 600,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {module.highlight}
                          </Box>
                        )}
                      </Box>
                      <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                        {module.description}
                      </SupportingText>
                    </Box>
                  </Box>

                  {/* Stats Section */}
                  {module.stats && (
                    <Box 
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 'var(--spacing-3)',
                        mt: 'var(--spacing-4)',
                      }}
                    >
                      {module.stats.map((stat) => (
                        <Box
                          key={stat.label}
                          sx={{
                            p: 'var(--spacing-3)',
                            borderRadius: 'var(--radius-lg)',
                            backgroundColor: 'hsl(var(--background-alt))',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 'var(--spacing-1)',
                          }}
                        >
                          <Box 
                            sx={{ 
                              fontSize: 'var(--font-size-xl)',
                              fontWeight: 600,
                              color: module.color,
                              lineHeight: 1,
                            }}
                          >
                            {stat.value}
                          </Box>
                          <SupportingText sx={{ color: 'hsl(var(--text-secondary))' }}>
                            {stat.label}
                          </SupportingText>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {/* Card Footer */}
                <Box 
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'hsl(var(--border))',
                    p: 'var(--spacing-4)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SupportingText 
                    sx={{ 
                      color: module.color,
                      fontWeight: 600,
                      fontSize: 'var(--font-size-sm)',
                    }}
                  >
                    Learn More
                  </SupportingText>
                  <ArrowForwardIcon 
                    className="arrow-icon"
                    sx={{ 
                      color: module.color,
                      transition: 'transform 0.2s ease-in-out',
                      fontSize: 'var(--spacing-5)',
                    }} 
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Info Section */}
        <Paper
          elevation={0}
          sx={{
            ...cardStyles.info,
            mt: 'var(--spacing-6)',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <FeatureHeader sx={{ mb: 2, color: 'hsl(var(--brand-primary))' }}>
                Why Career Planning Matters
              </FeatureHeader>
              <BodyText>
                Early career planning and exploration can help you make informed decisions about your future.
                Our tools and resources are designed to help you discover your interests, gain valuable experience,
                and build a strong foundation for your professional journey.
              </BodyText>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default Careers; 